import React, { useState } from 'react';
import { DashboardLayout } from '../DashboardLayout/DashboardLayout';
import { CMSNavbarComponent } from '../navbar/CMSnavcomponent';
import { AiTwotoneSetting } from "react-icons/ai";
import { CiCirclePlus } from "react-icons/ci";
import { HiOutlineEye } from "react-icons/hi";
import { MdOutlineEdit, MdKeyboardArrowUp, MdKeyboardArrowDown, MdDelete } from "react-icons/md";
import { FiSave } from "react-icons/fi";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Select from 'react-select';
import { BannersSliders } from './BannersSliders';
const Carousels = ({updatedData}) => {
  const options = [
    { value: 'apple', label: 'Apple' },
    { value: 'banana', label: 'Banana' },
    { value: 'orange', label: 'Orange' },
    { value: 'grape', label: 'Grape' },
  ];

  const [formData, setFormData] = useState({
    radio: '',
    selectedOption: null,
    selectedOptions: [],
  });

  const handleSelectChange = (selectedOptions) => {
    setFormData({ ...formData, selectedOptions });
  };

  const handleSelectnoramChange = (selectedOption) => {
    setFormData({ ...formData, selectedOption });
  };

  const handleRadioChange = (event) => {
    setFormData({ ...formData, radio: event.target.value });
  };
  const [formSubmitted, setFormSubmitted] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    setFormSubmitted(true);
  };
  return (
    <div>
      <div className='text-block-end'>
        <div className='text-block-border'>
          <h5 className='dashboard-subtitle'>Carousel</h5>
          <div className='d-flex gap-4'>
            <AiTwotoneSetting color='#5C5C5C' />
            <CiCirclePlus />
            <HiOutlineEye />
            <MdOutlineEdit />
            <FiSave onClick={handleSubmit}/>
            <MdKeyboardArrowUp />
            <MdKeyboardArrowDown />
            <MdDelete />
          </div>
        </div>
        <div className='p-2'>
          {
          formSubmitted && formData.radio ?<BannersSliders type={formData.radio} />:
            <form>
            <div className='d-flex align-itmes-center gap-4'>
              <div className='form-group'>
                <label>Banner Slider Item</label>
              </div>
              <div className="form-check-inline">
                <label className="form-check-label ari-radio-label">
                  <input
                    type="radio"
                    className="ari-radio"
                    name="radioGroup"
                    value="slide"
                    onChange={handleRadioChange}
                    checked={formData.radio === 'slide'}
                  />
                  Slide
                </label>
              </div>
              <div className="form-check-inline">
                <label className="form-check-label ari-radio-label">
                  <input
                    type="radio"
                    className="ari-radio"
                    name="radioGroup"
                    value="grid"
                    onChange={handleRadioChange}
                    checked={formData.radio === 'grid'}
                  />
                  Grid
                </label>
              </div>
            </div>
            <div className='form-group'>
              <label>Apply To:</label>
              <Select
                options={options}
                value={formData.selectedOption}
                onChange={handleSelectnoramChange}
              />
            </div>
            <div className='form-group'>
              <label>Select Category:</label>
              <Select
                options={options}
                isMulti
                value={formData.selectedOptions}
                onChange={handleSelectChange}
              />
            </div>
          </form>
          }
        </div>
      </div>
    </div>
  );
};

export { Carousels };

import React, { useState, useEffect } from 'react';
import { PopupModal } from '../Components';
import "./Orders.css";
import { MdChevronRight, MdClose, MdOutlineSearch, MdCheck } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import Modal from 'react-bootstrap/Modal';
import axiosConfig from '../Service/axiosConfig'
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import useDebouncedValue from "../Utils/Debounce";

const ModifyOrder = () => {
    const navigate = useNavigate()
    const { order_id } = useParams()
    const location = useLocation()
    const { order_data } = location.state || {};
    console.log(order_data, "order_data");
    const [show, setShow] = useState(false);
    const [page, setPage] = useState(1);
    const handleClose = () => {
        setShow(false)
        setSearch('')
        setPage(1)
    }
    const handleAdd = () => {
        setShow(true)
    }
    const [products, setProducts] = useState([])
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState('')
    const debouncedSearchTerm = useDebouncedValue(search, 500);
    let store_id = JSON.parse(localStorage.getItem('Store'))?.id
    const [order, setOrder] = useState({
        products: [],
        client: '',
        ordered_date: '',
        expected_date_time: '',
        payment_mode: order_data.payment_mode,
        delivery_charges: order_data.delivery_charges,
        packing_charges: order_data.packing_charges,
        packing_charges_offer: order_data.packing_charges_offer,
        delivery_charges_offer: order_data.delivery_charges_offer
    });
    const [count, setCount] = useState(0)
    const [selectedClient, setSelectedClient] = useState(null);
    const [popupType, setpopupType] = useState("")
    const [Error, SetError] = useState("")
    const [message, setMessage] = useState(null)
    const [LoadingButton, setLoadingButton] = useState();
    const GetProducts = async () => {
        setLoading(true)
        try {
            const response = await axiosConfig.get(`catlog/products/?store=${store_id ? store_id : order_data?.store?.id}&page_size=10&page=${page}&name=${debouncedSearchTerm}&is_suspended=false`)
            setCount(response.data.count)
            if (page === 1) {
                setProducts(response.data.results);
            } else {
                setProducts((prev) => {
                    const existingProductIds = new Set(prev.map(prevprod => prevprod.id));
                    const uniqueNewProducts = response.data.results.filter(prod => !existingProductIds.has(prod.id));
                    const nonUniqueProducts = response.data.results.filter(prod => existingProductIds.has(prod.id));
                    console.log(nonUniqueProducts, page, "non unique")
                    return [...prev, ...uniqueNewProducts];
                });
            }
            setLoading(false)
        } catch (error) {
            console.log("Error getting products:", error)
            setLoading(false)
        }
    }

    const GetOrderDetails = async () => {
        try {
            const response = await axiosConfig(`/accounts/order-details/?order=${order_id}&page_size=1000`)
            console.log("Order details : ", response.data.results)
            const products = response.data.results.map((product) => ({
                id: product.product.id,
                min_qty: product.quantity,
                image: product.product.image ? product.product.image.image : '',
                price: product.price,
                name: product.product.name,
                available: product.product.available
            }))
            setOrder((prev) => ({
                ...prev,
                products: products,
                ordered_date: order_data.order_date ? order_data.order_date.split('T')[0] : null,
                expected_date_time: order_data.expected_date_time ? order_data.expected_date_time.split('T')[0] : null,
            }))
            // setSelectedClient({ delivery_agent: response.data.results[0].order.delivery_agent.full_name })
        } catch (error) {
            console.log("Error getting details:", error)
        }
    }

    const handleSelect = (item) => {
        const existingProductIndex = order.products.findIndex((product) => product.id === item.id);
        if (item.available) {
            if (existingProductIndex !== -1) {
                setOrder((prev) => ({
                    ...prev,
                    products: prev.products.filter((product) => product.id !== item.id),
                }));
            } else {
                console.log(order)
                const newProduct = {
                    id: item.id,
                    min_qty: 1,
                    image: item.image ? item.image.image : '',
                    price: item.price,
                    name: item.name,
                    available: item.available
                };
                setOrder((prev) => ({
                    ...prev,
                    products: [...prev.products, newProduct],
                }));
                const existing = {
                    ...order,
                    products: [...order.products, newProduct],
                }
                console.log("Clicked item : ", item.name, existing)
            }
        }
    };

    const HandleBlur = (e, item) => {
        const productIndex = order.products.findIndex((product) => product.id === item.id);
        if (productIndex !== -1) {
            let updatedProducts = [...order.products];
            const value = parseFloat(e.target.value)
            if (value === 0 || isNaN(value)) {
                updatedProducts.splice(productIndex, 1);
            }
            setOrder(prev => ({
                ...prev,
                products: updatedProducts,
            }))
        }
    }

    const HandleQtyChange = (e, item) => {
        const productIndex = order.products.findIndex((product) => product.id === item.id);
        if (productIndex !== -1) {
            let updatedProducts = [...order.products];
            console.log(typeof (e.target.value), "type check")
            const value = parseFloat(e.target.value)
            if (!isNaN(value) && value > -0.01) {
                updatedProducts[productIndex] = {
                    ...updatedProducts[productIndex],
                    min_qty: value,
                };
            } else {
                updatedProducts[productIndex] = {
                    ...updatedProducts[productIndex],
                    min_qty: '',
                };
            }
            setOrder(prev => ({
                ...prev,
                products: updatedProducts,
            }));
        }
    };
    const createOrder = async () => {
        console.log(order)
        if (order.products.length > 0) {
            const product = order.products
                .map((product) => ({
                    id: product.id,
                    min_qty: product.min_qty,
                }))
                .filter((product) => product.min_qty !== 0 && product.min_qty !== '');

            const order_to_post = {
                ...order,
                products: product,
                ordered_date: order.ordered_date,
                expected_date_time: order.expected_date_time,
                delivery_charges: order.delivery_charges,
                packing_charges: order.packing_charges
            };
            if (!order_to_post.ordered_date) {
                delete order_to_post.ordered_date
            }
            if (!order_to_post.expected_date_time) {
                delete order_to_post.expected_date_time
            }
            setLoadingButton(true)
            console.log(order_to_post)
            try {
                const response = await axiosConfig.put(`/accounts/orders/${order_id}/`, order_to_post, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
                console.log("Order updated:", response);
                setpopupType("success");
                setLoadingButton(false)
                setMessage("Order Updated Successfully")
            } catch (error) {
                console.log("Error creating/updating order:", error);
                setLoadingButton(false)
                setpopupType("error");
                if (error.message) {
                    console.log("caught network error")
                    SetError(error.message)
                }
                if (Object.keys(error.response.data)[0] === "The following products are not available in the store:") {
                    SetError("Store is unassigned for this client / Some products are not available in the store")
                }
                if (error.response.data.error) {
                    SetError(error.response.data.error)
                }
                if (error.response.data.message === "Minimum order value is Rs 200. Please add more items to your order.") {
                    SetError("Minimum order value is Rs 200. Please add more items to your order")
                }
            }
        }
    };


    const handleCancel = () => {
        setShow(false);
        setSearch('')
        setPage(1)
        setOrder({
            products: [],
            client: '',
            ordered_date: null,
            expected_date_time: null,
        })
    }



    const handleDelete = (id) => {
        const productIndex = order.products.findIndex((product) => product.id === id);
        const updatedProducts = [...order.products];
        updatedProducts.splice(productIndex, 1);
        setOrder((prev) => ({
            ...prev,
            products: updatedProducts,
        }));
    }

    const handleScroll = (e) => {
        const { clientHeight, scrollTop, scrollHeight } = e.target
        if (clientHeight + scrollTop >= scrollHeight - 1 && Math.ceil(count / 10) > page) {
            setPage(page + 1)
        }
    }

    const handleSearch = async (value) => {
        setSearch(value)
        setPage(1)
    }

    const handleDateChange = (e) => {
        const { name, value } = e.target
        if (name === "Ordered Date") {
            setOrder((prev) => ({
                ...prev,
                ordered_date: value
            }))
        } else if (name === "Delivered Date") {
            setOrder((prev) => ({
                ...prev,
                expected_date_time: value
            }))
        }
    }

    const HandleChange = (e) => {
        const { name, value } = e.target;
        console.log(name, value)
        setOrder({ ...order, [name]: value })
    }
    useEffect(() => {
        const handlePageUnload = (e) => {
            e.preventDefault();
            return (e.returnValue = '');
        };

        window.addEventListener('beforeunload', handlePageUnload, { capture: true });

        return () => {
            window.removeEventListener('beforeunload', handlePageUnload, { capture: true });
        };
    }, []);

    useEffect(() => {
        GetProducts();
    }, [debouncedSearchTerm, page])
    useEffect(() => {
        // ProductMRP(order.products)
        if (order_id) {
            GetOrderDetails();
        }
    }, [])
    console.log(order, "order")
    return (
        <>
            <div className='create-ord-top'>
                <div className='create-ord-left'>
                    <div>Orders</div>
                    <MdChevronRight />
                    <div style={{ color: 'black' }}>Modify Order</div>
                </div>
                <div className='create-ord-right'>
                    <button className='btn btn-white' onClick={() => navigate('/dashboard/orders')}>Cancel</button>
                    <button className={order.products.length > 0 ? 'btn btn-primary' : 'btn btn-inactive'} onClick={createOrder}>
                        {LoadingButton ? <div>
                            <img src={require('../Components/FormParser/images/loader.gif')} alt="Loading..." className="loader-image" />
                        </div> :
                            "Update Order"}
                    </button>
                </div>
            </div>
            <div className='m_20 row'>
                {order_data &&
                    <div className='details-sub col-6'>
                        <label className='f_14_500'>
                            Order ID : <span className='f_14_400'>{order_id}</span>
                        </label>

                        <label className='f_14_500 d-flex align-items-center'>
                            <span style={{ flexShrink: 0 }}>Ordered Date : </span>
                            <input
                                type="date"
                                name="Ordered Date"
                                value={order.ordered_date}
                                onChange={(e) => handleDateChange(e)}
                                className='form-control ms-3'
                            />
                        </label>
                    </div>
                }
                {order_data && order_data.invoice &&
                    <div className='details-sub col-6'>
                        <label className='f_14_500'>
                            Invoice ID : <span className='f_14_400'>{order_data.invoice.invoice_id}</span>
                        </label>

                        <label className='f_14_500 d-flex align-items-center'>
                            <span style={{ flexShrink: 0 }}>Delivered Date :</span>
                            <input
                                type="date"
                                name="Delivered Date"
                                value={order.expected_date_time}
                                onChange={(e) => handleDateChange(e)}
                                className='form-control ms-3'
                            />
                        </label>
                    </div>
                }
            </div>
            <div className='row m_20'>
                <div className='col-6'>
                    <p>Client</p>
                    <p className='delivery-agent'>
                        {order_data ? order_data.user?.full_name : 'No user Assigned'}
                    </p>
                    {/* <input
                        placeholder="Client"
                        // options={clients}
                        value={order_data.user.full_name}
                        onChange={(selectedOption) => handleClientChange(selectedOption, "Client")}
                        className='f_13_500'
                        isDisabled={order_id ? true : false}
                    /> */}
                    <div className='create-ord-card'>
                        <div className='create-card-top'>
                            <div className='f_14_500'>Items {order.products.length > 0 && `(${order.products.length})`}</div>
                            <button className='btn btn-primary' onClick={() => handleAdd()}>+ Add Item</button>
                        </div>
                        <div>
                            {order.products.length > 0 &&
                                <div className='ord-items'>
                                    {order.products.map((product) => {
                                        return (
                                            <div className='product-main'>
                                                <div className='product-info'>
                                                    <div className='img-contaoner-modal'>
                                                        <img src={product.image} />
                                                    </div>
                                                    <div className='product-stock'>
                                                        <div className='product-name'>{product.name}</div>
                                                        <div className='f_14_400' style={{ color: '#8C8C8C' }}>Price: ₹{product.price}  |   {product.available ? 'In Stock' : 'Out of Stock'}</div>
                                                    </div>
                                                </div>
                                                <div className='product-info'>
                                                    <input
                                                        type='number'
                                                        value={product.min_qty}
                                                        className='product-qty'
                                                        onChange={(e) => HandleQtyChange(e, product)}
                                                        onWheel={(e) => e.target.blur()}
                                                        onBlur={(e) => HandleBlur(e, product)}
                                                    />
                                                    <div className='f_12_400' style={{ width: '60px' }}>₹{product.price}</div>
                                                    <RiDeleteBinLine size={15} color='#1B7D78' className='delete-icon' onClick={() => handleDelete(product.id)} />
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className='col-6'>
                    <p>Delivery Agent</p>
                    <p className='delivery-agent'>
                        {order_data ? order_data.delivery_agent ? order_data.delivery_agent?.user?.full_name : 'No Delivery Agent Assigned' : '--- Select client to see Delivery Agent ---'}
                    </p>
                    <div className="form-group">
                        <label>Packing Charges :</label>
                        <div className="input-box">
                            <input type="number" className="form-control" placeholder="Packing Charges"
                                name="packing_charges"
                                onChange={HandleChange}
                                value={order.packing_charges}
                                step="0.10"
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Deliver Charges :</label>
                        <div className="input-box">
                            <input type="number" className="form-control" placeholder="Deliver Charges"
                                name="delivery_charges"
                                // maxLength={fieldItem.max_length ? fieldItem.max_length : null}
                                onChange={HandleChange}
                                value={order.delivery_charges}
                                step="0.10"
                            />
                        </div>
                    </div>
                    <div className='create-ord-card'>
                        <div className='create-card-top'>
                            <div className='f_14_500'>Order Summary</div>
                        </div>
                        <div>
                            {order.products.length > 0 &&
                                <div>
                                    <div className='subtotal'>
                                        <div className='f_14_400 rowBetweenCenter'>
                                            <div>Subtotal</div>
                                            <div>
                                                ₹{order.products.reduce((total, product) => total + product.price * product.min_qty, 0)}.00
                                            </div>
                                        </div>
                                        <div className='f_14_400 rowBetweenCenter'>
                                            <div>Tax</div>
                                            <div>₹0.00</div>
                                        </div>
                                    </div>
                                    <div className='f_14_500 total rowBetweenCenter'>
                                        <div>Total</div>
                                        <div>
                                            ₹{order.products.reduce((total, product) => total + product.price * product.min_qty, 0)}.00
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                show={show}
                onHide={handleClose}
                centered
                size="lg"
            >
                <Modal.Body>
                    <div className='add-header'>
                        <div>Add a Product</div>
                        <MdClose onClick={() => handleClose()} />
                    </div>
                    <div className='search-input'>
                        <MdOutlineSearch size={17} />
                        <input type='text' placeholder='Search...' onChange={(e) => handleSearch(e.target.value)} />
                    </div>
                    <div className='product-list' onScroll={(e) => handleScroll(e)}>
                        {products.length > 0 &&
                            products.map((product) => {
                                return (
                                    <div className='add-header' onClick={() => handleSelect(product)}>
                                        <div className='product-info'>
                                            <div className='rowBetweenCenter gap-20'>
                                                <div className='item-checkbox'>
                                                    {order.products.some((p) => p.id === product.id) && <MdCheck color='#1B7D78' />}
                                                </div>
                                                <div className='img-contaoner-modal'>
                                                    <img src={product.image ? product.image.image : ''} />
                                                </div>
                                                <div>
                                                    <div>{product.name}</div>
                                                </div>
                                            </div>
                                            {!product.available ?
                                                <div className='out-of-stock'>out of stock</div>
                                                :
                                                order.products.some((p) => p.id === product.id) ?
                                                    <input
                                                        type='number'
                                                        defaultValue={0}
                                                        value={order.products.find((p) => p.id === product.id).min_qty}
                                                        className='product-qty'
                                                        onChange={(e) => HandleQtyChange(e, product)}
                                                        onClick={(e) => e.stopPropagation()}
                                                        onWheel={(e) => e.target.blur()}
                                                        onBlur={(e) => HandleBlur(e, product)}
                                                    />
                                                    :
                                                    <></>
                                            }
                                        </div>
                                        <div className='f_12_400'>₹{product.price}.00</div>
                                    </div>
                                )
                            })
                        }
                        {loading &&
                            <div className='d-flex justify-content-center align-items-center'>
                                <img src={require('../Components/FormParser/images/loader.gif')} style={{ width: '30px', height: '30px' }} />
                            </div>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn btn-primary' onClick={() => handleCancel()}>cancel</button>
                    <button className={order.products.length > 0 ? 'btn btn-primary' : 'btn btn-inactive'} onClick={handleClose}>
                        Add to Order
                    </button>
                </Modal.Footer>
            </Modal>
            {popupType != "" &&
                <PopupModal popupType={popupType} setpopupType={setpopupType} Navigation="/dashboard/orders" error={Error} Message={message} />
            }
        </>
    )
}

export { ModifyOrder }

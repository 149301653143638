import React, { useEffect, useState } from "react";
import { DashboardLayout, FormParser, EditPopupModal,DeletePopupModal, Pagination, PopupModal } from "../Components";
import { NavLink } from "react-router-dom";
import Masters from "../Components/masters.json"
import "./Pages.css"
import axiosConfig from "../Service/axiosConfig"
import { MdOutlineEdit } from "react-icons/md";
import Modal from 'react-bootstrap/Modal';
import { useParams } from "react-router-dom";
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import { PriorityAdd } from ".";
import tableCustomStyles from "../Components/PopupModal/tableCustomStyles"
const TariffCardEdit = () => {
    let { id } = useParams();
    const [popupType, setpopupType] = useState("");
    const [EditPopupType, setEditpopupType] = useState("");
    const [Show, setShow] = useState(false);
    const [loading, setLoading] = useState(false)
    const[deletePopupType,setDeletepopupType]=useState("")
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("")
    const MastersModal = Masters.TariffCard
    const [Error, SetError] = useState("")
    const [EditData, SetEditData] = useState()
    const [Clientsinfo, SetClientinfo] = useState({
        serice_amount: "",
        night_amount: "",
        alias_code: "",
        alias_name: "",
        tariffcategory: "",
        service: "",
        priority: "",
        client: ""
    })
    const[Name,setName]=useState()
    const FilterData = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        console.log(name, value)
        setName(value)
        const filteredData = DataFilter.filter(item => item.subdepartment_code.includes(value));
        console.log(filteredData)
        SetServiceAmount(filteredData)
        setPerpage(filteredData.slice(0, pageNumber ? pageNumber : 30))
    }
    const HandleChange = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        console.log(name, value)
        SetClientinfo({ ...Clientsinfo, [name]: value })

    }
    const[EditTariff,SetEditTarif]=useState([])
    const[EditTariffid,SetEditTarifid]=useState({})
     let data=[]
    const HandleEditChange = (e,service) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        console.log(value)
        const existingIndex = EditTariff.findIndex((item) => item.id === service);
        if (existingIndex === -1) {
            SetEditTarif([...EditTariff, {"id":service,"amount":value}]);
          }
          else {
            const updatedValues = [...EditTariff];
            updatedValues[existingIndex] = {"id":service,"amount":value};
            SetEditTarif(updatedValues);
          }
  }
   console.log(EditTariff)
    const [ClientsEdit, SetClientEdit] = useState([])
    const [DefaultData, SetDefaultData] = useState([])
    const HandleEdit = (id) => {
        let TarifPriceData = []
        let defobj = {}
        let editTariff=[]
        // console.log(id)
        SetEditData(id)
        const FindData = perpage.find(data => data.service === id)
        console.log(FindData)
        FindData.prices.forEach(res => {
            console.log(res)
            TarifPriceData.push({ "service": res.id, [res.id + "_" + id]: res.serice_amount })
            defobj[res.id + "_" + id] = res.serice_amount
            editTariff.push({"id":res.id,"amount":res.serice_amount});
        })
        SetClientEdit(TarifPriceData)
        SetDefaultData(defobj)
        SetEditTarif(editTariff)
    }
    const ClientinfonSubmit = (e) => {
        e.preventDefault()
        // console.log(Clientsinfo)
        SetClientinfo({
            serice_amount: "",
            night_amount: "",
            alias_code: "",
            alias_name: "",
        })
        const { serice_amount,
            night_amount,
            alias_code,
            alias_name,
            tariffcategory,
            service,
            priority,
            client } = Clientsinfo
        axiosConfig.post("/client/clienttariff/", Clientsinfo).then(res => {
            console.log(res)
            setpopupType("success")
            GetData()
            setShow(!Show)
        }).catch(error => {
            setpopupType("error")
        })
    }
    const formSubmit = (Data) => {
        // console.log(Data)
        axiosConfig.patch(`/masters/tariffcategories/${id}/`, Data).then(res => {
            console.log(res)
            setpopupType("success")
        }).catch(error => {
            console.log(error)
            setpopupType("error")
        })
    }
    const [TariffId, SetTariffId] = useState()
    const [Data, SetData] = useState([])
    const [DataFilter, SetDataFilter] = useState([])
    const GetData = async () => {
        let tariff_list = []
        setLoading(true)
        try {
            const response = await axiosConfig.get(`/client/clienttariff/?tariffcategory=${id}`)
            console.log(response.data)
            response.data.forEach((obj, index) => {
                if (tariff_list.findIndex(list_obj => list_obj.service === obj.service) === -1) {
                    tariff_list.push({
                        "service": obj.service, "service_name": obj.service_name, "subdepartment_code": obj.subdepartment_code,  "prices": [{
                            "id": obj.id,
                            "serice_amount": obj.serice_amount,
                            "priority": obj.priority,
                            "priority_name": obj.priority_name
                        }]
                    })
                } else {
                    let exist_idx = tariff_list.findIndex(list_obj => list_obj.service === obj.service)
                    tariff_list[exist_idx]['prices'].push({
                        "id": obj.id,
                        "serice_amount": obj.serice_amount,
                        "priority": obj.priority,
                        "priority_name": obj.priority_name
                    })
                }
                setLoading(false)
            })
            // var result = response.data.reduce((curr, next) => {
            //     (curr[next.service] = curr[next.service] || []).push(next);
            //     return curr;
            // }, {});
            // GetPriorityPrice(result)
            //     console.log(result)
        }
        catch (error) {
            console.log(error)
        }
        // console.log(tariff_list)
        SetServiceAmount(tariff_list)
        SetDataFilter(tariff_list)
        setPerpage(tariff_list.slice(0,pageNumber?pageNumber:30))
    }
    const [perpage, setPerpage] = useState([])
    const [pageNumber, SetPageNumber] = useState()
    const HandlePerPage = (pageNumber) => {
        // console.log(pageNumber)
        SetPageNumber(pageNumber)
        setPerpage(ServiceAmount.slice(0, pageNumber))
    }
    const HandleNextPage = (pageNumber,page) => {
        console.log(pageNumber,page)
        setPerpage(ServiceAmount.slice( page* pageNumber - pageNumber,  page* pageNumber))
    }
    const HandleStartPage = () => {
        setPerpage(ServiceAmount.slice(0, 30))
    }
    const HandleEndPage = (end,endPage) => {
        console.log(end,endPage)
         setPerpage(ServiceAmount.slice(endPage*end-end, end*endPage))
    }
    const [ServiceAmount, SetServiceAmount] = useState([])
    const[Editdata,SetEditdata]=useState([])
    const clientinfoEdit = (e) => {
        e.preventDefault()
        console.log(id)
         const  updatedValues=[...perpage]
          const Index=updatedValues.findIndex(data=>data.service===EditData)
        console.log(EditTariff[0].amount)
        updatedValues[Index]["prices"][0].serice_amount=EditTariff[0].amount
        updatedValues[Index]["prices"][1].serice_amount=EditTariff[1].amount
        updatedValues[Index]["prices"][2].serice_amount=EditTariff[2].amount
        setPerpage(updatedValues)
         EditTariff.forEach(res=>{
                 axiosConfig.put(`/client/clienttariff/${res.id}/`,{serice_amount:res.amount}).then(res => {
                     console.log(res.data)
                     setEditpopupType("success")
                     SetEditData(null)
                     SetDefaultData(res.data.serice_amount)
                     
                 }).catch(error => {
                    setEditpopupType("error")
                     console.log(error)
                 })
                })
    }
    const [ClientData, SetClientData] = useState([])
    const ReferenceDoamain = () => {
        axiosConfig.get("/masters/tariffcategories/").then(res => {
             console.log(res.data)
            SetClientData(res.data)
        }).catch(error => {
            console.log(error)
        })
    }
    const [ServiceData, SetServiceData] = useState([])
    const Service = () => {
        axiosConfig.get("masters/services/").then(res => {
            // console.log(res.data)
            SetServiceData(res.data)
        }).catch(error => {
            console.log(error)
        })
    }
    const [Priority, SetPriority] = useState([])
    const PriorityData = () => {
        axiosConfig.get("/masters/priority/").then(res => {
            // console.log(res.data)
            SetPriority(res.data)
        }).catch(error => {
            console.log(error)
        })
    }
    const [Clients, SetClients] = useState([])
    const clients = () => {
        axiosConfig.get("/client/clients/").then(res => {
            // console.log(res.data)
            SetClients(res.data)
        }).catch(error => {
            console.log(error)
        })
    }
    const [TariffEditData, SetTariffEditData] = useState([])
    const clientEditData = () => {
        axiosConfig.get(`/masters/tariffcategories/${id}/`).then(res => {
              console.log(res.data)
            SetTariffEditData(res.data)
        }).catch(error => {
            console.log(error)
        })
    }

    useEffect(() => {
        PriorityData()
        clientEditData()
        clients()
        Service()
        ReferenceDoamain()
        GetData()
    }, [])
    const [RowId, SetRowId] = useState()
    const DeleteAlert = (service) => {
        const FindData = ServiceAmount.find(data => data.service===service)
        let data=[]
        FindData.prices.forEach(res=>{
            data.push(res.id)
        })
       
         SetRowId(data)
         SetAlertDeletePopupType("success")
    }
    const CancelAlerrt = () => {
        SetAlertDeletePopupType("error")
    }
     const HandleDelete = (id) => {
         console.log(id)
          id.forEach(res=>{
             axiosConfig.delete(`/client/clienttariff/${res}/`).then(res => {
                      console.log(res)
                      setDeletepopupType("success")
                      SetAlertDeletePopupType("error")
                      GetData();
                  }).catch(error => {
                      console.log(error)
                      SetAlertDeletePopupType("error")
                      setDeletepopupType("error")
                  })
          })
    
     }
    const [DefaultPrices, SetDefaultPrices] = useState([])
    const GetPriorities = async () => {
        try {
            const response = await axiosConfig.get(`/masters/priority/`)
            let defobj = []
            response.data.forEach((obj, index) => {
                defobj.push(obj.code)
            })
            SetDefaultPrices(defobj)
        }
        catch (error) {
            console.log(error)
        }

    }
    const [subdepartment, SetSubDepartment] = useState([])
    const GetSubDepartment = async () => {
        try {
            const response = await axiosConfig.get(`/masters/subdepartments/`)
            SetSubDepartment(response.data)
        }
        catch (error) {
            console.log(error)
        }

    }
    useEffect(() => {
        GetSubDepartment()
        GetPriorities()
    }, [])

    return (
        <>
            <DashboardLayout>
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">View/Edit Tariff Card</h5>
                    <NavLink to="/dashboard/masters/tariffcard" ><button className="btn btn-white">Manage</button></NavLink>
                </div>
                <div className="accordion-container">
                    <div className="accordion" id="accordionClient">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    <h5 className="dashboard-subtitle">View / Edit Tariff Card</h5>
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionClient">
                                <div className="accordion-body">
                                    <FormParser modelObject={MastersModal} formSubmit={formSubmit} formData={TariffEditData} error={Error} />
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    <h5 className="dashboard-subtitle"> Tariff Additional Information</h5>
                                </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionClient">
                                <div className="accordion-body">
                                    <div className="d-flex justify-content-end">
                                        <div className="mx-4 row">
                                            <div className="col-md-12">
                                                <select className="form-select" name="modality" value={Clientsinfo.modality} onChange={FilterData} required>
                                                    <option value="">Filter By Modality</option>
                                                    {
                                                        subdepartment.map((optionItem, i) => {
                                                            return (
                                                                <option value={optionItem.subdepartment_name} key={i}>{optionItem.subdepartment_name}</option>
                                                            )
                                                        })
                                                    }

                                                </select>
                                            </div>
                                        </div>
                                        {/* <button type="submit" className="btn btn-primary" onClick={() => setShow(!Show)}>Add New</button> */}
                                    </div>
                                    {/* <Modal
                                        size="lg"
                                        show={Show}
                                        onHide={() => setShow(!Show)}
                                        aria-labelledby="example-modal-sizes-title-sm">
                                        <Modal.Header closeButton>
                                            <Modal.Title id="example-modal-sizes-title-sm">

                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <form onSubmit={ClientinfonSubmit}>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label >Service Amount:</label>
                                                            <input type="number" className="form-control" placeholder="Service Amount" name="serice_amount" value={Clientsinfo.serice_amount} onChange={HandleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label >Night Amount:</label>
                                                            <input type="number" className="form-control" placeholder="Night Amount" name="night_amount" value={Clientsinfo.night_amount} onChange={HandleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-label">Code:</label>
                                                            <input type="text" className="form-control" placeholder="Code" name="alias_code" value={Clientsinfo.alias_code} onChange={HandleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-label">Name:</label>
                                                            <input type="text" className="form-control" placeholder="Name" name="alias_name" value={Clientsinfo.alias_name} onChange={HandleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Tariff Category:</label>
                                                        <select className="form-select" name="tariffcategory" value={Clientsinfo.tariffcategory} onChange={HandleChange} required>
                                                            <option value="">SELECT Tariff Category</option>
                                                            {
                                                                ClientData.map((optionItem, i) => {
                                                                    return (
                                                                        <option value={optionItem.id} key={i}>{optionItem.tariffcategory_code}</option>
                                                                    )
                                                                })
                                                            }

                                                        </select>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="form-label">Service Name:</label>
                                                        <select className="form-select" name="service_id" value={Clientsinfo.service} onChange={HandleChange} required>
                                                            <option value="">SELECT Service</option>
                                                            {
                                                                ServiceData.map((optionItem, i) => {
                                                                    return (
                                                                        <option value={optionItem.id} key={i}>{optionItem.service_name}</option>
                                                                    )
                                                                })
                                                            }

                                                        </select>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <label className="form-label">Priority:</label>
                                                        <select className="form-select" name="priority_id" value={Clientsinfo.priority_id} onChange={HandleChange} required>
                                                            <option value="">SELECT Priority</option>
                                                            {
                                                                Priority.map((optionItem, i) => {
                                                                    return (
                                                                        <option value={optionItem.id} key={i}>{optionItem.code}</option>
                                                                    )
                                                                })
                                                            }

                                                        </select>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <label className="form-label">Clients:</label>
                                                        <select className="form-select" name="client" value={Clientsinfo.client} onChange={HandleChange} required>
                                                            <option value="">SELECT Clients</option>
                                                            {
                                                                Clients.map((optionItem, i) => {
                                                                    return (
                                                                        <option value={optionItem.id} key={i}>{optionItem.client_name}</option>
                                                                    )
                                                                })
                                                            }

                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-end">
                                                    <button type="submit" className="btn btn-primary">Submit</button>
                                                </div>
                                            </form>
                                        </Modal.Body>
                                    </Modal> */}
                                    <div className="table-responsive">
                                        {loading ? (<div className="loader-container">
                                            <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                                        </div>) :TariffId === 0 ? <p className="text-center"> There are no records to display</p> :
                                        <div className="tablescroll">
                                            <table className="table">
                                                <thead  className="sticky-top">
                                                    <tr>
                                                        <th>S.NO</th>
                                                        <th>SERVICE ID</th>
                                                        <th>SERVICE NAME</th>
                                                        {DefaultPrices.map(item => {
                                                            return (
                                                                <th key={item.id}>{item}</th>
                                                            )
                                                        })}
                                                        <th>ACTIONS</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        perpage.map((tariffcard, id) => {
                                                            return (
                                                                EditData === tariffcard.service ?
                                                                    <tr key={tariffcard.id}>
                                                                        <td>{id+1}</td>
                                                                        <td>{tariffcard.service}</td>
                                                                        <td>{tariffcard.service_name}</td>
                                                                        {
                                                                            ClientsEdit.map((res, i) => {
                                                                                return (
                                                                                    <td>
                                                                                        <div className="form-group">
                                                                                            <input type="text" className="form-control" placeholder="Code"  name={res.service + "_" + EditData} defaultValue={DefaultData[res.service + "_" + EditData]} onChange={(e,service)=>HandleEditChange(e,res.service)} />
                                                                                        </div>
                                                                                    </td>
                                                                                )
                                                                            })
                                                                        }
                                                                        <td>
                                                                            <button type="submit" className="btn btn-primary" onClick={clientinfoEdit}>Update</button></td>
                                                                    </tr> :
                                                                    <tr key={tariffcard.service}> 
                                                                    <td>{id+1}</td>
                                                                        <td>{tariffcard.service}</td>
                                                                        <td>{tariffcard.service_name}</td>
                                                                        {
                                                                            tariffcard.prices.map((prices, ind) => {
                                                                                return (
                                                                                    <td key={ind}>{prices.serice_amount}</td>
                                                                                )
                                                                            })
                                                                        }
                                                                        <td>
                                                                            <div className="row">
                                                                                <div className="col-12">
                                                                                <td><MdOutlineEdit className="dashboard-tabe-data-actions" onClick={(id) => HandleEdit(tariffcard.service)} /> </td>

                                                                                </div>
                                                                                {/* <div className="col-6">
                                                                                    <img className="dashboard-tabe-data-actions" onClick={(Tariffid) => DeleteAlert(tariffcard.service)} src={require("./images/delete.png")} alt="delete" />
                                                                                </div> */}
                                                                            </div>
                                                                        </td>
                                                                        {
                                                                            AlertDeletePopupType === "success" &&
                                                                            <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} HandleDelete={HandleDelete} CancelAlerrt={CancelAlerrt} row={RowId} />
                                                                        }
                                                                    </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            </div>}
                                        {perpage.length>0&&<Pagination data={ServiceAmount} HandlePerPage={HandlePerPage} HandleNextPage={HandleNextPage} HandleStartPage={HandleStartPage} HandleEndPage={HandleEndPage} />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {popupType != "" &&
                   <PopupModal popupType={popupType}/>
                }
                {EditPopupType != "" &&
                    <EditPopupModal EditPopupType={EditPopupType} setEditpopupType={setEditpopupType} Message="Record Updated Successfully"/>
                }
            </DashboardLayout>
        </>
    )

}
export { TariffCardEdit }
import React, { useEffect, useState } from "react";
import { DashboardLayout,DeletePopupModal } from "../Components";
import { NavLink, useNavigate } from "react-router-dom";
import axiosConfig from "../Service/axiosConfig"
import DataTable from 'react-data-table-component';
import {MdDeleteOutline,MdOutlineEdit} from "react-icons/md"
import {AiOutlineEye} from "react-icons/ai"
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
const RadioLogistLsit = () => {
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("")
    const[deletePopupType,setDeletepopupType]=useState("")
    const [RowId, SetRowId] = useState()
    let navigate = useNavigate();

    const [Data, SetData] = useState([])
    const GetData = async () => {
        try {
            const responce = await axiosConfig.get("/masters/priority/")
            SetData(responce.data)
        }
        catch (error) {
            console.log(error)
        }

    }
    useEffect(() => {
        GetData();
    }, [])

    const columns = [
        {
            name: "Id",
            selector: row => row.id,
            sortable: true
        },
        {
            name: "Code",
            selector: row => row.code,
            sortable: true
        },
        {
            name: "Name",
            selector: row => row.name,
            sortable: true
        },
        {
            name: "Tat In Minutes",
            selector: row => row.tat_in_minutes,
            sortable: true
        },
        {
            name: "From Time",
            selector: row => row.from_time,
            sortable: true
        },
        {
            name: "To Time",
            selector: row => row.to_time,
            sortable: true
        }, {
            name: "ACTIONS",
            selector: row => {
                const HandleEdit = async() => {
                    return navigate(`/dashboard/masters/priority/${row.id}/edit`);
                }
                const DeleteAlert = () => {
                    SetRowId(row.id)
                    SetAlertDeletePopupType("success")
                }
                const CancelAlerrt=()=>{
                    SetAlertDeletePopupType("error")
                }
                const HandleDelete = async(id) => {
                    await axiosConfig.delete(`/masters/priority/${id}/`).then(()=>{
                        SetAlertDeletePopupType("error")
                        setDeletepopupType("success")
                        GetData()
                    }).catch((error)=> {
                        setDeletepopupType("error")
                        console.log(error)
                    })
                }
                const HandleView=()=>{
                    navigate(`/dashboard/masters/priority/${row.id}/edit`)
                }
                return (
                    <>
                         <div className="row" >
                            <div className="col-4">
                            <AiOutlineEye className="dashboard-tabe-data-actions"  onClick={HandleView}/>
                            </div>
                            <div className="col-4">
                                <MdOutlineEdit className="dashboard-tabe-data-actions"  onClick={HandleEdit}/>
                            </div>
                            <div className="col-4">
                                <MdDeleteOutline className="dashboard-tabe-data-actions" onClick={DeleteAlert}/>
                            </div>
                        </div>
                        {
                            AlertDeletePopupType=== "success" &&
                            <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} HandleDelete={HandleDelete} CancelAlerrt={CancelAlerrt} row={RowId} />
                        }
                    </>
                )

            }
        }
    ]
    const tableCustomStyles = {
        cells: {
            style: {
                justifyContent:"center"
                           },
        },
        headCells: {
          style: {
            display:"flex",
            justifyContent:"center"
          },
        },
        }
    return (
        <>
            <DashboardLayout>
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">View Radiologist</h5>
                    <NavLink to="/dashboard/radiologist/add" ><button className="btn btn-white">Add New</button></NavLink>
                </div>
                <div className="table-responsive">
                <DataTable columns={columns} data={Data} pagination fixedHeader customStyles={tableCustomStyles} />
                </div>
                {deletePopupType != "" &&
                    <DeletePopupModal deletePopupType={deletePopupType} />
                }
            </DashboardLayout>
        </>
    )
}
export { RadioLogistLsit }
import React, { useEffect, useState } from "react";
import { DashboardLayout, DeletePopupModal, PopupModal } from "../Components";
import { NavLink, useNavigate } from "react-router-dom";
import axiosConfig from "../Service/axiosConfig"
import { MdDeleteOutline, MdOutlineEdit } from "react-icons/md"
import { FaHandHoldingHeart } from "react-icons/fa"
import DataTable from 'react-data-table-component';
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import "./master_categories.css";
import { IoMdArrowDropdown } from "react-icons/io";
import { BsFillCloudUploadFill } from "react-icons/bs"
import { BiSearch } from "react-icons/bi"
import { Button, Modal } from 'react-bootstrap';
import Select from 'react-select';
import { toFormData } from "axios";
import { HiOutlineClipboardList } from "react-icons/hi"
import { VscSymbolColor } from "react-icons/vsc"
import { FileManager } from "../Components/FileManager";
import { NavbarComponent } from "../Components/navbar/NavComponent";
import useDebouncedValue from "../Utils/Debounce";
import { useURLParams } from '../Components/UrlUtils/useURLParams';

const Products = () => {
    const { setParam, getParam } = useURLParams();
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("")
    const [deletePopupType, setDeletepopupType] = useState("")
    const [loading, setLoading] = useState(false)
    const [RowId, SetRowId] = useState()
    let navigate = useNavigate();
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(20);
    const [popupType, setpopupType] = useState("")
    const [show, setShow] = useState(false);
    const [subcategoryOptions, setSubcategoryOptions] = useState([]);
    const [message, setMessage] = useState('')
    const [search, SetSearch] = useState({
        search:getParam('search') || ""
    })
    const debouncedSearchTerm = useDebouncedValue(search.search, 500);
    const tableCustomStyles = {
        rows: {
            style: {
            },
        },
        cells: {
            style: {
                justifyContent: "center"
            },
        },
        headCells: {
            style: {
                color: "#5C5C5C",
                justifyContent: "center",
                color: "rgb(33,37,41)",
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "28px",
            },
        },
    }
    const [selectedCategoryId, setSelectedCategoryId] = useState({
        subcategory: getParam('subcategory') || "",
        category: getParam('category') || ""
    });
    const [Categories, setCategory] = useState([])
    const categories = async () => {
        await axiosConfig.get("catlog/categories/?page_size=50&is_suspended=false").then(res => {
            setCategory(res.data.results)
        }).catch(error => {
            console.log(error)
        })
    }
    const [Subcategories, setSubcategory] = useState([])
    const subategories = async () => {
        await axiosConfig.get(`catlog/sub-categories/?category=${selectedCategoryId.category}&page_size=500&is_suspended=false`).then(res => {
            setSubcategory(res.data.results)
        }).catch(error => {
            console.log(error)
        })
    }

    useEffect(() => {
        GetData();
        categories()
        subategories();
    }, [currentPage, RowPerPage, selectedCategoryId.category, selectedCategoryId.subcategory, selectedCategoryId.category, debouncedSearchTerm]);



    const handleCategoryFilterChange = (selectedOption, name) => {
        setSelectedCategoryId({ ...selectedCategoryId, [name]: selectedOption.value });
        setParam(name, selectedOption.value)
    };
    const clearSelection = (name) => {
        setSelectedCategoryId({ ...selectedCategoryId, [name]: "" });
        setParam(name, ''); // Reset or remove the URL parameter
      };
    
    const [Datafilter, SetDatafilter] = useState([])
    const [Data, SetData] = useState([])
    const GetData = async () => {
        setLoading(true);
        try {
            let url = `catlog/products/?page=${currentPage}&page_size=${RowPerPage}&subcategory=${selectedCategoryId.subcategory ? selectedCategoryId.subcategory : ""}&subcategory__category=${selectedCategoryId.category ? selectedCategoryId.category : ""}&name=${debouncedSearchTerm}`;
            const response = await axiosConfig.get(url);
            SetData(response.data.results);
            setLoading(false);
            setTotalRows(response.data.count);
        } catch (error) {
            console.log(error);
        }
    };
    const HandleSearch = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        console.log(name, value)
        SetSearch({ ...search, [name]: value })
        setParam('search', value);
    }
    const handleSort = (column, direction) => {
        console.log(column.selector, direction)
        axiosConfig.get(`catlog/products/?page=${currentPage}&page_size=${RowPerPage}&ordering=${direction === "asc" ? column.selector : -column.selector}`).then(res => {
            SetData(res.data.results)
        }).catch(error => {
            console.log(error)
        })
        setCurrentPage(1);
    };
    const handleNaigate = () => {
        navigate("/dashboard/masters/products/add")
    }
    const columns = [
        {
            name: "S.No",
            selector: "id",
            cell: (row, i) => (currentPage - 1) * RowPerPage + i + 1,
            sortable: true
        },
        {
            name: "Name",
            selector: "name",
            cell: row => row.name,
            sortable: true
        },
        {
            name: "image",
            selector: row => <>
                <img className="mng-prod-img" src={row.image && row.image.image} alt={row.image && row.image.image} />
            </>,
            sortable: true,
            width: "200px"
        },
        {
            name: "Price",
            selector: "price",
            cell: row => row.price,
            sortable: true
        },
        {
            name: "Subcategory_name",
            selector: "Subcategory_name",
            cell: row => row.subcategory_name,
            sortable: true
        },
        {
            name: "min_qty",
            selector: "min_qty",
            cell: row => row.min_qty,
            sortable: true
        },
        {
            name: "max_qty",
            selector: "max_qty",
            cell: row => row.max_qty,
            sortable: true
        },
        {
            name: "offer_price",
            selector: "offer_price",
            cell: row => row.offer_price,
            sortable: true
        },
        {
            name: "weight",
            selector: "weight",
            cell: row => row.weight,
            sortable: true
        },
        {
            name: "slug",
            selector: "slug",
            cell: row => row.slug,
            sortable: true
        },
        {
            name: "ACTIONS",
            selector: row => {
                const HandleEdit = async () => {
                    navigate(`/dashboard/masters/products/${row.id}/edit`)

                }
                const DeleteAlert = () => {
                    SetRowId(row.id)
                    SetAlertDeletePopupType("success")
                }
                const CancelAlerrt = () => {
                    SetAlertDeletePopupType("error")
                }
                const HandleDelete = async (id) => {
                    await axiosConfig.delete(`catlog/products/${id}/`).then(() => {
                        SetAlertDeletePopupType("error")
                        setDeletepopupType("success")
                        GetData();
                    }).catch((error) => {
                        setDeletepopupType("error")
                        SetAlertDeletePopupType("error")
                        console.log(error)
                    })
                }
                const handleRevive = async () => {
                    try {
                        const response = await axiosConfig.post(`catlog/products/${row.id}/revive/`)
                        setpopupType("success")
                        setMessage("Product revived successfully")
                        GetData()
                    } catch (error) {
                        console.log("Error While Reviving", error)
                    }
                }
                return (
                    <>
                        {!row.is_suspended ?
                            <div className="row" >
                                <div className="col-4">
                                    <MdOutlineEdit className="dashboard-tabe-data-actions" onClick={HandleEdit} />
                                </div>
                                <div className="col-4">
                                    <MdDeleteOutline className="dashboard-tabe-data-actions" onClick={DeleteAlert} />
                                </div>
                            </div>
                            :
                            <>
                                <FaHandHoldingHeart className="dashboard-tabe-data-actions" onClick={handleRevive} />
                            </>
                        }
                        {
                            AlertDeletePopupType === "success" &&
                            <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} HandleDelete={HandleDelete} CancelAlerrt={CancelAlerrt} row={RowId} />
                        }
                    </>
                )

            }
        }
    ]
    return (
        <>
            <div className="child-sidenav">
                <NavbarComponent />
                <div className="childnav-content">
                    <div>
                        <div className="button-pro">
                            <div className="cat-button">
                                <div className="btn" onClick={handleNaigate} >
                                    + Add New
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="space-flex">
                                <div className="col-6">
                                    <div className="flex">
                                        {console.log(Categories)}
                                        <div className="product-filter">
                                            <Select options={Categories.map(Categories => ({ value: Categories.id, label: Categories.name }))} placeholder="Select Category" value={Categories && Categories.map(res => ({ "value": res.id, "label": res.name })).find(res => res.value === parseFloat(selectedCategoryId.category))||null} onChange={(selectedOption) => {
                                                if (!selectedOption) {
                                                    clearSelection("category"); // Clear selection if nothing is selected
                                                } else {
                                                    handleCategoryFilterChange(selectedOption,"category"); // Handle change otherwise
                                                }
                                            }} isClearable required />
                                        </div>

                                        <div className="product-filter">
                                            <Select options={Subcategories.map(Subcategories => ({ value: Subcategories.id, label: Subcategories.name }))} placeholder="Select Subcategory" value={Subcategories && Subcategories.map(res => ({ "value": res.id, "label": res.name })).find(res => res.value === parseFloat(selectedCategoryId.subcategory))||null} onChange={(selectedOption) => {
                                                if (!selectedOption) {
                                                    clearSelection("subcategory"); // Clear selection if nothing is selected
                                                } else {
                                                    handleCategoryFilterChange(selectedOption,"subcategory"); // Handle change otherwise
                                                }
                                            }} isClearable required />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 input-with-icon">
                                    <span className="icon-product"><BiSearch /></span>
                                    <input type="text" className="input-control" placeholder="Search" name="search" value={search.search} onChange={HandleSearch} />
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive marginTop">
                            <DataTable
                                columns={columns}
                                data={Data} pagination paginationTotalRows={totalRows}
                                paginationPerPage={RowPerPage}
                                paginationDefaultPage={currentPage}
                                onChangePage={(page) => setCurrentPage(page)}
                                paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
                                paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
                                onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
                                onSort={handleSort}
                                paginationServer fixedHeader subHeader customStyles={tableCustomStyles} progressPending={loading}
                                progressComponent={<div className="loader-container">
                                    <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                                </div>}
                                subHeaderComponent={
                                    <input type="text" className="form-control w-50" placeholder="Search" name="search" value={search.search} onChange={HandleSearch} />} />
                        </div>
                    </div>
                </div>
            </div>
            {popupType != "" &&
                <PopupModal setpopupType={setpopupType} popupType={popupType} Navigation="/dashboard/masters/products" Message={message} />
            }
            {deletePopupType != "" &&
                <DeletePopupModal setDeletepopupType={setDeletepopupType} deletePopupType={deletePopupType} />
            }
        </>
    )
}
export { Products }
import React, { useEffect, useState } from "react";
import { DashboardLayout, DeletePopupModal, PopupModal } from "../Components";
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import { MarketingNavbarComponent } from "../Components/navbar/marketingnav";
import { BiSearch } from "react-icons/bi";
import axiosConfig from "../Service/axiosConfig"
import { FormParser } from "../Components";
import Modal from 'react-bootstrap/Modal';
import Masters from "../Components/masters.json";
import DataTable from 'react-data-table-component';
import { MdDeleteOutline, MdOutlineEdit, MdOutlineRemoveRedEye } from "react-icons/md";
import useDebouncedValue from "../Utils/Debounce";

const CustomerManagement = () => {
    const MastersModal = Masters.CustomerManagement
    const [search, SetSearch] = useState({
        search: ""
    })
    const debouncedSearchTerm = useDebouncedValue(search.search, 500);
    const HandleSearch = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        console.log(name, value)
        SetSearch({ ...search, [name]: value })
    }
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("");
    const [Error, SetError] = useState("")
    const [deletePopupType, setDeletepopupType] = useState("");
    const [Data, SetData] = useState([]);
    const [loading, setLoading] = useState(false)
    const [RowId, SetRowId] = useState()
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(20);
    const [popupType, setpopupType] = useState("")
    const [show, setShow] = useState(false);
    const [modalTitle, setModalTitle] = useState("Create Customer");
    const [formData, setFormData] = useState({
        full_name: '',
        email: '',
        mobileno: '',
        role: "customer"
    });
    const handleShow = () => {
        setShow(true)
        // setSelectedImage("")
        setFormData({
            full_name: '',
            email: '',
            mobileno: ''
        });
        setCustomerid(null);
        setModalTitle("Create Customer");
    };
    const handleClose = () => {
        setShow(false)
        // SetError("")
        setpopupType("")
    };
    const handleSort = (column, direction) => {
        console.log(column.selector, direction)
        axiosConfig.get(`/accounts/users/?page=${currentPage}&page_size=${RowPerPage}&ordering=${direction === "asc" ? column.selector : -column.selector}`).then(res => {
            SetData(res.data.results)
        }).catch(error => {
            console.log(error)
        })
        setCurrentPage(1);
    };
    const formSubmit = (FormData) => {
        console.log(FormData)
        axiosConfig.defaults.headers['Content-Type'] = 'multipart/form-data';
        if (FormData.id) {
            axiosConfig.patch(`/accounts/users/${FormData.id}/`, FormData)
                .then(res => {
                    console.log(res)
                    setpopupType("success")
                    GetData();
                    setShow(false);
                }).catch(error => {
                    console.log(error)
                    setpopupType("error")
                })
        } else {
            axiosConfig.post("/accounts/users/", FormData).then(res => {
                console.log(res)
                setpopupType("success")
                GetData();
                setShow(false);
            }).catch(error => {
                console.log(error)
                setpopupType(error)
            })
        }
    };
    const GetData = async () => {
        setLoading(true)
        try {
            const responce = await axiosConfig.get(`/accounts/users/?page=${currentPage}&page_size=${RowPerPage}`)
            console.log(responce)
            SetData(responce.data.results)
            setLoading(false)
            setTotalRows(responce.data.count)
        }
        catch (error) {
            console.log(error)
        }

    }
    useEffect(() => {
        const getSearchResults = async () => {
            axiosConfig.get(`/accounts/users/?search=${debouncedSearchTerm}`).then(res => {
                SetData(res.data.results)
            }).catch(error => {
                console.log(error)
            })
        };
        getSearchResults();
    }, [debouncedSearchTerm]);
    useEffect(() => {
        GetData();
    }, [currentPage, RowPerPage]);
    const [customerid, setCustomerid] = useState()
    const Customeredit = (id) => {
        console.log(id, 'iddddd')
        axiosConfig.get(`/accounts/users/${id}/`).then(res => {
            console.log(res.data)
            setFormData(res.data)
            setCustomerid(id)
            setShow(true)
        }).catch(error => {
            console.log(error)
        })
    }
    const tableCustomStyles = {
        rows: {
            style: {
            },
        },
        cells: {
            style: {
                justifyContent: "center"
            },
        },
        headCells: {
            style: {
                color: "#5C5C5C",
                justifyContent: "center",
                color: "rgb(33,37,41)",
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "28px",
            },
        },
    };
    const columns = [
        {
            name: "S.No",
            selector: "id",
            cell: (row, i) => (currentPage - 1) * RowPerPage + i + 1,
            sortable: true
        },
        {
            name: "Full Name",
            selector: "full_name",
            cell: row => row.full_name,
            sortable: true
        },
        {
            name: "Email",
            selector: "email",
            cell: row => row.email,
            sortable: true
        },
        {
            name: "Mobile no.",
            selector: "mobileno",
            cell: row => row.mobileno,
            sortable: true
        },
        {
            name: "Registered Date",
            selector: "date_joined",
            cell: row => row.created_date?.split("T")[0],
            sortable: true
        },
        {
            name: "ACTIONS",
            selector: row => {
                const HandleEdit = async () => {
                    console.log(row.id, 'iddddd')
                    setShow(true)
                    Customeredit(row.id)
                    handleShow(row.id);
                    setModalTitle("Edit Customer");


                }
                const DeleteAlert = () => {
                    SetRowId(row.id)
                    SetAlertDeletePopupType("success")
                }
                const CancelAlerrt = () => {
                    SetAlertDeletePopupType("error")
                }
                // const HandleDelete = async (id) => {
                //     await axiosConfig.delete(`/accounts/users/${id}/`).then(() => {
                //         SetAlertDeletePopupType("error")
                //         setDeletepopupType("success")
                //         GetData();
                //     }).catch((error) => {
                //         setDeletepopupType("error")
                //         SetAlertDeletePopupType("error")
                //         console.log(error)
                //     })
                // }
                return (
                    <>
                        <div className="row" >
                            <div className="col-4">
                                <MdOutlineRemoveRedEye className="dashboard-tabe-data-actions" onClick={HandleEdit} />
                            </div>
                            <div className="col-4">
                                <MdOutlineEdit className="dashboard-tabe-data-actions" onClick={HandleEdit} />
                            </div>
                            {/* <div className="col-4">
                                <MdDeleteOutline className="dashboard-tabe-data-actions" onClick={DeleteAlert} />
                            </div> */}
                        </div>
                        {
                            AlertDeletePopupType === "success" &&
                            <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} CancelAlerrt={CancelAlerrt} row={RowId} />
                        }
                    </>
                )

            }
        }
    ]

    return (
        <>
            <div className="child-sidenav">
                <MarketingNavbarComponent />
                <div className="childnav-content">
                <div className="d-flex align-items-center">
            <div className="input-cat-with-icon">
              <span className="icon-product">
                <BiSearch />
              </span>
              <input
                type="text"
                className="input-control"
                placeholder="Search"
                name="search"
                value={search.search}
                onChange={HandleSearch}
              />
            </div>
            <div className="cat-button">
              <div className="btn" onClick={handleShow}>
                + Add New
              </div>
            </div>
          </div>
                    <div className="table-responsive marginTop">
                        <DataTable columns={columns} data={Data} pagination paginationTotalRows={totalRows}
                            paginationPerPage={RowPerPage}
                            paginationDefaultPage={currentPage}
                            onChangePage={(page) => setCurrentPage(page)}
                            paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
                            paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
                            onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
                            onSort={handleSort}
                            paginationServer fixedHeader subHeader customStyles={tableCustomStyles} progressPending={loading}
                            progressComponent={<div className="loader-container">
                                <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                            </div>}
                        />
                    </div>
                    <div>
                        <Modal
                            size="xl"
                            show={show}
                            onHide={handleClose}
                            aria-labelledby="example-modal-sizes-title-lg"
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="example-modal-sizes-title-lg">
                                    {modalTitle}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <FormParser modelObject={MastersModal} formData={formData} formSubmit={formSubmit} error={Error} buttonTitle='Save' />
                            </Modal.Body>
                        </Modal>
                        {popupType !== "" &&
                            <PopupModal setpopupType={setpopupType} popupType={popupType} Navigation="" />
                        }
                        {deletePopupType !== "" &&
                            <DeletePopupModal setDeletepopupType={setDeletepopupType} deletePopupType={deletePopupType} />
                        }
                    </div>

                </div>
            </div>
        </>
    )
}
export { CustomerManagement }
import React, { useEffect, useState } from "react";
import { DashboardLayout, DeletePopupModal } from "../Components";
import { NavLink, useNavigate } from "react-router-dom";
import axiosConfig from "../Service/axiosConfig"
import DataTable from 'react-data-table-component';
import {MdDeleteOutline,MdOutlineEdit} from "react-icons/md"
import {AiOutlineEye} from "react-icons/ai"
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import tableCustomStyles from "../Components/PopupModal/tableCustomStyles";
const TariffTypeList = () => {
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("")
    const [deletePopupType, setDeletepopupType] = useState("")
    const [RowId, SetRowId] = useState()
    const [Loading, setLoading] = useState(false)
    let navigate = useNavigate();
    const [search, SetSearch] = useState({
        search: ""
    })
    const [Datafilter, SetDatafilter] = useState([])
    const [Data, SetData] = useState([])
    const GetData = async () => {
        setLoading(true)
        try {
            const responce = await axiosConfig.get("/masters/tarifftype/")
            SetData(responce.data)
            SetDatafilter(responce.data)
            setLoading(false)
        }
        catch (error) {
            console.log(error)
        }
    }
    const HandleSearch = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        console.log(name, value)
        SetSearch({ ...search, [name]: value })
        const response = Datafilter.filter(data => data.tarifftype_code&&data.tarifftype_code.toLowerCase().includes(value.toLowerCase())||data.name&&data.name.toLowerCase().includes(value.toLowerCase())||data.currency_code&&data.currency_code.toLowerCase().includes(value.toLowerCase()))
        console.log(response)
        SetData(response)
    }
    useEffect(() => {
        GetData();
    }, [])

    const columns = [
        {
            name: "Id",
            selector: row => row.id,
            sortable: true
        },
        {
            name: "Code",
            selector: row => row.tarifftype_code,
            sortable: true
        },
        {
            name: "Name",
            selector: row => row.name,
            sortable: true
        },
        {
            name: "Currency",
            selector: row => row.currency_code,
            sortable: true
        }, {
            name: "ACTIONS",
            selector: row => {
                const HandleEdit = async () => {
                    return navigate(`/dashboard/masters/tarifftype/${row.id}/edit`);
                }
                const DeleteAlert = () => {
                    SetRowId(row.id)
                    SetAlertDeletePopupType("success")
                }
                const CancelAlerrt = () => {
                    SetAlertDeletePopupType("error")
                }
                const HandleDelete = async (id) => {
                    await axiosConfig.delete(`/masters/tarifftype/${id}/`).then(() => {
                        SetAlertDeletePopupType("error")
                        setDeletepopupType("success")
                        GetData()
                    }).catch((error) => {
                        setDeletepopupType("error")
                        console.log(error)
                    })
                }
                return (
                    <>
                         <div className="row" >
                            <div className="col-4">
                            <AiOutlineEye className="dashboard-tabe-data-actions"  onClick={HandleEdit}/>
                            </div>
                            <div className="col-4">
                                <MdOutlineEdit className="dashboard-tabe-data-actions"  onClick={HandleEdit}/>
                            </div>
                            <div className="col-4">
                                <MdDeleteOutline className="dashboard-tabe-data-actions" onClick={DeleteAlert}/>
                            </div>
                        </div>
                        {
                            AlertDeletePopupType === "success" &&
                            <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} HandleDelete={HandleDelete} CancelAlerrt={CancelAlerrt} row={RowId} />
                        }
                    </>
                )

            }
        }
    ]
    return (
        <>
            <DashboardLayout>
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">View TariffType</h5>
                    <NavLink to="/dashboard/masters/tarifftype/add" ><button className="btn btn-white">Add New</button></NavLink>
                </div>
                <div className="table-responsive">
                    <DataTable columns={columns} data={Data} pagination fixedHeader subHeader customStyles={tableCustomStyles}
                        progressPending={Loading}
                        progressComponent={<div className="loader-container">
                            <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                        </div>}
                        subHeaderComponent={
                            <input type="text" className="form-control w-25" name="search" value={search.search} onChange={HandleSearch} />} />
                </div>
                {deletePopupType != "" &&
                    <DeletePopupModal setDeletepopupType={setDeletepopupType} deletePopupType={deletePopupType} />
                }
            </DashboardLayout>
        </>
    )
}
export { TariffTypeList }
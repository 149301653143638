import React, { useEffect, useState } from "react";
import { Layout, FormParser, PopupModal, DashboardLayout } from "../Components";
import { NavLink, useParams } from "react-router-dom";
import Masters from "../Components/masters.json"
import "./Pages.css"
import axiosConfig from "../Service/axiosConfig"
import sidebarData from "../Components/DashboardLayout/sidebar";
import {BsArrowLeftShort} from "react-icons/bs"
const DoctorVacationsClinics = () => {
    let { id } = useParams();
    const [popupType, setpopupType] = useState("");
    const [formerrorData, SetformErrorData] = useState({})
    const MastersModal = Masters.DoctorVacations
    const sidebarDatavalue = sidebarData.DoctorProfile
    const [Error, SetError] = useState("")
    const [Data, setData] = useState([])
    const [doctorVacations, setDoctorVacations] = useState([])
    const [activeState, setActiveState] = useState("")
    const [ChildNavs, SetChildNavs] = useState("")
    let data = localStorage.getItem("user_data")
    const sidebarValue={"DoctorProfile": [
        {
            "SubTitle":"Doctor Profile",
            "NavItems":[
                {"label":"Doctor Info","path":`/dashboard/doctors/${id}`},
                {"label":"Specialites","path":`/dashboard/profile/${id}/doctor_specialities`},
                {"label":"Availability","path":`/dashboard/users/${id}/doctor_availability`},
                {"label":"Vacations","path":`/dashboard/users/${id}/vacations`}
            ]
        }
    ],
    }
    const formSubmit = (Data) => {
        console.log(Data)
        SetError([])
        axiosConfig.patch(`/clinics/doctorvacation/${id}/`, { fromdatetime: Data.fromdatetime, todatetime: Data.todatetime, doctor: id }).then(res => {
            console.log(res)
            setpopupType("success")
        }).catch(error => {
            let errorData = {}
            console.log(errorData)
            SetformErrorData(errorData)
            if (error.response.data.country_name[0] === "Enter a valid country name.") {
                errorData.country_name = "Enter a valid country name."
                SetError("Enter a valid country name.")
            }
            if (error.response.data.country_name[0] === "This field must be unique.") {
                errorData.country_name = "Country Already Exists."
                SetError("Country Already Exists.")

            }
            if (error.response.data.country_name[0] === "This field may not be blank.") {
                SetError("Country may not be blank.")

            }
            setpopupType("error")
        })
    }

    const getDoctorVacations = async () => {
        try {
            const responce = await axiosConfig.get(`/clinics/doctorvacation/`);
            console.log(responce.data.results, "vacations");
            setDoctorVacations(responce.data.results)
        }
        catch (error) {
            console.log(error)
        }

    }
    const DoctorVacations = async () => {
        try {
            const responce = await axiosConfig.get(`/clinics/doctorvacation/?doctor=${id}`);
            console.log(responce.data.results, "vacations");
            setData(responce.data.results)
        }
        catch (error) {
            console.log(error)
        }

    }
    const OnChildNavClicked = (path) => {
        SetChildNavs(path)
        localStorage.setItem("url",path)
    }
    useEffect(() => {
        getDoctorVacations()
        DoctorVacations();
        SetChildNavs(localStorage.getItem("url"))
    }, [])
    return (
        <>
            <DashboardLayout>
                <div className="mainbar">
                    <div className="sidebar-filters px-2">
                    <NavLink to="/dashboard/doctors/list" ><button type="submit" className="btn btn-primary"><BsArrowLeftShort/>back to doctors</button></NavLink>
                        {
                            sidebarValue.DoctorProfile.map((childItem, index) => {
                                return (
                                    <div key={index}>
                                        <h5>{childItem.SubTitle}</h5>
                                        {
                                            childItem.NavItems.map((item, idx) => {
                                                return (
                                                    <div key={idx} className={ChildNavs == item.path ? "Configiration-list active" : "Configiration-list"}>
                                                        <NavLink to={item.path} className={ChildNavs == item.path ? "colorChange" : "NoColorChange"} onClick={() => OnChildNavClicked(item.path)}>
                                                            <span className="configiration-icon">{item.icon}</span>
                                                            <span className="list-name">{item.label}</span>
                                                        </NavLink>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="main-code">
                        <div className="dashboard-header">
                            <h5 className="dashboard-subtitle">Vacations</h5>
                        </div>
                        <FormParser modelObject={MastersModal} formSubmit={formSubmit} formData={Data} />
                        <div>
                            <h3 className="my-3">Past vacations</h3>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>S No.</th>
                                        <th>From Date</th>
                                        <th>To Date</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {doctorVacations && doctorVacations.map((result, index) => (
                                        <tr key={result.id}>
                                            <td>{index + 1}</td>
                                            <td>{result.fromdatetime}</td>
                                            <td>{result.todatetime}</td>
                                            <td>{result.comments ? 'Completed' : 'Pending'}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                {popupType != "" &&
                    <PopupModal popupType={popupType} Navigation="" />
                }
            </DashboardLayout>
        </>
    )

}
export { DoctorVacationsClinics }
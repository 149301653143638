import React, { useEffect, useState } from "react";
import { Layout, FormParser,PopupModal } from "../Components";
import { NavLink,useParams } from "react-router-dom";
import Masters from "../Components/masters.json"
import "./Pages.css"
import axiosConfig from "../Service/axiosConfig"
const AreaEdit = () => {
    const[popupType,setpopupType]=useState("")
    const[Error,SetError]=useState("")
    const MastersModal=Masters.Area
    const formSubmit=(Data)=>{
        axiosConfig.put(`/masters/area/${id}/`,Data).then(res=>{
                   console.log(res)
                   setpopupType("success")
        }).catch(error=>{
            console.log(error)
            const formData=[]
                formData.push({city_or_town_id:error.response.data.city_or_town_id||["null"],
                    code:error.response.data.code||["null"],
                    pincode:error.response.data.pincode||["null"],
                    address:error.response.data.address||["null"]})
                    console.log(formData[0])
            if (formData[0].code[0] === "area with this code already exists.") {
                SetError("Code Already Exists")
            }
            if (formData[0].city_or_town_id[0] === "This field may not be null.") {
                SetError("This field may not be null.")
            }
            if (formData[0].pincode[0] === "Only 0-9 are allowed.") {
                SetError("Invalid PinCode.")
            }
            setpopupType("error")
        })
      }
    let { id } = useParams();
    const [Data, SetData] = useState([])
    const GetData = async () => {
        try {
            const responce = await axiosConfig.get(`/masters/area/${id}/`)
            SetData(responce.data)
        }
        catch (error) {
            console.log(error)
        }

    }
    useEffect(() => {
        GetData();
    }, [])
    return (
        <>
            <Layout>
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">Edit Area {id}</h5>
                    <NavLink to="/dashboard/masters/areas" ><button className="btn btn-white">Manage</button></NavLink>
                </div>
                <FormParser modelObject={MastersModal} formData={Data} formSubmit={formSubmit} error={Error}/>
                {popupType != "" &&
                    <PopupModal popupType={popupType} Navigation="/dashboard/masters/areas"/>
                }
            </Layout>
        </>
    )

}
export {AreaEdit}
import React, { useState } from "react";
import { Layout, FormParser, PopupModal } from "../Components";
import { NavLink } from "react-router-dom";
import Masters from "../Components/masters.json"
import "./Pages.css"
import axiosConfig from "../Service/axiosConfig"

const ProcudreAdd = () => {
    const [popupType, setpopupType] = useState("");
    const[formerrorData,SetformErrorData]=useState({})
    const MastersModal = Masters.Procedure
    const [Error, SetError] = useState("")
    const formSubmit = (Data) => {
            axiosConfig.post("/masters/procedure/", Data).then(res => {
                console.log(res)
                setpopupType("success")
            }).catch(error => {
                console.log(error)
                let ErrorData=[]
                ErrorData.push({
                    code:error.response.data.code||["null"],
                    name:error.response.data.name||["null"],
                    })
                    console.log(ErrorData[0])
            if(ErrorData[0].code[0]==="This field must be unique."){
                SetError("Code Already Exists")
            }
            if(ErrorData[0].name[0]==="This field must be unique."){
                SetError("Name Already Exists")
            }
                setpopupType("error")
            })
    }
    return (
        <>
            <Layout>
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">Add Procedure</h5>
                    <NavLink to="/dashboard/masters/procedure" ><button className="btn btn-white">Manage</button></NavLink>
                </div>
                <FormParser modelObject={MastersModal} formSubmit={formSubmit} error={Error}/>
                {popupType != "" &&
                    <PopupModal popupType={popupType} Navigation="/dashboard/masters/procedure"/>
                }
            </Layout>
        </>
    )

}
export { ProcudreAdd }
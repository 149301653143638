import React, { useState } from "react";
import { DashboardLayout, FormParser,PopupModal } from "../Components";
import { NavLink } from "react-router-dom";
import Masters from "../Components/masters.json"
import "./Pages.css"
import axiosConfig from "../Service/axiosConfig"

const DepartmentAdd = () => {
    const[Error,SetError]=useState("")
    const[CustomError,SetCustomError]=useState("")
    const[popupType,setpopupType]=useState("")
    const MastersModal=Masters.Department
    const formSubmit=(Data)=>{
        console.log(Data)
        axiosConfig.post("/masters/departments/",Data).then(res=>{
                   console.log(res)
                   setpopupType("success")
        }).catch(error=>{
            console.log(error.response.data)
            const ErrorData=[]
            ErrorData.push({
                    department_code:error.response.data.department_code||["null"],
                    department_name:error.response.data.department_name||["null"],
                    })
                    console.log(ErrorData[0])
            if(ErrorData[0].department_code[0]==="This field may not be blank."){
                SetError("Department Code field may not be blank.")
            }
            if(ErrorData[0].department_name[0]==="This field may not be blank."){
                SetError("Department Name field may not be blank.")
            }
            if(ErrorData[0].department_name[0]==="This field must be unique."){
                SetError("Department Name Already Exists")
            }
            if(ErrorData[0].department_code[0]=== "This field must be unique."){
                SetError("Department code Already Exists")
            }
            setpopupType("error")
        })
      }
    return (
        <>
            <DashboardLayout>
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">Add Department</h5>
                    <NavLink to="/dashboard/masters/departments" ><button className="btn btn-white">Manage</button></NavLink>
                </div>
                <FormParser modelObject={MastersModal} formSubmit={formSubmit} error={Error} CustomError={CustomError}/>
                {popupType != "" &&
                    <PopupModal popupType={popupType} Navigation="/dashboard/masters/departments"/>
                }
            </DashboardLayout>
        </>
    )

}
export {DepartmentAdd}
import React, { useEffect, useState } from "react";
import {
  DashboardLayout,
  DeletePopupModal,
  PopupModal,
  FormParser,
} from "../Components";
import { NavLink, useNavigate } from "react-router-dom";
import axiosConfig from "../Service/axiosConfig";
import { MdDeleteOutline, MdOutlineEdit } from "react-icons/md";
import { FaHandHoldingHeart } from "react-icons/fa";
import DataTable from "react-data-table-component";
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import "./master_categories.css";
import { Button, Modal } from "react-bootstrap";
import { BiSearch } from "react-icons/bi";
import Masters from "../Components/masters.json";
import { NavbarComponent } from "../Components/navbar/NavComponent";
import useDebouncedValue from "../Utils/Debounce";
import { useURLParams } from '../Components/UrlUtils/useURLParams';
const Brand = () => {
  const MastersModal = Masters.Brand;
  const { setParam, getParam } = useURLParams();
  const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("");
  const [deletePopupType, setDeletepopupType] = useState("");
  const [loading, setLoading] = useState(false);
  const [RowId, SetRowId] = useState();
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [RowPerPage, setRowPerPage] = useState(20);
  const [popupType, setpopupType] = useState("");
  const [show, setShow] = useState(false);
  const [modalTitle, setModalTitle] = useState("Add New Item");
  const [message, setMessage] = useState("");
  const [Error, setError] = useState("");
  const handleClose = () => {
    setShow(false);
    setError("");
  };

  const tableCustomStyles = {
    rows: {
      style: {},
    },
    cells: {
      style: {
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        color: "#5C5C5C",
        justifyContent: "center",
        color: "rgb(33,37,41)",
        fontSize: "12px",
        fontWeight: 500,
        lineHeight: "28px",
      },
    },
  };
  const handleShow = () => {
    setShow(true);
    setFormData({
      brand_name: "",
      image: "",
      sort: "",
      slug: "",
    });
    setError("");
    setModalTitle("Add New Item");
  };
  const [search, SetSearch] = useState({
    search:getParam("search")||"",
  });
  const debouncedSearchTerm = useDebouncedValue(search.search, 500);
  const [Data, SetData] = useState([]);
  const GetData = async () => {
    setLoading(true);
    try {
      const responce = await axiosConfig.get(
        `catlog/brands/?page=${currentPage}&page_size=${RowPerPage}&search=${debouncedSearchTerm}`
      );
      SetData(responce.data.results);
      setLoading(false);
      setTotalRows(responce.data.count);
    } catch (error) {
      console.log(error);
    }
  };
  const HandleSearch = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    console.log(name, value);
    SetSearch({ ...search, [name]: value });
    setParam("search",value)
  };
  const handleSort = (column, direction) => {
    console.log(column.selector, direction);
    axiosConfig
      .get(
        `catlog/brands/?ordering=${
          direction === "asc" ? column.selector : -column.selector
        }`
      )
      .then((res) => {
        SetData(res.data.results);
      })
      .catch((error) => {
        console.log(error);
      });
    setCurrentPage(1);
  };
  const [formData, setFormData] = useState({
    brand_name: "",
    image: "",
    sort: "",
    slug: "",
  });

  const catedit = (id) => {
    axiosConfig
      .get(`catlog/brands/${id}/`)
      .then((res) => {
        console.log(res);
        setFormData(res.data);
        setShow(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    GetData();
  }, [currentPage, RowPerPage,debouncedSearchTerm]);

  console.log(formData);
  const formSubmit = (formData, setFieldErrors) => {
    console.log(formData);
    const formDataToSend = new FormData();
    formDataToSend.append("brand_name", formData.brand_name);
    formDataToSend.append("sort", formData.sort);
    formDataToSend.append("image", formData.image.id);
    formDataToSend.append("slug", formData.slug);
    axiosConfig.defaults.headers["Content-Type"] = "multipart/form-data";
    if (formData.id) {
      axiosConfig
        .patch(`catlog/brands/${formData.id}/`, formDataToSend)
        .then((res) => {
          console.log(res);
          setpopupType("success");
          setMessage("Brand Updated Successfully");
          setShow(false);
          GetData();
          setError("");
        })
        .catch((error) => {
          console.log(error);
          setpopupType("error");
          if (error.response.data.brand_name) {
            setFieldErrors((prev) => ({
              ...prev,
              ["brand_name"]: "Brand name already exists.",
            }));
            setError("Brand name already exists.");
          }
          if (
            error.response.data.error === "Brand with this slug already exists."
          ) {
            setError("Brand with this slug already exists.");
          }
          if (error.response.data.sort[0] === "A valid integer is required.") {
            setError("A valid integer is required.");
          }
        });
    } else {
      axiosConfig
        .post("catlog/brands/", formDataToSend)
        .then((res) => {
          console.log(res);
          setMessage("Brand Created Successfully");
          setpopupType("success");
          GetData();
          setShow(false);
        })
        .catch((error) => {
          console.log(error);
          setpopupType("error");
          if (error.response.data.brand_name) {
            setFieldErrors((prev) => ({
              ...prev,
              ["brand_name"]: "Brand name already exists.",
            }));
            setError("Brand name already exists.");
          }
          if (
            error.response.data.error === "Brand with this slug already exists."
          ) {
            setError("Brand with this slug already exists.");
          }
          if (error.response.data.sort[0] === "A valid integer is required.") {
            setError("A valid integer is required.");
          }
        });
    }
  };

  const columns = [
    {
      name: "Name",
      selector: "brand_name",
      cell: (row) => row.brand_name,
      sortable: true,
    },
    {
      name: "Image",
      selector: (row) => (
        <>
          <img
            className="image"
            src={row.image && row.image.image}
            alt={row.image && row.image.image}
          />
        </>
      ),
      sortable: true,
    },
    {
      name: "Slug",
      selector: "slug",
      cell: (row) => row.slug,
      sortable: true,
    },
    {
      name: "sort",
      selector: "sort",
      cell: (row) => row.sort,
      sortable: true,
    },
    {
      name: "ACTIONS",
      selector: (row) => {
        const HandleEdit = async () => {
          console.log(row.id, "iddddd");
          catedit(row.id);
          setModalTitle("Edit Item");
        };
        const DeleteAlert = () => {
          SetRowId(row.id);
          SetAlertDeletePopupType("success");
        };
        const CancelAlerrt = () => {
          SetAlertDeletePopupType("error");
        };
        const HandleDelete = async (id) => {
          await axiosConfig
            .delete(`catlog/brands/${id}/`)
            .then(() => {
              SetAlertDeletePopupType("error");
              setDeletepopupType("success");
              GetData();
            })
            .catch((error) => {
              setDeletepopupType("error");
              SetAlertDeletePopupType("error");
              console.log(error);
            });
        };
        const handleRevive = async () => {
          try {
            const response = await axiosConfig.post(
              `catlog/brands/${row.id}/revive/`
            );
            setpopupType("success");
            setMessage("Product revived successfully");
            GetData();
          } catch (error) {
            console.log("Error While Reviving", error);
          }
        };
        return (
          <>
            {!row.is_suspended ? (
              <div className="row">
                <div className="col-4">
                  <MdOutlineEdit
                    className="dashboard-tabe-data-actions"
                    onClick={HandleEdit}
                  />
                </div>
                <div className="col-4">
                  <MdDeleteOutline
                    className="dashboard-tabe-data-actions"
                    onClick={DeleteAlert}
                  />
                </div>
              </div>
            ) : (
              <>
                <FaHandHoldingHeart
                  className="dashboard-tabe-data-actions"
                  onClick={handleRevive}
                />
              </>
            )}

            {AlertDeletePopupType === "success" && (
              <AlertDeletePopupModal
                AlertDeletePopupType={AlertDeletePopupType}
                HandleDelete={HandleDelete}
                CancelAlerrt={CancelAlerrt}
                row={RowId}
              />
            )}
          </>
        );
      },
    },
  ];
  return (
    <>
      <div className="child-sidenav">
        <NavbarComponent />
        <div className="childnav-content">
          <div className="d-flex align-items-center">
            <div className="input-cat-with-icon">
              <span className="icon-product">
                <BiSearch />
              </span>
              <input
                type="text"
                className="input-control"
                placeholder="Search"
                name="search"
                value={search.search}
                onChange={HandleSearch}
              />
            </div>
            <div className="cat-button">
              <div className="btn" onClick={handleShow}>
                + Add New
              </div>
            </div>
          </div>
          <div className="table-responsive marginTop">
            <DataTable
              columns={columns}
              data={Data}
              pagination
              paginationTotalRows={totalRows}
              paginationPerPage={RowPerPage}
              paginationDefaultPage={currentPage}
              onChangePage={(page) => setCurrentPage(page)}
              paginationPerPageOptions={[10, 20, 25, 30, 40, 50, totalRows]}
              paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50, totalRows]}
              onChangeRowsPerPage={(RowsPerPageChange) =>
                setRowPerPage(RowsPerPageChange)
              }
              onSort={handleSort}
              paginationServer
              fixedHeader
              subHeader
              customStyles={tableCustomStyles}
              progressPending={loading}
              progressComponent={
                <div className="loader-container">
                  <img
                    src={require("../Components/FormParser/images/loader2.gif")}
                    alt="Loading..."
                  />
                </div>
              }
            />
          </div>
        </div>
      </div>
      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            {modalTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormParser
            modelObject={MastersModal}
            formData={formData}
            formSubmit={formSubmit}
            button="btn-block"
          />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      {popupType != "" && (
        <PopupModal
          popupType={popupType}
          setpopupType={setpopupType}
          Navigation="/dashboard/masters/brand"
          Message={message}
          error={Error}
        />
      )}
      {deletePopupType != "" && (
        <DeletePopupModal
          setDeletepopupType={setDeletepopupType}
          deletePopupType={deletePopupType}
        />
      )}
    </>
  );
};
export { Brand };

import React, { useState, useEffect } from "react";
import { Layout, FormParser, PopupModal } from "../Components";
import { NavLink, useNavigate} from "react-router-dom";
import Masters from "../Components/masters.json"
import "./Pages.css"
import axiosConfig from "../Service/axiosConfig"

const ReferenceDoaminAdd = () => {
    const [popupType, setpopupType] = useState("");
    const[Error,SetError]=useState("")
    const MastersModal=Masters.ReferenceDomain
    const formSubmit=(Data)=>{
        console.log(Data)
        axiosConfig.post("/masters/referencedomain/",Data).then(res=>{
                   console.log(res)
                   setpopupType("success")
        }).catch(error=>{
            console.log(error)
            const ErrorData=[]
            ErrorData.push({
                    code:error.response.data.code||["null"]
                    })
                    console.log(ErrorData[0].code)
                    if(ErrorData[0].code[0]==="This field must be unique."){
                        SetError("Code Already Exists")
                    }
            setpopupType("error")
        })
      }
    return (
        <>
            <Layout>
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">Add Domain</h5>
                    <NavLink to="/dashboard/masters/domains" ><button className="btn btn-white">Manage</button></NavLink>
                </div>
                <FormParser modelObject={MastersModal} formSubmit={formSubmit} error={Error}/>
                <div className="f-copy">
                    <p><span className="copy-sym">&copy;</span> Pride Product of Apollo Radiology International. All Copy Rights Reserved 2023
                    </p>
                </div>
                {popupType!=="" &&
                    <PopupModal popupType={popupType} Navigation="/dashboard/masters/domains" />
                }
            </Layout>
        </>
    )

}
export {ReferenceDoaminAdd}
import React, { useState } from 'react';
import { DashboardLayout } from '../DashboardLayout/DashboardLayout';
import { CMSNavbarComponent } from '../navbar/CMSnavcomponent';
import ModuleModal from './ModuleModal';
import { Carousels } from "./carousels"
import { TextBlock } from './TextBlocks';
import { FullWidthBanners } from './FullWidthBanners';
import { Banner } from '../../Pages';
import Settingsmodal from './Settingsmodal';
function Homedesign() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [moduleButtons, setModuleButtons] = useState([{ id: 1 }]); // Initial modules
  const [id, setid] = useState();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const openModal = (id) => {
    setModalIsOpen(true);
    setid(id)
  };

  const addNewModule = () => {
    setModuleButtons([...moduleButtons, { id: moduleButtons.length + 1 }]);
  };
  const addNewComponent = (id, type) => {
    const index = moduleButtons.findIndex((val) => val.id === id);
    let updatedData = [...moduleButtons];

    if (index !== -1) {
      console.log(type)
      if (type === "Carousel") {
        updatedData[index] = { id: moduleButtons[index].id, component: "Carousel" };
        setModuleButtons(updatedData);
      } else if (type === "Text Block") {
        updatedData[index] = { id: moduleButtons[index].id, component: "Text Block" };
        setModuleButtons(updatedData);
      } else if (type === "Full Width Banner") {
        updatedData[index] = { id: moduleButtons[index].id, component: "Full Width Banner" };
        setModuleButtons(updatedData);
      } else if (type === "Banner Slider") {
        updatedData[index] = { id: moduleButtons[index].id, component: "Banner Slider" };
        setModuleButtons(updatedData);
      }
    }
  };

  return (
    <div className="child-sidenav">
      <CMSNavbarComponent handleShow={handleShow} />
      <div className="childnav-content">
        {moduleButtons.map((module, index) => (
          <div key={index}>
            {module.component ? (
              // Render the component
              <div key={index}>
                {module.component === "Carousel" ? (
                  <Carousels key={module.id} id={module.id} setModuleButtons={setModuleButtons} moduleButtons={moduleButtons} />
                ) : module.component === "Text Block" ? (
                  <TextBlock key={module.id} id={module.id} setModuleButtons={setModuleButtons} moduleButtons={moduleButtons} />
                ) : module.component === "Full Width Banner" ? (
                  <FullWidthBanners key={module.id} id={module.id} setModuleButtons={setModuleButtons} moduleButtons={moduleButtons} />
                ) : module.component === "Banner Slider" ? (
                  <Banner key={module.id} id={module.id} setModuleButtons={setModuleButtons} moduleButtons={moduleButtons} />
                ) : null}
              </div>
            ) : (
              // Render the button to add a new module
              <div className='btn-add-module'>
                <button className='btn btn-primary' onClick={() => openModal(module.id)}>
                  Add Module
                </button>
              </div>
            )}
          </div>
        ))}

        <div className='text-center cursor-pointer' onClick={addNewModule}>
          <p className='text-decoration-underline py-4'>+ Add New Section</p>
        </div>
      </div>
      {
        modalIsOpen &&
        <ModuleModal modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} addNewComponent={addNewComponent} id={id} />
      }
      {
        show &&
        <Settingsmodal show={show} setShow={setShow} />
      }
    </div>
  );
}

export { Homedesign };

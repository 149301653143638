import React, { useEffect, useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { DashboardLayout, Pagination } from "../Components";
import { FaRegUserCircle } from "react-icons/fa";
import { BiArrowBack } from "react-icons/bi";
import axiosConfig from "../Service/axiosConfig";
import { MdOutlineMedicalInformation, MdListAlt, MdMeetingRoom, MdHistory } from "react-icons/md"
import { FaFileInvoice } from "react-icons/fa"
import sidebarData from "../Components/DashboardLayout/sidebar";
import DataTable from 'react-data-table-component';
import tableCustomStyles from "../Components/PopupModal/tableCustomStyles";
import { useParams } from "react-router-dom";

const PatientRecords = () => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [patientData, setPatientData] = useState([])
    const [records, setRecords] = useState([])
    const [Direction, setDirection] = useState("");
    const [Columns, setColumns] = useState("");
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(20);
    const [patientDataFetched, setPatientDataFetched] = useState(false); 
    const [search, SetSearch] = useState({
        search: ""
    })
    const [ChildNavs, SetChildNavs] = useState("")
    const { uhid } = useParams();
    let patient_details;
    const patient_profile = [
        {
            "SubTitle": "Patient Profile",
            "NavItems": [
                { "label": "Info", "path": `/dashboard/patients/${uhid}/info`, "icon": <MdOutlineMedicalInformation fontSize='small' /> },
                { "label": "Invoices", "path": `/dashboard/patients/${uhid}/invoices`, "icon": <FaFileInvoice fontSize='small' /> },
                { "label": "Records", "path": `/dashboard/patients/${uhid}/records`, "icon": <MdListAlt fontSize='small' /> },
                { "label": "Appointments", "path": `/dashboard/patients/${uhid}/appointments`, "icon": <MdMeetingRoom fontSize='small' /> },
                { "label": "Visit History", "path": `/dashboard/patients/${uhid}/visit_history`, "icon": <MdHistory fontSize='small' /> },
            ]
        }
    ]

    const patient_records = [
        {
            name: 'ID',
            selector: 'id',
            cell: row => row.id,
            sortable: true,
        },
        {
            name: 'Title',
            selector: 'title',
            cell: row => row.title,
            sortable: true,
        },
        {
            name: 'Bill',
            selector: 'bill',
            cell: row => row.bill,
            sortable: true,
        },
        {
            name: 'Date',
            selector: 'date',
            cell: row => row.date,
            sortable: true,
        },
        {
            name: 'Patient',
            selector: 'patient',
            cell: row => row.patient,
            sortable: true,
        }
    ];

    const GetPatientData = async () => {
        setLoading(true)
        try {
            const responce = await axiosConfig.get(`/clinics/patient/?uhid=${uhid}`)
            setPatientData(responce.data.results)
            setPatientDataFetched(true)
            setLoading(false)
        }
        catch (error) {
            console.log(error)
        }
    }

    const GetPatientRecords = async () => {
        setLoading(true)
        try {
            const responce = await axiosConfig.get(`/clinics/PatientRecord/?patient_id=${patientData?patientData[0].id:""}&page=${currentPage}&page_size=${RowPerPage}&ordering=${Direction === "asc" ? Columns : `-${Columns}`}`)
            console.log(responce,"patient records")
            setRecords(responce.data.results)
            setLoading(false)
        }
        catch (error) {
            console.log(error)
        }
    }

    const OnChildNavClicked = (path) => {
        SetChildNavs(path)
        localStorage.setItem("url", path)
    }

    const HandleSearch = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        axiosConfig.get(`clinics/PatientRecord/?search=${value}`).then(res => {
            setRecords(res.data.results)
        }).catch(error => {
            console.log(error)
        })
        SetSearch({ ...search, [name]: value })
    }

    const handleSort = (column, direction) => {
        setDirection(direction)
        setColumns(column.selector)
        setCurrentPage(1);
    };

    useEffect(() => {
        GetPatientData()
        if (patientData) {
            GetPatientRecords();
        }
    }, [currentPage, RowPerPage, Columns, Direction,patientDataFetched])

    return (
        <DashboardLayout>
            <div className="mainbar">
                <div className="sidebar-filters px-3" style={{ height: "100vh" }}>
                    <button className="btn btn-primary mb-3" onClick={() => navigate(`/dashboard/patients/registration`)}><BiArrowBack />back to patients</button>
                    {
                        patient_profile.map((res, index) => {
                            return (
                                <div key={index}>
                                    <h5>{res.SubTitle}</h5>
                                    {
                                        res.NavItems.map((item, idx) => {
                                            return (
                                                <div key={idx} className={ChildNavs == item.path ? "Configiration-list active" : "Configiration-list"}>
                                                    <NavLink to={item.path} className={ChildNavs == item.path ? "colorChange" : "NoColorChange"} onClick={() => OnChildNavClicked(item.path)}>
                                                        <span className="configiration-icon">{item.icon}</span>
                                                        <span className="list-name">{item.label}</span>
                                                    </NavLink>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            )
                        })
                    }
                </div>
                <div className="main-code">
                    <div className="dashboard-header">
                        <h5 className="dashboard-subtitle">Patient Records</h5>
                        <NavLink className="text-decoration-none" to="#"><button className="btn btn-primary d-inline">New Invoice</button></NavLink>
                    </div>
                    <DataTable
                        columns={patient_records}
                        data={records}
                        subHeader
                        fixedHeader
                        pagination paginationTotalRows={totalRows}
                        paginationPerPage={RowPerPage}
                        paginationDefaultPage={currentPage}
                        onChangePage={(page) => setCurrentPage(page)}
                        paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
                        paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
                        onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
                        onSort={handleSort}
                        paginationServer
                        subHeaderComponent={
                            <>
                                <input type="text" className="form-control w-25" name="search" value={search.search} onChange={HandleSearch} />
                            </>}
                        customStyles={tableCustomStyles}
                    />
                </div>
            </div>
        </DashboardLayout>
    )
}

export { PatientRecords }
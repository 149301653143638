import React, { useEffect, useState } from "react";
import { DashboardLayout, Pagination } from "../Components";
import DataTable from 'react-data-table-component';
import { AiOutlineEye } from "react-icons/ai"
import { MdDeleteOutline, MdOutlineEdit } from "react-icons/md"
import tableCustomStyles from "../Components/PopupModal/tableCustomStyles";
import { NavLink, useNavigate, useSearchParams, useParams } from "react-router-dom";
import axiosConfig from "../Service/axiosConfig"




const ClientBillDetails = () => {

    let navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    let { id } = useParams();
    const [billDetails, setBillDetails] = useState([])
    const [patientDetails, setPatientDetails] = useState({})
    const [total, setTotal] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [toggleValue, setToggleValue] = useState(true)
    const [search, SetSearch] = useState({
        search: ""
    })

    const handleToggle = () => {
        // alert('discounted')
        setToggleValue(!toggleValue);
    };

    const GetBillDetails = async () => {

        setLoading(true)
        try {
            const responce = await axiosConfig.get(`clinics/bill-items/?&patient=${id}`)
            setBillDetails(responce.data.results)
            responce.data.results.map((res, i) => {
                if (i == 0) {
                    setPatientDetails(res.patient)
                }
            })
            if (responce.data.results.length > 0) {
                const serviceAmounts = responce.data.results.map((result) => parseFloat(result.service_amount));
                const discounts = responce.data.results.map((result) => parseFloat(result.discount));
                const totalSum = serviceAmounts.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                const totalDiscount = discounts.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                setTotal(totalSum);
                setDiscount(totalDiscount);
            }
            setLoading(false)
        }
        catch (error) {
            console.log(error)
        }
    }

    const HandleSearch = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        console.log(name, value, "value")
        axiosConfig.get(`clinics/bill-items/?search=${value}`).then(res => {
            setBillDetails(res.data.results)
        }).catch(error => {
            console.log(error)
        })
        SetSearch({ ...search, [name]: value })
    }

    useEffect(() => {
        GetBillDetails();
    }, [])

    const patientColumns = [
        {
            name: "S.No",
            selector: (row, i) => i + 1,
            sortable: true
        },
        {
            name: "Service",
            selector: row => row.service
            ,
            sortable: true
        },
        {
            name: "Date",
            selector: row => row.booking_date_time.split('T')[0],
            sortable: true
        },
        {
            name: "Service Amount",
            selector: row => row.service_amount,
            sortable: true
        },
        {
            name: "Doctor",
            selector: row => row.doctor_name,
            sortable: true
        },
        {
            name: "Status",
            selector: row => row.status,
            sortable: true
        }
    ]

    return (
        <>
            <DashboardLayout>
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">Client Bill Details</h5>
                    <button className="btn btn-primary d-inline">New Registration</button>
                </div>

                <div className="d-flex justify-content-between px-4 my-5">
                    <button className="btn btn-primary">New service</button>
                </div>

                <div className="d-flex justify-content-around text-center my-3">
                    <label>
                        <h6>UH Id</h6>
                        <span>{patientDetails.uhid}</span>
                    </label>
                    <label>
                        <h6>Name</h6>
                        <span>{patientDetails.name}</span>
                    </label>
                    <label>
                        <h6>Age</h6>
                        <span>{patientDetails.age}</span>
                    </label>
                    <label>
                        <h6>Gender</h6>
                        <span>{patientDetails.gender}</span>
                    </label>
                </div>

                <div className="d-flex justify-content-between px-4">
                    <h5 className="mt-5">Unpaid list:</h5>
                    <input type="checkbox" />
                </div>
                <DataTable
                    columns={patientColumns}
                    data={billDetails}
                    subHeader
                    fixedHeader
                    pagination
                    subHeaderComponent={
                        <>
                            <input type="text" className="form-control w-25" name="search"
                                value={search.search}
                                onChange={HandleSearch}
                            />
                        </>}
                    customStyles={tableCustomStyles} />
                <div className="float-end me-5">
                    <div className="btn btn-primary mb-3" onClick={handleToggle}>+ Discount</div>
                    <p><b>Total Amount:</b> {total}</p>
                    <p><b>Discount:</b> {toggleValue ? discount : 0}</p>
                    <p><b>Net Amount:</b> {total - discount}</p>
                </div>

            </DashboardLayout>
        </>
    )
}

export { ClientBillDetails }

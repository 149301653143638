import React, { useEffect, useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { DashboardLayout, Pagination } from "../Components";
import { FaRegUserCircle } from "react-icons/fa";
import { BiArrowBack } from "react-icons/bi";
import axiosConfig from "../Service/axiosConfig";
import { MdOutlineMedicalInformation, MdListAlt, MdMeetingRoom, MdHistory } from "react-icons/md"
import { FaFileInvoice } from "react-icons/fa"
import sidebarData from "../Components/DashboardLayout/sidebar";
import DataTable from 'react-data-table-component';
import tableCustomStyles from "../Components/PopupModal/tableCustomStyles";
import { useParams } from "react-router-dom";
import { CreateAppointment } from "./AppointmentsCreate";

const PatientAppointments = () => {

    const navigate = useNavigate();
    const[Appointment,setAppointment]=useState()
    const [loading, setLoading] = useState(false);
    const [patientData, setPatientData] = useState([])
    const[patient,setpatient]=useState({})
    const [appointments, setAppointments] = useState([])
    const [ChildNavs, SetChildNavs] = useState("")
    const [Direction, setDirection] = useState("");
    const [Columns, setColumns] = useState("");
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(20);
    const [activeButton, setActiveButton] = useState('');
    const [patientDataFetched, setPatientDataFetched] = useState(false);
    const [search, SetSearch] = useState({
        search: ""
    })
    const { uhid } = useParams();
    let patient_details;
    const patient_profile = [
        {
            "SubTitle": "Patient Profile",
            "NavItems": [
                { "label": "Info", "path": `/dashboard/patients/${uhid}/info`, "icon": <MdOutlineMedicalInformation fontSize='small' /> },
                { "label": "Invoices", "path": `/dashboard/patients/${uhid}/invoices`, "icon": <FaFileInvoice fontSize='small' /> },
                { "label": "Records", "path": `/dashboard/patients/${uhid}/records`, "icon": <MdListAlt fontSize='small' /> },
                { "label": "Appointments", "path": `/dashboard/patients/${uhid}/appointments`, "icon": <MdMeetingRoom fontSize='small' /> },
                { "label": "Visit History", "path": `/dashboard/patients/${uhid}/visit_history`, "icon": <MdHistory fontSize='small' /> },
            ]
        }
    ]

    const appointment_columns = [
        {
            name: 'ID',
            selector: row => row.id,
            sortable: true,
        },
        {
            name: 'Speciality',
            selector: "speciality_name",
            cell: row => row.speciality_name,
            sortable: true,
        },
        {
            name: 'Doctor',
            selector: row => row.doctor_name,
            sortable: true,
        },
        {
            name: 'Service',
            selector: row => row.service_name,
            sortable: true,
        },
        {
            name: 'Start Time',
            selector: "appointment_start_datetime",
            cell: row => new Date(row.appointment_start_datetime).toLocaleString(),
            sortable: true,
        },
        {
            name: 'End Time',
            selector: "appointment_end_datetime",
            cell: row => new Date(row.appointment_end_datetime).toLocaleString(),
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.appointment_status,
            sortable: true,
        },
        {
            name: 'Patient Name',
            selector: row => row.patient_name,
            sortable: true,
        },
        {
            name: 'Age',
            selector: row => row.age,
            sortable: true,
        },
        {
            name: 'Mobile No',
            selector: row => row.mobile_no,
            sortable: true,
        },
        {
            name: 'Service Amount',
            selector: "service_amount",
            cell: row => row.service_amount !== null ? row.service_amount : "N/A",
            sortable: true,
        },
        {
            name: "ACTIONS",
            selector: row => {
                const HandleView = (e) => {
                    const { name, value } = e.target
                    if (value === "0") {
                        navigate(`/dashboard/patients/${row.uhid}/reschedule`)
                        localStorage.setItem("url", `/dashboard/patients/${row.uhid}/reschedule`)
                    }
                    if (value === "1") {
                        navigate(`/dashboard/patients/${row.uhid}/cancel`)
                        localStorage.setItem("url", `/dashboard/patients/${row.uhid}/cancel`)
                    }
                }
                return (
                    <>
                        <div className="row" onChange={HandleView}>
                            <div className="col">
                                <select className="form-select" >
                                    <option value="">SELECT</option>
                                    <option value="0">Reschedule</option>
                                    <option value="1">Cancel</option>
                                </select>
                            </div>
                        </div>
                    </>
                )

            }
        }
    ];

    const GetPatientData = async () => {
        setLoading(true)
        try {
            const responce = await axiosConfig.get(`/clinics/patient/?uhid=${uhid}`)
            setPatientData(responce.data.results)
            setPatientDataFetched(true)
            setLoading(false)
        }
        catch (error) {
            console.log(error)
        }
    }

    const GetAppointmentsList = async () => {
        setLoading(true)
        try {
            const responce = await axiosConfig.get(`/clinics/appointment/?clinic=&speciality=&doctor=&service=&appointment_start_datetime_after=&appointment_start_datetime_before=&appointment_status=&patient=${patientData ? patientData[0].id : ""}&page=${currentPage}&page_size=${RowPerPage}&ordering=${Direction === "asc" ? Columns : `-${Columns}`}`)
            setAppointments(responce.data.results)
            console.log(responce.data.results, "appointments patient")
            setTotalRows(responce.data.count)
            setLoading(false)
        }
        catch (error) {
            console.log(error)
        }
    }

    const OnChildNavClicked = (path) => {
        console.log(path, "path")
        SetChildNavs(path)
        localStorage.setItem("url", path)
    }

    const handleFilters = async (filter) => {
        setLoading(true)
        try {
            const responce = await axiosConfig.get(`/clinics/appointment/?clinic=&speciality=&doctor=&service=&appointment_start_datetime_after=&appointment_start_datetime_before=&appointment_status=${filter}`)
            setAppointments(responce.data.results)
            setLoading(false)
            setActiveButton(filter);
        }
        catch (error) {
            console.log(error)
        }
    }

    const HandleSearch = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        axiosConfig.get(`clinics/appointment/?search=${value}`).then(res => {
            setAppointments(res.data.results)
        }).catch(error => {
            console.log(error)
        })
        SetSearch({ ...search, [name]: value })
    }

    const handleSort = (column, direction) => {
        setDirection(direction)
        setColumns(column.selector)
        setCurrentPage(1);
    };

    useEffect(() => {
        GetPatientData()
        if (patientData) {
            GetAppointmentsList()
        }
    }, [currentPage, RowPerPage, Columns, Direction,patientDataFetched])
const CreateApp=()=>{
    setAppointment("success")
    patientData.forEach(patient=>{
        setpatient({patient:patient.id,service_type:"appointment"})
    })
}
    return (
        <DashboardLayout>
            <div className="mainbar">
                <div className="sidebar-filters px-3" style={{ height: "100vh" }}>
                    <button className="btn btn-primary mb-3" onClick={() => navigate(`/dashboard/patients/registration`)}><BiArrowBack />back to patients</button>
                    {
                        patient_profile.map((res, index) => {
                            return (
                                <div key={index}>
                                    <h5>{res.SubTitle}</h5>
                                    {
                                        res.NavItems.map((item, idx) => {
                                            return (
                                                <div key={idx} className={ChildNavs == item.path ? "Configiration-list active" : "Configiration-list"}>
                                                    <NavLink to={item.path} className={ChildNavs == item.path ? "colorChange" : "NoColorChange"} onClick={() => OnChildNavClicked(item.path)}>
                                                        <span className="configiration-icon">{item.icon}</span>
                                                        <span className="list-name">{item.label}</span>
                                                    </NavLink>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            )
                        })
                    }
                </div>
                <div className="main-code">
                    <div className="dashboard-header">
                        <h5 className="dashboard-subtitle">Patient Appointments</h5>
                        <NavLink className="text-decoration-none" to="#"><button className="btn btn-primary d-inline" onClick={CreateApp}>New Appointment</button></NavLink>
                    </div>

                    <DataTable
                        columns={appointment_columns}
                        data={appointments}
                        subHeader
                        fixedHeader
                        pagination paginationTotalRows={totalRows}
                        paginationPerPage={RowPerPage}
                        paginationDefaultPage={currentPage}
                        onChangePage={(page) => setCurrentPage(page)}
                        paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
                        paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
                        onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
                        onSort={handleSort}
                        paginationServer
                        customStyles={tableCustomStyles}
                        subHeaderComponent={
                            <>
                                <div className="d-flex">
                                    <button
                                        className={`${activeButton === 'Scheduled' ? 'btn btn-primary' : 'btn btn-white'}`}
                                        onClick={() => handleFilters('Scheduled')}>
                                        Scheduled
                                    </button>
                                    <button
                                        className={`${activeButton === 'Completed' ? 'btn btn-primary' : 'btn btn-white'}`}
                                        onClick={() => handleFilters('Completed')}>
                                        Completed
                                    </button>
                                    <input type="text" className="form-control" name="search" value={search.search} onChange={HandleSearch} />
                                </div>
                            </>}
                    />
                </div>
                {
          Appointment === "success" &&
          <CreateAppointment  setAppointment={setAppointment} Appointment={Appointment} doctor={patient}  />
        }
            </div>
        </DashboardLayout>
    )
}

export { PatientAppointments }
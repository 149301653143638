import React from 'react';

const generateSlots = (fromTime, toTime, slotTime) => {
  const slots = [];
  let currentTime = fromTime;

  while (currentTime <= toTime) {
    slots.push(currentTime);
    const [hours, minutes, seconds] = currentTime.split(':').map(Number);
    currentTime = new Date(0, 0, 0, hours, minutes + slotTime, seconds)
      .toTimeString()
      .slice(0, 8);
  }

  return slots;
};

const Slots = () => {
  const results = [
    {
        "id": 42,
        "doctor": 2,
        "days": "Monday",
        "from_time": "02:00:00",
        "to_time": "05:00:00",
        "doctor_name": "DEMO",
        "slot_time": 15
    },
    {
        "id": 43,
        "doctor": 2,
        "days": "Tuesday",
        "from_time": "02:00:00",
        "to_time": "05:00:00",
        "doctor_name": "DEMO",
        "slot_time": 15
    },
    {
        "id": 44,
        "doctor": 3,
        "days": "Sunday",
        "from_time": "15:27:00",
        "to_time": "15:25:00",
        "doctor_name": "Spectra",
        "slot_time": 15
    },
    {
        "id": 45,
        "doctor": 3,
        "days": "Monday",
        "from_time": "15:21:00",
        "to_time": "15:26:00",
        "doctor_name": "Spectra",
        "slot_time": 15
    },
    {
        "id": 46,
        "doctor": 2,
        "days": "Sunday",
        "from_time": "16:18:00",
        "to_time": "22:25:00",
        "doctor_name": "DEMO",
        "slot_time": 15
    },
    {
        "id": 47,
        "doctor": 2,
        "days": "Monday",
        "from_time": "16:19:00",
        "to_time": "22:25:00",
        "doctor_name": "DEMO",
        "slot_time": 15
    },
    {
        "id": 48,
        "doctor": 2,
        "days": "Sunday",
        "from_time": "18:03:00",
        "to_time": "23:08:00",
        "doctor_name": "DEMO",
        "slot_time": 15
    },
    {
        "id": 49,
        "doctor": 2,
        "days": "Sunday",
        "from_time": "10:59:00",
        "to_time": "16:02:00",
        "doctor_name": "DEMO",
        "slot_time": 15
    },
    {
        "id": 53,
        "doctor": 2,
        "days": "Sunday",
        "from_time": "13:00:00",
        "to_time": "22:10:00",
        "doctor_name": "DEMO",
        "slot_time": 15
    },
    {
        "id": 33,
        "doctor": 1,
        "days": "Sunday",
        "from_time": "15:20:00",
        "to_time": "15:21:00",
        "doctor_name": "Doctor DEMO",
        "slot_time": 15
    },
    {
        "id": 34,
        "doctor": 1,
        "days": "Monday",
        "from_time": "15:20:00",
        "to_time": "15:21:00",
        "doctor_name": "Doctor DEMO",
        "slot_time": 15
    },
    {
        "id": 35,
        "doctor": 1,
        "days": "Thursday",
        "from_time": "15:20:00",
        "to_time": "15:21:00",
        "doctor_name": "Doctor DEMO",
        "slot_time": 15
    },
    {
        "id": 36,
        "doctor": 1,
        "days": "Saturday",
        "from_time": "15:20:00",
        "to_time": "15:22:00",
        "doctor_name": "Doctor DEMO",
        "slot_time": 15
    },
    {
        "id": 37,
        "doctor": 1,
        "days": "Tuesday",
        "from_time": "23:25:00",
        "to_time": "23:26:00",
        "doctor_name": "Doctor DEMO",
        "slot_time": 15
    },
    {
        "id": 38,
        "doctor": 1,
        "days": "Wednesday",
        "from_time": "23:26:00",
        "to_time": "23:27:00",
        "doctor_name": "Doctor DEMO",
        "slot_time": 15
    }
];

  const doctors = [...new Set(results.map(result => result.doctor))];
  const days = [...new Set(results.map(result => result.days))];
  const doctorSlots = doctors.map(doctor => {
    const doctorResults = results.filter(result => result.doctor === doctor);
    const doctorName = doctorResults[0].doctor_name;
    const daySlots = days.map(day => {
      const dayResults = doctorResults.filter(result => result.days === day);
      console.log(dayResults)
      const fromTime = dayResults.reduce((min, result) => {
        return result.from_time < min ? result.from_time : min;
      }, '23:59:59');

      const toTime = dayResults.reduce((max, result) => {
        return result.to_time > max ? result.to_time : max;
      }, '00:00:00');
      const slots = generateSlots(fromTime, toTime, dayResults[0]&&dayResults[0].slot_time);
      console.log(slots)
      return { day, slots };
    });

    return { doctorName, daySlots };
  });

  return (
    <div>
      {doctorSlots.map(doctorSlot => (
        <div key={doctorSlot.doctorName}>
          <h3>{doctorSlot.doctorName}</h3>
          {doctorSlot.daySlots.map(daySlot => (
            <div key={daySlot.day}>
              <h4>{daySlot.day}</h4>
              {daySlot.slots.map(slot => (
                <div key={slot}>{slot}</div>
              ))}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export {Slots};

import React, { useEffect, useState } from "react";
import { DashboardLayout, Pagination } from "../Components";
import DataTable from 'react-data-table-component';
import { AiOutlineEye } from "react-icons/ai"
import { MdDeleteOutline, MdOutlineEdit } from "react-icons/md"
import tableCustomStyles from "../Components/PopupModal/tableCustomStyles";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import axiosConfig from "../Service/axiosConfig"
import Select from 'react-select';

const PatientRegistration = () => {

    let navigate = useNavigate();
    let today_date = (new Date()).toISOString().split('T')[0];
    const [patientData, setPatientData] = useState([])
    const [clinics, setClinics] = useState()
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState({
        fromDate: today_date,
        toDate: today_date,
        clinic: ''
    });
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(20);
    const [search, SetSearch] = useState({
        search: ""
    })
    const [userData, setUserData] = useState({})
    const GetPatientData = async () => {
        setLoading(true)
        try {
            const responce = await axiosConfig.get(`/clinics/patient/?page=${currentPage}&page_size=${RowPerPage}`)
            setPatientData(responce.data.results)
            setLoading(false)

        }
        catch (error) {
            console.log(error)
        }
    }

    const getClinics = async () => {
        try {
            const responce = await axiosConfig.get(`/clinics/clinics/`)
            setClinics(responce.data.results)
        }
        catch (error) {
            console.log(error)
        }

    }

    const HandleSearch = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        console.log(name, value, "value")
        axiosConfig.get(`clinics/patient/?search=${value}`).then(res => {
            setPatientData(res.data.results)
        }).catch(error => {
            console.log(error)
        })
        SetSearch({ ...search, [name]: value })
    }

    const handleSort = (column, direction) => {
        axiosConfig.get(`/clinics/patient/?ordering=${direction === "asc" ? column.selector : -column.selector}`).then(res => {
            setPatientData(res.data.results)
        }).catch(error => {
            console.log(error)
        })
        setCurrentPage(1);
    };
    let dta = localStorage.getItem("user_data")
    useEffect(() => {
        GetPatientData();
        getClinics();
        setUserData(JSON.parse(dta))
    }, [])

    const patientColumns = [

        {
            name: "UH Id",
            selector: "uhid",
            sortable: true
        },
        {
            name: "Name",
            selector: "name",
            sortable: true
        },
        {
            name: "Age",
            selector: "age",
            sortable: true
        },
        {
            name: "Gender",
            selector: "gender",
            sortable: true
        },
        {
            name: "Mobile no.",
            selector: "mobile_no",
            sortable: true
        },
        {
            name: "ACTIONS",
            selector: row => {
                const HandleView = (e) => {
                    const { name, value } = e.target
                    if (value === "0") {
                        navigate(`/dashboard/patients/${row.uhid}/info`)
                        localStorage.setItem("url", `/dashboard/patients/${row.uhid}/info`)
                    }
                    if (value === "1") {
                        navigate(`/dashboard/patients/${row.uhid}/invoices`)
                        localStorage.setItem("url", `/dashboard/patients/${row.uhid}/invoices`)
                    }
                    if (value === "2") {
                        navigate(`/dashboard/patients/${row.uhid}/records`)
                        localStorage.setItem("url", (`/dashboard/patients/${row.uhid}/records`))
                    }
                    if (value === "3") {
                        navigate(`/dashboard/patients/${row.uhid}/appointments`)
                        localStorage.setItem("url", `/dashboard/patients/${row.uhid}/appointments`)
                    }
                    if (value === "4") {
                        navigate(`/dashboard/patients/${row.uhid}/visit_history`)
                        localStorage.setItem("url", `/dashboard/patients/${row.uhid}/visit_history`)
                    }
                }
                return (
                    <>
                        <div className="row" onChange={HandleView}>
                            <div className="col">
                                <select className="form-select" >
                                    <option value="">SELECT</option>
                                    <option value="0">Info/Edit</option>
                                    <option value="1">Invoices</option>
                                    <option value="2">Records</option>
                                    <option value="3">Appointments</option>
                                    <option value="4">Visit History</option>
                                </select>
                            </div>
                        </div>
                    </>
                )

            },
        },
    ]

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const HandleSelect = (selectedOption, name) => {
        setFormData({ ...formData, [name]: selectedOption.value })
    }

    const handleSubmit = async () => {
        setLoading(true)
        try {
            const responce = await axiosConfig.get(`/clinics/patient/?from_date=${formData.fromDate}&to_date=${formData.toDate}&clinic_id=${formData.clinic}`)
            setPatientData(responce.data.results)
            setLoading(false)
        }
        catch (error) {
            console.log(error)
        }
    }
    return (
        <>
            <DashboardLayout>
                <div className="mainbar">
                    <div className="sidebar-filters px-3" style={{ height: "100vh" }}>
                        <div className="form-group">
                            <label>From Date</label>
                            <input
                                className="form-control"
                                type="date"
                                name="fromDate"
                                value={formData.fromDate}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label>To Date</label>
                            <input
                                className="form-control"
                                type="date"
                                name="toDate"
                                value={formData.toDate}
                                onChange={handleChange}
                            />
                        </div>
                        {userData && userData.user_type === "admin" && <div className="form-group">
                            <label>Clinic</label>
                            <Select
                                options={clinics && clinics.map((clinic) => ({ "value": clinic.id, "label": clinic.clinic_name }))}
                                onChange={(selectedOption) => HandleSelect(selectedOption, "clinic")}
                            />
                        </div>}
                        <button className="btn btn-primary"
                            onClick={handleSubmit}
                        >
                            Filter
                        </button>
                    </div>
                    <div className="main-code">
                        <div className="dashboard-header">
                            <h5 className="dashboard-subtitle">Patient Registrations</h5>
                            <NavLink className="text-decoration-none" to="/dashboard/patients/add"><button className="btn btn-primary d-inline">New Registration</button></NavLink>
                        </div>
                        <DataTable
                            columns={patientColumns}
                            data={patientData}
                            subHeader
                            fixedHeader
                            pagination paginationTotalRows={totalRows}
                            paginationPerPage={RowPerPage}
                            paginationDefaultPage={currentPage}
                            onChangePage={(page) => setCurrentPage(page)}
                            paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
                            paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
                            onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
                            onSort={handleSort}
                            paginationServer
                            subHeaderComponent={
                                <>
                                    <input type="text" className="form-control w-25" name="search"
                                        value={search.search}
                                        onChange={HandleSearch}
                                    />
                                </>}
                            customStyles={tableCustomStyles} />
                    </div>
                </div>
            </DashboardLayout>
        </>
    )
}

export { PatientRegistration }

import React, { useState, useRef, useEffect } from 'react';
import Select from 'react-select';

import { MdDeleteOutline, MdAdd } from "react-icons/md"

const EMRinput = ({ rw_data, title, subtitle, SetFormData, SetChoose,showData, FormData,Choose, id }) => {
  let false_list = []
  let true_list = []
  let null_list = []
  let headers_list = []
  for (let i = 0; i < rw_data.length; i++) {
    false_list.push(false)
    null_list.push(null)
    true_list.push(true)
    headers_list.push(rw_data[i]['label_name'])
  }
  console.log(headers_list)
  let currentDate = new Date()
  const [rowItems, setRowItems] = useState([{ selectOpen:false_list, selectedValue:null_list}]);
  console.log(rowItems,showData)
  const handleOpenSelect = (rowIndex, selectIndex) => {
    setRowItems((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems[rowIndex].selectOpen = true_list;
      return updatedItems;
    });
  };

  const handleCloseSelect = (rowIndex, selectIndex) => {
    // setRowItems((prevItems) => {
    //   const updatedItems = [...prevItems];
    //   updatedItems[rowIndex].selectOpen = [false, false, false];
    //   return updatedItems;
    // });
  };

  const handleSelectChange = (rowIndex, selectIndex, selectedOption, label, title) => {
    if (selectedOption.label === "day" || selectedOption.label === "week" || selectedOption.label === "month" || selectedOption.label === "year") {
      if (rowItems[rowIndex].selectedValue[selectIndex - 1]) {
        let number = rowItems[rowIndex].selectedValue[selectIndex - 1].value
        let period = selectedOption.label
        if (period === "day") {
          currentDate.setDate(currentDate.getDate() + parseFloat(number));
        }
        else if (period === "week") {
          currentDate.setDate(currentDate.getDate() + (parseFloat(number) * 7));
        }
        else if (period === "month") {
          currentDate.setMonth(currentDate.getMonth() + parseFloat(number));
        } else if (period === "year") {
          currentDate.setFullYear(currentDate.getFullYear() + parseFloat(number));
        }

        var day = currentDate.getDate();
        var month = currentDate.getMonth() + 1; 
        var year = currentDate.getFullYear();

       
        var formattedDate = day.toString().padStart(2, "0") + "-" + month.toString().padStart(2, "0") + "-" + year;
           let updatedItems = FormData
        if (updatedItems[subtitle][rowIndex]) {
          updatedItems[subtitle][rowIndex][[rw_data[selectIndex]["name"]]] = selectedOption.value
          if (rw_data[selectIndex + 1]["name"] === "date") {
            updatedItems[subtitle][rowIndex]["date"] = formattedDate
            if(!Choose.includes(title)){
              SetChoose(pre => [...pre, title])
            }
          }
            if (rw_data[selectIndex + 1]["name"] === "notes") {
              updatedItems[subtitle][rowIndex]["notes"] = ""
              if(!Choose.includes(title))
            {
                SetChoose(pre => [...pre, title])
              }
            }
          SetFormData(updatedItems)
        }
        else {
          updatedItems[subtitle][rowIndex] = { [rw_data[selectIndex]["name"]]: selectedOption.value }
          console.log(updatedItems)
          SetFormData(updatedItems)
        }
        setRowItems((prevItems) => {
          const updatedItems = [...prevItems];
          updatedItems[rowIndex].selectedValue[selectIndex] = selectedOption;
          if (rw_data[selectIndex + 1]["name"] === "date") {
            updatedItems[rowIndex].selectedValue[selectIndex + 1] = { "value": formattedDate, label: "date" }
          }
          return updatedItems;
        });
      }
    }
    else {
      let updatedItems = FormData
      if (updatedItems[subtitle][rowIndex]) {
        updatedItems[subtitle][rowIndex][rw_data[selectIndex]["name"]] = selectedOption.value
        console.log(updatedItems)
        SetFormData(updatedItems)
      }
      else {
        updatedItems[subtitle][rowIndex] = { [rw_data[selectIndex]["name"]]: selectedOption.value }
        console.log(updatedItems)
        SetFormData(updatedItems)
      }
      // else
      // {

      //   SetFormData(prevDoctor => ({
      //     ...prevDoctor, 
      //     [title]: [{...prevDoctor[title]["rowIndex"],[headers_list[selectIndex]+"_"+"id"]:selectedOption.value}]
      // }));
      // }
      setRowItems((prevItems) => {
        const updatedItems = [...prevItems];
        updatedItems[rowIndex].selectedValue[selectIndex] = selectedOption;
        console.log(rowItems)
        return updatedItems;
      });
    }
  };
  const handleAddRowItem = () => {
    setRowItems((prevItems) => [...prevItems, { selectOpen: false_list, selectedValue: null_list }]);
  };

  const handleRemoveRowItem = (index) => {
    console.log(index, rowItems)
    setRowItems((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems.splice(index, 1);
      return updatedItems;
    });
  };

  const outsideDropdownRefs = useRef([]);
 const GetFormData=()=>{
  if(showData){
    setRowItems(prevState => {
      const updatedRowItems = [...prevState];
      showData.forEach((data, index) => {
        if (!updatedRowItems[index]) {
          updatedRowItems[index] = {}; // Initialize the object if it doesn't exist
        }
    
        updatedRowItems[index].selectOpen = false_list;
        updatedRowItems[index].selectedValue = data ? data : null;
      });
    
      return updatedRowItems;
  })

 }
}

useEffect(() => {
  setRowItems(prevState => {
    const updatedRowItems = [...prevState]
    showData.forEach((data, index) => {
      if (!updatedRowItems[index]) {
        updatedRowItems[index] = {}; 
      }
  
      updatedRowItems[index].selectOpen = false_list;
      updatedRowItems[index].selectedValue = data ? data : null;
    });
  
    return updatedRowItems;
})
}, [showData]);
  useEffect(() => {
    GetFormData();
    const handleOutsideDropdownClick = (event) => {
      for (let i = 0; i < outsideDropdownRefs.current.length; i++) {
        if (outsideDropdownRefs.current[i] && !outsideDropdownRefs.current[i].contains(event.target)) {
          setRowItems((prevItems) => {
            const updatedItems = prevItems.map((item, index) => {
              if (index === i) {
                return {
                  ...item,
                  selectOpen: false_list,
                };
              }
              return item;
            });
            return updatedItems;
          });
        }
      }
    };
    document.addEventListener('mousedown', handleOutsideDropdownClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideDropdownClick);
    };

  }, []);
  const HandleChange = (e, rowIndex, selectIndex,title) => {
    const { name, value } = e.target
    const updatedItems = [...rowItems]
    updatedItems[rowIndex].selectedValue[selectIndex] = { "value": value, "label": name }
    let updatedData = FormData
     if (updatedData[subtitle][rowIndex]) {
       console.log(updatedData,subtitle,rowIndex)
       updatedData[subtitle][rowIndex][name] = value
       console.log(updatedData)
        SetFormData(updatedData)
       if (rw_data[selectIndex]["name"] === "notes") {
         if(!Choose.includes(title)){
           SetChoose(pre => [...pre, title])
         }
     }
    }
    else {
      updatedData[subtitle][rowIndex] = { [name]: value }
      console.log(updatedData)
      SetFormData(updatedData)
      }
    setRowItems(updatedItems)
  }
  return (
    <>
      <div className="row">
        <div className="col-6">
          <h3>{title}</h3>
        </div>
      </div>
      <div className="set-of-rows" id={id}>
        {rowItems.map((rowItem, rowIndex) => (
          <div className="row-item" key={rowIndex} ref={(el) => (outsideDropdownRefs.current[rowIndex] = el)}>
            <div className="row-df">
              {rowItem.selectOpen.map((isOpen, selectIndex) => (
                <div className="row-item" key={selectIndex}>
                  <div className="select-div">
                    {rowIndex === 0 &&
                      <label>{rw_data[selectIndex].label_name}</label>
                    }
                    {rw_data[selectIndex].type ? <input type={rw_data[selectIndex].type} className='form-control' name={rw_data[selectIndex].name} value={rowItem.selectedValue[selectIndex] && rowItem.selectedValue[selectIndex].value} onChange={(e) => HandleChange(e, rowIndex, selectIndex,title)} /> : <Select
                      menuIsOpen={isOpen}
                      options={rw_data[selectIndex].options_list.map((option) => ({ value: option.value, label: option.label }))}
                      value={rowItem.selectedValue[selectIndex]}
                      onChange={(selectedOption) => handleSelectChange(rowIndex, selectIndex, selectedOption, rw_data[selectIndex].label_name, title)}
                      onMenuOpen={() => handleOpenSelect(rowIndex, selectIndex)}
                      onMenuClose={() => handleCloseSelect(rowIndex, selectIndex)}
                    />}
                  </div>
                </div>
              ))}
              {rowIndex !== 0 &&
                <div className="row-item">
                  <button className="btn btn-danger" onClick={() => handleRemoveRowItem(rowIndex)}>
                    <MdDeleteOutline className="emr-form-icon del" />
                  </button>
                </div>
              }
            </div>
          </div>

        ))}

        <button className='btn btn-success' onClick={handleAddRowItem}>
          <MdAdd className="emr-form-icon add" />
        </button>
      </div>
      {/* <div className="buttons">
        <div className="row">
          <div className="col-6">
            <button className="btn btn-danger" onClick={handleRemoveRowItem}>
              Minus
            </button>
          </div>
        </div>
      </div> */}
    </>
  );
};

export { EMRinput };

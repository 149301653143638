import React, { useState } from 'react';
import { DashboardLayout } from '../DashboardLayout/DashboardLayout';
import { CMSNavbarComponent } from '../navbar/CMSnavcomponent';
import { AiTwotoneSetting } from "react-icons/ai";
import { CiCirclePlus } from "react-icons/ci";
import { HiOutlineEye } from "react-icons/hi";
import { MdOutlineEdit, MdKeyboardArrowUp, MdKeyboardArrowDown, MdDelete, MdOutlineFileUpload } from "react-icons/md";
import { FiSave } from "react-icons/fi";
import { FileManager } from "../FileManager/FileManager";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
const FullWidthBanners = ({id,setModuleButtons,moduleButtons}) => {
  const [isDragActive, setIsDragActive] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null)
  const [modalShow, setModalShow] = useState(false)
  const onDragEnter = (e) => {
    e.preventDefault();
    setIsDragActive(true);
  };
  const handleedit=()=>{
    setUploadedImage(null)
  }
  const onDragLeave = () => {
    setIsDragActive(false);
  };

  const onDrop = (e) => {
    e.preventDefault();
    setIsDragActive(false);

    const files = e.dataTransfer.files;
    handleFileChange(files);
  };

  const handleFileChange = (files) => {
    console.log(files)
     if (files.length > 0) {
       const file = files[0];
       const imageUrl =file.ImageUrl;
       setUploadedImage(imageUrl);
       const index=moduleButtons.findIndex(val=>val.id===id)
       if (index !== -1) {
         let updatedData=[...moduleButtons]
         updatedData[index] = {
           ...updatedData[index], 
           image: imageUrl,
         };
         console.log(updatedData)
         setModuleButtons([...updatedData]);
       }
     }
    console.log(files)
  };
  const Handlefilemanager = () => {
    setModalShow(true)
}
  return (
      <div
        className='text-block-end'
        onDragEnter={onDragEnter}
        onDragOver={(e) => e.preventDefault()}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        <div className='text-block-border'>
          <h5 className='dashboard-subtitle'>Full Width Banner</h5>
          <div className='d-flex gap-4'>
            <AiTwotoneSetting color='#5C5C5C' />
            <CiCirclePlus />
            <HiOutlineEye />
            <MdOutlineEdit />
            <FiSave />
            <MdKeyboardArrowUp />
            <MdKeyboardArrowDown />
            <MdDelete />
          </div>
        </div>
        <label className='p-2 w-100' htmlFor='upload-file'>
          {uploadedImage ? (
            <div className='backgroun-upload position-relative text-center p-0'>
              <div className='acitve-image'>
                <img src={uploadedImage} alt='Uploaded' style={{ width: '100%',height:"100%" }} />
              </div>
              <div className='postion-finish' onClick={handleedit}>
        <button className='btn btn-primary'>
        Click to Change Banner</button>
                  </div> 
            </div>
          ) : (
            <div className='backgroun-upload text-center' onClick={Handlefilemanager}>
              <div>
                <MdOutlineFileUpload color='#1b7d78' size={52} />
              </div>
              <label className='childnav-name'>Drag and Drop Files or Upload Your Computer.</label>
            </div>
          )}
        </label> 
        {
                    modalShow &&
                    <FileManager modalShow={modalShow} setModalShow={setModalShow} handleFile={handleFileChange} value="image" ismultiple={false}/>
                }
      </div>
  );
};

export { FullWidthBanners };

import React, { useEffect, useState } from "react";
import { DashboardLayout } from "../Components";
import { NavLink, useNavigate } from "react-router-dom";
import axiosConfig from "../Service/axiosConfig"
import DataTable from 'react-data-table-component';
import {MdDeleteOutline,MdOutlineEdit} from "react-icons/md"
import {AiOutlineEye} from "react-icons/ai"
const RoleList=()=>{
    let navigate = useNavigate();

    const [Data, SetData] = useState([])
    const GetData = async () => {
        try {
            const responce = await axiosConfig.get("/account/roleview/")
            SetData(responce.data)
        }
        catch (error) {
            console.log(error)
        }

    }
    useEffect(() => {
        GetData();
    }, [])

    const columns = [
        {
            name: "Id",
            selector: row => row.id,
            sortable: true
        },
        {
            name: "Role Name",
            selector: row => row.role_name,
            sortable: true
        },
         {
            name: "ACTIONS",
            selector: row => {
                const HandleEdit = async() => {
                    return navigate(`/dashboard/masters/role/${row.id}/edit`);
                }
                const HandleDelete = async() => {
                    await axiosConfig.delete(`/account/roleview/${row.id}/`).then(()=>{
                        GetData()
                    }).catch((error)=> {
                        console.log(error)
                    })
                }
                return (
                    <>
                         <div className="row" >
                            <div className="col-4">
                            <AiOutlineEye className="dashboard-tabe-data-actions"  onClick={HandleEdit}/>
                            </div>
                            <div className="col-4">
                                <MdOutlineEdit className="dashboard-tabe-data-actions"  onClick={HandleEdit}/>
                            </div>
                            <div className="col-4">
                                <MdDeleteOutline className="dashboard-tabe-data-actions" onClick={HandleEdit}/>
                            </div>
                        </div>
                    </>
                )

            }
        }
    ]
    const tableCustomStyles = {
        cells: {
            style: {
                justifyContent:"center"
                           },
        },
        headCells: {
          style: {
            display:"flex",
            justifyContent:"center"
          },
        },
        }
    return (
        <>
            <DashboardLayout>
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">View Role</h5>
                    <NavLink to="/dashboard/masters/role/add" ><button className="btn btn-white">Add New</button></NavLink>
                </div>
                <div className="table-responsive">
                <DataTable columns={columns} data={Data} pagination fixedHeader customStyles={tableCustomStyles}/>
                </div>
                
            </DashboardLayout>
        </>
    )
}
export { RoleList }
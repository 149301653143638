import React, { useState, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import axiosConfig from "../Service/axiosConfig";
import { MdDeleteOutline, MdOutlineEdit } from "react-icons/md";
import DataTable from "react-data-table-component";
import Masters from "../Components/masters.json";
import { DeletePopupModal, PopupModal, FormParser } from "../Components";
import { BiSearch } from "react-icons/bi";
import Modal from "react-bootstrap/Modal";
import { FaHandHoldingHeart } from "react-icons/fa";
import useDebouncedValue from "../Utils/Debounce";
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import { CMSNavbarComponent } from "../Components/navbar";
import { VendorsNav } from "../Components/navbar/VendorsNav";
import Select from "react-select";
import "./Vendor.css";

const VendorsInvoice = () => {
  let today_date = new Date().toISOString().split("T")[0];
  const [FilterData, setfilterData] = useState({
    fromDate: today_date,
    toDate: today_date,
    invoice_status: "",
  });
  const [Loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [modalTitle, setModalTitle] = useState("");
  const [LoadingButton, setLoadingButton] = useState();
  const [Data, SetData] = useState([]);
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const [RowPerPage, setRowPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [vendorId, setVendorId] = useState("");
  const [formData, setFormData] = useState({
    vendor: "",
    received_date: today_date,
    invoice_amount: "",
    return_item_worth: "",
    final_amount: "",
    pdf_path: "",
    invoice_status: "",
    store: "",
    remarks: "",
  });
  const [popupType, setpopupType] = useState("");
  const [RowId, SetRowId] = useState();
  const user_data = JSON.parse(localStorage.getItem("user_data"));
  let MastersModal =
    user_data.role === "opoto_staff"
      ? Masters.VendorsInvoiceOpoto
      : Masters.VendorsInvoice;

  const [masters, setMasters] = useState(MastersModal);
  const [deletePopupType, setDeletepopupType] = useState("");
  const [Error, SetError] = useState();
  const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("");
  const [search, SetSearch] = useState({
    search: "",
  });
  const debouncedSearchTerm = useDebouncedValue(search.search, 500);
  const options = [
    {
      label: "New",
      value: "New",
    },
    {
      label: "Approved",
      value: "Approved",
    },
    {
      label: "Pass",
      value: "Pass",
    },
    {
      label: "Hold",
      value: "Hold",
    },
    {
      label: "Review",
      value: "Review",
    },
    {
      label: "Payment initiated",
      value: "Payment initiated",
    },
    {
      label: "Payment completed",
      value: "Payment completed",
    },
  ];
  const HandleSearch = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    console.log(name, value);
    SetSearch({ ...search, [name]: value });
  };

  const VendorInvoice_columns = [
    {
      name: "ID",
      cell: (row, index) => index + 1,
    },
    {
      name: "Vendor",
      cell: (row) => row.vendor?.vendor_name,
      sortable: true,
    },
    {
      name: "Received Date",
      selector: "received_date",
      sortable: true,
    },
    {
      name: "Invoice Amount",
      selector: "invoice_amount",
      sortable: true,
    },
    {
      name: "Return Item Worth",
      selector: "return_item_worth",
      sortable: true,
    },
    {
      name: "Final Amount",
      selector: "final_amount",
      sortable: true,
    },
    {
      name: "PDF Path",
      selector: "pdf_path",
      cell: (row) => (
        <img
          src={row.pdf_path}
          alt="image"
          style={{ width: "50px", height: "50px" }}
        />
      ),
    },
    {
      name: "Remarks",
      selector: "remarks",
      sortable: true,
    },
    {
      name: "Store",
      cell: (row) => row.store?.name,
      sortable: true,
    },
    {
      name: "Invoice Status",
      selector: "invoice_status",
      cell: (row) =>
        row.invoice_status ? (
          <button
            style={{
              backgroundColor:
                row.invoice_status === "Approved"
                  ? "green"
                  : row.invoice_status === "New"
                  ? "blue"
                  : row.invoice_status === "Hold"
                  ? "red"
                  : row.invoice_status === "Remark"
                  ? "orange"
                  : "gray",
              color: "white",
              border: "none",
              padding: "5px 10px",
              borderRadius: "5px",
              cursor: "pointer",
              minWidth: "100px",
            }}
          >
            {row.invoice_status}
          </button>
        ) : (
          "-"
        ),
      sortable: true,
    },
    {
      name: "Updated By",
      cell: (row) => row.muser?.full_name,
      sortable: true,
    },

    {
      name: "ACTIONS",
      selector: (row) => {
        const HandleEdit = async () => {
          let masters = user_data.role === "opoto_staff" ? Masters.VendorsInvoiceOpoto : Masters.VendorsInvoiceEdit
          setMasters(masters);
          setShow(true);
          vendorInvoiceEdit(row.id);
          // handleShow(row.id);
          setModalTitle("Edit vendors Invoice");
        };
        const DeleteAlert = () => {
          SetRowId(row.id);
          SetAlertDeletePopupType("success");
        };
        const CancelAlerrt = () => {
          SetAlertDeletePopupType("error");
        };
        const HandleDelete = async (id) => {
          await axiosConfig
            .delete(`/accounts/vendor-invoice/${id}/`)
            .then(() => {
              SetAlertDeletePopupType("error");
              setDeletepopupType("success");
              GetvendorInvoice();
            })
            .catch((error) => {
              setDeletepopupType("error");
              SetAlertDeletePopupType("error");
              console.log(error);
            });
        };
        const handleRevive = async () => {
          try {
            const response = await axiosConfig.post(
              `/accounts/vendor-invoice/${row.id}/revive/`
            );
            setpopupType("success");
            setMessage("vendors Invoice revived successfully");
            GetvendorInvoice();
          } catch (error) {
            console.log("Error While Reviving", error);
          }
        };
        const vendorInvoiceEdit = (id) => {
          axiosConfig
            .get(`/accounts/vendor-invoice/${id}/`)
            .then((res) => {
              console.log(res.data, "edit");
              setFormData({
                ...res.data,
                vendor: res.data.vendor?.id,
                store: res.data.store?.id,
                is_user_specific: !!(res.data.instant || res.data.next_bill),
              });
              if (res.data.vendor?.id) {
                setVendorId(res.data.vendor?.id);
              }
              setShow(true);
              setMessage("Record Updated Successfully");
            })
            .catch((error) => {
              console.log(error);
            });
        };
        return (
          <>
            {!row.is_suspended ? (
              <div className="row">
                <div className="col-4">
                  <MdOutlineEdit
                    className="dashboard-tabe-data-actions"
                    onClick={HandleEdit}
                  />
                </div>
                <div className="col-4">
                  <MdDeleteOutline
                    className="dashboard-tabe-data-actions"
                    onClick={DeleteAlert}
                  />
                </div>
              </div>
            ) : (
              <>
                <FaHandHoldingHeart
                  className="dashboard-tabe-data-actions"
                  onClick={handleRevive}
                />
              </>
            )}
            {AlertDeletePopupType === "success" && (
              <AlertDeletePopupModal
                AlertDeletePopupType={AlertDeletePopupType}
                HandleDelete={HandleDelete}
                CancelAlerrt={CancelAlerrt}
                row={RowId}
              />
            )}
          </>
        );
      },
    },
  ];
  const GetvendorInvoice = async () => {
    setLoading(true);
    try {
      let response = await axiosConfig.get(
        `/accounts/vendor-invoice/?page=${currentPage}&page_size=${RowPerPage}&search=${debouncedSearchTerm}&cdate_after=${FilterData.fromDate}&cdate_before=${FilterData.toDate}&invoice_status=${FilterData.invoice_status}`
      );
      console.log(response, "data");
      SetData(response.data.results);
      setLoading(false);
      setTotalRows(response.data.count);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const GetNextBill = async () => {
    try {
      let response = await axiosConfig.get(
        `/accounts/next-bill/?vendor=${vendorId}&is_paid=false`
      );

      if (response.data.results) {
        // Calculate the total balance_amount using reduce
        const totalBalance = response.data.results.reduce((acc, curr) => {
          return acc + (parseFloat(curr.balance_amount) || 0); // Add current balance_amount, handle undefined or null values
        }, 0); // Start with an initial value of 0
        console.log(totalBalance);
        // Update the formData with the total balance
        setFormData((prevState) => ({
          ...prevState,
          vendor: vendorId,
          balance_amount: totalBalance,
          previous_amount: totalBalance,
        }));
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (vendorId) {
      GetNextBill();
    }
  }, [vendorId]);
  useEffect(() => {
    GetvendorInvoice();
  }, [currentPage, RowPerPage, FilterData.invoice_status, debouncedSearchTerm]);
  const formSubmit = async (FormData) => {
    console.log(FormData, "formdata");
    const data_to_send = {
      ...FormData,
    };
    delete data_to_send.cuser;
    delete data_to_send.muser;
    if (typeof FormData.pdf_path === "string") {
      delete data_to_send.pdf_path;
    }
    axiosConfig.defaults.headers["Content-Type"] = "multipart/form-data";
    setLoadingButton(true);
    if (FormData.id) {
      await axiosConfig
        .patch(`/accounts/vendor-invoice/${FormData.id}/`, data_to_send)
        .then((res) => {
          console.log(res);
          setpopupType("success");
          GetvendorInvoice();
          setShow(false);
          setMessage("Record Updated Successfully");
          handleClose();
        })
        .catch((error) => {
          console.log(error);
          let error_message = error.response.data;
          Object.values(error_message)[0] &&
            SetError(Object.values(error_message)[0]);
          setpopupType("error");
        });
      setLoadingButton(false);
    } else {
      await axiosConfig
        .post("/accounts/vendor-invoice/", {...data_to_send, invoice_status: "New"})
        .then((res) => {
          console.log(res);
          setpopupType("success");
          GetvendorInvoice();

          handleClose();
        })
        .catch((error) => {
          console.log(error);
          let error_message = error.response.data;
          Object.values(error_message)[0] &&
            SetError(Object.values(error_message)[0]);
          setpopupType(error);
        });
      setLoadingButton(false);
    }
  };
  const handleClose = () => {
    setShow(false);
    SetError("");
    setpopupType("");
    setMessage("");
    setLoadingButton(false);
    setVendorId("");
    setFormData({
      vendor: "",
      received_date: today_date,
      invoice_amount: "",
      return_item_worth: "",
      final_amount: "",
      pdf_path: "",
      invoice_status: "",
      store: "",
      remarks: "",
    });
  };
  const handleSort = (column, direction) => {
    console.log(column.selector, direction);
    axiosConfig
      .get(
        `/accounts/vendor-invoice/?page=${currentPage}&page_size=${RowPerPage}&ordering=${
          direction === "asc" ? column.selector : -column.selector
        }&cdate_after=${FilterData.fromDate}&cdate_before=${
          FilterData.toDate
        }&invoice_status=${FilterData.invoice_status}`
      )
      .then((res) => {
        SetData(res.data.results);
      })
      .catch((error) => {
        console.log(error);
      });
    setCurrentPage(1);
  };
  const handleShow = () => {
    let masters = user_data.role === "opoto_staff"? Masters.VendorsInvoiceOpoto: Masters.VendorsInvoice
    setMasters(masters);
    setShow(true);
    setFormData({
      instant: true,
      received_date: today_date,
    });
    setModalTitle("Add vendors Invoice");
  };
  const tableCustomStyles = {
    rows: {
      style: {},
    },
    cells: {
      style: {
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        color: "#5C5C5C",
        justifyContent: "center",
        color: "rgb(33,37,41)",
        fontSize: "12px",
        fontWeight: 500,
        lineHeight: "28px",
      },
    },
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setfilterData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const HandleTabSelect = (eventKey) => {
    setfilterData((prev) => ({
      ...prev,
      invoice_status: eventKey,
    }));
  };
  return (
    <>
      <div className="child-sidenav">
        <VendorsNav />
        <div className="childnav-content">
          <div className="row">
            <div className="form-group col-3">
              <label>From Date</label>
              <input
                className="form-control"
                type="date"
                name="fromDate"
                value={FilterData.fromDate}
                onChange={handleChange}
              />
            </div>
            <div className="form-group col-3">
              <label>To Date</label>
              <input
                className="form-control"
                type="date"
                name="fromDate"
                value={FilterData.toDate}
                onChange={handleChange}
              />
            </div>
            <div className="form-group col-3" style={{ zIndex: 1 }}>
              <label style={{ marginBottom: "55px" }}></label>
              <button
                className="btn btn-primary btn-filter"
                onClick={GetvendorInvoice}
              >
                Filter
              </button>
            </div>
            <div className="form-group col-3">
              <div className="cat-button" style={{ marginTop: "33px" }}>
                <div className="btn" onClick={handleShow}>
                  + Add New
                </div>
              </div>
            </div>
          </div>
          <Tabs
            defaultActiveKey="New"
            className="custom-tabs"
            onSelect={(eventkey) => HandleTabSelect(eventkey)} //{}}
            // justify
          >
            {options.map((item) => (
              <Tab eventKey={item.value} title={item.label} key={item.value} />
            ))}
          </Tabs>
          <div className="table-responsive marginTop" style={{ zIndex: 0 }}>
            <DataTable
              columns={VendorInvoice_columns}
              data={Data}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationPerPage={RowPerPage}
              paginationDefaultPage={currentPage}
              onChangePage={(page) => setCurrentPage(page)}
              paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
              paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
              onChangeRowsPerPage={(RowsPerPageChange) =>
                setRowPerPage(RowsPerPageChange)
              }
              onSort={handleSort}
              fixedHeader
              subHeader
              customStyles={tableCustomStyles}
              progressPending={Loading}
              progressComponent={
                <div className="loader-container">
                  <img
                    src={require("../Components/FormParser/images/loader2.gif")}
                    alt="Loading..."
                  />
                </div>
              }
            />
          </div>
          <Modal
            size="xl"
            show={show}
            onHide={handleClose}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-lg">
                {modalTitle}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <FormParser
                modelObject={masters}
                formData={formData}
                formSubmit={formSubmit}
                setVendor={setVendorId}
                error={Error}
                buttonTitle="Save"
                LoadingButton={LoadingButton}
              />
            </Modal.Body>
          </Modal>
          {popupType !== "" && (
            <PopupModal
              setpopupType={setpopupType}
              popupType={popupType}
              Navigation=""
              Message={message}
              error={Error}
              setMessage={setMessage}
            />
          )}
          {deletePopupType !== "" && (
            <DeletePopupModal
              setDeletepopupType={setDeletepopupType}
              deletePopupType={deletePopupType}
            />
          )}
        </div>
      </div>
    </>
  );
};
export { VendorsInvoice };

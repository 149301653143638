import React, { useEffect, useState } from "react";
import { Layout, DashboardLayout, DeletePopupModal } from "../Components";
import { NavLink, useNavigate } from "react-router-dom";
import axiosConfig from "../Service/axiosConfig"
import { MdDeleteOutline, MdOutlineEdit } from "react-icons/md"
import { AiOutlineEye } from "react-icons/ai"
import DataTable from 'react-data-table-component';
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import tableCustomStyles from "../Components/PopupModal/tableCustomStyles";
import Select from 'react-select';
const DoctorRegestrationList = () => {
    const [specialities, setSpacalitys] = useState([]);
  const [ClinicsData, setClinicData] = useState([]);
    const [visible, SetVisable] = useState([])
    const [action, setaction] = useState(0)
    const [action2, setaction2] = useState(0)
    const [action3, setaction3] = useState(0)
    const [loading, setLoading] = useState(false);
    const [deletePopupType, setDeletepopupType] = useState("")
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("")
    const [RowId, SetRowId] = useState()
    const [Datafilter, SetDatafilter] = useState([])
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(20);
    const [search, SetSearch] = useState({
        search: ""
    })
    let data=JSON.parse(localStorage.getItem("user_data"))
    const [filterValue, setFilterValues] = useState({
        speciality: "",
        clinics:data.clinic_id?data.clinic_id:""
      });
      const HandleSelcetfilter = (Selected, name) => {
        setFilterValues({ ...filterValue, [name]: Selected })
      }
    let navigate = useNavigate();

    const [Data, SetData] = useState([])
    const GetData = async () => {
        setLoading(true)
        try {
            const responce = await axiosConfig.get(`/clinics/doctors/?clinic=${filterValue.clinics}&page=${currentPage}&page_size=${RowPerPage}`)
            SetData(responce.data.results)
            SetDatafilter(responce.data.results)
            setLoading(false)
            setTotalRows(responce.data.count)
        }
        catch (error) {
            console.log(error)
        }

    }
    const GetSpacalitysData = async () => {
        try {
          const responce = await axiosConfig.get(`/masters/specialities/`)
          setSpacalitys(responce.data.results)
        }
        catch (error) {
          console.log(error)
        }
     
      }
      const GetClinicsData = async () => {
        try {
          const responce = await axiosConfig.get(`/clinics/clinics/`)
          setClinicData(responce.data.results)
        }
        catch (error) {
          console.log(error)
        }
     
      }
    useEffect(() => {
        GetSpacalitysData()
        GetClinicsData()
        GetData();
    }, [currentPage, RowPerPage])

    const HandleSearch = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        console.log(name, value)
        axiosConfig.get(`/clinics/doctors/?clinic=${filterValue.clinics}?page=${currentPage}&page_size=${RowPerPage}&search=${value}`).then(res => {
            SetData(res.data.results)
        }).catch(error => {
            console.log(error)
        })
        SetSearch({ ...search, [name]: value })

    }
    const handleSort = (column, direction) => {
        console.log(column.selector, direction)
        axiosConfig.get(`/clinics/doctors/?clinic=${filterValue.clinics}?page=${currentPage}&page_size=${RowPerPage}&ordering=${direction === "asc" ? column.selector : `-${column.selector}`}}`).then(res => {
            SetData(res.data.results)
        }).catch(error => {
            console.log(error)
        })
        setCurrentPage(1);
    };
    const handleSubmit = async () => {
        setLoading(true)
        GetData();
    };
    const columns = [
        {
            name: "Name",
            selector: "name",
            sortable: true,
        },
        {
            name: "Qualification",
            selector: "qualification_name",
            sortable: true,
        },
        {
            name: "Registration Number",
            selector: "registration_number",
            sortable: true,
        },
        {
            name: "clinic",
            selector: "clinic_name",
            sortable: true,
        },
        {
            name: "ACTIONS",
            selector: (row) => {
                const HandleEdit = async () => {
                    return navigate(`/dashboard/doctors/${row.id}`);
                }
                const DeleteAlert = () => {
                    SetRowId(row.id)
                    SetAlertDeletePopupType("success")
                }
                const CancelAlerrt = () => {
                    SetAlertDeletePopupType("error")
                }
                const HandleDelete = async (id) => {
                    await axiosConfig.delete(`/clinics/doctors/${id}/`).then(() => {
                        setDeletepopupType("success")
                        SetAlertDeletePopupType("error")
                        GetData()
                    }).catch((error) => {
                        console.log(error)
                        setDeletepopupType("error")
                        SetAlertDeletePopupType("error")
                    })
                }
                const HandleView = async (id) => {
                    return navigate(`/dashboard/profile/${row.id}`);
                }
                const Handlechange = (e) => {
                    const { name, value } = e.target
                    if (value === "0") {
                        navigate(`/dashboard/doctors/${row.id}`)
                        localStorage.setItem("url", `/dashboard/doctors/${row.id}`)
                    }
                    if (value === "1") {
                        navigate(`/dashboard/profile/${row.id}/doctor_specialities`)
                        localStorage.setItem("url", `/dashboard/profile/${row.id}/doctor_specialities`)
                    }
                    if (value === "2") {
                        navigate(`/dashboard/users/${row.id}/doctor_availability`)
                        localStorage.setItem("url", `/dashboard/profile/${row.id}/doctor_availability`)

                    }
                    if (value === "3") {
                        navigate(`/dashboard/users/${row.id}/vacations`)
                        localStorage.setItem("url", `/dashboard/profile/${row.id}/vacations`)

                    }
                }
                return (
                    <>
                        <div className="row align-items-center" >
                            <div className="col-2">
                                <MdDeleteOutline className="dashboard-tabe-data-actions" data-toggle="tooltip" data-placement="right" title="Delete" onClick={DeleteAlert} />
                            </div>
                            <div className="col-8">
                                <select className="form-select" onChange={Handlechange} >
                                    <option value="">SELECT</option>
                                    <option value="0">Doctor Info</option>
                                    <option value="1">Specialities</option>
                                    <option value="2">Availability</option>
                                    <option value="3">Vacations</option>
                                </select>
                            </div>
                        </div>
                        {
                            AlertDeletePopupType === "success" &&
                            <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} HandleDelete={HandleDelete} CancelAlerrt={CancelAlerrt} row={RowId} />
                        }
                    </>
                )

            },
        },
    ]
    return (
        <>
            <DashboardLayout>
                <div className="mainbar">
                    <div className="sidebar-filters px-2">
                       {data.user_type==="admin"&&<div className="form-group">
                            <label className="form-label">Clinics:</label>
                            <Select placeholder="Clinics" options={ClinicsData.map((res) => (
                                { "value": res.id, "label": res.clinic_name }))} value={ClinicsData.map((res) => (
                                    { "value": res.id, "label": res.clinic_name })).find(find => find.value === data.clinic_id)} onChange={(selectedOption) => HandleSelcetfilter(selectedOption.value, "clinics")} />
                        </div>}
                        <div className="form-group">
                            <label className="form-label">Specialities:</label>
                            <Select placeholder="Specialities" options={specialities.map((res) => (
                                { "value": res.id, "label": res.name }))} onChange={(selectedOption) => HandleSelcetfilter(selectedOption.value, "speciality")} />
                        </div>
                        <button className="btn btn-primary btn-filter" onClick={handleSubmit}>
                            Filter
                        </button>
                    </div>
                    <div className="main-code">
                        <div className="dashboard-header">
                            <h5 className="dashboard-subtitle">View Doctors</h5>
                            <NavLink to="/dashboard/doctors/add" ><button className="btn btn-white">Add New</button></NavLink>
                        </div>
                        <div className="table-responsive">
                            <DataTable columns={columns} data={Data} pagination paginationTotalRows={totalRows}
                                paginationPerPage={RowPerPage}
                                paginationDefaultPage={currentPage}
                                onChangePage={(page) => setCurrentPage(page)}
                                paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
                                paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
                                onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
                                onSort={handleSort}
                                paginationServer fixedHeader customStyles={tableCustomStyles} subHeader progressPending={loading}
                                progressComponent={<div className="loader-container">
                                    <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                                </div>}
                                subHeaderComponent={
                                    <input type="text" className="form-control w-25" name="search" value={search.search} onChange={HandleSearch} />} />
                        </div>
                    </div>
                </div>
                {deletePopupType != "" &&
                    <DeletePopupModal setDeletepopupType={setDeletepopupType} deletePopupType={deletePopupType} />
                }
            </DashboardLayout>
        </>
    )
}
export { DoctorRegestrationList }
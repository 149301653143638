import React, { useState, useEffect } from "react";
import { Layout, FormParser, PopupModal, DashboardLayout } from "../Components";
import { NavLink, useParams } from "react-router-dom";
import Masters from "../Components/masters.json"
import "./Pages.css"
import axiosConfig from "../Service/axiosConfig"
import { AiFillSetting } from "react-icons/ai"
import { DoctorRegestrationAdd } from "./DoctorregestrationAdd"
import sidebarData from "../Components/DashboardLayout/sidebar";
import { BsArrowLeftShort } from "react-icons/bs"

const DoctorSpecialitesClinic = ({ children }) => {
    const [popupType, setpopupType] = useState("");
    const [formerrorData, SetformErrorData] = useState({})
    const [Error, SetError] = useState("")
    const [specialities_id, setId] = useState()
    const [serviceId, setServiceId] = useState()
    const [Data, SetData] = useState([])
    const [doctorSpecialities, setDoctorSpecialities] = useState([])
    const [doctorServices, setDoctorServices] = useState([])
    const [specialtyArray, setSpecialtyArray] = useState([])
    const [serviceArray, setServiceArray] = useState([])
    const [specialFilter, Setspecailfilter] = useState([])
    const [ServiceFilter, SetserviceFilter] = useState([])
    const [servicetitle, setserviceTitle] = useState("")
    const [ChildNavs, SetChildNavs] = useState("")
    const [tariffs, SetTariffs] = useState([])
    const [fee, SetFee] = useState("")

    let { id } = useParams()
    const MastersModal = {
        "DoctorProfile": [
            {
                "SubTitle": "Doctor Profile",
                "NavItems": [
                    { "label": "Doctor Info", "path": `/dashboard/doctors/${id}` },
                    { "label": "Specialites", "path": `/dashboard/profile/${id}/doctor_specialities` },
                    { "label": "Availability", "path": `/dashboard/users/${id}/doctor_availability` },
                    { "label": "Vacations", "path": `/dashboard/users/${id}/vacations` }
                ]
            }
        ],
    }

    const doctor_profile = sidebarData.DoctorProfile

    const [activeState, setActiveState] = useState("")
    const Masters = MastersModal.DoctorProfile
    let data = localStorage.getItem("user_data")
    const clinicId = JSON.parse(data).clinic_id
    const role = JSON.parse(data).user_type

    const [PostData, SetPostdata] = useState({
        "doctor": JSON.parse(data).doctor_id?JSON.parse(data).doctor_id :id,
        "service": '',
        "amount": '',
        "doctor_share": null,
    })

    const GetData = async () => {
        try {
            const responce = await axiosConfig.get(`/masters/specialities/`)
            SetData(responce.data.results)
            Setspecailfilter(responce.data.results)
        }
        catch (error) {
            console.log(error)

        }

    }
    const GetDoctors = async () => {
        try {
            const responce = await axiosConfig.get(`/clinics/doctors/?id=${JSON.parse(data).doctor_id?JSON.parse(data).doctor_id :id}`)
            console.log(responce.data.results," doctor list")
            SetPostdata({...PostData,"clinic": clinicId?clinicId: responce.data.results[0].clinic})
        }
        catch (error) {
            console.log(error)

        }

    }

    const [ServiceData, setServiceData] = useState([])
    const GetServiceData = async (res) => {
        try {
            const responce = await axiosConfig.get(`/masters/services/?speciality=${res.id}`)
            const services_data = await axiosConfig.get(`clinics/doctorservices/?service=&doctorspeciality__speciality=${res.id}`)
            const services = services_data.data.results.map((result) => result.service_name)
            setDoctorServices(services_data.data.results)
            setServiceArray(services)
            setServiceData(responce.data.results)
            SetserviceFilter(responce.data.results)
        }
        catch (error) {
            console.log(error)

        }

    }

    const HandClincserviceApi = async (res, name, title) => {
        let serviceTariff = tariffs.filter(result => result.service_name === res.name)
        setserviceTitle(title)
        if (serviceTariff[0] && serviceTariff[0].amount) {
            SetFee(serviceTariff[0].amount)
            console.log(serviceTariff[0].amount, serviceTariff, "set fee")
        } else {
            SetFee('')
        }
        try {
            const responce = await axiosConfig.get(`/clinics/doctorservices/?service=${res.id}&doctorspeciality__speciality=${res.speciality}`)
            setServiceId(responce.data.results[0].service)
            SetPostdata({
                ...PostData,
                [name]: res.id
            })
        }
        catch (error) {
            console.log(error)

        }
    }

    const getDoctorSpecialites = async () => {
        try {
            let page = 1
            let allResults = [];
            const responce = await axiosConfig.get(`/clinics/doctorspecialitys/?speciality=&doctor=${JSON.parse(data).doctor_id?JSON.parse(data).doctor_id :id}&page=${page}`)
            let count = responce.data.count
            let pages = Math.ceil(count / 20)
            for (let page = 1; page <= pages; page++) {
                const res = await axiosConfig.get(`/clinics/doctorspecialitys/?speciality=&doctor=${JSON.parse(data).doctor_id?JSON.parse(data).doctor_id :id}&page=${page}`);
                const results = res.data.results;

                allResults = [...allResults, ...results];
            }
            setDoctorSpecialities(allResults)
            console.log(allResults, pages, "doctor specialities")
            const specialities = allResults.map((result) => result.speciality_name);
            setSpecialtyArray(specialities)
        }
        catch (error) {
            console.log(error)

        }
    }

    const getDoctorServices = async (res) => {
        try {
            const response = await axiosConfig.get(`clinics/doctorservices/?service=&doctorspeciality__speciality=${res.speciality}`);
            const services = response.data.results.map((result) => result.service_name)
            setDoctorServices(response.data.results)
            setServiceArray(services)
        } catch (error) {
            console.log(error);
        }
    }

    const getTariffs = async () => {
        try {
            const responce = await axiosConfig.get(`/clinics/tariffs/?doctor=${JSON.parse(data).doctor_id?JSON.parse(data).doctor_id :id}`)
            SetTariffs(responce.data.results)
        }
        catch (error) {
            console.log(error)

        }

    }

    const Handlespecialities = (e) => {
        const { name, value } = e.target
        const find_data = specialFilter.filter(find => find.name && find.name.toLowerCase().includes(value.toLowerCase()))
        SetData(find_data)
    }
    const HandleServices = (e) => {
        const { name, value } = e.target
        const find_data = ServiceFilter.filter(find => find.name && find.name.toLowerCase().includes(value.toLowerCase()))
        setServiceData(find_data)
    }
    const HandlePost = async (e) => {
        e.preventDefault();

        try {
            const existingData = await axiosConfig.get(`/clinics/tariffs/?clinic=${PostData.clinic}&doctor=${PostData.doctor}&service=${PostData.service}`);
            console.log(existingData.data.results, PostData,"post data");

            if (existingData.data.results.length > 0) {
                // Data already exists, send PUT request
                const response = await axiosConfig.patch(`/clinics/tariffs/${existingData.data.results[0].id}/`, PostData);
                console.log(response);
                setpopupType("success");
            } else {
                // Data does not exist, send POST request
                const response = await axiosConfig.post(`/clinics/tariffs/`, PostData);
                console.log(response);
                setpopupType("success");
            }
        } catch (error) {
            console.log(error);
        }
        // window.location.reload()
    };


    const HandleChnage = (e) => {
        const { name, value } = e.target
        console.log(name, value)
        SetPostdata({ ...PostData, [name]: value })
    }

    const handleSpecialityCheck = async (res, event) => {
        let deleteItem = doctorSpecialities.filter((result) => result.speciality_name === res.name)
        if (event.target.checked) {
            await axiosConfig.post(`/clinics/doctorspecialitys/`, {
                "speciality": res.id,
                "doctor": JSON.parse(data).doctor_id?JSON.parse(data).doctor_id :id,
                "is_configured": false
            }).then(res => {
                console.log(res, "posted data")
                getDoctorSpecialites();
            }).catch(error => {
                console.log(error)
            })
        } else {
            await axiosConfig.delete(`/clinics/doctorspecialitys/${deleteItem[0].id}/`)
                .then(res => {
                    console.log(res, "deleted item")
                    getDoctorSpecialites();
                }).catch(error => {
                    console.log(error)
                })
        }
    }

    const handleServiceCheck = async (res, event) => {
        let deleteItem = doctorServices.filter((result) => result.service_name === res.name)
        let postItem = doctorSpecialities.filter((result) => result.speciality_name === res.speciality_name)
        if (event.target.checked) {
            await axiosConfig.post(`/clinics/doctorservices/`, {
                "service": res.id,
                "doctorspeciality": postItem[0].id,
                "is_configured": false
            }).then(posted_data => {

                getDoctorServices(res)
            }).catch(error => {
                console.log(error)
            })
        } else {
            await axiosConfig.delete(`/clinics/doctorservices/${deleteItem[0].id}/`)
                .then(deleted_data => {
                    getDoctorServices(res);
                }).catch(error => {
                    console.log(error)
                })
        }
    }

    const currentURL = window.location.href;
    useEffect(() => {
        GetDoctors();
        getTariffs();
        getDoctorSpecialites();
        GetData();
        SetChildNavs(localStorage.getItem("url"))
    }, [])

    const OnChildNavClicked = (path) => {
        SetChildNavs(path)
        localStorage.setItem("url", path)
    }

    return (
        <>
            <DashboardLayout>
                <div className="mainbar">
                    <div className="sidebar-filters px-2">
                        {role === "admin" || role === "staff" ?
                            <NavLink to="/dashboard/doctors/list" ><button type="submit" className="btn btn-primary"><BsArrowLeftShort />back to doctors</button></NavLink>
                            :""
                        }
                        {
                            role === "doctor" &&
                            doctor_profile.map((childItem, index) => {
                                return (
                                    <div key={index}>
                                        <h5>{childItem.SubTitle}</h5>
                                        {
                                            childItem.NavItems.map((item, idx) => {
                                                return (
                                                    <div key={idx} className={ChildNavs == item.path ? "Configiration-list active" : "Configiration-list"}>
                                                        <NavLink to={item.path} className={ChildNavs == item.path ? "colorChange" : "NoColorChange"} onClick={() => OnChildNavClicked(item.path)}>
                                                            <span className="configiration-icon">{item.icon}</span>
                                                            <span className="list-name">{item.label}</span>
                                                        </NavLink>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            })
                        }
                        {
                            role === "admin" || role === "staff" ?
                            Masters.map((childItem, index) => {
                                return (
                                    <div key={index}>
                                        <h5>{childItem.SubTitle}</h5>
                                        {
                                            childItem.NavItems.map((item, idx) => {
                                                return (
                                                    <div key={idx} className={ChildNavs == item.path ? "Configiration-list active" : "Configiration-list"}>
                                                        <NavLink to={item.path} className={ChildNavs == item.path ? "colorChange" : "NoColorChange"} onClick={() => OnChildNavClicked(item.path)}>
                                                            <span className="configiration-icon">{item.icon}</span>
                                                            <span className="list-name">{item.label}</span>
                                                        </NavLink>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            }):""
                        }
                    </div>
                    <div className="main-code">
                        <div className="dashboard-header">
                            <h5 className="dashboard-subtitle">Specialities {id}</h5>
                            {/* <div className="d-md-flex gap-4">
                     <NavLink to={`/dashboard/profile/${id}/doctor_specialities`}><button onClick={()=>handleActive(`/dashboard/profile/${id}/doctor_specialities`)} className={activeState === `accounts`?"btn btn-primary":"btn btn-white"}>Specialities</button></NavLink>
                    <NavLink to={`/dashboard/users/${id}/doctor_availability`}><button onClick={()=>handleActive(`/dashboard/users/${id}/doctor_availability`)} className={activeState === `/dashboard/users/${id}/doctor_availability`?"btn btn-primary":"btn btn-white"}>Availability</button></NavLink>
                    <NavLink to={`/dashboard/users/${id}/vacations`}><button onClick={()=>handleActive(`/dashboard/users/${id}/vacations`)} className={activeState === `/dashboard/users/${id}/vacations`?"btn btn-primary":"btn btn-white"}>Vacations</button></NavLink> 
                    </div> */}
                        </div>
                        <div className="row specialities-main">
                            <div className="col-md-3">
                                <div className="Specialities-master">
                                    <div className="d-flex justify-content-between">
                                        <p className="list-name">Specialities</p>
                                        <input className="form-control form-discount w-50" onChange={Handlespecialities} />
                                    </div>
                                    {
                                        Data.map((res, i) => {
                                            let matchedSpecialities = doctorSpecialities.length > 0 && doctorSpecialities.filter((result) => result.speciality_name === res.name && result.is_configured);
                                            return (
                                                <div key={i} className={res.id === specialities_id ? "specialties-mas Acitve" : "specialties-mas inactive"}>
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox" id={`inlineCheckbox${i}`}
                                                            name="clinicspeciality" value="true"
                                                            checked={specialtyArray.includes(res.name)}
                                                            onChange={(e) => handleSpecialityCheck(res, e)}
                                                        />
                                                        <label className="form-check-label" htmlFor={`inlineCheckbox${i}`}>{res.name}</label>
                                                    </div>
                                                    {specialtyArray.includes(res.name) ?
                                                        <AiFillSetting
                                                            onClick={() => GetServiceData(res)}
                                                            className={matchedSpecialities.length > 0 ? "clinicspeciality acitve" : "clinicspeciality inacitve"}
                                                        /> : ""
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="Specialities-master">
                                    <div className="d-flex justify-content-between">
                                        <p className="list-name">Services</p>
                                        <input className="form-control form-discount w-50" onChange={HandleServices} />
                                    </div>
                                    {
                                        ServiceData.map((res, i) => {
                                            let matchedServices = doctorServices.length > 0 && doctorServices.filter(result => result.service_name === res.name && result.is_configured);
                                            return (
                                                <div key={i} className={res.id === serviceId ? "specialties-mas Acitve" : "specialties-service"}>
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id={`service${i}`}
                                                            name="service"
                                                            value="true"
                                                            checked={serviceArray.includes(res.name)}
                                                            onChange={(event) => handleServiceCheck(res, event)}
                                                        />
                                                        <label className="form-check-label" htmlFor={`service${i}`}>{res.name}</label>
                                                    </div>
                                                    {serviceArray.includes(res.name) ?
                                                        <AiFillSetting
                                                            onClick={() => HandClincserviceApi(res, "service", res.name)}
                                                            className={matchedServices.length > 0 ? "clinicspeciality acitve" : "clinicspeciality inacitve"}
                                                        /> : ""
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="Specialities-master">
                                    <p>{servicetitle}</p>
                                    <form>
                                        <div className="form-group row">
                                            <label className="col-sm-2 col-form-label">Fee:</label>
                                            <div className="col-sm-10">
                                                <input type="text" className="form-control form-discount w-50" name="amount" defaultValue={fee} onChange={HandleChnage} />
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-end">
                                            <button type="submit" className="btn btn-primary" onClick={HandlePost}>Save</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {popupType != "" &&
                    <PopupModal popupType={popupType} />
                }
            </DashboardLayout>
        </>
    )

}
export { DoctorSpecialitesClinic }
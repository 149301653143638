import React, { useEffect, useState } from "react";
import { DashboardLayout, PopupModal } from "../Components";
import { NavLink, useParams,useNavigate } from "react-router-dom";
import axiosConfig from "../Service/axiosConfig"
import DataTable from 'react-data-table-component';
import Select from 'react-select';
import tableCustomStyles from "../Components/PopupModal/tableCustomStyles";
const AssignTariff = () => {
    let navigate = useNavigate();
    const[loading,setLoading]=useState()
    let{id}=useParams()
    const[TariffCatagory,SetTariffCatogory]=useState({
        tariffcategory:""
    })
    const[clientData,SetClientData]=useState([])
    const HandleChange=(selectedOption, name)=>{
        console.log(selectedOption.value,name)
        SetTariffCatogory({...TariffCatagory,[name]:selectedOption.value})
        setLoading(true)
        axiosConfig.get(`/client/clienttariff/?tariffcategory=${selectedOption.value}`).then(res => {
            console.log(res.data)
            setLoading(false)
            SetClientData(res.data)
        }).catch(error => {
            console.log(error)
        })
    }
    const[Data,SetData]=useState([])
    const tariffcategorie = () => {
        axiosConfig.get("/masters/tariffcategories/").then(res => {
            console.log(res.data)
            SetData(res.data)
        }).catch(error => {
            console.log(error)
        })
    }
    const[TaariffId,SetTariffId]=useState()
    const GetData = () => {
        axiosConfig.get(`/client/clientagreement/?client=${id}`).then(res => {
            console.log(res.data[0].primary_tariff)
            SetTariffId(res.data)
        }).catch(error => {
            console.log(error)
        })
    }
    const OnSubmit=(e)=>{
        e.preventDefault()
          const{tariffcategory}=TariffCatagory
        axiosConfig.post(`/client/clients/${id}/assign_tariffcard/`,TariffCatagory).then(res=>{
            console.log(res)
            return navigate(`/dashboard/clients/${id}/tariff`);

        }).catch(error=>{
            console.log(error)
        })

    }
    useEffect(() => {
        tariffcategorie()
    }, [])
    const columns = [
        {
            name: "Id",
            selector: row =>row.id,
            sortable: true,
            },
        {
            name: "Code",
            selector: (row) =>row.alias_code,
            sortable: true,
           
        },
        {
            name: "Name",
            selector: (row) =>row.alias_name,
            sortable: true,
        }, 
        {
            name: "Client Name",
            selector: (row) =>row.client_name,
            sortable: true,
        },
        {
            name: "Night Amount",
            selector: (row) =>row.night_amount,
            sortable: true,
        }, 
        {
            name: "Priority",
            selector: (row) =>row.priority_name,
            sortable: true,
        },
        {
            name: "Service Amount",
            selector: (row) =>row.serice_amount,
            sortable: true,
        },
        {
            name: "Tariff Type",
            selector: (row) =>row.tariff_type,
            sortable: true,
        }, 
    ]
    return (
        <DashboardLayout>
            <>
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">Assign Tariff</h5>
                     <NavLink to="/dashboard/masters/tariffcard" ><button className="btn btn-white">Manage</button></NavLink> 
                </div>
                <form onSubmit={OnSubmit}>
                <div className="form-group">
                <label className="form-label">Tariff Category:</label>
                <Select options={Data&&Data.map((res) =>(
                                                        {"value": res.id, "label": res.tariffcategory_code} 
                                    ))} placeholder="SELECT Tariff Category" onChange={(selectedOption)=>HandleChange(selectedOption, "tariffcategory")}   required/>
                                     </div>
                <button type="submit" className="btn btn-primary">Submit</button>
                </form>
                {loading ? (<div className="loader-container">
                                            <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                                        </div>) :Data.length === 0 ? <p className="text-center"> There are no records to display</p> :
                <div className="table-responsive">
                <DataTable columns={columns} data={clientData} pagination fixedHeader customStyles={tableCustomStyles}/>
            </div>}
            </>
        </DashboardLayout>
    )
}
export { AssignTariff }
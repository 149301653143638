import React, { useState, useRef, useEffect } from "react";
import { DashboardLayout, FormParser, PopupModal } from "../Components";
import { NavLink } from "react-router-dom";
import Masters from "../Components/masters.json"
import "./Pages.css"
import axiosConfig from "../Service/axiosConfig"

const RadioLigistAdd = () => {
    const fileInputRef = useRef(null);
    const fileInputRef1 = useRef(null);
    const fileInputRef2 = useRef(null);
    const fileInputRef3 = useRef(null);
    const [popupType, setpopupType] = useState("")
    const [Next, SetNext] = useState(0)
    const [RadioLigist, SetRadioligist] = useState({
        id_no: "",
        name: "",
        doctor_name: "",
        type_of_employment: "",
        qualification: "",
        registration_no: "",
        email: "",
        city: "",
        address: "",
        user_id: "",
        password: "",
        confirm_password: "",
        basic_qualification: "",
        pg_diploma: "",
        medical_council_registration: "",
        upload_signature: "",
        domestic: "",
        international: "",
        modality: "",
        services: ""
    })
    console.log(RadioLigist)
    const HandleChange = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        console.log(name, value)
        SetRadioligist({ ...RadioLigist, [name]: value })
    }
    const HandleFile = (e) => {
        e.preventDefault()
        const file = e.target.files[0];
        const name = e.target.name;
        const value = file;
        console.log(name, value);
        SetRadioligist({ ...RadioLigist, [name]: value })
    }
    const NextForm = (e) => {
        e.preventDefault();
        SetNext(Next + 1)
    }
    const PrevForm = (e) => {
        e.preventDefault()
        SetNext(Next - 1)
    }
    const handleFileUpload = () => {
        fileInputRef.current.click();
    }
    const handleFileUpload1 = () => {
        fileInputRef1.current.click();
    }
    const handleFileUpload2 = () => {
        fileInputRef2.current.click();
    }
    const handleFileUpload3 = () => {
        fileInputRef3.current.click();
    }
    const [subdepartment, SetSubDepartment] = useState([])
    const GetSubDepartment = async () => {
        try {
            const response = await axiosConfig.get(`/masters/subdepartments/`)
            SetSubDepartment(response.data)
        }
        catch (error) {
            console.log(error)
        }

    }
    const FilterData = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        console.log(name, value)
    }
    useEffect(() => {
        GetSubDepartment()
    }, [])
    return (
        <>
            <DashboardLayout>
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">Add Radiologist</h5>
                    <NavLink to="/dashboard/radiologist" ><button className="btn btn-white">Manage</button></NavLink>
                </div>
                <form>
                    {Next === 0 &&
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label >ID No:</label>
                                    <input type="number" className="form-control" placeholder="Id No" name="id_no" value={RadioLigist.id_no} onChange={HandleChange} />
                                </div>
                            </div>
                            <div className="col-md-1">
                                <div className="form-group">
                                    <label className="form-label">Name:</label>
                                    <select className="form-select" name="name" value={RadioLigist.name} onChange={HandleChange}  >
                                        <option value="">SELECT Name</option>
                                        <option value="1" >""</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <label className="form-label"></label>
                                <div className="form-group">
                                    <input type="text" className="form-control" placeholder="Name" name="doctor_name" value={RadioLigist.doctor_name} onChange={HandleChange} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label className="form-label">Type of employment</label>
                                <div className="form-group">
                                    <input type="text" className="form-control" placeholder="Type of employment" name="type_of_employment" value={RadioLigist.type_of_employment} onChange={HandleChange} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label className="form-label">Qualification</label>
                                <div className="form-group">
                                    <input type="text" className="form-control" placeholder="Qualification" name="qualification" value={RadioLigist.Qualification} onChange={HandleChange} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label >Registration No:</label>
                                    <input type="number" className="form-control" placeholder="Registration No." name="registration_no." value={RadioLigist.registration_no} onChange={HandleChange} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label >Modile Number:</label>
                                    <input type="number" className="form-control" placeholder="Registration No." name="registration_no." value={RadioLigist.registration_no} onChange={HandleChange} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label >Alternative No:</label>
                                    <input type="number" className="form-control" placeholder="Registration No." name="registration_no." value={RadioLigist.registration_no} onChange={HandleChange} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label >Email Id:</label>
                                    <input type="email" className="form-control" placeholder="Email." name="email." value={RadioLigist.email} onChange={HandleChange} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label >City</label>
                                    <input type="text" className="form-control" placeholder="City" name="city" value={RadioLigist.city} onChange={HandleChange} />
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Address:</label>
                                <textarea className="form-control" rows="2" placeholder="Address" name="address" onChange={HandleChange}></textarea>
                            </div>
                            <div className="d-flex justify-content-end">
                                <button type="submit" className="btn btn-primary" onClick={NextForm}>Next</button>
                            </div>
                        </div>
                    }
                    {
                        Next === 1 &&
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label >User ID</label>
                                    <input type="text" className="form-control" placeholder="User Id" name="user_id" value={RadioLigist.user_id} onChange={HandleChange} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>password</label>
                                    <input type="password" className="form-control" placeholder="Password" name="password" value={RadioLigist.password} onChange={HandleChange} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label >Confirm Password</label>
                                    <input type="password" className="form-control" placeholder="Confirm Password" name="confirm_password" value={RadioLigist.confirm_password} onChange={HandleChange} />
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <button type="submit" className="btn btn-primary" onClick={PrevForm}>Back</button>
                                <button type="submit" className="btn btn-primary" onClick={NextForm}>Next</button>
                            </div>
                        </div>
                    }
                    {
                        Next === 2 &&
                        <div>
                            <div className="row">
                                <div className="col-md-3">
                                    <label className="tariff-type">Upload Certificates</label>
                                    <p className="radio-ptag">(Basic Qualification)</p>
                                </div>
                                <div className="col-md-3">
                                    <label htmlFor="file-input" className="radioupload">
                                        <button onClick={handleFileUpload} className="btn btn-primary">Choose File</button>
                                        <input id="file-input" type="file" ref={fileInputRef} name="basic_qualification" onChange={HandleFile} required />  
                                    </label>
                                    <span>{RadioLigist.basic_qualification.name}</span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <label className="triff-type">Upload Certificates</label>
                                    <p className="radio-ptag">(Pg/Diploma)</p>
                                </div>
                                <div className="col-md-3">
                                    <label htmlFor="file-input" className="radioupload">
                                        <button onClick={handleFileUpload1} className="btn btn-primary">Choose File</button>
                                        <input id="file-input" type="file" ref={fileInputRef1} name="pg_diploma" onChange={HandleFile} required />
                                    </label>
                                    <span>{RadioLigist.pg_diploma.name}</span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <label className="triff-type">Upload Certificates</label>
                                    <p className="radio-ptag">(Medical Council Registration)</p>
                                </div>
                                <div className="col-md-3">
                                    <label htmlFor="file-input" className="radioupload">
                                        <button onClick={handleFileUpload2} className="btn btn-primary">Choose File</button>
                                        <input id="file-input" type="file" ref={fileInputRef2} name="medical_council_registration" onChange={HandleFile} required />
                                    </label>
                                    <span>{RadioLigist.medical_council_registration.name}</span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <label className="triff-type">Upload Signature</label>
                                </div>
                                <div className="col-md-6">
                                    <div htmlFor="file-input" className="radioupload">
                                        <button onClick={handleFileUpload3} className="btn btn-primary">Choose File</button>
                                        <input id="file-input" type="file" ref={fileInputRef3} name="upload_signature" onChange={HandleFile} required />
                                        <p>{RadioLigist.upload_signature.name}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <button type="submit" className="btn btn-primary" onClick={PrevForm}>Back</button>
                                <button type="submit" className="btn btn-primary" onClick={NextForm}>Next</button>
                            </div>
                        </div>
                    }
                    {
                        Next === 3 &&
                        <div>
                            <div className="row radio-formcheck">
                                <div className="col-md-3">
                                    <div className="form-group form-check">
                                        <label className="form-check-label">
                                            Domestic<input className="form-check-input" type="checkbox" name="domestic" value="true" onChange={HandleChange} required />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group form-check">
                                        <label className="form-check-label">
                                            International<input className="form-check-input" type="checkbox" name="international" value="true" onChange={HandleChange} required />
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h5 className="dashboard-subtitle">Preferences</h5>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <select className="form-select" name="modality" value={RadioLigist.modality} onChange={HandleChange}>
                                        <option value="">Select Services</option>
                                        <option value="1">""</option>
                                    </select>
                                </div>
                                <div className="col-md-3">
                                    <select className="form-select" name="services" value={RadioLigist.services} onChange={HandleChange}>
                                        <option value="">Select Modality</option>
                                        <option value="1">""</option>
                                    </select>
                                </div>
                                <div className="col-md-6">
                                    <button type="submit" className="btn btn-primary">Add More</button>
                                </div>
                            </div>
                            <hr />
                            <div>
                                <h5 className="dashboard-subtitle">Non-Preferences</h5>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <select className="form-select" name="modality" value={RadioLigist.modality} onChange={HandleChange}>
                                        <option value="">Select Services</option>
                                        <option value="1">""</option>
                                    </select>
                                </div>
                                <div className="col-md-3">
                                    <select className="form-select" name="services" value={RadioLigist.services} onChange={HandleChange}>
                                        <option value="">Select Modality</option>
                                        <option value="1">""</option>
                                    </select>
                                </div>
                                <div className="col-md-6">
                                    <button type="submit" className="btn btn-primary">Add More</button>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between my-4">
                                <button type="submit" className="btn btn-primary" onClick={PrevForm}>Back</button>
                                <button type="submit" className="btn btn-primary" onClick={NextForm}>Next</button>
                            </div>
                        </div>
                    }
                    {
                        Next === 4 &&
                        <div>
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="form-check form-check-inline">
                                        Fixed Pay<input type="radio" className="form-check-input" name="fixed_pay" value="true" onChange={HandleChange} />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-check form-check-inline">
                                        Pay Per Case<input type="radio" className="form-check-input" name="pay_per_case" value="true" onChange={HandleChange} />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <p>For Fixed Pay</p>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Add Monthly Pay</label>
                                        <input type="text" className="form-control" name="user_id" value={RadioLigist.user_id} onChange={HandleChange} />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <p>For Pay Per Case</p>
                                <div className="row">
                                    <div className="col-md-3">
                                        <h4>Select Sub Department</h4>
                                    </div>
                                    <div className="col-md-9">
                                        <select className="form-select" name="subdepart_ment" value={subdepartment.subdepart_ment} onChange={FilterData} required>
                                            <option value="">Filter By Modality</option>
                                            {
                                                subdepartment.map((optionItem, i) => {
                                                    return (
                                                        <option value={optionItem.id} key={i}>{optionItem.subdepartment_name}</option>
                                                    )
                                                })
                                            }

                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between my-4">
                                <button type="submit" className="btn btn-primary" onClick={PrevForm}>Back</button>
                                <button type="submit" className="btn btn-primary">Save</button>
                            </div>
                        </div>
                    }
                </form>
                {popupType != "" &&
                    <PopupModal popupType={popupType} Navigation="/dashboard/masters/priority" />
                }
            </DashboardLayout>
        </>
    )

}
export { RadioLigistAdd }
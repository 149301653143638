import React, { useEffect, useState } from "react";
import { DashboardLayout, DeletePopupModal, FormParser, PopupModal } from "../Components";
import { NavLink, useNavigate } from "react-router-dom";
import axiosConfig from "../Service/axiosConfig"
import { MdDeleteOutline, MdOutlineEdit } from "react-icons/md"
import DataTable from 'react-data-table-component';
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import "./master_categories.css"
import { BsFillCloudUploadFill } from "react-icons/bs"
import { Button, Modal } from 'react-bootstrap';
import Select from 'react-select';
import { BiSearch } from "react-icons/bi"
import { AiTwotoneSetting } from "react-icons/ai";
import { CiCirclePlus } from "react-icons/ci";
import { HiOutlineEye } from "react-icons/hi";
import { MdKeyboardArrowUp, MdKeyboardArrowDown, MdDelete, MdOutlineFileUpload } from "react-icons/md";
import { FiSave } from "react-icons/fi";
import { CMSNavbarComponent } from "../Components/navbar/CMSnavcomponent";
import masters from "../Components/masters.json"
const Banner = () => {
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("")
    const [deletePopupType, setDeletepopupType] = useState("")
    const [loading, setLoading] = useState(false)
    const [RowId, SetRowId] = useState()
    let masterModal = masters.Banners
    let navigate = useNavigate();
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(20);
    const [popupType, setpopupType] = useState("")
    const [show, setShow] = useState(false);
    const [subcategoryOptions, setSubcategoryOptions] = useState([]);
    const [modalTitle, setModalTitle] = useState("Add New Item");
    const handleClose = () => setShow(false);
    useEffect(() => {
        const fetchCategories = async () => {
            try {
                let allCategories = [];
                let nextPage = "catlog/sub-categories/";

                while (nextPage) {
                    const response = await axiosConfig.get(nextPage);
                    const subcategories = response.data.results.map(subcategory => ({
                        value: subcategory.id,
                        label: subcategory.name
                    }));
                    allCategories = allCategories.concat(subcategories);
                    nextPage = response.data.next;
                }

                setSubcategoryOptions(allCategories);
            } catch (error) {
                console.error("Error fetching subcategories:", error);
                setSubcategoryOptions([]);
            }
        };
        fetchCategories();
    }, []);

    const tableCustomStyles = {
        rows: {
            style: {
            },
        },
        cells: {
            style: {
                justifyContent: "center"
            },
        },
        headCells: {
            style: {
                color: "#5C5C5C",
                justifyContent: "center",
                color: "rgb(33,37,41)",
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "28px",
            },
        },
    }
    const handleShow = () => {
        setShow(true);
        setSelectedImage("");
        setFormData({
            subcategory: '',
            image: '',
        });
        setcatid(null);
        setModalTitle("Add New Item");
    };

    const HandleDropdownChange = (selectedOption, name) => {
        setFormData({
            ...formData,
            [name]: {
                value: selectedOption.value,
                label: selectedOption.label
            }
        });
    };
    const [catid, setcatid] = useState()
    const [selectedImage, setSelectedImage] = useState(null);
    const [search, SetSearch] = useState({
        search: ""
    })
    const [Datafilter, SetDatafilter] = useState([])
    const [Data, SetData] = useState([])
    const GetData = async () => {
        setLoading(true)
        try {
            const responce = await axiosConfig.get(`catlog/banners/?page=${currentPage}&page_size=${RowPerPage}`)
            SetData(responce.data.results)
            setLoading(false)
            setTotalRows(responce.data.count)
        }
        catch (error) {
            console.log(error)
        }

    }
    const HandleSearch = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        console.log(name, value)
        SetSearch({ ...search, [name]: value })
        axiosConfig.get(`catlog/banners/`).then(res => {
            SetData(res.data.results)
        }).catch(error => {
            console.log(error)
        })
    }
    const handleSort = (column, direction) => {
        console.log(column.selector, direction)
        axiosConfig.get(`catlog/banners/?ordering=${direction === "asc" ? column.selector : -column.selector}`).then(res => {
            SetData(res.data.results)
        }).catch(error => {
            console.log(error)
        })
        setCurrentPage(1);
    };
    const [formData, setFormData] = useState({
        subcategory: '',
        image: '',
    });

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

    };


    const catedit = (id) => {
        axiosConfig.get(`catlog/banners/${id}/`).then(res => {
            console.log(res)
            setFormData({
                ...res.data,
                subcategory: { value: res.data.subcategory, label: res.data.subcategory_name },
            });
            setcatid(id);
            setShow(true)
        }).catch(error => {
            console.log(error)
        })
    }



    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setSelectedImage(file);
        setFormData({ ...formData, ["image"]: file });
    };
    useEffect(() => {
        GetData();
    }, [currentPage, RowPerPage])

    console.log(formData)
    const formSubmit = (id) => {
        const formDataToSend = new FormData();
        formDataToSend.append("subcategory", formData.subcategory.value);

        if (selectedImage) {
            formDataToSend.append("image", selectedImage);
        }

        axiosConfig.defaults.headers['Content-Type'] = 'multipart/form-data';
        if (id) {
            axiosConfig.patch(`catlog/banners/${id}/`, formDataToSend).then(res => {
                console.log(res)
                setpopupType("success")
                GetData();
            }).catch(error => {
                console.log(error)
                setpopupType("error")
            });
        } else {
            axiosConfig.post("catlog/banners/", formDataToSend).then(res => {
                console.log(res)
                setpopupType("success")
                GetData();
            }).catch(error => {
                console.log(error)
            });
        }
    };


    const columns = [
        {
            name: "S.No",
            selector: "id",
            cell: (row, i) => (currentPage - 1) * RowPerPage + i + 1,
            sortable: true
        },

        {
            name: "image",
            selector: row => <>
                <img className="image" src={row.image} alt={row.image} />
            </>,
            sortable: true

        },
        {
            name: "Subcategory",
            selector: "subcategory",
            cell: row => row.subcategory_name,
            sortable: true
        },
        {
            name: "ACTIONS",
            selector: row => {
                const HandleEdit = async () => {
                    console.log(row.id, 'iddddd')
                    catedit(row.id);
                    setModalTitle("Edit Item");
                };
                const DeleteAlert = () => {
                    SetRowId(row.id)
                    SetAlertDeletePopupType("success")
                }
                const CancelAlerrt = () => {
                    SetAlertDeletePopupType("error")
                }
                const HandleDelete = async (id) => {
                    await axiosConfig.delete(`catlog/banners/${id}/`).then(() => {
                        SetAlertDeletePopupType("error")
                        setDeletepopupType("success")
                        GetData();
                    }).catch((error) => {
                        setDeletepopupType("error")
                        SetAlertDeletePopupType("error")
                        console.log(error)
                    })
                }
                return (
                    <>
                        <div className="row" >
                            <div className="col-4">
                                <MdOutlineEdit className="dashboard-tabe-data-actions" onClick={HandleEdit} />
                            </div>
                            <div className="col-4">
                                <MdDeleteOutline className="dashboard-tabe-data-actions" onClick={DeleteAlert} />
                            </div>
                        </div>

                        {
                            AlertDeletePopupType === "success" &&
                            <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} HandleDelete={HandleDelete} CancelAlerrt={CancelAlerrt} row={RowId} />
                        }
                    </>
                )

            }
        }
    ]
    return (
        <>
                        <div className='text-block-end'>
                            <div className='text-block-border'>
                                <h5 className='dashboard-subtitle'>Banner Slider</h5>
                                <div className='d-flex gap-4'>
                                    <AiTwotoneSetting color='#5C5C5C' />
                                    <CiCirclePlus />
                                    <HiOutlineEye />
                                    <MdOutlineEdit />
                                    <FiSave />
                                    <MdKeyboardArrowUp />
                                    <MdKeyboardArrowDown />
                                    <MdDelete />
                                </div>
                            </div>
                            <div className='p-2'>
                                <button className="btn btn-primary" onClick={handleShow} >
                                    + Add New Banner Slider Item
                                </button>
                                <div className="table-responsive">
                                    <DataTable columns={columns} data={Data} pagination paginationTotalRows={totalRows}
                                        paginationPerPage={RowPerPage}
                                        paginationDefaultPage={currentPage}
                                        onChangePage={(page) => setCurrentPage(page)}
                                        paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
                                        paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
                                        onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
                                        onSort={handleSort}
                                        paginationServer fixedHeader subHeader customStyles={tableCustomStyles} progressPending={loading}
                                        progressComponent={<div className="loader-container">
                                            <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                                        </div>}
                                    />
                                </div>
                            </div>
                        </div>
                <Modal
                    size="lg"
                    show={show}
                    onHide={handleClose}
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                            Banner Slider Item
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormParser modelObject={masterModal} />
                        {/* <form >
                            <div className="row align-items-center">
                                <div className="col-6 banner-image">
                                    <div className="image-field">
                                        <label className="image-container" htmlFor="imageInput">
                                            {formData.image || selectedImage ? (
                                                <img

                                                    className="image"
                                                    src={selectedImage ? URL.createObjectURL(selectedImage) : formData.image}
                                                    alt={selectedImage ? selectedImage.name : formData.image}
                                                />
                                            ) : (
                                                <label htmlFor="imageInput" className="image-icon">
                                                    <BsFillCloudUploadFill className="upload-icon" />
                                                    <p className="textfile">Choose a file</p>
                                                </label>
                                            )}
                                            <input
                                                type="file"
                                                id="imageInput"
                                                name="image"
                                                accept="image/*"
                                                className="form-control"
                                                onChange={handleImageChange}
                                                style={{ display: "none" }}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-6 banner-form">
                                    <div className="form-fields">
                                        <label htmlFor="subcategory">Subcategory:</label>
                                        <Select
                                            options={subcategoryOptions}
                                            value={formData.subcategory}
                                            onChange={(selectedOption) => HandleDropdownChange(selectedOption, "subcategory")}
                                            placeholder="Select Subcategory"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </form> */}
                    </Modal.Body>
                    {/* <Modal.Footer>
                        {catid ? (
                            <>
                                <button type="button" className="btn custom-submit-button" onClick={handleClose}>Cancel</button>
                                <button type="button" className="btn custom-submit-button" onClick={() => formSubmit(catid)}>Save Changes</button>
                            </>
                        ) : (
                            <button type="button" className="btn custom-submit-button" onClick={() => formSubmit(null)}>Submit</button>
                        )}
                    </Modal.Footer> */}

                </Modal>

                {popupType != "" &&
                    <PopupModal popupType={popupType} Navigation="/dashboard/masters/banners" />
                }
                {deletePopupType != "" &&
                    <DeletePopupModal setDeletepopupType={setDeletepopupType} deletePopupType={deletePopupType} />
                }
        </>
    )
}
export { Banner }
import React, { useEffect, useState } from "react";
import { GoogleMap, Marker, InfoWindow, LoadScript } from '@react-google-maps/api';
const librariesArray = ["places"];
const AddressModal = ({ setFormData, formData,readOnly }) => {
    const [selectedLocation, setSelectedLocation] = React.useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const handlePlaceSearch = () => {
        const placesService = new window.google.maps.places.PlacesService(document.createElement('div'));
        const searchQueryWithLocation = `${searchQuery} India`;
        const indiaBounds = new window.google.maps.LatLngBounds(
            new window.google.maps.LatLng(6.753515, 68.162916), // Southwest corner of India
            new window.google.maps.LatLng(35.6745, 97.3956) // Northeast corner of India
        );
        const request = {
            query: searchQueryWithLocation,
            bounds: indiaBounds,
            fields: ['name', 'geometry'],
        };
        placesService.textSearch(request, (results, status) => {
            console.log(results, "results")
            if (status === window.google.maps.places.PlacesServiceStatus.OK && results && results.length > 0) {
                setSearchResults(results);
            } else {
                setSearchResults([]);
            }
        });
    };
    const handleResultClick = (result) => {
        console.log(result, "mapresult")
        setFormData({
            ...formData,
            address: result.formatted_address,
            latitude: result.geometry.location.toJSON().lat,
            longitude: result.geometry.location.toJSON().lng,
        });
        console.log(result.name)
        setSearchQuery(result.name);
        setSelectedLocation(result.geometry.location.toJSON());
        setSearchResults([]);
    };
    const handleMapClick = (e) => {
        const lat = e.latLng.toJSON().lat;
        const lng = e.latLng.toJSON().lng;
        setSelectedLocation({ lat, lng });
        console.log('Selected Location:', { lat, lng });
        const geocodingApiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyDkOk8E2_1LC7XdwAGlnf_YE84jR30oveQ`;
        fetch(geocodingApiUrl)
            .then(response => response.json())
            .then(data => {
                if (data.results && data.results.length > 0) {
                    const address = data.results[0].formatted_address;
                    console.log({ ...formData, latitude: lat, longitude: lng, address });
                    setFormData({ ...formData, latitude: lat, longitude: lng, address });
                    setSearchQuery(data.plus_code.compound_code);
                }
            })
            .catch(error => {
                console.error('Error fetching address:', error);
            });
    };
    useEffect(() => {
        if (formData.latitude && formData.longitude) {
            setSelectedLocation({ lat: formData.latitude, lng: formData.longitude });
        }
        else {
            setSelectedLocation({ lat: 	
                17.387140, lng: 	
                78.491684 })
        }
    }, [formData])
    return (
        <div className="">
            <div className="d-flex">
                <input
                    type="text"
                    value={searchQuery}
                    onChange={(e) => {
                        setSearchQuery(e.target.value);
                        handlePlaceSearch();
                    }}
                    placeholder="Search for a place"
                    className="form-control"
                    disabled={readOnly}
                />
            </div>
            {searchResults.length > 0 && (
                <div className="position-relative">
                    <ul className="list-container">
                        {searchResults.map((result, index) => {
                            return (
                                <li key={index} className="search-list-item" onClick={() => handleResultClick(result)}>
                                    {result.name}
                                </li>
                            )
                        })}
                    </ul>
                </div>
            )}
            <LoadScript
                googleMapsApiKey="AIzaSyDkOk8E2_1LC7XdwAGlnf_YE84jR30oveQ"
                libraries={librariesArray}
            >
                <GoogleMap
                    mapContainerStyle={{ width: '100%', height: '250px' }}
                    center={selectedLocation || { lat:  17.387140, lng: 78.491684 }}
                    zoom={13}
                    onClick={readOnly ? null : handleMapClick}
                    clickable={!readOnly}  
                    draggable={!readOnly}
                >
                    {selectedLocation && (
                        <Marker position={selectedLocation}>
                            <InfoWindow position={selectedLocation}>
                                <div>Selected Location</div>
                            </InfoWindow>
                        </Marker>
                    )}
                </GoogleMap>
            </LoadScript>
        </div >
    )
}
export { AddressModal }
import React, { useEffect, useRef, useState } from "react";
import { DashboardLayout, DeletePopupModal, PopupModal, EditPopupModal } from "../Components";
import { NavLink, useNavigate } from "react-router-dom";
import { usePrompt } from 'react-router-dom';
import axiosConfig from "../Service/axiosConfig"
import DataTable from 'react-data-table-component';
import tableCustomStyles from "../Components/PopupModal/tableCustomStyles";
import { AiOutlineEdit } from 'react-icons/ai'
import { MdOutlineEdit, MdOutlineLocationOn, MdOutlineSupportAgent } from "react-icons/md";
import { FaRegHospital } from "react-icons/fa";
import { useParams } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import { ClientBilledServices } from "./ClientBilledServices";
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import ConfirmModal from "../Components/PopupModal/ConfirmModal";
import { downloadExcel, useDownloadExcel } from 'react-export-table-to-excel';
const ClientBillsView = () => {
    const [clientData, setClientData] = useState([])
    const [popupType, setpopupType] = useState("");
    const [confirmType, setConfirmType] = useState("");
    const [EditPopupType, setEditpopupType] = useState("");
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("")
    const [objectData, SetObjectData] = useState([])
    const downloadref = useRef();
    let navigate = useNavigate();
    let { id, month } = useParams();
    const [Count, setCount] = useState(0)
    const [Datafilter, SetDatafilter] = useState([])
    const [smShow, setSmShow] = useState(false);
    const [UpdateData, SetUpdateData] = useState({
        cbs_id: undefined,
        service_id: undefined,
        amount: "",
        service_name: ""
    })
    const [BillsInfo, SetBillsInfo] = useState({
        no_of_cases: 0,
        total_amount: 0,
        discount: 0,
        percentage: 0,
        net_amount: 0,
    })
    // const { onDownload } = useDownloadExcel({
    //     currentTableRef: downloadref.current,
    //     filename: `${clientData.client_name && clientData.client_name} table`,
    //     sheet: 'Users',
    //     tablePayload: {
    //         header,
    //     },
    // })
    const [Search, setSearch] = useState()
    const [CategoryInfo, SetCategoryInfo] = useState({})
    const HandleUpdate = async () => {
        const findData = Data.findIndex(res => res.id === UpdateData.cbs_id)
        const updatedValues = [...Data];
        updatedValues[findData].casedetail.billing_description = UpdateData.service_name
        updatedValues[findData].net_amount = UpdateData.amount
        SetData(updatedValues)
        setSmShow(false)
    }
    const handleSelectChange = async (selectedOption, name) => {
        try {
            const response = await axiosConfig.post(`/client/clientbilledservices/${UpdateData.cbs_id}/get_estimation/`, { 'service_id': selectedOption.value })
            SetUpdateData({ ...UpdateData, [name]: selectedOption.value, ["service_name"]: selectedOption.label, ['amount']: response.data[0] })
            const existingIndex = objectData.findIndex((item) => item.cbs_id === UpdateData.cbs_id);
            if (existingIndex === -1) {
                SetObjectData([...objectData, { [name]: selectedOption.value, ["service_name"]: selectedOption.label, "cbs_id": UpdateData.cbs_id, ['amount']: response.data[0] }]);
            }
            else {
                const updatedValues = [...objectData];
                updatedValues[existingIndex] = { [name]: selectedOption.value, ["service_name"]: selectedOption.label, "cbs_id": UpdateData.cbs_id, ['amount']: response.data[0] };
                SetObjectData(updatedValues);
            }
        }
        catch (error) {
            console.log(error)
        }
    };
    // const GetServiceId= async () => {
    //     try {
    //         const response = await axiosConfig.get(`/client/clientbilledservices/${UpdateData.cbs_id}/get_estimation/`)
    //         console.log(response.data)
    //         // SetUpdateData({ ...UpdateData, [name]: selectedOption.value, ['amount']: response.data })
    //     }
    //     catch (error) {
    //         console.log(error)
    //     }
    // };
    const HandleChangeEdit = (e) => {
        const { name, value } = e.target
        console.log(name, value)
        SetUpdateData({ ...UpdateData, [name]: value })
        const existingIndex = objectData.findIndex((item) => item.cbs_id === UpdateData.cbs_id);
        if (existingIndex === -1) {
            SetObjectData([...objectData, { ["service_id"]: UpdateData.service_id, ["service_name"]: UpdateData.service_name, "cbs_id": UpdateData.cbs_id, ['amount']: value }]);
        }
        else {
            const updatedValues = [...objectData];
            updatedValues[existingIndex] = { ["service_id"]: UpdateData.service_id, ["service_name"]: UpdateData.service_name, "cbs_id": UpdateData.cbs_id, ['amount']: value };
            SetObjectData(updatedValues);
        }
    }

    const [loading, setLoading] = useState(false);
    const [Data, SetData] = useState([])
    const [search, SetSearch] = useState({
        search: "",
    })
    const [select, Setselct] = useState({
        Month: ""
    })

    const [Services, SetServices] = useState([])
    const GetData = async (id, month) => {
        setLoading(true)
        try {
            const response = await axiosConfig.get(`/client/clientbilledservices/?casedetail__caseinfo__group=${id}&month=${month}`)
            SetData(response.data)
            SetDatafilter(response.data)
            let sum = response.data.reduce(function (prev, current) {
                return prev + +current.net_amount
            }, 0);
            var result = response.data.reduce((curr, next) => {
                (curr[next.casedetail.caseinfo.modality] = curr[next.casedetail.caseinfo.modality] || []).push(next);
                return curr;
            }, {});
            SetCategoryInfo({ 'CT': result['CT'] ? result["CT"].length : 0, 'DX': result['DX'] ? result['DX'].length : 0, "PETCT": result['PETCT'] ? result["PETCT"].length : 0, "MR": result['MR'] ? result["MR"].length : 0 })
            setLoading(false)
        }
        catch (error) {
            console.log(error)
        }
    }
    const header = ["S.No",
        "Id",
        "Patien ID",
        "Patient Name",
        "Age",
        "Gender",
        "Modality",
        "Study Description",
        "Billing Description",
        "Billed Amount",];
    const body3 = Data.map((res, index) => [index + 1, res.id, res.casedetail.caseinfo.patient_id, res.casedetail.caseinfo.patient_name, res.casedetail.caseinfo.age, res.casedetail.caseinfo.gender, res.casedetail.caseinfo.modality, res.casedetail.case_study_description, res.casedetail.billing_description, res.net_amount,])
    function handleDownloadExcel() {
        downloadExcel({
            fileName: `${clientData.client_name && clientData.client_name}`,
            sheet: `${clientData.client_name && clientData.client_name}`,
            tablePayload: {
                header,
                body: body3,
            },
        });
    }
    const GetServices = async () => {
        setLoading(true)
        try {
            const responce = await axiosConfig.get(`/masters/services/`)
            SetServices(responce.data)
            setLoading(false)
        }
        catch (error) {
            console.log(error)
        }

    }
    const ClientData = async () => {
        try {
            const responce = await axiosConfig.get(`/client/get_invoice/?client=${id}&month=${month}`)
            console.log(responce.data)
            setClientData(responce.data)
            SetBillsInfo({ net_amount: responce.data.total_net_amount, total_amount: responce.data.total_amount, discount: responce.data.discount, })
            const discountAmount = responce.data.discount;
            const totalAmount = responce.data.total_amount;
            const discountPercentage = ((discountAmount / totalAmount) * 100).toFixed(0);
            console.log(discountPercentage)
            setPercentage({ "discount": discountPercentage > 0 ? discountPercentage : 0 })
        }
        catch (error) {
            console.log(error)
        }

    }
    useEffect(() => {
        // GetServiceId()
        ClientData()
        GetData(id, month);
        GetServices();
        Setselct({ ...select, ['Month']: month })
        handlePercentage()

    }, [id, month])
    const [URL, setURL] = useState()
    const HandleSelect = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        setURL(value)
        return navigate(`/dashboard/billing/client-bills/${id}/view/${value}`);

    }
    const [CBSItem, SetclientbillEditData] = useState([])
    const [CBSEditData, SetCBSEditData] = useState({})
    const HandleEdit = async (id, patient_name, modality, study_description, billing_description, net_amount) => {
        SetCBSEditData({ "id": id, "patient_name": patient_name, "modality": modality, "study_description": study_description, "billing_description": billing_description, "net_amount": net_amount })
        try {
            const response = await axiosConfig.get(`/client/clientbilledservices/${id}/get_estimation/`)
            console.log(response.data[1])
            SetUpdateData({ 'amount': net_amount, 'cbs_id': id, 'service_id': response.data[1], "service_name": billing_description })
            setSmShow(true)
            const existingIndex = objectData.findIndex((item) => item.cbs_id === id);
            if (existingIndex === -1) {
                SetObjectData([...objectData, { 'amount': net_amount, 'cbs_id': id, 'service_id': response.data[1], "service_name": billing_description }]);
            }
            else {
                const updatedValues = [...UpdateData];
                updatedValues[existingIndex] = { 'amount': net_amount, 'cbs_id': id, 'service_id': response.data[1], "service_name": billing_description };
                SetObjectData(updatedValues);
            }
        }
        catch (error) {
            console.log(error)
        }
    }
    console.log(objectData)
    const [count, setcount] = useState(0)
    const handleSave = (e) => {
        const { name, value } = e.target
        console.log(name, value)
        const result = BillsInfo.total_amount - parseInt(value).toFixed(2)
        SetBillsInfo({ ...BillsInfo, "discount": value, 'net_amount': result })
        const discountPercentage = ((value / BillsInfo.total_amount) * 100).toFixed(2);
        setPercentage({ "discount": discountPercentage })
    };
    const [percentage, setPercentage] = useState({
        discount: 0
    })

    const handlePercentage = (e) => {
        const discountAmount = BillsInfo.discount;
        const totalAmount = BillsInfo.total_amount;
        const discountPercentage = ((discountAmount / totalAmount) * 100).toFixed(2);
        console.log(discountPercentage)
        setPercentage({ "discount": discountPercentage > 0 ? discountPercentage : 0 })
    };
    const PercentageChange = (e) => {
        const { name, value } = e.target
        const totalAmount = BillsInfo.total_amount;
        const discountAmount = (totalAmount * (value / 100)).toFixed(2);
        console.log(discountAmount)
        const net_amount = (totalAmount - discountAmount).toFixed(2)
        setPercentage({ ...percentage, [name]: value })
        SetBillsInfo({ ...BillsInfo, "discount": discountAmount, net_amount: net_amount })
    }
    function handleInputChange() {
        setHasUnsavedChanges(true);
    }
    const HandleSubmit = async () => {
        const api1 = axiosConfig.post(`/client/client-invoices/${clientData.id}/update_invoice/`, { discount: BillsInfo.discount, invoice_type: "save" });
        const api2 = objectData.forEach(async (res) => {
            await axiosConfig.post(`/client/clientbilledservices/${res.cbs_id}/update_price/`, { 'service_id': res.service_id, 'amount': res.amount }).then(res => {
                console.log(res)
            }).catch(error => {
                console.log(error)
            })
        })

        Promise.all([api1, api2])
            .then(([res1, res2]) => {
                console.log(res1, res2);
                ClientData()
                setEditpopupType("success")
            })
            .catch((error) => {
                console.error(error);
                setEditpopupType("error")
            });
    }
    const HandleGenerate = async () => {
        try {
            const responce = await axiosConfig.post(`/client/client-invoices/${clientData.id}/update_invoice/`, { discount: BillsInfo.discount, invoice_type: "generate" })
            localStorage.setItem('ChildNavs', "/dashboard/billing/client-final-bills");
            navigate(`/dashboard/billing/client-final-bills?month=${month}`)
        }
        catch (error) {
            console.log(error)
        }
    }
    const HandleSearch = (e) => {
        const { name, value } = e.target
        const responce = Datafilter.filter(res => res.casedetail.caseinfo.patient_id && res.casedetail.caseinfo.patient_id.toLowerCase().includes(value.toLowerCase()) || res.casedetail.caseinfo.modality && res.casedetail.caseinfo.modality.toLowerCase().includes(value.toLowerCase()) || res.casedetail.case_study_description && res.casedetail.case_study_description.toLowerCase().includes(value.trim().toLowerCase()) || res.casedetail.case_study_description && res.casedetail.case_study_description.toLowerCase().includes(value.trim().toLowerCase()) || res.net_amount && res.net_amount.toString().includes(value) || res.casedetail.caseinfo.patient_name && res.casedetail.caseinfo.patient_name.toLowerCase().includes(value.toLowerCase()))
        SetData(responce)
    }
    const CancelAlerrt = () => {
        SetAlertDeletePopupType("error")
    }
    function flattenData(data) {
        let flatData = {};
        for (let key in data) {
            if (typeof data[key] === "object" && data[key] !== null) {
                let nestedData = flattenData(data[key]);
                for (let nestedKey in nestedData) {
                    flatData[key + "." + nestedKey] = nestedData[nestedKey];
                }
            } else {
                flatData[key] = data[key];
            }
        }
        return flatData;
    }
    function convertToCSV(objArray, headerArray) {
        const array = typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
        let str = "";
        let headers = headerArray.join(",");
        str += headers + "\r\n";
        for (let i = 0; i < array.length; i++) {
            let flatData = flattenData(array[i]);
            let line = "";
            for (let index in headerArray) {
                let header = headerArray[index];
                if (line !== "") line += ",";
                line += flatData[header] || "";
            }
            str += line + "\r\n";
        }
        return str;
    }
    function handleDownloadList() {
        const headers = Object.keys(flattenData(Data[0]));
        const csvData = convertToCSV(Data, headers);
        const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", `${clientData.client_name && clientData.client_name}.csv`);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    return (
        <>
            <DashboardLayout>
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">View Client Bills:</h5>
                    <input type="Month" name="Month" className="form-control month-selector" value={select.Month} onChange={HandleSelect} />
                </div>
                <div className="clientbill-info">
                    <div className="clientinformation">
                        <div className="row">
                            <div className="col-md-3">
                                <p><FaRegHospital /> #{clientData.client && clientData.client} {clientData.client_name && clientData.client_name}</p>
                            </div>
                            <div className="col-md-6">
                                <p><MdOutlineLocationOn /> {clientData.email && clientData.email} | {clientData.phone && clientData.phone}</p>
                            </div>
                            <div className="col-md-3">
                                <p><MdOutlineSupportAgent /> {clientData.address && clientData.address}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8">
                            <div className="billingModality">
                                <span>Total No. of Cases: {clientData.quantity},</span>
                                <span>CT: {CategoryInfo.CT},</span>
                                <span>DX: {CategoryInfo.DX},</span>
                                <span>MR: {CategoryInfo.MR},</span>
                                <span>PETCT: {CategoryInfo.PETCT},</span>
                            </div>
                            <div className="row">
                                <div className="col-md-2">
                                    <button type="submit" className="btn btn-primary" onClick={HandleSubmit}>Save</button>
                                </div>
                                <div className="col-md-4">
                                    {console.log(UpdateData.service_name, CBSEditData.billing_description, CBSEditData.net_amount, UpdateData.amount)}
                                    <button type="submit" className="btn btn-primary" onClick={HandleGenerate}>Generate Invoice</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <p>Total Amount: {BillsInfo.total_amount}</p>
                            {/* <div className="row  discount-container">
                                <div className="col-sm-4">
                                    <label>discount%:</label>
                                </div>
                                <div className="col-sm-2">
                                    <input type="text" placeholder="percentage" className="form-control form-discount" name="percentage" maxLength="2" value={BillsInfo.percentage} onChange={handlePercentage} />
                                </div>
                                <div className="col-2">
                                    <label>Discount:</label>
                                </div>
                            </div> */}
                            <div className="d-md-flex gap-1">
                                <div className="form-group">
                                    <label className="form-check-label" for="inlineRadio1">Flat Discount</label>
                                    <input type="text" placeholder="Discount" className="form-control form-discount" name="discount" value={BillsInfo.discount} onChange={handleSave} />
                                </div>
                                <div className="form-group">
                                    <label>Discount percentage</label>
                                    <div className="d-flex align-items-center">
                                        <input type="text" placeholder="Discount" className="form-control form-discount" name="discount" value={percentage.discount} maxLength="2" onChange={PercentageChange} />
                                        <span>%</span>
                                    </div>
                                </div>
                            </div>
                            <p>Net Amount: {BillsInfo.net_amount}</p>
                        </div>
                    </div>
                </div>
                <div className="table-responsive">
                    <div className="d-md-flex justify-content-between">
                        <div className="w-25">
                            <input className="form-control" name="Search" onChange={HandleSearch} />
                        </div>
                        <div>
                            <button type="submit" className="btn btn-primary" onClick={handleDownloadExcel} >View Full Sheet</button>
                        </div>
                    </div>
                    <div className="tablescroll">
                        {loading ? (<div className="loader-container">
                            <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                        </div>) : Data.length === 0 ? <p className="text-center"> There are no records to display</p> :
                            <table ref={downloadref} className="table">
                                <thead className="sticky-top">
                                    <tr>
                                        <th>S.No</th>
                                        <th>Id</th>
                                        <th>Patient ID</th>
                                        <th>Patient Name</th>
                                        <th>Age</th>
                                        <th>Gender</th>
                                        <th>Modality</th>
                                        <th>Study Description</th>
                                        <th> Billing Description</th>
                                        <th>Billed Amount</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        Data.map((res, index) => {
                                            return (
                                                <tr key={res.id}>
                                                    <td>{index + 1}</td>
                                                    <td>{res.id}</td>
                                                    <td>{res.casedetail.caseinfo.patient_id}</td>
                                                    <td>{res.casedetail.caseinfo.patient_name}</td>
                                                    <td>{res.casedetail.caseinfo.age}</td>
                                                    <td>{res.casedetail.caseinfo.gender}</td>
                                                    <td>{res.casedetail.caseinfo.modality}</td>
                                                    <td>{res.casedetail.case_study_description}</td>
                                                    <td>{res.casedetail.billing_description}</td>
                                                    <td>{res.net_amount}</td>
                                                    <td><MdOutlineEdit onClick={() => HandleEdit(res.id, res.casedetail.caseinfo.patient_name, res.casedetail.caseinfo.modality, res.casedetail.case_study_description, res.casedetail.billing_description, res.net_amount)} /> </td>
                                                </tr>
                                            )
                                        })

                                    }
                                </tbody>
                            </table>}


                    </div>
                </div>
                {/* <div className="clientbill-info">
                        <div className="row">
                            <div className="col-md-10">
                                <p>Total No. of Cases: {BillsInfo.no_of_cases}</p>
                                {CategoryInfo.CT && <p>CT: {CategoryInfo.CT}</p> }
                                {CategoryInfo.DX && <p>DX: {CategoryInfo.DX}</p> }
                            </div>
                            <div className="col-md-2">
                                <p>Total Amount: {BillsInfo.total_amount}</p>
                                <p>Discount: {BillsInfo.discount}</p>
                                <p>Net Amount: {BillsInfo.net_amount}</p>
                            </div>
                        </div>
                    </div> */}
                <Modal
                    size="lg"
                    show={smShow}
                    onHide={() => setSmShow(false)}
                    aria-labelledby="example-modal-sizes-title-sm">
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-sm">
                            <h5 className="dashboard-subtitle">Update Client Bills</h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="success-icon">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Patient Name</th>
                                    <th>Modality</th>
                                    <th>Study Description</th>
                                    <th>Billing Description</th>
                                    <th>Billed Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {CBSEditData && (
                                    <tr>
                                        <td>{CBSEditData.id}</td>
                                        <td>{CBSEditData.patient_name}</td>
                                        <td>{CBSEditData.modality}</td>
                                        <td>{CBSEditData.study_description}</td>
                                        <td>{CBSEditData.billing_description}</td>
                                        <td>{CBSEditData.net_amount}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <div>
                            <form>
                                <div className="row">
                                    <div className="col-6">
                                        <label>Select Service</label>
                                        <Select options={CBSEditData && Services.map((res) => (
                                            { "key": res.id, "value": res.id, "label": res.service_name }))}
                                            placeholder="Select Service"
                                            name="service_id"
                                            defaultValue={CBSEditData && Services.map((res) => ({ "value": res.id, "label": res.service_name })).find(res => res.label === CBSEditData.billing_description)}
                                            onChange={(selectedOption) => {
                                                handleSelectChange(selectedOption, "service_id")
                                            }
                                            } required />
                                    </div>
                                    <div className="col-6">
                                        <label>Amount</label>
                                        <input type="number" name="amount" className="form-control" value={UpdateData.amount} onChange={HandleChangeEdit} />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button variant="primary" className="btn btn-primary" onClick={() => setSmShow(false)}>
                            Cancel
                        </button>
                        <button variant="primary" className="btn btn-primary" onClick={HandleUpdate}>
                            Confirm
                        </button>
                    </Modal.Footer>
                </Modal>
                {/* {popupType != "" &&
                    <PopupModal popupType={popupType} Navigation="/dashboard/masters/countries"/>
                } */}
                {
                    AlertDeletePopupType === "success" &&
                    <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} CancelAlerrt={CancelAlerrt} />
                }
                {popupType != "" &&
                    <PopupModal popupType={popupType} />
                }
                {EditPopupType != "" &&
                    <EditPopupModal EditPopupType={EditPopupType} setEditpopupType={setEditpopupType} Message="Record Updated Successfully" />
                }
                {
                    confirmType === "success" &&
                    <ConfirmModal confirmType={confirmType} />
                }
            </DashboardLayout>
        </>
    )
}
export { ClientBillsView }
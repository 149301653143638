import React, { useState, useEffect } from "react";
import axiosConfig from "../Service/axiosConfig";
import { MdDeleteOutline, MdOutlineEdit } from "react-icons/md";
import DataTable from "react-data-table-component";
import Masters from "../Components/masters.json";
import { DeletePopupModal, PopupModal, FormParser } from "../Components";
import { BiSearch } from "react-icons/bi";
import Modal from "react-bootstrap/Modal";
import { FaHandHoldingHeart } from "react-icons/fa";
import useDebouncedValue from "../Utils/Debounce";
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import { CMSNavbarComponent } from "../Components/navbar";
import { VendorsNav } from "../Components/navbar/VendorsNav";
const VendorsProfile = () => {
  const [Loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [modalTitle, setModalTitle] = useState("");
  const [LoadingButton, setLoadingButton] = useState();
  const [Data, SetData] = useState([]);
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const [RowPerPage, setRowPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [formData, setFormData] = useState({
    "contact_name":"",
    "reg_no":"",
    "gst_no":"",
    "pan_no":"",
    "address":"",
    "phone_number":"",
    "alternate_no":"",
    "goods_handled":"",
    "margin_offered":10,
    "credit_period":7,
    "return_terms":"",
    "bank_name":"",
    "bank_account_number":"",
    "ifsc_code":"",
    "returns_allowed":""
  });
  const [popupType, setpopupType] = useState("");
  const [RowId, SetRowId] = useState();
  const MastersModal = Masters.VendorsProfile;
  const [deletePopupType, setDeletepopupType] = useState("");
  const [Error, SetError] = useState();
  const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("");
  const [search, SetSearch] = useState({
    search: "",
  });
  const debouncedSearchTerm = useDebouncedValue(search.search, 500);
  const HandleSearch = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    console.log(name, value);
    SetSearch({ ...search, [name]: value });
  };

  const VendorProfile_columns = [
    {
      name: "ID",
      cell: (row, index) => index + 1,
    },
    {
      name: "Vendor Name",
      selector: "vendor_name",
      sortable: true,
    },
    {
      name: "Registration Number",
      selector: "reg_no",
      sortable: true,
    },
    {
      name: "GST Number",
      selector: "gst_no",
      sortable: true,
    },
    {
      name: "PAN Number",
      selector: "pan_no",
      sortable: true,
    },
    {
      name: "Address",
      selector: "address",
      sortable: true,
    },
    {
      name: "Phone Number",
      selector: "phone_number",
      sortable: true,
    },
    {
      name: "Alternate Number",
      selector: "alternate_no",
      sortable: true,
    },
    {
      name: "Goods Handled",
      selector: "goods_handled",
      sortable: true,
    },
    {
      name: "Margin Offered",
      selector: "margin_offered",
      sortable: true,
    },
    {
      name: "Credit Period",
      selector: "credit_period",
      sortable: true,
    },

    {
      name: "Return Terms",
      selector: "return_terms",
      sortable: true,
    },
    {
      name: "Bank Name",
      selector: "bank_name",
      sortable: true,
    },
    {
      name: "Bank Account Number",
      selector: "bank_account_number",
      sortable: true,
    },
    {
      name: "IFSC Code",
      selector: "ifsc_code",
      sortable: true,
    },
    {
      name: "Returns Allowed",
      selector: "returns_allowed",
      cell: (row) => (row.returns_allowed ? "Yes" : "No"),
      sortable: true,
    },
    {
      name: "ACTIONS",
      selector: (row) => {
        const HandleEdit = async () => {
          setShow(true);
          vendorProfileEdit(row.id);
          // handleShow(row.id);
          setModalTitle("Edit vendors Profile");
        };
        const DeleteAlert = () => {
          SetRowId(row.id);
          SetAlertDeletePopupType("success");
        };
        const CancelAlerrt = () => {
          SetAlertDeletePopupType("error");
        };
        const HandleDelete = async (id) => {
          await axiosConfig
            .delete(`/accounts/vendor-profile/${id}/`)
            .then(() => {
              SetAlertDeletePopupType("error");
              setDeletepopupType("success");
              GetvendorProfile();
            })
            .catch((error) => {
              setDeletepopupType("error");
              SetAlertDeletePopupType("error");
              console.log(error);
            });
        };
        const handleRevive = async () => {
          try {
            const response = await axiosConfig.post(
              `/accounts/vendor-profile/${row.id}/revive/`
            );
            setpopupType("success");
            setMessage("vendors Profile revived successfully");
            GetvendorProfile();
          } catch (error) {
            console.log("Error While Reviving", error);
          }
        };
        const vendorProfileEdit = (id) => {
          axiosConfig
            .get(`/accounts/vendor-profile/${id}/`)
            .then((res) => {
              console.log(res.data, "edit");
              setFormData({
                ...res.data,
                id: res.data.id,
              });
              setShow(true);
              setMessage("Record Updated Successfully");
            })
            .catch((error) => {
              console.log(error);
            });
        };
        return (
          <>
            {!row.is_suspended ? (
              <div className="row">
                <div className="col-4">
                  <MdOutlineEdit
                    className="dashboard-tabe-data-actions"
                    onClick={HandleEdit}
                  />
                </div>
                <div className="col-4">
                  <MdDeleteOutline
                    className="dashboard-tabe-data-actions"
                    onClick={DeleteAlert}
                  />
                </div>
              </div>
            ) : (
              <>
                <FaHandHoldingHeart
                  className="dashboard-tabe-data-actions"
                  onClick={handleRevive}
                />
              </>
            )}
            {AlertDeletePopupType === "success" && (
              <AlertDeletePopupModal
                AlertDeletePopupType={AlertDeletePopupType}
                HandleDelete={HandleDelete}
                CancelAlerrt={CancelAlerrt}
                row={RowId}
              />
            )}
          </>
        );
      },
    },
  ];
  const GetvendorProfile = async () => {
    try {
      let response = await axiosConfig.get(
        `/accounts/vendor-profile/?page=${currentPage}&page_size=${RowPerPage}&search=${debouncedSearchTerm}`
      );
      console.log(response, "data");
      SetData(response.data.results);
      setLoading(false);
      setTotalRows(response.data.count);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    GetvendorProfile();
  }, [currentPage, RowPerPage, debouncedSearchTerm]);
  const formSubmit = async (FormData) => {
    console.log(FormData, "formdata");
    const data_to_send = {
      ...FormData,
      returns_terms: FormData?.returns_terms ? FormData.returns_terms : null,
    };
    delete data_to_send.cuser;
    delete data_to_send.muser;
    setLoadingButton(true);
    if (FormData.id) {
      await axiosConfig
        .patch(`/accounts/vendor-profile/${FormData.id}/`, data_to_send)
        .then((res) => {
          console.log(res);
          setpopupType("success");
          GetvendorProfile();
          setShow(false);
          setMessage("Record Updated Successfully");
        })
        .catch((error) => {
          console.log(error);
          let error_message = error.response.data;
          Object.values(error_message)[0] &&
            SetError(Object.values(error_message)[0]);
          setpopupType("error");
        });
      setLoadingButton(false);
    } else {
      await axiosConfig
        .post("/accounts/vendor-profile/", data_to_send)
        .then((res) => {
          console.log(res);
          setpopupType("success");
          GetvendorProfile();
          setShow(false);
        })
        .catch((error) => {
          console.log(error);
          let error_message = error.response.data;
          Object.values(error_message)[0] &&
            SetError(Object.values(error_message)[0]);
          setpopupType(error);
        });
      setLoadingButton(false);
    }
  };
  const handleClose = () => {
    setShow(false);
    SetError("");
    setpopupType("");
    setMessage("");
    setFormData({
      "contact_name":"",
      "reg_no":"",
      "gst_no":"",
      "pan_no":"",
      "address":"",
      "phone_number":"",
      "alternate_no":"",
      "goods_handled":"",
      "margin_offered":10,
      "credit_period":7,
      "return_terms":"",
      "bank_name":"",
      "bank_account_number":"",
      "ifsc_code":"",
      "returns_allowed":""
    })
    setLoadingButton(false);
  };
  const handleSort = (column, direction) => {
    console.log(column.selector, direction);
    axiosConfig
      .get(
        `/accounts/vendor-profile/?page=${currentPage}&page_size=${RowPerPage}&ordering=${
          direction === "asc" ? column.selector : -column.selector
        }`
      )
      .then((res) => {
        SetData(res.data.results);
      })
      .catch((error) => {
        console.log(error);
      });
    setCurrentPage(1);
  };
  const handleShow = () => {
    setShow(true);
    // setFormData({

    // });
    setModalTitle("Add vendors Profile");
  };
  const tableCustomStyles = {
    rows: {
      style: {},
    },
    cells: {
      style: {
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        color: "#5C5C5C",
        justifyContent: "center",
        color: "rgb(33,37,41)",
        fontSize: "12px",
        fontWeight: 500,
        lineHeight: "28px",
      },
    },
  };

  return (
    <>
      <div className="child-sidenav">
        <VendorsNav />
        <div className="childnav-content">
          <div className="d-flex align-items-center">
            {/* <div className="col-4 input-cat-with-icon">
              <span className="icon-product">
                <BiSearch />
              </span>
              <input
                type="text"
                className="input-control"
                placeholder="Search"
                name="search"
                value={search.search}
                onChange={HandleSearch}
              />
            </div> */}
            <div className="cat-button ms-auto">
              <div className="btn" onClick={handleShow}>
                + Add New
              </div>
            </div>
          </div>
          <div className="table-responsive marginTop">
            <DataTable
              columns={VendorProfile_columns}
              data={Data}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationPerPage={RowPerPage}
              paginationDefaultPage={currentPage}
              onChangePage={(page) => setCurrentPage(page)}
              paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
              paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
              onChangeRowsPerPage={(RowsPerPageChange) =>
                setRowPerPage(RowsPerPageChange)
              }
              onSort={handleSort}
              fixedHeader
              subHeader
              customStyles={tableCustomStyles}
              progressPending={Loading}
              progressComponent={
                <div className="loader-container">
                  <img
                    src={require("../Components/FormParser/images/loader2.gif")}
                    alt="Loading..."
                  />
                </div>
              }
            />
          </div>
          <Modal
            size="xl"
            show={show}
            onHide={handleClose}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-lg">
                {modalTitle}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <FormParser
                modelObject={MastersModal}
                formData={formData}
                formSubmit={formSubmit}
                error={Error}
                buttonTitle="Save"
                LoadingButton={LoadingButton}
              />
            </Modal.Body>
          </Modal>
          {popupType !== "" && (
            <PopupModal
              setpopupType={setpopupType}
              popupType={popupType}
              Navigation=""
              Message={message}
              error={Error}
              setMessage={setMessage}
            />
          )}
          {deletePopupType !== "" && (
            <DeletePopupModal
              setDeletepopupType={setDeletepopupType}
              deletePopupType={deletePopupType}
            />
          )}
        </div>
      </div>
    </>
  );
};
export { VendorsProfile };

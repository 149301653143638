import React, { useEffect, useState } from "react";
import { DashboardLayout, Layout, FormParser, PopupModal } from "../Components";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Masters from "../Components/masters.json"
import "./Pages.css"
import axiosConfig from "../Service/axiosConfig"
import Modal from 'react-bootstrap/Modal';
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import {BsArrowLeftShort} from "react-icons/bs"
const ClinicsAdd = () => {
    let Navigate = useNavigate()
    const [popupType, setpopupType] = useState("");
    const [Show, setShow] = useState(false);
    const MastersModal = Masters.Clinics
    const [Error, SetError] = useState("")
    const [EditData, SetEditData] = useState()
    const [clientid, SetClientid] = useState()
    const formSubmit = (Data) => {
        console.log(Data)
        const formData = new FormData();
        formData.append("clinic_name",Data.clinic_name)
        formData.append("email",Data.email)
        formData.append("clinic_email",Data.clinic_email)
        formData.append("mobileno",Data.mobileno)
        formData.append("clinic_mobileno",Data.clinic_mobileno)
        formData.append("registration_number",Data.registration_number)
        formData.append( "about",Data.about)
        formData.append( "first_name",Data.first_name)
        formData.append( "last_name",Data.last_name)
        formData.append("logo",Data.logo)
        formData.append( "password",Data.password)
    Data.facilities?Data.facilities.forEach(res=>{
            formData.append( "facilities",res)
        }):formData.append( "facilities",[])
        formData.append( "address",Data.address)
        formData.append("pincode",Data.pincode)
        formData.append( "city",Data.city)
        formData.append("gps_coordinates",Data.gps_coordinates)
        axiosConfig.defaults.headers['Content-Type'] = 'multipart/form-data'; 
        axiosConfig.post("/clinics/clinics/", formData).then(res => {
            console.log(res)
            SetClientid(res.data.id)
            setpopupType("success")
        }).catch(error => {
            console.log(error.response.data.error)
            if (error.response.data.detail=== "User with this mobile number already exists.") {
                SetError("Mobile Number Already Exists")
            }
            if (error.response.data.detail=== "User with this email  already exists.") {
                SetError("Email Already Exists")
            }
            setpopupType("error")
        })
    }
    return (
        <>
            <DashboardLayout>
                <div className="mainbar">
                    <div className="sidebar-filters px-2">
                    <NavLink to="/dashboard/clinics/list" ><button type="submit" className="btn btn-primary"><BsArrowLeftShort/>back to Clinics</button></NavLink>
                    </div>
                    <div className="main-code">
                    <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">Add Clinic</h5>
                </div>
                <FormParser modelObject={MastersModal} formSubmit={formSubmit} error={Error} />
                    </div>
                </div>
                {popupType != "" &&
                    <PopupModal popupType={popupType} Navigation={`/dashboard/clinics/list`} />
                }
            </DashboardLayout>
        </>
    )

}
export { ClinicsAdd }

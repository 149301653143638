import React, { useEffect, useState } from "react";
import { MdDeleteOutline, MdOutlineEdit, } from "react-icons/md"
import { AiOutlineEye, AiOutlineCloudDownload } from "react-icons/ai"
import { IoMdDownload } from "react-icons/io"
import 'bootstrap/dist/css/bootstrap.min.css';
import { DashboardLayout, Pagination, PopupModal } from "../Components";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import axiosConfig from "../Service/axiosConfig"
import DataTable from 'react-data-table-component';
import tableCustomStyles from "../Components/PopupModal/tableCustomStyles";
import Dropdown from 'react-bootstrap/Dropdown';
import { downloadExcel, useDownloadExcel } from 'react-export-table-to-excel';
const Invoices = (props) => {
    const [pdfBlob, setPdfBlob] = useState(null);
    const [pdfUrl, setPdfUrl] = useState(null);
    const [selectedOption, setSelectedOption] = useState("");
    console.log(props.props)
    const [popupType, setpopupType] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    let month = searchParams.get("month")
    const [showMenu, setShowMenu] = useState(false);
    console.log(month)
    let navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [Data, SetData] = useState([])
    const [search, SetSearch] = useState({
        search: "",
        month: ""
    })
    const [select, Setselct] = useState({
        month: ""
    })

    const [Datafilter, SetDatafilter] = useState([])
    const [page, NextPage] = useState(1)
    console.log(page)
    const GetData = async () => {
        if (month && month.length > 0) {

            setLoading(true)
            try {
                console.log(page)
                const responce = await axiosConfig.get(`/client/client-invoices/?month__startswith=${month}`)
                // console.log(responce.data.results)
                console.log(responce.data)
                SetData(responce.data)
                SetDatafilter(responce.data)
                setLoading(false)
            }
            catch (error) {
                console.log(error)
            }
        }
    }
    useEffect(() => {
        GetData();
    }, [])
    const HandleNextPage = () => {
        NextPage(page + 1)
    }
    const HandleSearch = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        console.log(name, value)
        SetSearch({ ...search, [name]: value })
        const response = Datafilter.filter(data => data.client_name && data.client_name.toLowerCase().includes(value.toLowerCase()) || data.invoice_id && data.invoice_id.toString().includes(value) || data.total_amount && data.total_amount.toString().includes(value) || data.total_net_amount && data.total_net_amount.toString().includes(value) || data.quantity && data.quantity.toString().includes(value))
        console.log(response)
        SetData(response)
    }

    const HandleSelect = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        month = value
        setSearchParams({ 'month': value });
        GetData();
    }
    const option = [{ "value": "1", "label": "View" }, { "value": "2", "label": "Edit" }, { "value": "3", "label": "Invoice" }, { "value": "4", "label": "Invoice With Logo" }, { "value": "5", "label": "Full Sheet" }]
    const columns = [
        {
            name: "S.No",
            selector: (row,i) => i+1,
            sortable: true
        },
        {
            name: "id",
            selector: row => row.id,
            sortable: true
        },
        {
            name: "Client Id",
            selector: row => row.client,
            sortable: true
        },
        {
            name: "Client Name",
            selector: row => row.client_name,
            sortable: true
        },
        {
            name: "Address",
            selector: row => row.address,
            sortable: true
        },
        {
            name: "Invoice No",
            selector: row => row.invoice_id,
            sortable: true
        },
        {
            name: "No of Cases",
            selector: row => row.quantity,
            sortable: true
        },
        {
            name: "Total Amount",
            selector: row => row.total_amount,
            sortable: true
        },
        {
            name: "Discount",
            selector: row => row.discount,
            sortable: true
        },
        {
            name: "Net Amount",
            selector: row => row.total_net_amount,
            sortable: true
        },
        {
            name: "ACTIONS",
            selector: row => {
                const HandleView = () => {
                    return navigate(`/dashboard/billing/client-final-bills/${row.client}/view/${month}`);
                }
                const HandleDownload = async (e) => {
                    e.preventDefault()
                    await axiosConfig.get(`/client/pdf/${row.client}/`).then(response => {
                        console.log(response)
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", `${row.client_name}.pdf`);
                        document.body.appendChild(link);
                        link.click();
                        setpopupType("success")

                    })
                        .catch(error => {
                            console.error('There was an error downloading the PDF', error);
                            setpopupType("error")
                        });
                }
                const HandleDownloadwithLogo = async (e) => {
                    e.preventDefault()
                    await axiosConfig.get(`/client/pdf/${row.client}/?logo=yes`).then(response => {
                        console.log(response)
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", `${row.client_name}.pdf`);
                        document.body.appendChild(link);
                        link.click();
                        setpopupType("success")

                    })
                        .catch(error => {
                            console.error('There was an error downloading the PDF', error);
                            setpopupType("error")
                        });
                }
                const fetchPDF = async () => {
                    try {
                        await axiosConfig.get(`/client/pdf/${row.client}/`).then(responce => {
                            const url = window.URL.createObjectURL(new Blob([responce.data], { type: 'application/pdf' }));
                            window.location.href = url;
                        }).catch(error => {
                            console.log(error)
                        })
                    } catch (error) {
                        console.error('Error fetching PDF:', error);
                    }
                }

                const handleOptionSelect = async (event) => {
                    console.log(event.target.value)
                    setSelectedOption(event.target.value);
                    if (event.target.value === "1") {
                        await axiosConfig.get(`/client/pdf/${row.client}/`).then(responce => {
                            const url = window.URL.createObjectURL(new Blob([responce.data], { type: 'application/pdf' }));
                            window.location.href = url;
                        }).catch(error => {
                            console.log(error)
                        })
                    }
                    else if (event.target.value === "2") {
                        return navigate(`/dashboard/billing/client-final-bills/${row.client}/view/${month}`);
                    }
                    else if (event.target.value === "3") {
                        await axiosConfig.get(`/client/pdf/${row.client}/`).then(response => {
                            console.log(response)
                            const url = window.URL.createObjectURL(new Blob([response.data]));
                            const link = document.createElement("a");
                            link.href = url;
                            link.setAttribute("download", `${row.client_name}.pdf`);
                            document.body.appendChild(link);
                            link.click();
                            setpopupType("success")

                        })
                            .catch(error => {
                                console.error('There was an error downloading the PDF', error);
                                setpopupType("error")
                            });
                    }
                    else if (event.target.value === "4") {
                        await axiosConfig.get(`/client/pdf/${row.client}/?logo=yes`).then(response => {
                            console.log(response)
                            const url = window.URL.createObjectURL(new Blob([response.data]));
                            const link = document.createElement("a");
                            link.href = url;
                            link.setAttribute("download", `${row.client_name}.pdf`);
                            document.body.appendChild(link);
                            link.click();
                            setpopupType("success")

                        })
                            .catch(error => {
                                console.error('There was an error downloading the PDF', error);
                                setpopupType("error")
                            });
                    }
                    else if (event.target.value === "5") {
                        try {
                            const response = await axiosConfig.get(`/client/clientbilledservices/?casedetail__caseinfo__group=${row.client}&month=${month}`)
                            // SetData(response.data)
                            console.log(response.data)
                            const header = ["S.No",
                                "Id",
                                "Patien ID",
                                "Patient Name",
                                "Age",
                                "Gender",
                                "Modality",
                                "Study Description",
                                "Billing Description",
                                "Billed Amount",];
                            const body3 = response.data.map((res, index) => [index + 1, res.id, res.casedetail.caseinfo.patient_id, res.casedetail.caseinfo.patient_name, res.casedetail.caseinfo.age, res.casedetail.caseinfo.gender, res.casedetail.caseinfo.modality, res.casedetail.case_study_description, res.casedetail.billing_description, res.net_amount,])
                            downloadExcel({
                                fileName: `${row.client_name && row.client_name}`,
                                sheet: `${row.client_name && row.client_name}`,
                                tablePayload: {
                                    header,
                                    body: body3,
                                },
                            });
                        }
                        catch (error) {
                            console.log(error)
                        }
                    }
                };
                return (
                    <>
                         <select className="form-select"  onChange={handleOptionSelect}>
                            <option value="">Actions</option>
                            {
                                option.map(optiontype => {
                                    return (
                                        <option key={optiontype.value} value={optiontype.value}>{optiontype.label}</option>
                                    )
                                })
                            }
                        </select> 
                        {/* <div className="row" >
                            <div className="col-md-2" onClick={fetchPDF}>
                                <AiOutlineEye />
                            </div>
                            <div className="col-md-2" onClick={HandleView}>
                                <MdOutlineEdit />
                            </div>
                            <div className="col-md-2">
                                <p className="">Download</p>
                            </div>
                        </div> */}
                        {/* <div className="col-md-3" onClick={HandleDownload}>
                                <AiOutlineCloudDownload />
                            </div>
                            <div className="col-md-3" onClick={HandleDownloadwithLogo}>
                                <IoMdDownload />
                            </div> */}
                    </>
                )

            },
        }
    ]
    console.log(pdfBlob)
    return (
        <>
            <DashboardLayout>
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">View Client Final Bills</h5>
                    <input type="month" name="month" className="form-control month-selector" value={month} onChange={HandleSelect} />
                </div>
                {month && month.length > 1 ? (
                    <div className="table-responsive">
                        <DataTable columns={columns} data={Data}
                            progressPending={loading}
                            progressComponent={<div className="loader-container">
                                <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                            </div>}
                            subHeader
                            fixedHeader
                            pagination
                            subHeaderComponent={
                                <>
                                    <input type="text" className="form-control w-25" name="search" value={search.search} onChange={HandleSearch} />
                                </>}
                            customStyles={tableCustomStyles} />
                    </div>

                ) : (
                    <>
                        <p className="table-error">Please select Month first</p>
                    </>
                )}
                {popupType != "" &&
                    <PopupModal popupType={popupType} Message="Invoice Downloaded Successfully !" />
                }
            </DashboardLayout>
        </>
    )
}
export { Invoices }
import React, { useEffect, useState } from "react";
import { DashboardLayout, FormParser,PopupModal } from "../Components";
import { NavLink,useParams } from "react-router-dom";
import Masters from "../Components/masters.json"
import "./Pages.css"
import axiosConfig from "../Service/axiosConfig"
const InfoEdit = () => {
    let { id } = useParams();
    const[Error,SetError]=useState("")
    const[popupType,setpopupType]=useState("")
    const MastersModal=Masters.ClinicInformation
    const formSubmit = (Data) => {
        axiosConfig.defaults.headers['Content-Type'] = 'multipart/form-data'; 
            axiosConfig.post(`/clinics/clinicinfos/`, {
            "logo": Data.logo,
            "about":Data.about,
            "facilities": Data.facilities,clinic:id}).then(res => {
                console.log(res)
                setpopupType("success")
            }).catch(error => {
                console.log(error)
                let ErrorData=[]
                ErrorData.push({
                    code:error.response.data.code||["null"],
                    name:error.response.data.name||["null"],
                    })
                    console.log(ErrorData[0])
            if(ErrorData[0].code[0]==="This field must be unique."){
                SetError("Code Already Exists")
            }
            if(ErrorData[0].name[0]==="This field must be unique."){
                SetError("Name Already Exists")
            }
                setpopupType("error")
            })
    }
    const [Data, SetData] = useState([])
    const GetData = async () => {
        try {
            const responce = await axiosConfig.get(`/clinics/clinicinfos/${id}/`)
            SetData(responce.data)
        }
        catch (error) {
            console.log(error)
            
        }

    }
    useEffect(() => {
        GetData();
    }, [])
    const currentURL = window.location.href;
    console.log(currentURL)
    return (
        <>
            <DashboardLayout>
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">clinicInfo {id}</h5>
                    <div className="d-md-flex gap-4">
                    <NavLink to={`/dashboard/clinics/${id}/infoEdit`}><button  className={currentURL===`http://localhost:3000/dashboard/clinics/${id}/info`&&currentURL===`http://localhost:3000/dashboard/clinics/${id}/info`||currentURL===`http://cms.krisantec.com/dashboard/clinics/${id}/info`&&currentURL===`http://cms.krisantec.com/dashboard/clinics/${id}/info`?"btn btn-primary":"btn btn-white"}>ClinicInfo</button></NavLink>
                    <NavLink to={`/dashboard/clinics/${id}/address`}><button  className={currentURL===`http://localhost:3000/dashboard/clinics/${id}/address`&&currentURL===`http://localhost:3000/dashboard/clinics/${id}/address`||currentURL===`http://cms.krisantec.com/dashboard/clinics/${id}/address`&&currentURL===`http://cms.krisantec.com/dashboard/clinics/${id}/address`?"btn btn-primary":"btn btn-white"}>Address</button></NavLink>
                    <NavLink to={`/dashboard/clinics/${id}/specialities`}><button  className={currentURL===`http://localhost:3000/dashboard/clinics/${id}/specialities`&&currentURL===`http://localhost:3000/dashboard/clinics/${id}/specialities`||currentURL===`http://cms.krisantec.com/dashboard/clinics/${id}/specialities`&&currentURL===`http://cms.krisantec.com/dashboard/clinics/${id}/specialities`?"btn btn-primary":"btn btn-white"}>Specialities</button></NavLink>
                    <NavLink to={`/dashboard/clinics/${id}/gallery`}><button   className={currentURL===`http://localhost:3000/dashboard/clinics/${id}/gallery`&&currentURL===`http://localhost:3000/dashboard/clinics/${id}/gallery`||currentURL===`http://cms.krisantec.com/dashboard/clinics/${id}/gallery`&&currentURL===`http://cms.krisantec.com/dashboard/clinics/${id}/gallery`?"btn btn-primary":"btn btn-white"}>Gallery</button></NavLink>
                    <NavLink to={`/dashboard/clinics/${id}/avalibility`}><button   className={currentURL===`http://localhost:3000/dashboard/clinics/${id}/avalibility`&&currentURL===`http://localhost:3000/dashboard/clinics/${id}/avalibility`||currentURL===`http://cms.krisantec.com/dashboard/clinics/${id}/avalibility`&&currentURL===`http://cms.krisantec.com/dashboard/clinics/${id}/avalibility`?"btn btn-primary":"btn btn-white"}>Availability</button></NavLink>
                    <NavLink to={`/dashboard/clinics/${id}/vacations`}><button   className={currentURL===`http://localhost:3000/dashboard/clinics/${id}/vacations`&&currentURL===`http://localhost:3000/dashboard/clinics/${id}/vacations`||currentURL===`http://cms.krisantec.com/dashboard/clinics/${id}/vacations`&&currentURL===`http://cms.krisantec.com/dashboard/clinics/${id}/vacations`?"btn btn-primary":"btn btn-white"}>Vacations</button></NavLink>
                    </div>
                </div>
                <FormParser modelObject={MastersModal} formData={Data} formSubmit={formSubmit} error={Error}/>
                {popupType != "" &&
                    <PopupModal popupType={popupType} Navigation=""/>
                }
            </DashboardLayout>
        </>
    )

}
export {InfoEdit}
import React, { useEffect, useState } from "react";
import { DashboardLayout } from "../Components";
import { NavLink, useNavigate } from "react-router-dom";
import axiosConfig from "../Service/axiosConfig";
import { MdOutlineDownload, MdOutlineEdit } from "react-icons/md";
import Select from "react-select";
import DataTable from "react-data-table-component";
import tableCustomStyles from "../Components/PopupModal/tableCustomStyles";
import { useDownloadExcel, downloadExcel } from "react-export-table-to-excel";
import { ReportsNav } from "../Components/navbar";
const BillItemList = () => {
  let today_date = new Date().toISOString().split("T")[0];
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [RowPerPage, setRowPerPage] = useState(20);
  const [UserData, SetUserData] = useState({});
  const [ExcellData, SetExcellData] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const Store_id = JSON.parse(localStorage.getItem("Store"));
  const [formData, setFormData] = useState({
    fromDate: today_date,
    toDate: today_date,
    store: localStorage.getItem("Store")
      ? JSON.parse(localStorage.getItem("Store"))
      : [],
    client: null,
  });
  const [dateType, setDateType] = useState([
    { label: "Ordered Date", value: "ordered_date" },
    { label: "Expected Date", value: "expected_date" },
    { label: "Delivered Date", value: "delivered_date" },
  ]);
  const [orderStatuses, setOrderStatuses] = useState([
    // { label: "Cart", value: "Cart" },
    { label: "Placed", value: "Placed" },
    { label: "Accepted", value: "Accepted" },
    { label: "Shipped", value: "Shipped" },
    { label: "Delivered", value: "Delivered" },
    { label: "Rejected", value: "Rejected" },
  ]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [selectedDateType, setSelectedDateType] = useState("");
  const [storeOptions, setStoreOptions] = useState([]);
  const [clientOptions, setClientOptions] = useState([]);
  const [Data, SetData] = useState([]);
  let navigate = useNavigate();

  const GetData = async () => {
    setLoading(true);
    let date_filter_from =
      selectedDateType === "ordered_date"
        ? "order_date_after"
        : selectedDateType === "expected_date"
        ? "expected_date_time_after"
        : "delivered_time_after";
    let date_filter_to =
      selectedDateType === "ordered_date"
        ? "order_date_before"
        : selectedDateType === "expected_date"
        ? "expected_date_time_before"
        : "delivered_time_before";
    try {
      const responce = await axiosConfig.get(
        `/accounts/orders/?orderstatus=${selectedStatuses.join(
          ","
        )}&${date_filter_from}=${formData.fromDate}&${date_filter_to}=${
          formData.toDate
        }&stores=${formData.store?.id ? formData.store?.id : ""}&user=${
          formData.client ? formData.client : ""
        }&page=${currentPage}&page_size=${RowPerPage}`
      );
      SetData(responce.data.results);
      setLoading(false);
      setTotalRows(responce.data.count);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSort = (column, direction) => {
    console.log(column, direction);
    axiosConfig
      .get(
        `/accounts/orders/?orderstatus=Delivered&store=${
          formData.store?.id ? formData.store?.id : ""
        }&${
          selectedDateType === "ordered_date"
            ? "order_date_after"
            : "expected_date_time_before"
        }=${formData.fromDate}&${
          selectedDateType === "ordered_date"
            ? "order_date_before"
            : "expected_date_time_after"
        }=${
          formData.toDate
        }&page=${currentPage}&page_size=${RowPerPage}&ordering=${
          direction === "asc" ? column.name : `-${column.selector}`
        }`
      )
      .then((res) => {
        SetData(res.data.results);
      })
      .catch((error) => {
        console.log(error);
      });
    setCurrentPage(1);
  };

  const HandlePrint = (invoice) => {
    if (invoice) {
      window.open(invoice.pdf_path);
    }
  };

  const HandleEdit = (order) => {
    navigate(`/dashboard/orders/${order.id}/modify`, {
      state: { order_data: order, client_data: order.client },
    });
  };

  function formatDateTime(dateTimeString) {
    const optionsDate = { year: "numeric", month: "short", day: "numeric" };
    const optionsTime = { hour: "numeric", minute: "numeric", hour12: true };

    const dateTime = new Date(dateTimeString);

    const formattedDate = dateTime.toLocaleDateString("en-US", optionsDate);
    const formattedTime = dateTime.toLocaleTimeString("en-US", optionsTime);

    return `${formattedDate} - ${formattedTime}`;
  }

  const columns = [
    {
      name: "Order ID",
      selector: "id",
      sortable: true,
      width: "120px",
    },
    {
      name: "Invoice ID",
      selector: "invoice",
      cell: (row) => <div>{row.invoice ? row.invoice.invoice_id : ""}</div>,
      sortable: true,
      width: "120px",
    },
    {
      name: "User",
      cell: (row) => <div>{row.user_name}</div>,
      sortable: true,
      width: "100px",
    },
    // {
    //     name: 'Store',
    //     selector: 'store',
    //     cell: (row) => <div>{row.store.name}</div>,
    //     sortable: true,
    //     width: '100px'
    // },
    // {
    //     name: 'Delivery Agent',
    //     selector: 'delivery_agent',
    //     cell: (row) =>
    //         <div className="ellipsis w-120">
    //             {row.delivery_agent?.full_name}
    //         </div>,
    //     sortable: true,
    //     width: '120px'
    // },
    {
      name: "Total Amount",
      selector: "total_amount",
      sortable: true,
      width: "100px",
    },
    {
      name: "Total Items",
      selector: "total_quantity",
      sortable: true,
      width: "80px",
    },
    {
      name: "Ordered Date",
      selector: "order_date",
      cell: (row) => formatDateTime(row.order_date),
      sortable: true,
      width: "120px",
    },
    {
      name: "Delivered Date",
      selector: "delivered_time",
      cell: (row) =>
        row.delivered_time ? formatDateTime(row.delivered_time) : "N/A",
      sortable: true,
      width: "120px",
    },
    {
      name: "Invoice",
      cell: (row) => (
        <div style={{ cursor: "pointer" }} className="d-flex gap-10">
          <MdOutlineDownload
            size={15}
            color={"#1b7d78"}
            onClick={() => HandlePrint(row.invoice)}
          />
          {/* <MdOutlineEdit size={15} color={"#1b7d78"} onClick={() => HandleEdit(row)} /> */}
        </div>
      ),
      width: "80px",
    },
  ];

  const handleRowsPerPageChange = (event) => {
    console.log(event.target.value);
    setCurrentPage(1);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const fetchStore = async () => {
    const response = await axiosConfig.get(`/stores/stores/?page_size=1000`);
    setStoreOptions([
      { id: "", name: "---Select Store---" },
      ...response.data.results,
    ]);
  };

  const fetchClients = async () => {
    const response = await axiosConfig.get(`/accounts/users/?page_size=1000`);
    setClientOptions([
      { id: "", client_name: "---Select Client---" },
      ...response.data.results,
    ]);
    setFilteredClients([
      { id: "", client_name: "---Select Client---" },
      ...response.data.results,
    ]);
  };

  const HandleselectChange = (selectedOptions, fieldName) => {
    const selectedValues = selectedOptions.map((item) => item.value);
    if (fieldName === "store") {
      if (selectedOptions.length === 0) {
        setFilteredClients(clientOptions);
      } else {
        let filtered_clients = clientOptions.filter((client) => {
          return client.store && selectedValues.includes(client.store.id);
        });
        console.log(filtered_clients, storeOptions, "filtered_clients");
        setFilteredClients(filtered_clients);
      }
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: selectedValues,
    }));
  };

  const HandleTypeChange = (type, selectedOption) => {
    if (type === "dateType") {
      setSelectedDateType(selectedOption.value);
    } else if (type === "orderStatus") {
      console.log(selectedOption);
      setSelectedStatuses(selectedOption.map((item) => item.value));
    }
  };

  const handleSubmit = () => {
    GetData();
  };

  const multiSelectStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#c9dddc" : "white", // Change background color for selected option
      color: state.isSelected ? "#1b7d78" : "black", // Change text color for selected option
      ":hover": {
        backgroundColor: "#c9dddc", // Change background color on hover for options
      },
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "#c9dddc", // Change background color for the multi-value container
      color: "#1b7d78", // Change text color for the multi-value container
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "#1b7d78", // Change text color for the label within the multi-value container
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: "#1b7d78", // Change color for the "X" button within the multi-value container
      ":hover": {
        backgroundColor: "#1b7d78", // Change background color on hover for the "X" button
        color: "white",
      },
    }),
  };

  useEffect(() => {
    GetData();
    fetchStore();
    fetchClients();
  }, [currentPage, RowPerPage]);

  return (
    <>
      <div className="child-sidenav">
        <ReportsNav />
        <div className="flex-1 reports-parent">
          <div className="report-filters">
            <Select
              className="flex-1"
              options={orderStatuses}
              onChange={(selectedOption) =>
                HandleTypeChange("orderStatus", selectedOption)
              }
              styles={multiSelectStyles}
              placeholder="Order Status"
              isMulti
            />
            <Select
              placeholder="Date Type"
              className="flex-1"
              options={dateType}
              value={dateType.find(
                (option) => option.value === selectedDateType
              )}
              onChange={(selectedOption) =>
                HandleTypeChange("dateType", selectedOption)
              }
              styles={multiSelectStyles}
            />
            <div className="form-group flex-1">
              <input
                className="form-control"
                type="date"
                name="fromDate"
                value={formData.fromDate}
                onChange={handleChange}
              />
            </div>
            <div className="form-group flex-1">
              <input
                className="form-control"
                type="date"
                name="toDate"
                value={formData.toDate}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="report-filters">
            <Select
              placeholder="Store"
              className="flex-1"
              options={storeOptions.map((res) => ({
                value: res.id,
                label: res.name,
              }))}
              isMulti
              value={storeOptions
                .map((res) => ({ value: res.id, label: res.name }))
                .find((res) => res.value === formData.store?.id)}
              onChange={(selectedOption) =>
                HandleselectChange(selectedOption, "store")
              }
              styles={multiSelectStyles}
              isDisabled={formData.store?.id ? true : false}
            />
            <Select
              placeholder="Users"
              className="flex-1"
              options={filteredClients.map((res) => ({
                value: res.id,
                label: res.full_name,
              }))}
              isMulti
              value={filteredClients
                .map((res) => ({ value: res.id, label: res.client_name }))
                .find((res) => res.value === formData.client)}
              onChange={(selectedOption) =>
                HandleselectChange(selectedOption, "client")
              }
              styles={multiSelectStyles}
            />
            <button
              className="btn btn-primary"
              style={{ height: "37px" }}
              onClick={handleSubmit}
            >
              Get Reports
            </button>
          </div>
          <DataTable
            columns={columns}
            data={Data}
            pagination
            paginationTotalRows={totalRows}
            paginationPerPage={RowPerPage}
            paginationDefaultPage={currentPage}
            onChangePage={(page) => setCurrentPage(page)}
            paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
            paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
            onChangeRowsPerPage={(RowsPerPageChange) =>
              setRowPerPage(RowsPerPageChange)
            }
            onSort={handleSort}
            paginationServer
            customStyles={tableCustomStyles}
            progressPending={loading}
            progressComponent={
              <div className="loader-container">
                <img
                  src={require("../Components/FormParser/images/loader2.gif")}
                  alt="Loading..."
                />
              </div>
            }
          />
        </div>
      </div>
    </>
  );
};
export { BillItemList };

import React, { useEffect, useState } from "react";
import { DashboardLayout } from "../Components";
import { NavLink, useNavigate } from "react-router-dom";

const NotFound = () => {
    
    return (
        <>
            <DashboardLayout>
                <div className="mt-5">
                    <h1>404 - Page Not Found</h1>
                    <p>Sorry, the page you are trying to access does not exist.</p>
                </div>
                
            </DashboardLayout>
        </>
    )
}
export { NotFound }
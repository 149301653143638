import React, { useEffect, useState } from "react";
import { DashboardLayout, DeletePopupModal, PopupModal } from "../Components";
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import { MarketingNavbarComponent } from "../Components/navbar/marketingnav";
import { BiSearch } from "react-icons/bi";
import axiosConfig from "../Service/axiosConfig"
import { FormParser } from "../Components";
import Modal from 'react-bootstrap/Modal';
import Masters from "../Components/masters.json";
import DataTable from 'react-data-table-component';
import { MdDeleteOutline, MdOutlineEdit } from "react-icons/md";
import { FaHandHoldingHeart } from "react-icons/fa";
import useDebouncedValue from "../Utils/Debounce";

const Discounts = () => {
    let today_date = (new Date()).toISOString().split('T')[0];
    const [search, SetSearch] = useState({
        search: ""
    })
    const debouncedSearchTerm = useDebouncedValue(search.search, 500);
    const HandleSearch = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        console.log(name, value)
        SetSearch({ ...search, [name]: value })
    }
    const MastersModal = Masters.Discount
    const MastersModalEdit = Masters.DiscountEdit
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("");
    const [Error, SetError] = useState("")
    const [deletePopupType, setDeletepopupType] = useState("");
    const [Data, SetData] = useState([]);
    const [loading, setLoading] = useState(false)
    const [RowId, SetRowId] = useState()
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(20);
    const [popupType, setpopupType] = useState("")
    const [show, setShow] = useState(false);
    const [modalTitle, setModalTitle] = useState("Discounts");
    const [message, setMessage] = useState('')
    const [date, setDate] = useState({
        from_date: "",
        to_date: ""
    });
    const [formData, setFormData] = useState({
    });

    const handleShow = () => {
        setShow(true)
        // setSelectedImage("")
        setFormData({
        });
        setBlogid(null);
        setModalTitle("Discounts");
    };
    const handleClose = () => {
        setShow(false)
        // SetError("")
        setpopupType("")
    };
    const handleSort = (column, direction) => {
        console.log(column.selector, direction)
        axiosConfig.get(`/catlog/discount-coupons/?page=${currentPage}&page_size=${RowPerPage}&ordering=${direction === "asc" ? column.selector : -column.selector}&page_size=${RowPerPage}&coupon_end_date_after=${date.from_date}&coupon_end_date_before=${date.to_date}&fields_coupon=start_date,end_date,code,description,active,is_auto_apply,can_apply_with_other_coupons,user_redeem_limit,is_display_coupon,id`).then(res => {
            SetData(res.data.results)
        }).catch(error => {
            console.log(error)
        })
        setCurrentPage(1);
    };
    const [passwordError, setPasswordError] = useState("");
    const formSubmit = (FormData, id) => {
        console.log(FormData, "formdata")
        let formData = {
            active: FormData?.active,
            can_apply_with_other_coupons: FormData?.can_apply_with_other_coupons,
            category: FormData?.is_productorcategory_is_specific ? FormData?.category : null,
            code: FormData?.code.toUpperCase(),
            coupon: FormData?.coupon,
            description: `use ${FormData?.code} to get ${FormData?.discount_percent ? `${FormData.discount_percent}%` : `${FormData.discount_amount}`
                } discount on a purchase over ${FormData?.minimum_purchase_amount}`,
            discount_amount: FormData?.discount_amount ? FormData.discount_amount : null,
            discount_percent: FormData?.discount_percent ? FormData.discount_percent : null,
            discount_type: FormData?.discount_type,
            end_date: FormData?.end_date,
            id: FormData?.id,
            is_auto_apply: FormData?.is_auto_apply,
            is_display_coupon: FormData?.is_display_coupon,
            maximum_discount_amount: FormData?.maximum_discount_amount,
            minimum_purchase_amount: FormData?.minimum_purchase_amount,
            product_id: FormData?.is_productorcategory_is_specific ? FormData?.product_id : null,
            start_date: FormData?.start_date,
            is_applicable_inapp: FormData.is_applicable_inapp,
            user_redeem_limit: FormData?.user_redeem_limit?FormData?.user_redeem_limit:null,
            user: FormData?.is_user_spesific ? FormData.user ? FormData.user : null : null,
            customer_type: FormData?.customer_type
        }
        if (new Date(formData.start_date) >= new Date(formData.end_date)) {
            setpopupType("error");
            setMessage("End date must be greater than start date.");
            return;
        }
        if (FormData.id) {
            axiosConfig.put(`/catlog/discount-coupons/${FormData.id}/`, formData)
                .then(res => {
                    console.log(res)
                    setpopupType("success")
                    setPasswordError("");
                    GetData();
                    setShow(false);
                    setMessage("Record Updated Successfully !")
                }).catch(error => {
                    setpopupType("error")
                    if (error.response.data?.error) {
                        setMessage(error.response.data?.error)
                    }
                })
        } else {
            axiosConfig.post("/catlog/discount-coupons/", formData).then(res => {
                console.log(res)
                setpopupType("success")
                setPasswordError("");
                GetData();
                setShow(false);
            }).catch(error => {
                console.log(error)
                setpopupType("error")
                if (error.response.data?.error) {
                    console.log(error.response.data?.error)
                    setMessage(error.response.data?.error)
                }
            })
        }
    };
    const GetData = async () => {
        setLoading(true)
        try {
            const responce = await axiosConfig.get(`/catlog/discount-coupons/?page=${currentPage}&search=${debouncedSearchTerm}&page_size=${RowPerPage}&coupon_end_date_after=${date.from_date}&coupon_end_date_before=${date.to_date}&fields_coupon=start_date,end_date,code,description,active,is_auto_apply,can_apply_with_other_coupons,user_redeem_limit,is_display_coupon,id&fields_free_product_id=name,image&fields_discount_product_id=name,image`)
            SetData(responce.data.results)
            setLoading(false)
            setTotalRows(responce.data.count)
        }
        catch (error) {
            console.log(error)
        }

    }
    const handleChange = (event) => {
        const { name, value } = event.target;
        setDate((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };
    function extractDate(timestamp) {
        const date = new Date(timestamp);

        // Extracting the date in 'YYYY-MM-DD' format based on local time
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    }
    const tableCustomStyles = {
        rows: {
            style: {
            },
        },
        cells: {
            style: {
                justifyContent: "center"
            },
        },
        headCells: {
            style: {
                color: "#5C5C5C",
                justifyContent: "center",
                color: "rgb(33,37,41)",
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "28px",
            },
        },
    };
    useEffect(() => {
        GetData();
    }, [currentPage, RowPerPage, debouncedSearchTerm]);
    const [blogid, setBlogid] = useState()
    const blogedit = (id) => {
        axiosConfig.get(`/catlog/discount-coupons/${id}/?fields_coupon=start_date,end_date,code,description,active,is_auto_apply,can_apply_with_other_coupons,user_redeem_limit,is_display_coupon,id&fields_category=name,image,id&fields_product_id=name,image,id&fields_user=id,name`).then(res => {
            console.log(res.data, "edit")
            setFormData({
                user: res.data?.user?.id,
                id: res?.data?.id, "code": res.data?.coupon?.code,
                "description": res.data?.coupon?.description,
                "start_date": res.data?.coupon?.start_date,
                "end_date": res.data?.coupon?.end_date,
                "active": res.data?.coupon?.active,
                "is_auto_apply": res.data?.coupon?.is_auto_apply,
                "can_apply_with_other_coupons": res.data?.coupon?.can_apply_with_other_coupons,
                "is_display_coupon": res.data?.coupon?.is_display_coupon,
                "user_redeem_limit": res.data?.coupon?.user_redeem_limit,
                "discount_percent": res.data?.discount_percent,
                "category": res.data?.category?.id,
                is_applicable_inapp: res.data.is_applicable_inapp,
                is_productorcategory_is_specific: res.data?.category?.id ? true : false,
                is_user_spesific: res.data?.user?.id ? true : false,
                customer_type: res.data?.customer_type,
                "discount_amount": res.data?.discount_amount, discount_type: res.data?.discount_type, "coupon": res.data?.coupon?.id, product_id: res.data?.product_id?.id, minimum_purchase_amount: res.data?.minimum_purchase_amount, maximum_discount_amount: res.data?.maximum_discount_amount
            })
            setBlogid(id)
            setShow(true)
        }).catch(error => {
            console.log(error)
        })
    }
    const columns = [
        {
            name: "S.No",
            selector: "id",
            cell: (row, i) => (currentPage - 1) * RowPerPage + i + 1,
            sortable: true
        },
        {
            name: "Validity",
            selector: "active",
            cell: row => `${row.coupon?.start_date ? extractDate(row.coupon?.start_date) : "N/A"}-${row.coupon?.end_date ? extractDate(row.coupon?.end_date) : "N/A"}`,
            sortable: true
        },
        {
            name: "code",
            cell: row => `${row.coupon?.code}`,
            sortable: true
        },
        {
            name: "Configurations",
            cell: row => <>
                <div className="card-ma">
                    {row?.coupon?.active && <div class="client-type contract-background">Active</div>}
                    {row?.coupon?.is_auto_apply && <div class="client-type contract-background">Is Auto Apply</div>}
                    {row?.coupon?.can_apply_with_other_coupons && <div class="client-type contract-background">Can Apply With Other Coupons</div>}
                    {row?.coupon?.is_display_coupon && <div class="client-type contract-background">Is Display Coupon</div>}
                    {row?.is_applicable_inapp && <div class="client-type contract-background">Is Applicable In App</div>}
                </div>
            </>,
            sortable: true,
            style: {
                display: "flex",
                justifyContent: "start"

            },
        },
        {
            name: "Discount Percent",
            selector: "discount_percent",
            cell: row => row.discount_percent ? `${row.discount_percent}%` : 0,
            sortable: true
        },
        {
            name: "Discount Amount",
            selector: "discount_amount",
            cell: row => row.discount_amount ? `${row.discount_amount}` : 0,
            sortable: true
        },
        {
            name: "ACTIONS",
            selector: row => {
                const HandleEdit = async () => {
                    console.log(row.id, 'iddddd')
                    setShow(true)
                    blogedit(row.id)
                    handleShow(row.id);
                    setModalTitle("Edit Discounts");
                }
                const DeleteAlert = () => {
                    SetRowId(row.id)
                    SetAlertDeletePopupType("success")
                }
                const CancelAlerrt = () => {
                    SetAlertDeletePopupType("error")
                }
                const HandleDelete = async (id) => {
                    await axiosConfig.delete(`/catlog/discount-coupons/${id}/`).then(() => {
                        SetAlertDeletePopupType("error")
                        setDeletepopupType("success")
                        GetData();
                    }).catch((error) => {
                        setDeletepopupType("error")
                        SetAlertDeletePopupType("error")
                        console.log(error)
                    })
                }
                const handleRevive = async () => {
                    try {
                        const response = await axiosConfig.post(`/catlog/discount-coupons/${row.id}/revive/`)
                        setpopupType("success")
                        setMessage("Loyalty revived successfully")
                        GetData()
                    } catch (error) {
                        console.log("Error While Reviving", error)
                    }
                }
                return (
                    <>
                        {!row.is_suspended ?
                            <div className="row" >
                                <div className="col-4">
                                    <MdOutlineEdit className="dashboard-tabe-data-actions" onClick={HandleEdit} />
                                </div>
                                <div className="col-4">
                                    <MdDeleteOutline className="dashboard-tabe-data-actions" onClick={DeleteAlert} />
                                </div>
                            </div>
                            :
                            <>
                                <FaHandHoldingHeart className="dashboard-tabe-data-actions" onClick={handleRevive} />
                            </>
                        }
                        {
                            AlertDeletePopupType === "success" &&
                            <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} HandleDelete={HandleDelete} CancelAlerrt={CancelAlerrt} row={RowId} />
                        }
                    </>
                )

            }
        }
    ]
    return (
        <>
            <div className="child-sidenav">
                <MarketingNavbarComponent />
                <div className="childnav-content">
                    <div className="cat-button">
                        <h5 className="dashboard-subtitle">Discounts</h5>
                        <div className="btn" onClick={handleShow} >
                            + Add New
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3 form-group">
                            <input
                                className="form-control"
                                type="date"
                                name="from_date"
                                value={date.from_date}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-3 form-group">
                            <input
                                className="form-control"
                                type="date"
                                name="to_date"
                                value={date.to_date}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-1">
                            <div>
                                <button className="btn btn-primary" style={{ minHeight: '37px' }} onClick={GetData}>Filter</button>
                            </div>
                        </div>
                        <div className="col-5">
                            <div className="input-cat-with-icon">
                                <span className="icon-product"><BiSearch /></span>
                                <input type="text" className="input-control" placeholder="Search" name="search" value={search.search} onChange={HandleSearch} />
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-4 input-cat-with-icon">
                        <span className="icon-product"><BiSearch /></span>
                        <input type="text" className="input-control" placeholder="Search" name="search" value={search.search} onChange={HandleSearch} />
                    </div> */}
                    <div className="table-responsive marginTop">
                        <DataTable columns={columns} data={Data} pagination paginationTotalRows={totalRows}
                            paginationPerPage={RowPerPage}
                            paginationDefaultPage={currentPage}
                            onChangePage={(page) => setCurrentPage(page)}
                            paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
                            paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
                            onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
                            onSort={handleSort}
                            paginationServer fixedHeader subHeader customStyles={tableCustomStyles} progressPending={loading}
                            progressComponent={<div className="loader-container">
                                <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                            </div>}
                        />
                    </div>
                    <div>
                        <Modal
                            size="xl"
                            show={show}
                            onHide={handleClose}
                            aria-labelledby="example-modal-sizes-title-lg"
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="example-modal-sizes-title-lg">
                                    {modalTitle}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {passwordError && (
                                    <div className="alert alert-danger" role="alert">
                                        {passwordError}
                                    </div>
                                )}

                               {
                                modalTitle==="Edit Discounts"?
                               <FormParser modelObject={MastersModalEdit} formData={formData} formSubmit={formSubmit} error={Error} buttonTitle='Save' />:
                               <FormParser modelObject={MastersModal}  formSubmit={formSubmit} error={Error} buttonTitle='Save' />
                               }
                            </Modal.Body>
                        </Modal>
                        {popupType !== "" &&
                            <PopupModal setpopupType={setpopupType} popupType={popupType} Navigation="" error={message} Message={message} setMessage={setMessage} />
                        }
                        {deletePopupType !== "" &&
                            <DeletePopupModal setDeletepopupType={setDeletepopupType} deletePopupType={deletePopupType} />
                        }
                    </div>

                </div>
            </div>
        </>
    )
}
export { Discounts }
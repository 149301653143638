import React, { useEffect, useState } from "react";
import { DashboardLayout, DeletePopupModal } from "../Components";
import axiosConfig from "../Service/axiosConfig"
import Select from 'react-select';
import DataTable from 'react-data-table-component';
import { ReportsNav } from "../Components/navbar";
const ConsolidatedReports = () => {
    const user_data = JSON.parse(localStorage.getItem('user_data'));
    const Store_id = JSON.parse(localStorage.getItem('Store'));
    console.log(user_data)
    let today_date = (new Date()).toISOString().split('T')[0];
    const [storeOptions, setStoreOptions] = useState([]);
    const [clientOptions, setClientOptions] = useState([]);
    const [filteredClients, setFilteredClients] = useState([]);
    const [formData, setFormData] = useState({
        fromDate: today_date,
        toDate: today_date,
        store: Store_id?.id,
    })
    console.log(formData, "formdata")
    const [dateType, setDateType] = useState([{ label: "Ordered Date", value: "ordered_date" }, { label: "Expected Date", value: "expected_date" }, { label: "Delivered Date", value: "delivered_time" }]);
    const [selectedDateType, setSelectedDateType] = useState(dateType[0].value);
    const multiSelectStyles = {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#c9dddc' : 'white', // Change background color for selected option
            color: state.isSelected ? '#1b7d78' : 'black', // Change text color for selected option
            ':hover': {
                backgroundColor: '#c9dddc', // Change background color on hover for options
            },
        }),
        multiValue: (provided) => ({
            ...provided,
            backgroundColor: '#c9dddc', // Change background color for the multi-value container
            color: '#1b7d78', // Change text color for the multi-value container
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            color: '#1b7d78', // Change text color for the label within the multi-value container
        }),
        multiValueRemove: (provided) => ({
            ...provided,
            color: '#1b7d78', // Change color for the "X" button within the multi-value container
            ':hover': {
                backgroundColor: '#1b7d78', // Change background color on hover for the "X" button
                color: 'white',
            },
        }),
    };
    const HandleTypeChange = (selectedOption) => {
        setSelectedDateType(selectedOption.value);
    };
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };
    const handleSubmit = async () => {
        ExcellReports();
    };
    const ExcellReports = async () => {
        console.log(formData, "formdata")
        try {
            const response = await axiosConfig.post(`/accounts/report_xlsx/`,
                {
                    date_type: selectedDateType,
                    from_date: formData.fromDate,
                    to_date: formData.toDate,
                    store: formData.store,
                    client: formData.client
                },
                { responseType: 'blob' }
            );
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Report.xlsx`);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.log(error);
        }
    };
    const HandleselectChange = (selectedOptions, fieldName) => {
        const selectedValues = selectedOptions.map((item) => item.value);
        if (fieldName === "store") {
            if (selectedOptions.length === 0) {
                setFilteredClients(clientOptions);
            } else {
                let filtered_clients = clientOptions.filter((client) => {
                    return client.store && selectedValues.includes(client.store.id);
                });
                console.log(filtered_clients, storeOptions, "filtered_clients")
                setFilteredClients(filtered_clients)
            }
        }

        setFormData(prevFormData => ({
            ...prevFormData,
            [fieldName]: selectedValues,
        }));
    };
    const fetchStore = async () => {
        const response = await axiosConfig.get(`/stores/stores/?page_size=1000`);
        console.log("All Stores:", response.data.results);
        setStoreOptions([{ id: '', name: '--Select Store--' }, ...response.data.results]);
    };

    const fetchClients = async () => {
        const response = await axiosConfig.get(`/accounts/users/?page_size=1000`);
        console.log("All Clients:", response.data.results);
        setClientOptions([{ id: '', client_name: '---Select Client---' }, ...response.data.results]);
        setFilteredClients([{ id: '', client_name: '---Select Client---' }, ...response.data.results]);
    };
    useEffect(() => {
        fetchStore();
        fetchClients();
    }, [])
    return (
        <>
            <div className="child-sidenav">
                <ReportsNav />
                <div className="flex-1">
                    <div className="report-filters">
                        <Select
                            placeholder="Store"
                            className="flex-1"
                            options={dateType}
                            value={dateType.find(option => option.value === selectedDateType)}
                            onChange={(selectedOption) => HandleTypeChange(selectedOption)}
                            styles={multiSelectStyles}
                        />
                        <div className="form-group flex-1">
                            <input
                                type="date"
                                className="form-control"
                                name="fromDate"
                                value={formData.fromDate}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group flex-1">
                            <input
                                type="date"
                                className="form-control"
                                name="toDate"
                                value={formData.toDate}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="report-filters">
                        {user_data.role === "Admin" &&
                            <Select
                                placeholder="Store"
                                className="flex-1"
                                options={storeOptions.map((res) => (
                                    { "value": res.id, "label": res.name }
                                ))}
                                isMulti
                                value={storeOptions.map(res => ({ "value": res.id, "label": res.name })).find(res => res.value === formData.store)}
                                onChange={(selectedOption) => HandleselectChange(selectedOption, "store")}
                                isDisabled={Store_id?.id ? true : false}
                                styles={multiSelectStyles}
                            />}
                        <Select
                            placeholder="Clients"
                            className="flex-1"
                            options={filteredClients.map((res) => (
                                { "value": res.id, "label": res.full_name }
                            ))}
                            isMulti
                            value={filteredClients.map(res => ({ "value": res.id, "label": res.client_name })).find(res => res.value === formData.client)}
                            onChange={(selectedOption) => HandleselectChange(selectedOption, "client")}
                            styles={multiSelectStyles}
                        // isDisabled={true}
                        />
                        <button className="btn btn-primary" style={{ height: '37px' }} onClick={handleSubmit}>Get Reports</button>
                    </div>
                </div>

            </div>
        </>
    )
}
export { ConsolidatedReports }
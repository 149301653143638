import React, { useEffect, useState } from "react";
import { AiFillSetting } from 'react-icons/ai';
import { HiUserGroup } from 'react-icons/hi';
import { RiBillFill } from 'react-icons/ri';
import { MdWork } from 'react-icons/md';
import {  MdOutlineDashboard,MdManageAccounts,MdCalendarMonth,MdList  } from "react-icons/md"
import { HiDocumentReport } from 'react-icons/hi';
import { MdCircleNotifications } from "react-icons/md"
import { RiLogoutCircleRLine } from "react-icons/ri"
import { GoBell } from 'react-icons/go';
import { FaCommentMedical } from "react-icons/fa"
import { CgProfile } from "react-icons/cg"
import "./DashboardLayout.css"
import {BiUserCircle} from "react-icons/bi"
import sidebar from "./sidebar";
import { ChildNav } from "./ChildNav";
import { useNavigate, useParams } from "react-router";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ChartTable from "./Chart"
import { NavLink } from "react-router-dom";
import {TbReport} from "react-icons/tb"
const Layout = ({ children }) => {
    let { url } = useParams()
    const Navigate = useNavigate();
    const [smShow, setSmShow] = useState(false);
    const [childData, setChildData] = useState([])
    const [Toggle, SeToggle] = useState(true)
    const [ParentNav, SetParentNav] = useState("")
    const [ChildNavs, SetChildNavs] = useState("")
    const handleNav = (event, url) => {
        let parentnav = event.currentTarget.dataset.parentnav
        localStorage.setItem('ParentNav', parentnav);
        SetParentNav(parentnav)
        setChildData(sidebar[parentnav])
    }
    const AlertLogout = () => {
        setSmShow(!smShow)
    }
    const handleLogout = (event) => {
        localStorage.removeItem('token');
        setSmShow(false)
        Navigate("/")
    }

    const getParentNav = async () => {
        let childnavpath = localStorage.getItem('ChildNavs')
        SetChildNavs(childnavpath)
        setChildData(sidebar[localStorage.getItem('ParentNav')])
    }
    const childNavClick = (childnavpath) => {
        localStorage.setItem('ChildNavs', childnavpath);
        SetChildNavs(childnavpath)
    }
    console.log(childData)
    useEffect(() => {
        getParentNav();
    }, [])

    return (
        <>
            <div className="dashboard-layout">
                <header>
                    <div className="Dashboard-brand">
                        <img className="DashBoard-toggle" src={require("./images/Toggle.png")} alt="toggle" onClick={() => SeToggle(!Toggle)} />
                        <img className="DashBoard-Logo" src={require("./images/Logo.png")} alt="logo" />
                    </div>
                    <div className="ms-auto menu-right">
                        <MdCircleNotifications className="DashBoard-Notification" />
                        <img className="DashBoard-Photo" src={require("./images/Photo.png")} alt="userPhoto" />
                        <RiLogoutCircleRLine className="DashBoard-Photo" onClick={AlertLogout} />
                    </div>
                </header>
                <aside>
                    <nav className="sidebar-main">
                        <NavLink to="/dashboard/worklist/" className={localStorage.getItem('ParentNav') == "WorkLists" ? "sidebar-icon acitve" : "sidebar-icon"} ><div onClick={(e) => handleNav(e, "WorkLists")} className={localStorage.getItem('ParentNav') == "WorkLists" ? "navitem active" : "navitem"} data-parentnav="WorkLists" >
                            <MdOutlineDashboard />
                            <p className="sidebar-name">WorkLists</p>
                        </div></NavLink>
                        <NavLink to="/dashboard" className={localStorage.getItem('ParentNav') == "dashboard" ? "sidebar-icon acitve" : "sidebar-icon"}  ><div onClick={(e) => handleNav(e, "dashboard")} className={localStorage.getItem('ParentNav') == "dashboard" ? "navitem active" : "navitem"} data-parentnav="dashboard" >
                            <MdList className="sidebar-icon" />
                            <p className="sidebar-name">Dashboard</p>
                        </div></NavLink>
                        <NavLink to="/dashboard/appointments/calander" className={localStorage.getItem('ParentNav') == "Appointments" ?"sidebar-icon acitve":"sidebar-icon"}><div onClick={(e)=>handleNav(e,"Appointments")} className={localStorage.getItem('ParentNav') == "Appointments" ? "navitem active" : "navitem"} data-parentnav="Appointments" >
                            <MdCalendarMonth className="sidebar-icon" />
                            <p className="sidebar-name">Appointments</p>
                        </div></NavLink>
                        <NavLink to="/dashboard/patients/registration" className={localStorage.getItem('ParentNav') == "Patients" ?"sidebar-icon acitve":"sidebar-icon"}><div onClick={(e)=>handleNav(e,"Patients")} className={localStorage.getItem('ParentNav') == "Patients" ? "navitem active" : "navitem"} data-parentnav="Patients" >
                            <MdManageAccounts className="sidebar-icon" />
                            <p className="sidebar-name">Patients</p>
                        </div></NavLink>
                        <NavLink to="/dashboard/billing/client-bills" className={localStorage.getItem('ParentNav') == "billing" ?"sidebar-icon acitve":"sidebar-icon"} ><div onClick={(e)=>handleNav(e,"billing")} className={localStorage.getItem('ParentNav') == "billing" ? "navitem active" : "navitem"} data-parentnav="billing" >
                            <RiBillFill className="sidebar-icon" />
                            <p className="sidebar-name">Billing</p>
                        </div></NavLink>
                        <NavLink to="/reports" className={localStorage.getItem('ParentNav') == "reports" ?"sidebar-icon acitve":"sidebar-icon"}><div onClick={(e)=>handleNav(e,"reports")} className={localStorage.getItem('ParentNav') == "reports" ? "navitem active" : "navitem"} data-parentnav="reports" >
                            <TbReport className="sidebar-icon" />
                            <p className="sidebar-name">Reports</p>
                        </div></NavLink>
                        <NavLink to={`/${"masters"}`} className={localStorage.getItem('ParentNav') == "accounts" ?"sidebar-icon acitve":"sidebar-icon"}><div onClick={(e)=>handleNav(e,"masters")} className={localStorage.getItem('ParentNav') == "masters" ? "navitem active" : "navitem"} data-parentnav="masters" >
                            <AiFillSetting className="sidebar-icon" />
                            <p className="sidebar-name">Settings</p>
                        </div></NavLink>
                        <NavLink to="/dashboard/clinic/" className={localStorage.getItem('ParentNav') == "Clinic" ?"sidebar-icon acitve":"sidebar-icon"} ><div onClick={(e)=>handleNav(e,"/dashboard/clinic/")} className={localStorage.getItem('ParentNav') == "Clinic" ? "navitem active" : "navitem"} data-parentnav="Clinic" >
                            <BiUserCircle className="sidebar-icon" />
                            <p className="sidebar-name">Clinic Profile</p>
                        </div></NavLink>
                        <NavLink to={`/dashboard/profile`} className={localStorage.getItem('ParentNav') == "Clinic" ?"sidebar-icon acitve":"sidebar-icon"} ><div onClick={(e)=>handleNav(e,"/dashboard/profile")} className={localStorage.getItem('ParentNav') == "Doctor" ? "navitem active" : "navitem"} data-parentnav="Doctor" >
                            <BiUserCircle className="sidebar-icon" />
                            <p className="sidebar-name">Doctor Profile</p>
                        </div></NavLink>
                    </nav>
                    {Toggle && <nav className="sidebar-child">
                        <div className="sidebar-child-container">
                            <ChildNav childData={childData} childNavClick={childNavClick} ChildNavs={ChildNavs} />
                        </div>
                    </nav>}
                </aside>
                <div className="dashboard-content">
                    {children}
                </div>
            </div>
            <Modal
                show={smShow}
                onHide={() => setSmShow(false)}
                aria-labelledby="example-modal-sizes-title-sm">
                <Modal.Body className="success-icon">
                    <h5 className="successmsg">Are you sure you want to logout ?</h5>
                </Modal.Body>
                <Modal.Footer>
                    <button variant="primary" className="btn btn-primary" onClick={() => setSmShow(false)}>
                        Cancel
                    </button>
                    <button variant="primary" className="btn btn-primary" onClick={handleLogout}>
                        Ok
                    </button>
                </Modal.Footer>
            </Modal>

        </>
    )
}
export { Layout }

import React, { useEffect, useState } from "react";
import { DashboardLayout, PopupModal } from "../Components";
import { NavLink } from "react-router-dom";
import axiosConfig from "../Service/axiosConfig";
import DataTable from 'react-data-table-component';
import { AiOutlineSync } from 'react-icons/ai'
import '../../src/Pages/Pages.css';
import tableCustomStyles from "../Components/PopupModal/tableCustomStyles";
import Select from 'react-select';
import { NavbarComponent } from "../Components/navbar/NavComponent";
import useDebouncedValue from "../Utils/Debounce"

const ManageProducts = () => {
    const user = JSON.parse(localStorage.getItem("user_data"))
    const [store, setStore] = useState(JSON.parse(localStorage.getItem('Store'))?.id)
    const [products, setProducts] = useState([])
    const [stores, setStores] = useState()
    const [selectedStore, setSelectedStore] = useState(null);
    const [Loading, setLoading] = useState(false)
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(20);
    const [LoadingButton, setLoadingButton] = useState(false);
    const [search, SetSearch] = useState({
        search: ""
    })
    const debouncedSearchTerm = useDebouncedValue(search.search, 500);
    const [popupType, setpopupType] = useState('')
    const [message, setMessage] = useState('')
    const [error, setError] = useState('')
    const handleSort = (column, direction) => {
        console.log(column.selector, direction)
        axiosConfig.get(`/catlog/inventry/?store=${store ? store : ""}&ordering=${direction === "asc" ? column.selector : -column.selector}`).then(res => {
            setProducts(res.data.results)
        }).catch(error => {
            console.log(error)
        })
        setCurrentPage(1);
    };
    const HandleSearch = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        console.log(name, value)
        SetSearch({ ...search, [name]: value })
    }
    const product_columns = [
        {
            name: 'S No.',
            sortable: false,
            cell: (row, index) => index + 1
        },
        {
            name: 'Product',
            selector: row => <div><img className="mng-prod-img" src={row.productvariant.product.image && row.productvariant.product.image.image} alt={row.productvariant.product.name} /></div>,
            sortable: false,
        },
        {
            name: 'Name',
            selector: 'productvariant.product.name',
            sortable: true,
        },
        {
            name: 'Price',
            selector: 'productvariant.product.price',
            sortable: true,
        },
        {
            name: 'Available',
            selector: 'avaliable',
            sortable: true,
            cell: row => <div className="form-check form-switch">
                <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    defaultChecked={row.avaliable}
                    id={`flexSwitchCheckDefault-${row.id}`}
                    onChange={(e) => handleChange(row, e)}
                />
            </div>
        }
    ];

    const handleChange = async (row, e) => {
        try {
            const update = await axiosConfig.patch(`/catlog/inventry/${row.id}/`, { avaliable: e.target.checked })
            console.log(update)
        }
        catch (error) {
            console.log(error)
        }
    }
    const GetData = async () => {
        try {
            const responce = await axiosConfig.get(`/catlog/inventry/?store=${store ? store : ""}&page=${currentPage}&page_size=${RowPerPage}`)
            setTotalRows(responce.data.count)
            setProducts(responce.data.results)
        }
        catch (error) {
            console.log(error)
        }

    }
    const GetStores = async () => {
        try {
            const responce = await axiosConfig.get(`/stores/stores/?page_size=1000`)
            console.log(responce)
            setStores(responce.data.results)
        }
        catch (error) {
            console.log(error)
        }

    }
    const handleStoreChange = (selectedOption) => {
        setSelectedStore(selectedOption); // Update selected store
        setStore(selectedOption.value);
    };
    const handleSync = async () => {
        setLoadingButton(true)
        try {
            const responce = await axiosConfig.get(`/stores/stores/${store ? store : null}/sync_products/`)
            console.log(responce)
            setLoadingButton(false)
            setpopupType("success")
            setMessage(responce.data.message)
            // window.location.reload(true)
            GetData();
        }
        catch (error) {
            console.log(error)
            setLoadingButton(false)
            setpopupType("error")
            console.log(error.response.data.error)
            error.response.data.error && setError(error.response.data.error)
        }
    }

    useEffect(() => {
        const getSearchResults = async () => {
            try {
                axiosConfig.get(`/catlog/inventry/?store=${store ? store : ""}&search=${debouncedSearchTerm}`).then(res => {
                    setProducts(res.data.results)
                    console.log(res.data.results)
                }).catch(error => {
                    console.log(error)
                })
            } catch (error) {
                console.log(error);
            }
        };

        getSearchResults();
    }, [debouncedSearchTerm]);

    useEffect(() => {
        GetData();
        if (!store) {
            GetStores();
        }
    }, [currentPage, RowPerPage, store])

    return (
        <>
            <div className="child-sidenav">
                {user.role === "Admin" &&
                    <NavbarComponent />}
                <div className="childnav-content">
                    {stores && user.role === "Admin" &&
                        <div className="store-select">
                            <Select
                                options={stores.map(store => ({ value: store.id, label: store.name }))}
                                placeholder="Select Store"
                                value={selectedStore}
                                onChange={handleStoreChange}
                            />
                        </div>
                    }
                    <div>
                        <div className="prod-search">
                            <input type="text" className="form-control prod-search-input" placeholder="Enter product name" name="search" value={search.search} onChange={HandleSearch} />
                            <div className="sync" onClick={handleSync}>
                                {LoadingButton ? <div>
                                    <img src={require('../Components/FormParser/images/loader.gif')} alt="Loading..." className="loader-image" />
                                </div> : <AiOutlineSync />}

                            </div>
                        </div>
                        {store ?
                            <DataTable
                                title="All Items"
                                columns={product_columns}
                                data={products}
                                pagination paginationTotalRows={totalRows}
                                paginationPerPage={RowPerPage}
                                paginationDefaultPage={currentPage}
                                onChangePage={(page) => setCurrentPage(page)}
                                paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
                                paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
                                onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
                                onSort={handleSort}
                                paginationServer fixedHeader customStyles={tableCustomStyles}
                                progressPending={Loading}
                                progressComponent={<div className="loader-container">
                                    <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                                </div>}
                            />
                            :
                            <div className="text-center mt-5 f_14_500">Select Store to view products</div>
                        }
                    </div>
                </div>
            </div>
            {popupType != "" &&
                <PopupModal popupType={popupType} setpopupType={setpopupType} Message={message} error={error} />
            }
        </>
    )
}

export { ManageProducts }
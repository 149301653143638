import React, { useState } from "react";
import { DashboardLayout, FormParser, PopupModal } from "../Components";
import { NavLink } from "react-router-dom";
import Masters from "../Components/masters.json"
import "./Pages.css"
import axiosConfig from "../Service/axiosConfig"
import {BsArrowLeftShort} from "react-icons/bs"

const DoctorRegestrationAdd = ({children}) => {
    const [popupType, setpopupType] = useState("");
    const[formerrorData,SetformErrorData]=useState({})
    const MastersModal = Masters.DoctorRegestration
    const AdminModal=Masters.adminDoctorRegestration
    const [Error, SetError] = useState("")
    let user_data=JSON.parse(localStorage.getItem("user_data"))
   
    const formSubmit = (Data) => {
        axiosConfig.defaults.headers['Content-Type'] = 'multipart/form-data';
        let data=[]
        const formData=new FormData()
        formData.append("title",Data.title)
        formData.append("name",Data.name)
        formData.append("image",Data.image)
        Data.qualification.forEach(element => {
            formData.append("qualification",element)
        });
        formData.append("registration_number",Data.registration_number)
        formData.append("about",Data.about)
        formData.append("email",Data.email)
        formData.append("mobileno",Data.mobileno)
        formData.append("first_name",Data.first_name)
        formData.append("last_name",Data.last_name)
        formData.append("password",Data.password)
        formData.append("address",Data.address)
        formData.append("user_type",user_data.user_type)
        formData.append("clinic",user_data.clinic_id?user_data.clinic_id:Data.clinic)
            axiosConfig.post("/clinics/doctors/", formData).then(res => {
                console.log(res)
                setpopupType("success")
            }).catch(error => {
                console.log(error.response.data.detail)
                let ErrorData=[]
                ErrorData.push({
                    code:error.response.data.code||["null"],
                    name:error.response.data.name||["null"],
                    })
                    console.log(ErrorData[0])
                    if(error.response.data.detail==="User with this email already exists."){
                        SetError("Email Already Exists")
                    }
                    if(error.response.data.detail==="User with this  mobile number already exists."){
                        SetError("Mobile Number Already Exists")
                    }
                setpopupType("error")
            })
    }
    return (
        <>
            <DashboardLayout>
            <div className="mainbar">
                    <div className="sidebar-filters px-2">
                    <NavLink to="/dashboard/doctors/list" ><button type="submit" className="btn btn-primary"><BsArrowLeftShort/>back to doctors</button></NavLink>
                        </div>
                    <div className="main-code">
                    <div className="dashboard-header">
                <h5 className="dashboard-subtitle">Add Doctor</h5>
                </div>
                {user_data&&user_data.user_type==="staff"&&<FormParser modelObject={MastersModal} formSubmit={formSubmit} error={Error}/>}
                {user_data&&user_data.user_type==="admin"&&<FormParser modelObject={AdminModal} formSubmit={formSubmit} error={Error}/>}
                        </div>
                        </div>
                {popupType != "" &&
                    <PopupModal popupType={popupType} Navigation="/dashboard/doctors/list"/>
                }
            </DashboardLayout>
        </>
    )

}
export { DoctorRegestrationAdd }
import React, { useEffect, useState } from "react";
import axiosConfig from "../Service/axiosConfig"
import Select from 'react-select';
import { PopupModal } from "../Components/PopupModal/PopupModal"
import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import CalanderTime from "./CalanderTime";
import { CalanderSlot } from "./CalenderSlot";
import { FaRegUserCircle } from "react-icons/fa"
const SlotRegetration = ({ slotTime, doctor, handleSuccessfalse ,setIsRefreshed}) => {
    const [PatientData, setPatientData] = useState([])
    const [Patient, setPatient] = useState()
    const [serviceData, setservicesData] = useState([])
    const [ClinicData, setClinicsData] = useState([])
    const [ClinicValue, setClinicValue] = useState([])
    const [DoctoValue, setDocotrValue] = useState([])
    const [specialityData, setSpecialityData] = useState([])
    const [TarifData, setTarifData] = useState({})
    const [Doctor, setDoctorData] = useState([])
    const [search, setSearch] = useState([])
    const [Message, setMessage] = useState("")
    const [popupType, setpopupType] = useState("")
    const [patientId, setPatientId] = useState({})
    const [count, setCount] = useState(0)
    const today = new Date();
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const dayIndex = today.getDay();
    const date = today.getDate();
    const [days, setDays] = useState(daysOfWeek[dayIndex])
    const [Doctors, setDoctors] = useState([])
    let data = localStorage.getItem("user_data")
    const NewDate = (date) => {
        const currentDate = date ? date : new Date();

        const getDay = currentDate.getDate().toString().padStart(2, '0');
        const getMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const getYear = currentDate.getFullYear();

        const formattedDate = `${getYear}-${getMonth}-${getDay}`;
        return formattedDate;

    };
    const [filterValue, setFilterValues] = useState({
        FormData: NewDate(new Date()),
        ToDate: NewDate(new Date()),
        clinics: JSON.parse(data) ? JSON.parse(data).clinic_id : "",
        Doctor: JSON.parse(data) ? JSON.parse(data).doctor_id : "",
        services: "",
        speciality: ""
    });


    const [FullDate, setFullDate] = useState(NewDate());
    const [availabilityTime, setavailabilityTime] = useState([])
    const [doctoravailability, setDoctorAvalibilty] = useState([])
    const [doctorSlots, setdoctorSlots] = useState([])
    const [SlotBooking, setSlotBooking] = useState({
        patient: doctor.patient ? doctor.patient : "",
        patient_name: "",
        age: "",
        mobile_no: "",
        visit_type: "",
        speciality: "",
        doctor: doctor ? doctor.doctor :null,
        clinic: doctor ? doctor.clinic :null,
        service: "",
        service_amount: TarifData ? TarifData.amount :null,
        appointment_start_datetime: slotTime ? slotTime :null,
        appointment_end_datetime: "",
        main_complaints: "",
        appointment_status: "Scheduled",
        gender: ""
    })
    let Navigate = useNavigate()
    function addMinutesToTime(slotTime, Minutes) {
        setSlotBooking(prevState => {
            if(slotTime && Minutes){
                const dateTime = new Date(slotTime);
                dateTime.setMinutes(dateTime.getMinutes() + Minutes);
                const updatedYear = dateTime.getFullYear();
                const updatedMonth = String(dateTime.getMonth() + 1).padStart(2, "0");
                const updatedDay = String(dateTime.getDate()).padStart(2, "0");
                const updatedHours = String(dateTime.getHours()).padStart(2, "0");
                const updatedMinutes = String(dateTime.getMinutes()).padStart(2, "0");
                const updatedTime = `${updatedYear}-${updatedMonth}-${updatedDay}T${updatedHours}:${updatedMinutes}:00`;
                console.log(updatedTime);
                return { ...prevState, "appointment_end_datetime": updatedTime };
            }
            else{
                return { ...prevState, "appointment_end_datetime": null };
            }
            
        });
    }
    async function GetAppoinment(id) {
        const responce = await axiosConfig.get(`/clinics/appointment/${id}/`)
        setSlotBooking({
            patient:responce.data.patient,
            patient_name:responce.data.patient_name,
            age:responce.data.age,
            mobile_no:responce.data.mobile_no,
            visit_type:responce.data.visit_type,
            speciality:responce.data.speciality,
            doctor:responce.data.doctor,
            clinic:responce.data.clinic,
            service:responce.data.service,
            service_amount:responce.data.service_amount?responce.data.service_amount:0,
            appointment_start_datetime:responce.data.appointment_start_datetime,
            appointment_end_datetime:responce.data.appointment_end_datetime,
            main_complaints:responce.data.main_complaints,
            appointment_status: "Scheduled",
        })
        setPatient(`${responce.data.title} ${responce.data.name},${responce.data.age} yrs,${responce.data.gender_name},${responce.data.mobile_no}`)
        if (responce) {
            const patient = await axiosConfig.get(`/clinics/patient/${responce.data.patient}/`)
            setPatientId(patient.data)
            setSlotBooking({
                patient:responce.data.patient,
                patient_name:responce.data.patient_name,
                age:responce.data.age,
                mobile_no:responce.data.mobile_no,
                visit_type:responce.data.visit_type,
                speciality:responce.data.speciality,
                doctor:responce.data.doctor,
                clinic:responce.data.clinic,
                service:responce.data.service,
                service_amount:responce.data.service_amount?responce.data.service_amount:0,
                appointment_start_datetime:responce.data.appointment_start_datetime,
                appointment_end_datetime:responce.data.appointment_end_datetime,
                main_complaints:responce.data.main_complaints,
                appointment_status: "Scheduled",
                gender:patient.data.gender_name})
        }
    }
    const getPatient = async (id) => {
        const patient = await axiosConfig.get(`/clinics/patient/${id}/`)
            setSlotBooking({
                ...SlotBooking,
                patient: patient.data.id,
                patient_name: patient.data.name,
                age: patient.data.age,
                mobile_no: patient.data.mobile_no,
                gender: patient.data.gender_name
            })
        setPatient(`${patient.data.title} ${patient.data.name},${patient.data.age} yrs,${patient.data.gender_name},${patient.data.mobile_no}`)
        setPatientId(patient.data)
    }
    const GetDoctorsData = async (e) => {
        const { name, value } = e.target
        const responce = await axiosConfig.get(`/clinics/patient/?search=${value}`)
        if (value == "") {
            setPatientData([])
            setSlotBooking({ ...SlotBooking, [name]: "" })
        }
        else {
            setPatientData(responce.data.results)
            setSlotBooking({ ...SlotBooking, [name]: value })
        }
    }
    const ServicesData = async () => {
        try {
            const responce = await axiosConfig.get(`/masters/services/`)
            setservicesData(responce.data.results)
        }
        catch (error) {
            console.log(error)
        }
    }
    const ClinicsData = async () => {
        try {
            let data = []
            const responce = await axiosConfig.get(`/clinics/clinics/`)
            setClinicsData(responce.data.results)
            data.push(...responce.data.results)
            let count = Math.ceil(responce.data.count / 20)
            let number = []
            for (let i = 1; i < count + 1; i++) {
                if (i != 1) {
                    number.push(i)
                }
            }
            number.forEach(async (pages) => {
                const responce = await axiosConfig.get(`/clinics/clinics/?page=${pages}&page_size=20`)
                setClinicsData(prev => [...prev, ...responce.data.results])
                data.push(...responce.data.results)
                const find = data.find(find => find.id === doctor.clinic)
                setClinicValue({ value: find.id, label: find.clinic_name })
            })

        }
        catch (error) {
            console.log(error)
        }
    }
    const SpecialityData = async () => {
        try {
            const responce = await axiosConfig.get(`/masters/specialities/`)
            setSpecialityData(responce.data.results)
        }
        catch (error) {
            console.log(error)
        }
    }
    const TariffData = async (value) => {
        try {
            const responce = await axiosConfig.get(`/clinics/tariffs/?clinic=${SlotBooking.clinic}&doctor=${SlotBooking.doctor}&service=${SlotBooking.service}`)
            setTarifData(...responce.data.results)
            console.log(responce)
            responce.data.results.forEach(element => {
                setSlotBooking({ ...SlotBooking, "service": value ? value : "", "service_amount": element.amount })
            });
            if (responce.data.count === 0) {
                setSlotBooking({ ...SlotBooking, "service": value ? value : "", "service_amount": 0 })
            }
        }
        catch (error) {
            console.log(error)
        }
    }
    const formSubmit = (e) => {
        e.preventDefault();
        axiosConfig.post(`/clinics/appointment/`, SlotBooking).then(res => {
            setpopupType("success")

        }).catch(error => {
            setpopupType("error")
        })
    }
    const formCreateConsultation = (e,id) => {
        e.preventDefault();
        axiosConfig.get(`/clinics/appointment/${id}/create_consultation/`).then(res => {
            setpopupType("success")

        }).catch(error => {
            setpopupType("error")
        })
    }
    const formEdit = (e, id) => {
        e.preventDefault();
        axiosConfig.patch(`/clinics/appointment/${id}/`, SlotBooking).then(res => {
            setpopupType("success")
            setMessage("Record Updated SuccessFully")
        }).catch(error => {
            setpopupType("error")
        })
    }
    const formCancel = (e, id) => {
        e.preventDefault();
        axiosConfig.patch(`/clinics/appointment/${id}/`, { appointment_status: "Cancelled" }).then(res => {
            setpopupType("success")
            setMessage("Record Cancelled SuccessFully")
        }).catch(error => {
            setpopupType("error")
        })
    }
    const DoctorData = async () => {
        try {
            const responce = await axiosConfig.get(`/clinics/doctors/`)
            setDoctorData(responce.data.results)
            const find = responce.data.results.find(res => res.id === doctor.doctor)
            setDocotrValue({ value: find.id, label: find.name })

        }
        catch (error) {
            console.log(error)
        }
    }
    const HandleSelct = (title, id, name, age, mobile_no, uhid, gender) => {
        setPatient(`${title} ${name},${age} yrs,${gender},${mobile_no}`)
        setSlotBooking({
            ...SlotBooking,
            patient: id,
            patient_name: name,
            age: age,
            mobile_no: mobile_no,
            gender: gender
        })
        if (uhid) {
            setPatientData([])
        }
    }
    const HandleRadio = (e) => {
        const { value } = e.target
        setSlotBooking({ ...SlotBooking, ["visit_type"]: value })
    }
    const handleSelectChange = (selectedOption, name) => {
        setSlotBooking({ ...SlotBooking, [name]: selectedOption.value })
        if (name === "doctor") {
            setDocotrValue(selectedOption)
            GetDoctorsFilters(selectedOption.value)
            if(SlotBooking.appointment_end_datetime !==""){
                setSlotBooking({...SlotBooking,
                    appointment_end_datetime: "",
                    appointment_start_datetime: ""
                })
            }
        }
        if (name === "clinic") {
            setClinicValue(selectedOption)
        }
        if (name === "service") {
            TariffData(selectedOption.value);
        }
    }
    const HandleChange = (e) => {
        const { name, value } = e.target
        setSlotBooking({ ...SlotBooking, [name]: value })
    }
    const Button = () => {
        if (doctor.service_type === "appointment") {
            if (doctor.availabilityId) {
                return (
                    <>
                        <div className="d-flex justify-content-end">
                            <button className="btn btn-primary " onClick={(e) => formEdit(e, doctor.availabilityId)}>Update appointment</button>
                            <button className="btn btn-danger mx-4" onClick={(e) => formCancel(e, doctor.availabilityId)}>Cancel appointment</button>
                        </div>
                        {patientId.is_registred ? (
                            <button className="btn btn-success" onClick={(e)=>formCreateConsultation(e,doctor.availabilityId)}>Create Consultation</button>
                        ) : (
                            <NavLink to={`/dashboard/patients/${patientId.uhid}/info`}>
                                Register patient to create consultation
                            </NavLink>
                        )}
                    </>
                );
            } else {
                return (
                    <button className="btn btn-primary" onClick={formSubmit}>Create appointment</button>
                );
            }
        }
        else{
            return(
                <button  className="btn btn-primary ">Create Service Type</button>
            )
            }
    };
    const GetDoctorsFilters = async (id) => {
        try {
            const responce = await axiosConfig.get(`/clinics/doctors/?clinic=&id=${id}`)
            setDoctors(responce.data.results)
        }
        catch (error) {
            console.log(error)
        }

    }
    const GetDoctorsFiltersChange= async (id) => {
        GetDoctorsFilters(id)
        setSlotBooking({...SlotBooking,appointment_start_datetime:"",appointment_end_datetime:""})

    }
    const GetDoctorsAvalibilty = async (daystring) => {
        try {
            const responce = await axiosConfig.get(`/clinics/doctoravailability/?doctor=${filterValue.Doctor ? filterValue.Doctor : ""}&days=${daystring ? daystring : days}`)
            console.log(responce)
            setDoctorAvalibilty(responce.data.results)
            FormatTime(responce.data.results)
            let Id_doctor = []
            responce.data.results.forEach(async (doc) => {
                Id_doctor.push(doc.doctor)
            })
        }
        catch (error) {
            console.log(error)
        }
    }
    const appointmentData = async () => {
        try {
            const respo = await axiosConfig.get(`/clinics/appointment/?clinic=${filterValue.clinics ? filterValue.clinics : ""}&speciality=&doctor=${filterValue.Doctor ? filterValue.Doctor : ""}&service=&appointment_start_datetime_after=${FullDate}&appointment_start_datetime_before=${FullDate}`)
            console.log(respo)
            let data = []
            let appId = []
            respo.data.results.forEach(res => {
                appId.push(res.doctor)
                const datetime = new Date(res.appointment_start_datetime);

                let hours = datetime.getHours();
                let minutes = datetime.getMinutes();

                const period = hours >= 12 ? 'PM' : 'AM';
                hours = hours % 12 || 12;
                const formattedTime = `${hours}:${minutes.toString().padStart(2, '0')} ${period}`;
                const enddatetime = new Date(res.appointment_end_datetime);

                let endhours = enddatetime.getHours();
                let endminutes = enddatetime.getMinutes();

                const endperiod = endhours >= 12 ? 'PM' : 'AM';
                endhours = endhours % 12 || 12;
                const EndformattedTime = `${endhours}:${endminutes.toString().padStart(2, '0')} ${endperiod}`;
                console.log({ doctor: res.doctor, time: formattedTime + " to " + EndformattedTime })
                data.push({ id: res.id, doctor: res.doctor, time: formattedTime + " to " + EndformattedTime })
            })
            setavailabilityTime(data)
        }
        catch (error) {

        }
    }
    const FormatTime = (res) => {
        let data = []
        res.forEach(time => {
            const timeString = time.from_time;
            let [hours, minutes] = timeString.split(":");
            let period = "AM";
            if (parseFloat(hours) > 12) {
                hours = parseFloat(hours) - 12
                period = "PM";
                if (parseFloat(hours) < 10) {
                    hours = '0' + hours
                }
            }
            else if (parseFloat(hours) < 12) {
                hours = parseFloat(hours)
                if (parseFloat(hours) < 10) {
                    hours = '0' + hours
                }
            }
            else if (parseInt(hours) === 0) {
                hours = "12";
            }
            const formattedTime = `${hours}:${minutes} ${period}`;
            const endtime = time.to_time;
            let [endhours, endminutes] = endtime.split(":");
            let endperiod = "AM";
            if (parseFloat(endhours) > 12) {
                endhours = parseFloat(endhours) - 12
                endperiod = "PM";
                if (parseFloat(endhours) < 10) {
                    endhours = '0' + endhours
                }
            }
            else if (parseFloat(endhours) < 12) {
                endhours = parseFloat(endhours)
                if (parseFloat(endhours) < 10) {
                    endhours = '0' + endhours
                }
            }
            else if (parseInt(endhours) === 0) {
                endhours = "12";
            }
            const endformattedTime = `${endhours}:${endminutes} ${endperiod}`;
            data.push({ slot_time: time.slot_time, doctor: time.doctor, time: `${formattedTime} to ${endformattedTime}` })

        })
        console.log(data)
        setdoctorSlots(data)
    }
    const timeBlocks = [
        "00:00 AM",
        "01:00 AM",
        "02:00 AM",
        "03:00 AM",
        "04:00 AM",
        "05:00 AM",
        "06:00 AM",
        "07:00 AM",
        "08:00 AM",
        "09:00 AM",
        "10:00 AM",
        "11:00 AM",
        "12:00 PM",
        "01:00 PM",
        "02:00 PM",
        "03:00 PM",
        "04:00 PM",
        "05:00 PM",
        "06:00 PM",
        "07:00 PM",
        "08:00 PM",
        "09:00 PM",
        "10:00 PM",
        "11:00 PM",
        "12:00 AM"
    ];
    console.log(SlotBooking)
    useEffect(() => {
        GetDoctorsData();
        ServicesData();
        SpecialityData();
        DoctorData();
        addMinutesToTime(slotTime, doctor.minutes);
        ClinicsData();
        NewDate();
        GetDoctorsAvalibilty();
        appointmentData();
        if (doctor.availabilityId) {
            GetAppoinment(doctor.availabilityId)
        }
        if (doctor.patient) {
            getPatient(doctor.patient);
        }
    }, [])
    return (
        <div>
            {Patient ? <div className="clientbill-info">
                <div className="patient-info">
                    <div className="lf">
                        <FaRegUserCircle className="user-icon" />
                    </div>
                    <div className="rs">
                        <p>{SlotBooking.title} {SlotBooking.patient_name}</p>
                        <p>{SlotBooking.age} yrs, {SlotBooking.gender},  {SlotBooking.mobile_no} </p>
                    </div>
                </div>
            </div> : ""}
            {Patient ? "" : <div className="search-form">
                <div className="row">
                    <div className="col-2">
                        <label>Pataient:</label>
                    </div>
                    <div className="col-8">
                        <div className="searchinput-holder">
                            <input type="text" className="form-control position-relative" placeholder="Patient Name" name="patient_name" value={Patient} onChange={GetDoctorsData} required />
                            {PatientData.length > 0 && <div className="search-dropdowns position-absolute bg-body-secondary search-drop">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>uhid</th>
                                            <th>title</th>
                                            <th>name</th>
                                            <th>age</th>
                                            <th>dob</th>
                                            <th>gender</th>
                                            <th>blood group</th>
                                            <th>Is Registred</th>
                                            <th>Select</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {PatientData.map((Pataient, idx) => {
                                            console.log(Pataient)
                                            return (
                                                <tr key={Pataient.id}>
                                                    <td>{Pataient.uhid}</td>
                                                    <td>{Pataient.title}</td>
                                                    <td>{Pataient.name}</td>
                                                    <td>{Pataient.age}</td>
                                                    <td>{Pataient.dob}</td>
                                                    <td>{Pataient.gender}</td>
                                                    <td>{Pataient.blood_group}</td>
                                                    <td>{Pataient.is_registred ? "true" : "false"}</td>
                                                    <td>
                                                        <button type="submit" className="btn btn-primary" onClick={() => HandleSelct(Pataient.title_name, Pataient.id, Pataient.name, Pataient.age, Pataient.mobile_no, Pataient.uhid, Pataient.gender_name)}>Select</button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>

                            </div>}
                        </div>
                        <div className="row">
                            <div className="form-group col-4">
                                <label>Name</label>
                                <input type="text" className="form-control" placeholder="Patient Name" name="patient_name" value={SlotBooking.patient_name} onChange={GetDoctorsData} required />
                            </div>
                            <div className="form-group col-4">
                                <label>Mobile</label>
                                <input type="text" className="form-control" placeholder="Patient Name" name="mobile_no" value={SlotBooking.mobile_no} onChange={GetDoctorsData} required />
                            </div>
                            <div className="form-group col-4">
                                <label>Age</label>
                                <input type="text" className="form-control" placeholder="Patient Name" name="age" value={SlotBooking.age} onChange={GetDoctorsData} required />
                            </div>
                        </div>
                    </div>
                    <div className="col-2">
                        <button className="btn btn-primary" onClick={() => Navigate(`/dashboard/patients/add`)}>Register</button>
                    </div>
                </div>
            </div>}
            <h5 className="dashboard-subtitle">Visit Type</h5>
            <div className="row">
                <div className="col-2">
                </div>
                <div className="col-8">
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="First" checked={SlotBooking.visit_type === "First"} onChange={HandleRadio} />
                        <label className="form-check-label" for="inlineRadio1">First Consultation</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="Followup" checked={SlotBooking.visit_type === "Followup"} onChange={HandleRadio} />
                        <label className="form-check-label" for="inlineRadio2">followup</label>
                    </div>
                </div>
                <hr />
            </div>
            <div className="row">
                <div className="col-3">
                    <div className="form-group">
                        <label className="form-label">Speciality</label>
                        <Select options={specialityData.map((res) => (
                            { "value": res.id, "label": res.name }
                        ))} value={specialityData.map((res) => (
                            { "value": res.id, "label": res.name }
                        )).find(find => find.value === SlotBooking.speciality)} placeholder="Select Spaciality" onChange={(selectedOption) => handleSelectChange(selectedOption, "speciality")} required />
                    </div>
                </div>
                <div className="col-3">
                    <div className="form-group">
                        <label className="form-label">Doctor</label>
                        <Select options={Doctor.map((res) => (
                            { "value": res.id, "label": res.name }
                        ))} value={DoctoValue} placeholder="Select Doctor" onChange={(selectedOption) => handleSelectChange(selectedOption, "doctor")} />
                    </div>
                </div>
                <div className="col-3">
                    <div className="form-group">
                        <label className="form-label">Clinics</label>
                        <Select options={ClinicData.map((res) => (
                            { "value": res.id, "label": res.clinic_name }
                        ))} value={ClinicValue} placeholder="Select Doctor" onChange={(selectedOption) => handleSelectChange(selectedOption, "clinic")} />
                    </div>
                </div>
                <div className="col-3">
                    <div className="form-group">
                        <label className="form-label">Services</label>
                        <Select options={serviceData.map((res) => (
                            { "value": res.id, "label": res.name }
                        ))} value={serviceData.map((res) => (
                            { "value": res.id, "label": res.name }
                        )).find(find => find.value === SlotBooking.service)} placeholder="Select Services" onChange={(selectedOption) => handleSelectChange(selectedOption, "service")} required />
                    </div>
                </div>
                <hr />
            </div>
            <h5 className="dashboard-subtitle">Time</h5>
            {SlotBooking.appointment_start_datetime ? <div className="row">
                <div className="col-4">
                    <div className="form-group">
                        <label className="form-label">Date:</label>
                    </div>
                </div>

                <>
                    <div className="col-4">
                        <p>{SlotBooking.appointment_start_datetime}</p>
                    </div>
                    <div className="col-4">
                        <p className="text-time text-primary" onClick={() => GetDoctorsFiltersChange(SlotBooking.doctor)}>Change Options</p>
                    </div>
                </>
                <hr />
            </div> :
                <div className="calender-component">
                    <div className="cal-header">
                        <div className="selected-date">{FullDate}</div>
                    </div>
                    <div className="calander-time">
                        {
                            Doctors.map((doctors, i) => {
                                return (
                                    <div>
                                        <div className="time-block">
                                            <div>
                                                <p className="doctor-title"><img src={doctors.image} width="100" className="doctor-dp" />{doctors.name}</p>
                                                <p>Spaciality</p>
                                            </div>
                                            <div className="time-slot-main">
                                                {
                                                    timeBlocks.map(timeBlock => {
                                                        return (
                                                            <div className="time-slot-butn">
                                                                {i === 0 && <p>{timeBlock}</p>}
                                                                <CalanderSlot slot_time={10} timeblock={timeBlock} availability_list={availabilityTime} DoctorsData={doctors} doctorSlots={doctorSlots} is_popup={false} SlotBooking={SlotBooking} setSlotBooking={setSlotBooking} addMinutesToTime={addMinutesToTime} />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )

                            })

                        }

                    </div>
                </div>
            }
            <div className="row">
                <div className="col-2">
                    <div className="form-group">
                        <label className="form-label">Main Complaint:</label>
                    </div>
                </div>
                <div className="col-10">
                    <div className="form-group">
                        <textarea className="form-control" rows="2" name="main_complaints" value={SlotBooking.main_complaints} onChange={HandleChange}></textarea>
                    </div>
                </div>
            </div>
            <div className="d-flex align-items-center">
                <h5>Amount:</h5>
                <div>
                    <div className="form-group">
                        <input type="number" className="form-control" name="service_amount" value={SlotBooking.service_amount} onChange={HandleChange} />
                    </div>
                </div>
            </div>
            {Button()}
            {popupType != "" &&
                <PopupModal popupType={popupType} Navigation="" Message={Message} handleSuccessfalse={handleSuccessfalse} setIsRefreshed={setIsRefreshed}/>
            }
        </div>
    )
}
export default SlotRegetration
import React, { useState, useEffect } from "react";
import axiosConfig from "../Service/axiosConfig";
import { MdDeleteOutline, MdOutlineEdit } from "react-icons/md";
import DataTable from "react-data-table-component";
import Masters from "../Components/masters.json";
import { DeletePopupModal, PopupModal, FormParser } from "../Components";
import { BiSearch } from "react-icons/bi";
import Modal from "react-bootstrap/Modal";
import { FaHandHoldingHeart } from "react-icons/fa";
import useDebouncedValue from "../Utils/Debounce";
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import { CMSNavbarComponent } from "../Components/navbar";
import { VendorsNav } from "../Components/navbar/VendorsNav";
const VendorsPayments = () => {
  const [Loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [modalTitle, setModalTitle] = useState("");
  const [LoadingButton, setLoadingButton] = useState();
  const [Data, SetData] = useState([]);
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const [showNext, setShowNext] = useState(false);
  const [RowPerPage, setRowPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [formData, setFormData] = useState({});
  const [popupType, setpopupType] = useState("");
  const [RowId, SetRowId] = useState();
  const MastersModal = Masters.VendorsInvoice;
  const [deletePopupType, setDeletepopupType] = useState("");
  const [Error, SetError] = useState();
  const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("");
  const [search, SetSearch] = useState({
    search: "",
  });
  const debouncedSearchTerm = useDebouncedValue(search.search, 500);
  const HandleSearch = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    console.log(name, value);
    SetSearch({ ...search, [name]: value });
  };

  const VendorInvoice_columns = [
    {
      name: "ID",
      cell: (row, index) => index + 1,
    },
    {
      name: "Vendor",
      cell: (row) => row.vendor?.vendor_name,
      sortable: true,
    },
    {
      name: "Received Date",
      cell: (row) => row.vendor_invoice?.received_date,
      sortable: true,
    },
    {
      name: "Invoice Amount",
      cell: (row) => row.vendor_invoice?.invoice_amount,
      sortable: true,
    },
    {
      name: "Return Item Worth",
      cell: (row) => row.vendor_invoice?.return_item_worth,
      sortable: true,
    },
    {
      name: "Final Amount",
      cell: (row) => row.vendor_invoice?.final_amount,
      sortable: true,
    },
    {
      name: "PDF Path",
      selector: "pdf_path",
      cell: (row) => (
        <img
          src={row.vendor_invoice?.pdf_path}
          alt="PDF Thumbnail"
          style={{ width: "50px", height: "50px" }}
        />
      ),
    },
    {
      name: "Remarks",
      cell: (row) => row.vendor_invoice?.remarks,
      sortable: true,
    },
    {
      name: "Invoice Status",
      cell: (row) => row.vendor_invoice?.invoice_status,
      sortable: true,
    },
  ];
  const GetvendorInvoice = async () => {
    try {
      await axiosConfig.get(
        `/accounts/vendor_invoices_process_payments/?page=${currentPage}&page_size=${RowPerPage}&search=${debouncedSearchTerm}`
      );
      let response = await axiosConfig.get(
        `/accounts/vendor-payments/?page=${currentPage}&page_size=${RowPerPage}&search=${debouncedSearchTerm}`
      );
      console.log(response, "data");
      SetData(response.data.results);
      setLoading(false);
      setTotalRows(response.data.count);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    GetvendorInvoice();
  }, [currentPage, RowPerPage, debouncedSearchTerm]);
  const formSubmit = async (FormData) => {
    console.log(FormData, "formdata");
    const data_to_send = {
      ...FormData,
    };
    delete data_to_send.cuser;
    delete data_to_send.muser;
    if (typeof FormData.pdf_path === "string") {
      delete data_to_send.pdf_path;
    }
    axiosConfig.defaults.headers["Content-Type"] = "multipart/form-data";
    setLoadingButton(true);
    if (FormData.id) {
      await axiosConfig
        .patch(`/accounts/vendor-payments/${FormData.id}/`, data_to_send)
        .then((res) => {
          console.log(res);
          setpopupType("success");
          GetvendorInvoice();
          setShow(false);
          setMessage("Record Updated Successfully");
        })
        .catch((error) => {
          console.log(error);
          let error_message = error.response.data;
          Object.values(error_message)[0] &&
            SetError(Object.values(error_message)[0]);
          setpopupType("error");
        });
      setLoadingButton(false);
    } else {
      await axiosConfig
        .post("/accounts/vendor-payments/", data_to_send)
        .then((res) => {
          console.log(res);
          setpopupType("success");
          GetvendorInvoice();
          setShow(false);
        })
        .catch((error) => {
          console.log(error);
          let error_message = error.response.data;
          Object.values(error_message)[0] &&
            SetError(Object.values(error_message)[0]);
          setpopupType(error);
        });
      setLoadingButton(false);
    }
  };
  const handleClose = () => {
    setShow(false);
    SetError("");
    setpopupType("");
    setMessage("");
    setLoadingButton(false);
  };
  const handleSort = (column, direction) => {
    console.log(column.selector, direction);
    axiosConfig
      .get(
        `/accounts/vendor-payments/?page=${currentPage}&page_size=${RowPerPage}&ordering=${
          direction === "asc" ? column.selector : -column.selector
        }`
      )
      .then((res) => {
        SetData(res.data.results);
      })
      .catch((error) => {
        console.log(error);
      });
    setCurrentPage(1);
  };
  const handleShow = () => {
    setShow(true);
    // setFormData({

    // });
    setModalTitle("Add vendors Invoice");
  };
  const tableCustomStyles = {
    rows: {
      style: {},
    },
    cells: {
      style: {
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        color: "#5C5C5C",
        justifyContent: "center",
        color: "rgb(33,37,41)",
        fontSize: "12px",
        fontWeight: 500,
        lineHeight: "28px",
      },
    },
  };
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowsState, setSelectedRowsState] = useState([]);
  const [totalFinalAmount, setTotalFinalAmount] = useState(0);

  const [clearRows, setClearRows] = useState(false);
  let selectedColumns = [
    {
      name: "Vendor Name",
      selector: (row) => row.vendor?.vendor_name,
      cell: (row) => {
        if (row.id === "Totals") {
          return (
            <p
              style={{
                fontWeight: "500",
                fontSize: "13px",
                marginBottom: "0px",
              }}
            >
              {row.vendor?.vendor_name}
            </p>
          );
        } else {
          return <p className="mb-0">{row.vendor?.vendor_name}</p>;
        }
      },
      sortable: true,
    },
    {
      name: "Invoice Amount",
      selector: (row) => row.vendor_invoice?.invoice_amount,
      sortable: true,
    },
    {
      name: "Return Item Worth",
      selector: (row) => row.vendor_invoice?.return_item_worth,
      sortable: true,
    },
    {
      name: "Final Amount",
      selector: (row) => row.vendor_invoice?.final_amount,
      sortable: true,
    },
  ];
  const downloadExcel = async () => {
    try {
      const response = await axiosConfig.get(
        "/accounts/export-vendor-invoices/",
        {
          responseType: "blob", // Important for binary data
        }
      );

      // Create a URL for the blob
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Create a link element and trigger a download
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "invoices.xlsx"); // File name
      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading the file", error);
    }
  };
  const HandleMultPass = async (ids) => {
    await axiosConfig
      .patch(`/accounts/update_invoice_status/`, { invoice_ids: ids })
      .then(() => {
        SetAlertDeletePopupType("error");
        setpopupType("success");
        GetvendorInvoice();
        setClearRows(true);
        setTimeout(() => {
          downloadExcel();
        }, 5000);
      })
      .catch((error) => {
        setDeletepopupType("error");
        SetAlertDeletePopupType("error");
      });
  };
  const handleRowSelected = (state) => {
    // Collect IDs of the selected rows
    // const ids = state.selectedRows.map((row) => row.id);

    // Pass the selected IDs to the handler function
    // HandleMultPass(ids);

    // Update the selected rows state
    setSelectedRowsState(state.selectedRows.map((row) => row.id));
    setSelectedRows(state.selectedRows);
    let total = state.selectedRows.reduce((acc, row) => {
      return acc + parseFloat(row.vendor_invoice?.final_amount);
    }, 0);
    setTotalFinalAmount(total);
  };
  const handleSelectedRowsChange = ({ selectedRows }) => {
    let selections = selectedRows;
    const selectedIds = selections.map((row) => row.id);
    if (selectedIds.length !== selectedRowsState.length) {
      let total = selectedRows.reduce((acc, row) => {
        return acc + parseFloat(row.vendor_invoice?.final_amount);
      }, 0);
      setTotalFinalAmount(total);
      setSelectedRowsState(selectedIds);
    }
  };
  const handleNext = () => {
    setShowNext(true);
  };
  const handleNextClose = () => {
    setSelectedRowsState(selectedRows.map((row) => row.id));
    setShowNext(false);
  };
  return (
    <>
      <div className="child-sidenav">
        <VendorsNav />
        <div className="childnav-content">
          <button
            className={`btn mt-3 ${
              selectedRows.length === 0 ? "btn-inactive" : "btn-primary"
            }`}
            style={{ minWidth: "143px", height: "45px" }}
            onClick={handleNext}
            disabled={selectedRows.length === 0}
          >
            Next
          </button>
          <div className="table-responsive marginTop">
            <DataTable
              columns={VendorInvoice_columns}
              data={Data}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationPerPage={RowPerPage}
              paginationDefaultPage={currentPage}
              onChangePage={(page) => setCurrentPage(page)}
              paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
              paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
              onChangeRowsPerPage={(RowsPerPageChange) =>
                setRowPerPage(RowsPerPageChange)
              }
              onSort={handleSort}
              fixedHeader
              subHeader
              customStyles={tableCustomStyles}
              progressPending={Loading}
              selectableRows
              // selectableRowDisabled={(row) => row.payment_pass !== true}
              onSelectedRowsChange={handleRowSelected}
              // clearSelectedRows={selectedRows.length === 0 || clearRows}
              progressComponent={
                <div className="loader-container">
                  <img
                    src={require("../Components/FormParser/images/loader2.gif")}
                    alt="Loading..."
                  />
                </div>
              }
            />
          </div>
          <Modal
            size="xl"
            show={showNext}
            onHide={() => handleNextClose()}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-lg">
                Confirm Invoices
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <DataTable
                columns={selectedColumns}
                data={selectedRows}
                selectableRows
                selectableRowSelected={(row) =>
                  selectedRowsState.includes(row.id)
                }
                onSelectedRowsChange={handleSelectedRowsChange}
                selectableRowDisabled={(row) => row.id === "Totals"}
                pagination
              />
              <div className="rowAligned gap-10">
                <span className="f_14_500">Total Final Amount:</span>
                <span className="f_14_green">{totalFinalAmount}</span>
              </div>
            </Modal.Body>
          </Modal>
          <Modal
            size="xl"
            show={show}
            onHide={handleClose}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-lg">
                {modalTitle}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <FormParser
                modelObject={MastersModal}
                formData={formData}
                formSubmit={formSubmit}
                error={Error}
                buttonTitle="Save"
                LoadingButton={LoadingButton}
              />
            </Modal.Body>
          </Modal>
          {popupType !== "" && (
            <PopupModal
              setpopupType={setpopupType}
              popupType={popupType}
              Navigation=""
              Message={message}
              error={Error}
              setMessage={setMessage}
            />
          )}
          {deletePopupType !== "" && (
            <DeletePopupModal
              setDeletepopupType={setDeletepopupType}
              deletePopupType={deletePopupType}
            />
          )}
        </div>
      </div>
    </>
  );
};
export { VendorsPayments };

import React, { useEffect, useState } from "react";
import { MdDeleteOutline, MdOutlineEdit } from "react-icons/md"
import { AiOutlineEye } from "react-icons/ai"
import { DashboardLayout, Pagination } from "../Components";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import axiosConfig from "../Service/axiosConfig";
import Select from 'react-select';
import DataTable from 'react-data-table-component';
import tableCustomStyles from "../Components/PopupModal/tableCustomStyles";
const ClientBilledServices = (props) => {
    // const [searchParams, setSearchParams] = useSearchParams();
    // let month = searchParams.get("month")
    // console.log(month)
    let navigate = useNavigate();
    let dta=localStorage.getItem("user_data")
    const status_array = ['Paid','Unpaid']
    const [clinics, setClinics] = useState()
    const [services, setServices] = useState()
    const [doctors, setDoctors] = useState()
    const [patients, setPatients] = useState()
    const [loading, setLoading] = useState(false);
    const [Data, SetData] = useState([])
    const [billData, setBillData] = useState([])
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(20);
    const [search, SetSearch] = useState({
        search: ""
    })
    const [select, Setselct] = useState({
        month: ""
    })
    const[userData,setUserData]=useState({})
    const [Datafilter, SetDatafilter] = useState([])

    const [formData, setFormData] = useState({
        fromDate: '',
        toDate: '',
        clinic:JSON.parse(dta)&&JSON.parse(dta).clinic_id?JSON.parse(dta).clinic_id:"",
        patient: '',
        status:''
    });

    const [page, NextPage] = useState(1)
    console.log(page)
    // const GetData = async () => {
    //     if (month && month.length > 0) {

    //         setLoading(true)
    //         try {
    //             console.log(page)
    //             const responce = await axiosConfig.get(`client/group_by_client/?month=${month}`)
    //             // console.log(responce.data.results)
    //             console.log(responce.data)
    //             SetData(responce.data)
    //             SetDatafilter(responce.data)
    //             setLoading(false)
    //         }
    //         catch (error) {
    //             console.log(error)
    //         }
    //     }
    // }

    const GetBillData = async () => {

        setLoading(true)
        try {
            const responce = await axiosConfig.get(`clinics/bills/?page=${currentPage}&page_size=${RowPerPage}`)
            console.log(responce.data.results, "bills")
            setBillData(responce.data.results)
            setLoading(false)
        }
        catch (error) {
            console.log(error)
        }
    }

    const getClinics = async () => {
        try {
            const responce = await axiosConfig.get(`/clinics/clinics/`)
            setClinics(responce.data.results)
        }
        catch (error) {
            console.log(error)
        }

    }

    const getServices = async () => {
        try {
            const responce = await axiosConfig.get(`/clinics/doctorservices/`)
            setServices(responce.data.results)
        }
        catch (error) {
            console.log(error)
        }

    }

    const getDoctors = async () => {
        try {
            const responce = await axiosConfig.get(`/clinics/doctors/`)
            setDoctors(responce.data.results)
        }
        catch (error) {
            console.log(error)
        }

    }

    const getPatients = async () => {
        try {
            const responce = await axiosConfig.get(`/clinics/patient/`)
            setPatients(responce.data.results)
        }
        catch (error) {
            console.log(error)
        }

    }
    useEffect(() => {
        // GetData();
        GetBillData();
        getClinics();
        getServices();
        getDoctors();
        getPatients();
        setUserData(JSON.parse(dta))
    }, [currentPage,RowPerPage])

    const HandleNextPage = () => {
        NextPage(page + 1)
    }
    const HandleSearch = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        axiosConfig.get(`clinics/bills/?search=${value}`).then(res=>{
            setBillData(res.data.results)
        }).catch(error=>{
           console.log(error)
        })
        SetSearch({ ...search, [name]: value })
    }

    const HandleSelectOption = (selectedOption, name) => {
        console.log(`${name} ${selectedOption.value} lllll`)
        setFormData({ ...formData, [name]: selectedOption.value })
    }
    
    const handleSubmit = async () => {
        setLoading(true)
        try {
            const responce = await axiosConfig.get(`clinics/bills/?from_date=${formData.fromDate}&to_date=${formData.toDate}&patient=${formData.patient}&clinic=${formData.clinic}&status=${formData.status}`)
            console.log(responce.data.results, "bills")
            setBillData(responce.data.results)
            setLoading(false)
        }
        catch (error) {
            console.log(error)
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };
    const handleSort = (column, direction) => {
        console.log(column.selector,direction)
         axiosConfig.get(`clinics/bills/?ordering=${direction==="asc"?column.selector:-column.selector}`).then(res=>{
             setBillData(res.data.results)
         }).catch(error=>{
            console.log(error)
         })
         setCurrentPage(1);
      };
  
    const billColumns = [
        {
            name: "Patient",
            selector:"patient"
            ,
            sortable: true
        },
        {
            name: "Patient Name",
            selector:"patient_name",
            sortable: true
        },
        {
            name: "Services",
            selector:"qty",
            sortable: true
        },
        {
            name: "Total Amount",
            selector:"total_amount",
            sortable: true
        },
        {
            name: "ACTIONS",
            selector: row => {
                const HandleView = () => {
                    return navigate(`/dashboard/billing/client-bill-details/${row.patient}/`);
                }
                return (
                    <>
                        <div className="row" >
                            <div onClick={HandleView}>
                                <AiOutlineEye />
                                /
                                <MdOutlineEdit />
                            </div>
                        </div>
                    </>
                )

            },
        },
    ]
    return (
        <>
            <DashboardLayout>
                <div className="mainbar">
                    <div className="sidebar-filters px-3">
                        <div className="form-group">
                            <label>From Date</label>
                            <input
                                className="form-control"
                                type="date"
                                name="fromDate"
                                value={formData.fromDate}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label>To Date</label>
                            <input
                                className="form-control"
                                type="date"
                                name="toDate"
                                value={formData.toDate}
                                onChange={handleChange}
                            />
                        </div>
                        {userData&&userData.user_type==="admin"&&<div className="form-group">
                            <label>Clinic/Hospital</label>
                            <Select
                                options={clinics && clinics.map((clinic) => ({ "value": clinic.id, "label": clinic.clinic_name }))}
                                onChange={(selectedOption) => HandleSelectOption(selectedOption, "clinic")}
                            />
                        </div>}
                        <div className="form-group">
                            <label>Patient</label>
                            <Select
                                options={patients && patients.map((patient) => ({ "value": patient.uhid, "label": patient.name }))}
                                onChange={(selectedOption) => HandleSelectOption(selectedOption, "patient")}
                            />
                        </div>
                        <div className="form-group">
                            <label>Status</label>
                            <Select
                                options={status_array.map((status) => ({ "value": status, "label": status }))}
                                onChange={(selectedOption) => HandleSelectOption(selectedOption, "status")}
                            />
                        </div>
                        <button className="btn btn-primary" onClick={handleSubmit}>
                            Filter
                        </button>
                    </div>
                    <div className="main-code">
                        <div className="dashboard-header">
                            <h5 className="dashboard-subtitle">View Client Bills</h5>
                        </div>
                        <DataTable columns={billColumns} data={billData}
                            subHeader
                            fixedHeader
                            pagination paginationTotalRows={totalRows}
                            paginationPerPage={RowPerPage}
                            paginationDefaultPage={currentPage}
                            onChangePage={(page) => setCurrentPage(page)}  
                            paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
                            paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
                            onChangeRowsPerPage={(RowsPerPageChange)=>setRowPerPage(RowsPerPageChange)}  
                            onSort={handleSort} 
                            paginationServer 
                            subHeaderComponent={
                                <>
                                    <input type="text" className="form-control w-25" name="search" value={search.search} onChange={HandleSearch} />
                                </>}
                            customStyles={tableCustomStyles} />
                    </div>
                </div>
            </DashboardLayout>
        </>
    )
}
export { ClientBilledServices }
import React, { useEffect, useState } from "react";
import { DashboardLayout, FormParser, PopupModal } from "../Components";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Masters from "../Components/masters.json"
import "./Pages.css"
import axiosConfig from "../Service/axiosConfig"
import Modal from 'react-bootstrap/Modal';
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import Select from 'react-select';
import { VscAdd } from "react-icons/vsc"
import { MdClose } from "react-icons/md"
import sidebarData from "../Components/DashboardLayout/sidebar";
import { MdOutlineAdd } from "react-icons/md"
import { RxCross2 } from "react-icons/rx"
import {IoIosPeople} from "react-icons/io"
import {BsArrowLeftShort} from "react-icons/bs"
const AvalibilityAdd = () => {
    let { id } = useParams();
    const [week, setWeek] = useState([])
    const [popupType, setpopupType] = useState("");
    const [activeState, setActiveState] = useState("")
    const [StateData, setState] = useState({Sunday:[],Monday:[],Tuesday:[],Wednesday:[],Thursday:[],Friday:[],Saturday:[]})
    const [availability, setAvailability] = useState([])
    const sidebarDatavalue = sidebarData.clinicProfile;
    const [ChildNavs, SetChildNavs] = useState("")
    const [InputCount, setInputCount] = useState({})
    var days = [{ value: "Sunday", label: "Sunday" }, { value: "Monday", label: "Monday" }, { value: "Tuesday", label: "Tuesday" }, { value: "Wednesday", label: "Wednesday" }, { value: "Thursday", label: "Thursday" }, { value: "Friday", label: "Friday" }, { value: "Saturday", label: "Saturday" }];
    let data=JSON.parse(localStorage.getItem("user_data"))
    const [clinicavailability, setClinicAvalability] = useState({
        "clinic": data.clinic_id?data.clinic_id:id,
        "schedule": [
        ]
    })
    const clinicProfile = [
        {
            "SubTitle": "Clinics",
            "NavItems": [
                { "label": "Clinic", "path": `/dashboard/clinics/${id}/edit`, "icon": <IoIosPeople fontSize='small' /> },
                { "label": "Clinics Specialites", "path": `/dashboard/clinics/${id}/specialities`, "icon": <IoIosPeople fontSize='small' /> },
                { "label": "Clinics Availability", "path": `/dashboard/clinics/${id}/clinic_availability`, "icon": <IoIosPeople fontSize='small' /> },
                { "label": "Clinics Gallery", "path": `/dashboard/clinic/${id}/gallery`, "icon": <IoIosPeople fontSize='small' /> },
                { "label": "Clinics Vacations", "path": `/dashboard/clinics/${id}/clinic_vacations`, "icon": <IoIosPeople fontSize='small' /> }
            ]
        }
    ]
    const Handlechnage = (day) => {
        if (!week.includes(day)) {
            setWeek(prevData => [...prevData, day])
            setState(prevAvailability => ({
                ...prevAvailability,
                [day]: [...prevAvailability[day], { days: day, from_time: '', to_time: '' }], // Add the new schedule item to the schedule array
            }));

        }
        else if (week.includes(day)) {
            const existIndex = week.findIndex(find => find === day)
            days.forEach((responce,i)=>{
                let data=StateData
                const index=data[day].findIndex(find=>find.DayIndexs===i)
                data[day].splice(index,1)
                setState(data)
            })
            if (existIndex !== -1) {
                
                const upDate = [...week]
                const days=clinicavailability.schedule.findIndex(find=>find.days===day)
                let updatedDate=clinicavailability
                updatedDate.schedule[days].timeslots=[]
                console.log(updatedDate)
                setClinicAvalability(updatedDate)
                upDate.splice(existIndex, 1);
                setWeek(upDate)
            }
        }
    }
    const Timechnage = (e, day, dayin,index) => {
        const { name, value } = e.target
        
        console.log(name,dayin,index,)
        const updatedData = { ...clinicavailability };
        if (updatedData.schedule[dayin]) {
          if (updatedData.schedule[dayin].timeslots[index]) {
            updatedData.schedule[dayin].timeslots[index][name] = value;
          } else {
            updatedData.schedule[dayin].timeslots.push({ [name]: value });
          }
        } else {
          updatedData.schedule[dayin] = { days: day, timeslots: [{ [name]: value }] };
        }
        setClinicAvalability(updatedData);
    }
    const currentURL = window.location.href;
    const formSubmit = () => {
        axiosConfig.post("/clinics/clinicavailability/", clinicavailability).then(res => {
            console.log(res)
            setpopupType("success")
        }).catch(error => {
            console.log(error)
            setpopupType("error")
        })
    }
    const HandleDelete = (index) => {
        console.log(index)
    }

    const OnChildNavClicked = (path) => {
        SetChildNavs(path)
        localStorage.setItem("url",path)
    }
    const getAvailability = async () => {
        try {
            const responce = await axiosConfig.get(`/clinics/clinicavailability/?clinic=${data.clinic_id ? data.clinic_id :id}`)
            setAvailability(responce.data.results)
            console.log(responce.data.results)
            let sample = {
               Sunday: [],
               Monday: [],
               Tuesday: [],
               Wednesday: [],
               Thursday: [],
               Friday: [],
               Saturday: []
             };
             let upDate=[]
             responce.data.results.forEach(element => {
               const DayIndexs = days.findIndex(item => item.label === element.days);
               console.log(DayIndexs)
              
               const timeslot = {
                   days:element.days,
                   from_time: element.from_time,
                   to_time: element.to_time,
                   DayIndexs: DayIndexs,
               };
        
               sample[element.days].push(timeslot);
               setWeek(prevWeek => {
                const newWeek = new Set(prevWeek);
                  if (!newWeek.has(element.days)) {
                    newWeek.add(element.days);
                  }
                return Array.from(newWeek);
              });
           });
           const processedData = {
               clinic: data.clinic_id?data.clinic_id:id,
               schedule: Object.keys(sample).map(day => ({
                   days: day,
                   timeslots: sample[day]
               }))
           };
           setClinicAvalability(processedData);
           console.log(sample)
           setState(sample)
             
            
           
        }
        catch (error) {
            console.log(error)

        }

    }
    const AddTimeInput = (day,index) => {
        setState(prevAvailability => ({
            ...prevAvailability,
            [day]: [...prevAvailability[day], { days: `${day}`, from_time: '', to_time: '' }], 
        }));
        setInputCount((prevInputCount) => ({ ...prevInputCount, [day]: (prevInputCount[day] || 0) + 1 }));
    };
    const AddTimeRemoveInput = (day,i,index) => {
        const updatedData=clinicavailability
        updatedData.schedule[i].timeslots.splice(index,1)
        console.log(updatedData)
        setClinicAvalability(updatedData)
        setState(prevState => {
            const updatedStateData = { ...prevState };
            if (updatedStateData[day].length > 1) {
              updatedStateData[day].splice(-1, 1); 
              setInputCount(prevInputCount => ({ ...prevInputCount, [day]: prevInputCount[day] - 1 }));
            }
            return updatedStateData;
          });
    };
    console.log(week)
    useEffect(() => {
        getAvailability();
        SetChildNavs(localStorage.getItem("url"))
    }, [])
    return (
        <>
            <DashboardLayout>
                <div className="mainbar">

                    <div className="sidebar-filters px-2">
                    {data.user_type==="admin"&&<NavLink to="/dashboard/clinics/list" ><button type="submit" className="btn btn-primary"><BsArrowLeftShort />back to Clinics</button></NavLink>}
                    {data.user_type==="staff"&&<NavLink to="/dashboard/clinics/list" ><button type="submit" className="btn btn-primary"><BsArrowLeftShort />back to Clinics</button></NavLink>}
                        {
                            data.user_type==="staff"&&
                            sidebarDatavalue.map((childItem, index) => {
                                return (
                                    <div key={index}>
                                        <h5>{childItem.SubTitle}</h5>
                                        {
                                            childItem.NavItems.map((item, idx) => {
                                                return (
                                                    <div key={idx} className={ChildNavs == item.path ? "Configiration-list active" : "Configiration-list"}>
                                                        <NavLink to={item.path} className={ChildNavs == item.path ? "colorChange" : "NoColorChange"} onClick={() => OnChildNavClicked(item.path)}>
                                                            <span className="configiration-icon">{item.icon}</span>
                                                            <span className="list-name">{item.label}</span>
                                                        </NavLink>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            })
                        }
                         {
                            data.user_type==="admin"&&
                            clinicProfile.map((childItem, index) => {
                                return (
                                    <div key={index}>
                                        <h5>{childItem.SubTitle}</h5>
                                        {
                                            childItem.NavItems.map((item, idx) => {
                                                return (
                                                    <div key={idx} className={ChildNavs == item.path ? "Configiration-list active" : "Configiration-list"}>
                                                        <NavLink to={item.path} className={ChildNavs == item.path ? "colorChange" : "NoColorChange"} onClick={() => OnChildNavClicked(item.path)}>
                                                            <span className="configiration-icon">{item.icon}</span>
                                                            <span className="list-name">{item.label}</span>
                                                        </NavLink>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="main-code">
                        <div className="dashboard-header">
                            <h5 className="dashboard-subtitle">Clinic Availability</h5>
                        </div>
                        {
                            days.map((res,i) => {
                                return (
                                    <div className="row my-2" key={res.value}>
                                        <div className="form-group col-2">
                                            <label className="form-label">{res.label}:</label>
                                        </div>
                                        <div className="form-group col-2">
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" id={res.label} checked={week.includes(res.label)} onChange={() => Handlechnage(res.label)} />
                                                <label className="form-check-label" htmlFor={res.label}>{week.includes(res.label) ? "Open" : "Closed"}</label>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            {
                                                StateData[res.label].map((State, index) => {
                                                    if (res.label === State.days) {
                                                        return (
                                                            <div>
                                                                <div className="d-flex align-items-center justify-content-around">
                                                                    <div className="form-group">
                                                                        <input type="time" className="form-control time-input" name="from_time" data-day={res.label} defaultValue={State.from_time} onChange={(e) => Timechnage(e, res.label, i,index)} required />
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <input type="time" className="form-control time-input" name="to_time" data-day={res.label} defaultValue={State.to_time}  onChange={(e) => Timechnage(e, res.label, i,index)} required />
                                                                    </div>
                                                                    <div>
                                                                        {index === 0 ? ( 
                                                                            <MdOutlineAdd onClick={() => AddTimeInput(res.label,index)} />
                                                                        ):<RxCross2 onClick={()=>AddTimeRemoveInput(res.label,i,index)}/>}

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        )
                                                    }
                                                })}
                                        </div>
                                    </div>
                                )
                            })
                        }


                        <div >
                            <button type="submit" className="btn btn-primary" onClick={formSubmit}>Save</button>
                        </div>
                    </div>
                </div>
                {popupType != "" &&
                    <PopupModal popupType={popupType} Navigation="" />
                }
            </DashboardLayout >

        </>
    )
}
export { AvalibilityAdd }

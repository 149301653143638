import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  MdOutlineViewCarousel,
  MdOutlineImage,
  MdOutlineCollections,
} from "react-icons/md";
import { BsCardText, BsMoonStars } from "react-icons/bs";
import { BiImages, BiCategory } from "react-icons/bi";
import { TbInfoTriangle } from "react-icons/tb";
import { AiOutlineHome } from "react-icons/ai";
import { TbCategoryPlus } from "react-icons/tb";
import { CgProfile } from "react-icons/cg";
import { LiaFileInvoiceSolid } from "react-icons/lia";
import { MdPayments } from "react-icons/md";
const VendorsNav = () => {
    const[UserData,SetUserData]=useState({})
    const getUserData = async () => {
        const user_data = JSON.parse(localStorage.getItem('user_data'));
        SetUserData(user_data);
      }
      useEffect(()=>{
        getUserData()
      },[])
  return (
    <div className="categorie-header">
      <NavLink
        to="/dashboard/vendors/daily-collections"
        className="categorie-subtitle"
      >
        <div className="child-title">
          <MdOutlineCollections size={24} className="childnav-icon" />
          <p className="childnav-name">Daily Collections</p>
        </div>
      </NavLink>
      <NavLink
        to="/dashboard/vendors/vendor-profile"
        className="categorie-subtitle"
      >
        <div className="child-title">
          <CgProfile size={24} className="childnav-icon" />
          <p className="childnav-name">Vendors Profile</p>
        </div>
      </NavLink>
      <NavLink
        to="/dashboard/vendors/vendor-invoice"
        className="categorie-subtitle"
      >
        <div className="child-title">
          <LiaFileInvoiceSolid size={24} className="childnav-icon" />
          <p className="childnav-name">Vendors Invoice</p>
        </div>
      </NavLink>
     {UserData.role === "manager" && <NavLink
        to="/dashboard/vendors/vendor-payments"
        className="categorie-subtitle"
      >
        <div className="child-title">
          <MdPayments size={24} className="childnav-icon" />
          <p className="childnav-name">Vendors Payments</p>
        </div>
      </NavLink>}
    </div>
  );
};

export { VendorsNav };

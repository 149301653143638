import { useSearchParams } from 'react-router-dom';

export const useURLParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  // Function to update or remove URL parameters
  const setParam = (key, value) => {
    const updatedParams = new URLSearchParams(searchParams);
    if (value) {
      updatedParams.set(key, value);
    } else {
      updatedParams.delete(key);
    }
    setSearchParams(updatedParams);
  };

  // Function to get a specific URL parameter value
  const getParam = (key) => {
    return searchParams.get(key) || '';
  };
  const clearSelection = (setSelectedId,name) => {
    setSelectedId('');
    setParam(name, ''); // Reset or remove the URL parameter
  };
  return { setParam, getParam,clearSelection };
};

import React, { useEffect, useState } from "react";
import { DashboardLayout,PopupModal } from "../Components";
import { NavLink, useNavigate } from "react-router-dom";
import axiosConfig from "../Service/axiosConfig"
import DataTable from 'react-data-table-component';

const UploadCases = () => {
    const [popupType, setpopupType] = useState("");
    const[Data,setData]=useState()
    const HandleChange = (e) => {
        e.preventDefault()
        const file = e.target.files[0];
        const name = e.target.name;
        const value = file;
        console.log(name, value);
        setData({ ...Data, [name]: value })
    }
   const HandleSubmit=(e)=>{
    e.preventDefault()
    console.log(Data.file)
    const formData = new FormData()
    formData.append("file",Data.file)
    axiosConfig.defaults.headers['Content-Type'] = 'multipart/form-data'; 
     axiosConfig.post("/cases/Uploadfile/Upload_File/",formData).then(res=>{
        setpopupType("success")
        console.log(res.data)
       }).catch(error=>{
        setpopupType("error")
        console.log(error)
       })

   }
      const DownloadSampleCSV=()=>{
        axiosConfig.get("CSVFiles/Sample_CSV.csv").then(response=>{
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `SampleCSV.csv`);
            document.body.appendChild(link);
            link.click();
    })
    .catch(error => {
        console.error('There was an error downloading the PDF', error);
    })

      }


    return (
        <>
            <DashboardLayout>
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">Upload Raw Data/ Cases</h5>
                    <NavLink to="/dashboard/billing/upload-cases-list" ><button className="btn btn-primary">View Cases</button></NavLink>
                </div>
                <div className="container-form">
                    <form>
                        <label htmlFor="file-input" className="form-group uploadBox"> 
                             <label htmlFor="file-input">
                                <img src={require("./images/upload.png")}  alt="upload"/>
                                <h5 className="upload-head">{Data?Data.file.name:"Upload Raw Data"}</h5>
                                </label>
                            <input id="file-input" type="file" className="form-control"  name="file" onChange={HandleChange} required />
                        </label>
                        <button type="submit" onClick={HandleSubmit} className="btn btn-primary btn-block" >Submit</button>
                    </form>
                </div>
                <p className="upload-Download" onClick={ DownloadSampleCSV}>Download Sample CSV</p>
                <div className="f-copy">
                <p><span className="copy-sym">&copy;</span> Pride Product of Apollo Radiology International. All Copy Rights Reserved 2023
</p>
                </div>
                {popupType != "" &&
                    <PopupModal popupType={popupType} Navigation="/dashboard/billing/upload-cases"/>
                }
            </DashboardLayout>
        </>
    )
}
export { UploadCases }
import React, { useState } from "react";
import { DashboardLayout, FormParser, PopupModal } from "../Components";
import { NavLink } from "react-router-dom";
import Masters from "../Components/masters.json"
import "./Pages.css"
import axiosConfig from "../Service/axiosConfig"

const PataientAdd = () => {
    const [CustomError, SetCustomError] = useState("")
    const [CustomError1, SetCustomError1] = useState("")
    const [Error, SetError] = useState("")
    const [popupType, setpopupType] = useState("");

    const MastersModal = Masters.PataientRegestration
    const formSubmit = (Data) => {
        console.log(Data)
        axiosConfig.post("/clinics/patient/", Data).then(res => {
            console.log(res)
            setpopupType("success")
        }).catch(error => {
            console.log(error.response.data)
            const ErrorData = []
            ErrorData.push({
                code: error.response.data.code || ["null"],
                name: error.response.data.name || ["null"],
            })
            console.log(ErrorData[0])
            if (ErrorData[0].code[0] === "This field must be unique.") {
                SetError(" code already exists.")
            }
            if (ErrorData[0].name[0] === "This field must be unique.") {
                SetError("Tittle already Exists")
            }
            setpopupType("error")
        })
    }
    
    return (
        <>
            <DashboardLayout>
                <div className="mainbar">
                    <div className="main-code">
                        <div className="dashboard-header">
                            <h5 className="dashboard-subtitle">Add Patient</h5>
                            <NavLink to="/dashboard/patients/registration" ><button className="btn btn-white">Manage</button></NavLink>
                        </div>
                        <FormParser modelObject={MastersModal} formSubmit={formSubmit} error={Error} />
                        {popupType != "" &&
                            <PopupModal popupType={popupType} Navigation="/dashboard/patients/registration" />
                        }
                    </div>
                </div>
            </DashboardLayout>
        </>
    )

}
export { PataientAdd }
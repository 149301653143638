import React, { useEffect, useState } from "react";
import { DashboardLayout, DeletePopupModal, PopupModal } from "../Components";
import { SettingNavbarComponent } from "../Components/navbar/settingsnav";
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import { BiSearch } from "react-icons/bi";
import axiosConfig from "../Service/axiosConfig"
import { FormParser } from "../Components";
import Modal from 'react-bootstrap/Modal';
import Masters from "../Components/masters.json";
import DataTable from 'react-data-table-component';
import { MdOutlineEdit, MdDeleteOutline } from "react-icons/md";
import { FaHandHoldingHeart } from "react-icons/fa"
import { RiDeleteBin6Line } from "react-icons/ri"
import { useNavigate } from "react-router-dom"

const TaxClassification = () => {
    const MastersModal = Masters.TaxClasses
    const [search, SetSearch] = useState({
        search: ""
    })
    const HandleSearch = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        console.log(name, value)
        SetSearch({ ...search, [name]: value })
        axiosConfig.get(`/masters/taxclasses/?search=${value}`).then(res => {
            SetData(res.data.results)
        }).catch(error => {
            console.log(error)
        })
    }
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("");
    const [Error, SetError] = useState("")
    const [deletePopupType, setDeletepopupType] = useState("");
    const [Data, SetData] = useState([]);
    const [loading, setLoading] = useState(false)
    const [RowId, SetRowId] = useState()
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(20);
    const [popupType, setpopupType] = useState("")
    const [show, setShow] = useState(false);
    const [row_Id, setRow_Id] = useState(false)
    const [formData, setFormData] = useState({});
    const [message, setMessage] = useState('');
    const [modalTitle, setModalTitle] = useState('Add New Tax class')
    const [buttonTitle, setButtonTitle] = useState('Create Tax class')
    const handleShow = () => {
        setModalTitle('Add New Tax class')
        setButtonTitle('Create Tax class')
        setShow(true)
    };
    const handleClose = () => {
        setShow(false)
        setFormData("")
        setpopupType("")
        SetError("")
    };
    const handleSort = (column, direction) => {
        console.log(column.selector, direction)
        axiosConfig.get(`/masters/taxclasses/?page=${currentPage}&page_size=${RowPerPage}&ordering=${direction === "asc" ? column.selector : -column.selector}`).then(res => {
            SetData(res.data.results)
        }).catch(error => {
            console.log(error)
        })
        setCurrentPage(1);
    };
    const formSubmit = (FormData, setFieldErrors) => {
        console.log("Client post data : ", FormData)
        axiosConfig.defaults.headers['Content-Type'] = 'multipart/form-data';
        if (FormData.id) {
            axiosConfig.patch(`/masters/taxclasses/${FormData.id}/`, FormData)
                .then(res => {
                    console.log(res)
                    setMessage("Tax Updated Successfully")
                    setpopupType("success")
                    GetData();
                    setShow(false);
                }).catch(error => {
                    console.log(error)
                    SetError(error.response.data.title)
                    if (error.response.data.title) {
                        setFieldErrors(prev => ({ ...prev, ["title"]: "Title already exists." }));
                        SetError(error.response.data.title)
                    }
                    setpopupType("error")
                })
        } else {
            axiosConfig.post("/masters/taxclasses/", FormData).then(res => {
                console.log(res)
                setMessage("tax added SuccessFully")
                setpopupType("success")
                GetData();
                setShow(false);
            }).catch(error => {
                console.log(error)
                SetError(error.response.data.title)
                if (error.response.data.title) {
                    setFieldErrors(prev => ({ ...prev, ["title"]: "Title already exists." }));
                    SetError(error.response.data.title)
                }
                setpopupType(error)
            })
        }
    };
    const GetData = async () => {
        setLoading(true)
        try {
            const responce = await axiosConfig.get(`/masters/taxclasses/?page=${currentPage}&page_size=${RowPerPage}`)
            console.log(responce.data.results)
            SetData(responce.data.results)
            setLoading(false)
            setTotalRows(responce.data.count)
        }
        catch (error) {
            console.log(error)
        }

    }
    useEffect(() => {
        GetData();
    }, [currentPage, RowPerPage]);
    const Customeredit = (id) => {
        console.log(id, 'iddddd')
        axiosConfig.get(`/masters/taxclasses/${id}/`).then(res => {
            console.log(res.data)
            setFormData(res.data)
            setShow(true)
        }).catch(error => {
            console.log(error)
        })
    }
    const HandleMouse = (row) => {
        setRow_Id(row.id)
    }
    const HandleRowmouseLeave = () => {
        setRow_Id()
    }
    const tableCustomStyles = {
        rows: {
            style: {
            },
        },
        cells: {
            style: {
                justifyContent: "center"
            },
        },
        headCells: {
            style: {
                color: "#5C5C5C",
                justifyContent: "center",
                color: "rgb(33,37,41)",
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "28px",
            },
        },
    };
    const columns = [
        {
            name: "S.No",
            selector: "id",
            cell: (row, i) => (currentPage - 1) * RowPerPage + i + 1,
            sortable: true
        },
        {
            name: "Title",
            selector: "title",
            cell: row => row.title,
            sortable: true
        },
        {
            name: "tax Percentage",
            selector: "tax_percentage",
            cell: row => row.tax_percentage,
            sortable: true
        },
        {
            name: "HSN Code",
            selector: "hsn_code",
            cell: row => row.hsn_code,
            sortable: true
        },
        {
            name: "Status",
            selector: row => {
                const HandleEdit = async () => {
                    console.log(row.id, 'iddddd')
                    setModalTitle('Edit Tax class')
                    setButtonTitle('Save')
                    setShow(true)
                    Customeredit(row.id)
                }
                const DeleteAlert = () => {
                    SetRowId(row.id)
                    SetAlertDeletePopupType("success")
                }
                const CancelAlerrt = () => {
                    SetAlertDeletePopupType("error")
                }
                const HandleDelete = async (id) => {
                    await axiosConfig.delete(`/masters/taxclasses/${id}/`).then(() => {
                        SetAlertDeletePopupType("error")
                        setDeletepopupType("success")
                        GetData();
                    }).catch((error) => {
                        setDeletepopupType("error")
                        SetAlertDeletePopupType("error")
                        console.log(error)
                    })
                }
                const handleRevive = async () => {
                    try {
                        const response = await axiosConfig.post(`/masters/taxclasses/${row.id}/revive/`)
                        setpopupType("success")
                        setMessage("Tax Classification revived successfully")
                        GetData()
                    } catch (error) {
                        console.log("Error While Reviving", error)
                    }
                }
                return (
                    <>
                        {row_Id === row.id ? (
                            row.is_suspended ? (
                                <FaHandHoldingHeart className="dashboard-tabe-data-actions" onClick={handleRevive} />
                            ) : (
                                <>
                                    <div className="row">
                                        <div className="col-4">
                                            <MdOutlineEdit className="dashboard-tabe-data-actions" onClick={HandleEdit} />
                                        </div>
                                        <div className="col-4">
                                            <MdDeleteOutline className="dashboard-tabe-data-actions" onClick={DeleteAlert} />
                                        </div>
                                    </div>
                                </>
                            )
                        ) : (
                            <div className="client-type contract-background">{row.is_suspended ? "Suspended" : "Active"}</div>
                        )}

                        {
                            AlertDeletePopupType === "success" &&
                            <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} HandleDelete={HandleDelete} CancelAlerrt={CancelAlerrt} row={RowId} />
                        }
                    </>
                )

            }
        }
    ]
    return (
        <>
            <div className="child-sidenav">
                <SettingNavbarComponent />
                <div className="childnav-content">
                    <div className="cat-button">
                        <div className="btn" onClick={handleShow} >
                            + Add New
                        </div>
                    </div>
                    <div className="input-cat-with-icon">
                        <span className="icon-product"><BiSearch /></span>
                        <input type="text" className="input-control" placeholder="Search" name="search" value={search.search} onChange={HandleSearch} />
                    </div>
                    <div className="table-responsive marginTop">
                        <DataTable columns={columns} data={Data} pagination paginationTotalRows={totalRows}
                            paginationPerPage={RowPerPage}
                            paginationDefaultPage={currentPage}
                            onChangePage={(page) => setCurrentPage(page)}
                            paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
                            paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
                            onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
                            onSort={handleSort}
                            onRowMouseEnter={HandleMouse}
                            onRowMouseLeave={HandleRowmouseLeave}
                            highlightOnHover
                            paginationServer fixedHeader subHeader customStyles={tableCustomStyles} progressPending={loading}
                            progressComponent={<div className="loader-container">
                                <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                            </div>}
                        />
                    </div>
                    <Modal
                        size="xl"
                        show={show}
                        onHide={handleClose}
                        aria-labelledby="example-modal-sizes-title-lg"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="example-modal-sizes-title-lg">
                                {modalTitle}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FormParser modelObject={MastersModal} formData={formData} formSubmit={formSubmit} buttonTitle={buttonTitle} button='btn-block' />
                        </Modal.Body>
                    </Modal>
                    {popupType !== "" &&
                        <PopupModal setpopupType={setpopupType} popupType={popupType} Navigation="" Message={message} error={Error} />
                    }
                    {deletePopupType !== "" &&
                        <DeletePopupModal setDeletepopupType={setDeletepopupType} deletePopupType={deletePopupType} />
                    }
                </div>
            </div>
        </>
    )
}

export { TaxClassification }
import React, { useEffect, useState } from "react";
import { DashboardLayout, DeletePopupModal, PopupModal } from "../Components";
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import { MarketingNavbarComponent } from "../Components/navbar/marketingnav";
import { BiSearch } from "react-icons/bi";
import axiosConfig from "../Service/axiosConfig"
import { FormParser } from "../Components";
import Modal from 'react-bootstrap/Modal';
import Masters from "../Components/masters.json";
import DataTable from 'react-data-table-component';
import { FaHandHoldingHeart } from "react-icons/fa"
import { MdDeleteOutline, MdOutlineEdit } from "react-icons/md";
import useDebouncedValue from "../Utils/Debounce";
const JobCategory = () => {
    const MastersModal = Masters.JobCategory;
    const [search, SetSearch] = useState({
        search: ""
    });
    const debouncedSearchTerm = useDebouncedValue(search.search, 500);
    const HandleSearch = (e) => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;
        console.log(name, value);
        SetSearch({ ...search, [name]: value });
    };

    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("");
    const [Error, SetError] = useState("");
    const [deletePopupType, setDeletepopupType] = useState("");
    const [Data, SetData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [RowId, SetRowId] = useState();
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(20);
    const [popupType, setpopupType] = useState("");
    const [show, setShow] = useState(false);
    const [modalTitle, setModalTitle] = useState("Job Categories");
    const [message, setMessage] = useState('')
    const [formData, setFormData] = useState({});
    const handleShow = () => {
        setShow(true);
        setFormData({});
        setBlogCatid(null);
        setModalTitle("Job Categories");
    };

    const handleClose = () => {
        setShow(false);
        SetError("");
        setpopupType("");
    };

    const handleSort = (column, direction) => {
        console.log(column.selector, direction);
        axiosConfig.get(`/careers/job-categories/?page=${currentPage}&page_size=${RowPerPage}&ordering=${direction === "asc" ? column.selector : -column.selector}`).then(res => {
            SetData(res.data.results);
        }).catch(error => {
            console.log(error);
        });
        setCurrentPage(1);
    };

    const [passwordError, setPasswordError] = useState("");
    const formSubmit = (FormData) => {
        console.log(FormData)
        axiosConfig.defaults.headers['Content-Type'] = 'multipart/form-data';
        if (FormData.id) {
            let newFormData = FormData;
            if (typeof (FormData.image) == "string") {
                delete newFormData.image;
            }
            axiosConfig.patch(`/careers/job-categories/${FormData.id}/`, FormData)
                .then(res => {
                    console.log(res)
                    setpopupType("success")
                    setPasswordError("");
                    GetData();
                    setShow(false);
                }).catch(error => {
                    console.log(error)
                    setpopupType("error")
                })
        }
        else {
            axiosConfig.post("/careers/job-categories/", FormData).then(res => {
                console.log(res)
                setpopupType("success")
                GetData();
                setShow(false);
            }).catch(error => {
                console.log(error)
                if (error.response && error.response.status === 400) {
                    const errorMessage = error.response.data.image;
                    console.log(errorMessage[0])
                    if (errorMessage[0] === "The submitted data was not a file. Check the encoding type on the form.") {
                        setPasswordError('image field may not be blank.');
                    }
                }
                setpopupType("error")
            })
        }
    }
    const GetData = async () => {
        setLoading(true)
        try {
            const responce = await axiosConfig.get(`/careers/job-categories/?page=${currentPage}&page_size=${RowPerPage}`)
            SetData(responce.data.results)
            setLoading(false)
            setTotalRows(responce.data.count)
        }
        catch (error) {
            console.log(error)
        }
    };
    useEffect(() => {
        const getSearchResults = async () => {
            axiosConfig.get(`/careers/job-categories/?search=${debouncedSearchTerm}`).then(res => {
                SetData(res.data.results);
            }).catch(error => {
                console.log(error);
            });
        };
        getSearchResults();
    }, [debouncedSearchTerm]);
    useEffect(() => {
        GetData();
    }, [currentPage, RowPerPage]);
    const [blogCatid, setBlogCatid] = useState()
    const blogcatedit = (id) => {
        axiosConfig.get(`/careers/job-categories/${id}/`).then(res => {
            console.log(res.data)
            setFormData(res.data)
            setBlogCatid(id)
            setShow(true)
        }).catch(error => {
            console.log(error);
        });
    };

    const tableCustomStyles = {
        rows: {
            style: {
            },
        },
        cells: {
            style: {
                justifyContent: "center"
            },
        },
        headCells: {
            style: {
                color: "#5C5C5C",
                justifyContent: "center",
                color: "rgb(33,37,41)",
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "28px",
            },
        },
    };

    const columns = [
        {
            name: "S.No",
            selector: "id",
            cell: (row, i) => (currentPage - 1) * RowPerPage + i + 1,
            sortable: true
        },
        {
            name: "category",
            selector: "category",
            cell: row => row.category,
            sortable: true
        },
        {
            name: "ACTIONS",
            selector: row => {
                const HandleEdit = async () => {
                    console.log(row.id, 'iddddd')
                    setShow(true)
                    blogcatedit(row.id)
                    // handleShow(row.id);
                    setModalTitle("Edit Job Category");
                };

                const DeleteAlert = () => {
                    SetRowId(row.id);
                    SetAlertDeletePopupType("success");
                };

                const CancelAlerrt = () => {
                    SetAlertDeletePopupType("error");
                };

                const HandleDelete = async (id) => {
                    await axiosConfig.delete(`/careers/job-categories/${id}/`).then(() => {
                        SetAlertDeletePopupType("error");
                        setDeletepopupType("success");
                        GetData();
                    }).catch((error) => {
                        setDeletepopupType("error");
                        SetAlertDeletePopupType("error");
                        console.log(error);
                    });
                };
                const handleRevive = async () => {
                    try {
                        const response = await axiosConfig.post(`/careers/job-categories/${row.id}/revive/`)
                        setpopupType("success")
                        setMessage("Blog Category revived successfully")
                        GetData()
                    } catch (error) {
                        console.log("Error While Reviving", error)
                    }
                }
                return (
                    <>
                        {!row.is_suspended ?
                            <div className="row" >
                                <div className="col-4">
                                    <MdOutlineEdit className="dashboard-tabe-data-actions" onClick={HandleEdit} />
                                </div>
                                <div className="col-4">
                                    <MdDeleteOutline className="dashboard-tabe-data-actions" onClick={DeleteAlert} />
                                </div>
                            </div>
                            :
                            <>
                                <FaHandHoldingHeart className="dashboard-tabe-data-actions" onClick={handleRevive} />
                            </>
                        }
                        {
                            AlertDeletePopupType === "success" &&
                            <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} HandleDelete={HandleDelete} CancelAlerrt={CancelAlerrt} row={RowId} />
                        }
                    </>
                );
            }
        }
    ];

    return (
        <>
            <div className="child-sidenav">
                <MarketingNavbarComponent />
                <div className="childnav-content">
                <div className="d-flex align-items-center">
            <div className="input-cat-with-icon">
              <span className="icon-product">
                <BiSearch />
              </span>
              <input
                type="text"
                className="input-control"
                placeholder="Search"
                name="search"
                value={search.search}
                onChange={HandleSearch}
              />
            </div>
            <div className="cat-button">
              <div className="btn" onClick={handleShow}>
                + Add New
              </div>
            </div>
          </div>
                    <div className="table-responsive marginTop">
                        <DataTable columns={columns} data={Data} pagination paginationTotalRows={totalRows}
                            paginationPerPage={RowPerPage}
                            paginationDefaultPage={currentPage}
                            onChangePage={(page) => setCurrentPage(page)}
                            paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
                            paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
                            onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
                            onSort={handleSort}
                            paginationServer fixedHeader subHeader customStyles={tableCustomStyles} progressPending={loading}
                            progressComponent={<div className="loader-container">
                                <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                            </div>}
                        />
                    </div>
                    <div>
                        <Modal
                            size="xl"
                            show={show}
                            onHide={handleClose}
                            aria-labelledby="example-modal-sizes-title-lg"
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="example-modal-sizes-title-lg">
                                    {modalTitle}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {passwordError && (
                                    <div className="alert alert-danger" role="alert">
                                        {passwordError}
                                    </div>
                                )}

                                <FormParser modelObject={MastersModal} formData={formData} formSubmit={formSubmit} error={Error} buttonTitle='Save' />
                            </Modal.Body>
                        </Modal>
                        {popupType !== "" &&
                            <PopupModal setpopupType={setpopupType} popupType={popupType} Navigation="" Message={message} />
                        }
                        {deletePopupType !== "" &&
                            <DeletePopupModal setDeletepopupType={setDeletepopupType} deletePopupType={deletePopupType} />
                        }
                    </div>

                </div>
            </div>
        </>
    );
}
export { JobCategory }
import React, { useEffect, useState } from "react";
import { DashboardLayout, FormParser,PopupModal } from "../Components";
import { NavLink,useParams } from "react-router-dom";
import Masters from "../Components/masters.json"
import "./Pages.css"
import axiosConfig from "../Service/axiosConfig"
const SubDepartmentEdit = () => {
    const[Error,SetError]=useState("")
    const[popupType,setpopupType]=useState("")
    const MastersModal=Masters.SubDepartment
    const formSubmit=(Data)=>{
        axiosConfig.put(`/masters/subdepartments/${id}/`,Data).then(res=>{
                   console.log(res)
                   setpopupType("success")
        }).catch(error=>{
            console.log(error)
            const formData=[]
                formData.push({subdepartment_code:error.response.data.subdepartment_code||["null"],
                subdepartment_name:error.response.data.subdepartment_name||["null"],
                    department_id:error.response.data.department_id||["null"]})
                    console.log(formData[0])
            if (formData[0].subdepartment_code[0] === "enter subdepartment code") {
                SetError("Subdepartment Code Already Exists")
            }
            if (formData[0].subdepartment_name[0] === "enter subdepartment name") {
                SetError("Subdepartment Name Already Exists")
            }
            setpopupType("error")
        })
      }
    let { id } = useParams();
    const [Data, SetData] = useState([])
    const GetData = async () => {
        try {
            const responce = await axiosConfig.get(`/masters/subdepartments/${id}/`)
            SetData(responce.data)
        }
        catch (error) {
            console.log(error)
        }

    }
    useEffect(() => {
        GetData();
    }, [])
    return (
        <>
            <DashboardLayout>
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">Edit Sub-Department {id}</h5>
                    <NavLink to="/dashboard/masters/sub-departments" ><button className="btn btn-white">Manage</button></NavLink>
                </div>
                <FormParser modelObject={MastersModal} formData={Data} formSubmit={formSubmit} error={Error}/>
                {popupType != "" &&
                    <PopupModal popupType={popupType} Navigation="/dashboard/masters/sub-departments" />
                }
            </DashboardLayout>
        </>
    )

}
export {SubDepartmentEdit}
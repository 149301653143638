import React, { useEffect, useState } from "react";
import { DashboardLayout,DeletePopupModal } from "../Components";
import { NavLink, useNavigate } from "react-router-dom";
import axiosConfig from "../Service/axiosConfig"
import DataTable from 'react-data-table-component';
import {MdDeleteOutline,MdOutlineEdit} from "react-icons/md"
import {AiOutlineEye} from "react-icons/ai"
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import tableCustomStyles from "../Components/PopupModal/tableCustomStyles";
const AppointmentsList = () => {
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("")
    const[deletePopupType,setDeletepopupType]=useState("")
    const [RowId, SetRowId] = useState()
    const[Loading,setLoading]=useState(false)
    let navigate = useNavigate();
    const[search,SetSearch]=useState({
        search:""
    })
    const[Datafilter,SetDatafilter]=useState([])
    const [Data, SetData] = useState([])
    const GetData = async () => {
        setLoading(true)
        try {
            const responce = await axiosConfig.get("/clinics/Scheduler/")
            console.log(responce.data)
            SetData(responce.data)
            SetDatafilter(responce.data)
            setLoading(false)
        }
        catch (error) {
            console.log(error)
        }

    }
    const HandleSearch=(e)=>{
        e.preventDefault()
        const name=e.target.name
        const value=e.target.value
        console.log(name,value)
        SetSearch({...search,[name]:value})
        const response = Datafilter.filter(data=>data.speciality_name&&data.speciality_name.toLowerCase().includes(value.toLowerCase())||
        data.id&&data.id.toString().includes(value)||
        data.doctor_name&&data.doctor_name.toString().includes(value)||
        data.service_name&&data.service_name.toString().includes(value)||
        data.scheduled_date_time&&data.scheduled_date_time.toString().includes(value)||
        data.service_amount&&data.service_amount.toString().includes(value)||
        data.appointment_status&&data.appointment_status.toString().includes(value))
        console.log(response)
        SetData(response)
    }
    useEffect(() => {
        GetData();
    }, [])

    const columns = [
        {
            name: "S.No",
            selector: (row,i) => i+1,
            sortable: true
        },
        {
            name: "Speciality name",
            selector: row => row.speciality_name,
            sortable: true
        },
        {
            name: "Doctor name",
            selector: row => row.doctor_name,
            sortable: true
        },
        {
            name: "Service name",
            selector: row => row.service_name,
            sortable: true
        },
        {
            name: "Scheduled date time",
            selector: row => row.scheduled_date_time,
            sortable: true
        },
        {
            name: "Service amount",
            selector: row => row.service_amount,
            sortable: true
        },
        {
            name: "Appointment status",
            selector: row => row.appointment_status,
            sortable: true
        },
        {
            name: "ACTIONS",
            selector: row => {
                const HandleEdit = async() => {
                    return navigate(`/dashboard/qms/appointments/${row.id}/edit`);
                }
                const DeleteAlert = () => {
                    SetRowId(row.id)
                    SetAlertDeletePopupType("success")
                }
                const CancelAlerrt=()=>{
                    SetAlertDeletePopupType("error")
                }
                const HandleDelete = async(id) => {
                    await axiosConfig.delete(`/clinics/Scheduler/${id}/`).then(()=>{
                        SetAlertDeletePopupType("error")
                        setDeletepopupType("success")
                        GetData()
                    }).catch((error)=> {
                        setDeletepopupType("error")
                        console.log(error)
                    })
                }
                const HandleEmr=()=>{
                    navigate(`/dashboard/qms/${row.id}/emr`)
                }
                return (
                    <>
                        <div className="row" >
                            <div className="col-2">
                                <MdOutlineEdit className="dashboard-tabe-data-actions"  onClick={HandleEdit}/>
                            </div>
                            <div className="col-2">
                                <MdDeleteOutline className="dashboard-tabe-data-actions" onClick={DeleteAlert}/>
                            </div>
                            <div className="col-8">
                            <button type="submit" className="btn btn-primary" onClick={HandleEmr}>EMR</button>
                            </div>
                        </div>
                        {
                            AlertDeletePopupType=== "success" &&
                            <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} HandleDelete={HandleDelete} CancelAlerrt={CancelAlerrt} row={RowId} />
                        }
                    </>
                )

            }
        }
    ]
    return (
        <>
            <DashboardLayout>
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">View Appointments</h5>
                    <NavLink to="/dashboard/qms/appointments/add" ><button className="btn btn-white">Add New</button></NavLink>
                </div>
                <div className="table-responsive">
                <DataTable columns={columns} data={Data} pagination fixedHeader subHeader customStyles={tableCustomStyles}
                progressPending={Loading}
                progressComponent={<div className="loader-container">
                            <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                        </div>}
                    subHeaderComponent={
                        <input type="text" className="form-control w-25" name="search" value={search.search} onChange={HandleSearch}/>}/>
                </div>
                {deletePopupType != "" &&
                    <DeletePopupModal setDeletepopupType={setDeletepopupType} deletePopupType={deletePopupType} />
                }
            </DashboardLayout>
        </>
    )
}
export { AppointmentsList }
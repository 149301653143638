import React, { useState } from "react";
import { DashboardLayout, FormParser,PopupModal } from "../Components";
import { NavLink } from "react-router-dom";
import Masters from "../Components/masters.json"
import "./Pages.css"
import axiosConfig from "../Service/axiosConfig"
const SubDepartmentAdd = () => {
     const[popupType,setpopupType]=useState("")
     const[Error,SetError]=useState("")
    const MastersModal=Masters.SubDepartment
    const formSubmit=(Data)=>{
        console.log(Data)
        axiosConfig.post("/masters/subdepartments/",Data).then(res=>{
                   console.log(res)
                   setpopupType("success")
        }).catch(error=>{
            console.log(error)
            const formData=[]
                formData.push({subdepartment_code:error.response.data.subdepartment_code||["null"],
                    subdepartment_name:error.response.data.subdepartment_name||["null"],
                    department_id:error.response.data.department_id||["null"]})
                    console.log(formData[0])
            if (formData[0].subdepartment_code[0] === "This field must be unique.") {
                SetError("Subdepartment Code Already Exists")
            }
            if (formData[0].subdepartment_name[0] === "This field must be unique.") {
                SetError("Subdepartment Name Already Exists")
            }
            else {
                setpopupType("error")
            }
        })
      }
    return (
        <>
            <DashboardLayout>
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">Add Sub-Department</h5>
                    <NavLink to="/dashboard/masters/sub-departments" ><button className="btn btn-white">Manage</button></NavLink>
                </div>
                <FormParser modelObject={MastersModal} formSubmit={formSubmit} error={Error}/>
                {popupType != "" &&
                    <PopupModal popupType={popupType} Navigation="/dashboard/masters/sub-departments"/>
                }
            </DashboardLayout>
        </>
    )

}
export {SubDepartmentAdd}
import React, { useEffect, useState } from "react";
import { Layout, DeletePopupModal } from "../Components";
import { NavLink, useNavigate } from "react-router-dom";
import {MdDeleteOutline,MdOutlineEdit} from "react-icons/md"
import {AiOutlineEye} from "react-icons/ai"
import axiosConfig from "../Service/axiosConfig"
import DataTable from 'react-data-table-component';
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import tableCustomStyles from "../Components/PopupModal/tableCustomStyles";
const StateList = () => {
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("")
    const [deletePopupType, setDeletepopupType] = useState("")
    const [RowId, SetRowId] = useState()
    const[loading,setLoading]=useState(false)
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(20);
    const [search, SetSearch] = useState({
        search: ""
    })
    const[Datafilter,SetDatafilter]=useState([])
    let navigate = useNavigate();

    const [Data, SetData] = useState([])
    const GetData = async () => {
        setLoading(true)
        try {
            const responce = await axiosConfig.get(`/masters/states/?page=${currentPage}&page_size=${RowPerPage}`)
            SetData(responce.data.results)
            setLoading(false)
            setTotalRows(responce.data.count)
        }
        catch (error) {
            console.log(error)
        }

    }
    const HandleSearch=(e)=>{
        e.preventDefault()
        const name=e.target.name
        const value=e.target.value
        console.log(name,value)
        SetSearch({...search,[name]:value})
        axiosConfig.get(`/masters/states/?search=${value}`).then(res=>{
            SetData(res.data.results)
        }).catch(error=>{
           console.log(error)
        })
    }
    const handleSort = (column, direction) => {
        console.log(column.selector,direction)
         axiosConfig.get(`/masters/states/?ordering=${direction==="asc"?column.selector:-column.selector}`).then(res=>{
             SetData(res.data.results)
         }).catch(error=>{
            console.log(error)
         })
         setCurrentPage(1);
      };
    useEffect(() => {
        GetData();
    }, [currentPage,RowPerPage])

    const columns = [
        {
            name: "Id",
            selector: "id",
            sortable: true
        },
        {
            name: "Short Name",
            selector: "short_name",
            sortable: true
        },
        {
            name: "State Name",
            selector:"state_name",
            sortable: true
        },
        {
            name: "Country",
            selector:"country_name",
            sortable: true
        }, {
            name: "ACTIONS",
            selector: row => {
                const HandleEdit = async () => {
                    return navigate(`/dashboard/masters/states/${row.id}/edit`);
                }
                const DeleteAlert = () => {
                    SetRowId(row.id)
                    SetAlertDeletePopupType("success")
                }
                const CancelAlerrt = () => {
                    SetAlertDeletePopupType("error")
                }
                const HandleDelete = async (id) => {
                    await axiosConfig.delete(`masters/states/${id}/`).then(() => {
                        setDeletepopupType("success")
                        SetAlertDeletePopupType("error")
                        GetData()
                    }).catch((error) => {
                        setDeletepopupType("error")
                        SetAlertDeletePopupType("error")
                        console.log(error)
                    })
                }
                const HandleView=async(id)=>{
                    return navigate(`/dashboard/masters/states/${row.id}/edit`);
                }
                return (
                    <>
                        <div className="row" >
                            <div className="col-4">
                            <AiOutlineEye className="dashboard-tabe-data-actions"  onClick={HandleView}/>
                            </div>
                            <div className="col-4">
                                <MdOutlineEdit className="dashboard-tabe-data-actions"  onClick={HandleEdit}/>
                            </div>
                            <div className="col-4">
                                <MdDeleteOutline className="dashboard-tabe-data-actions" onClick={DeleteAlert}/>
                            </div>
                        </div>
                        {
                            AlertDeletePopupType === "success" &&
                            <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} HandleDelete={HandleDelete} CancelAlerrt={CancelAlerrt} row={RowId} />
                        }
                    </>
                )

            }
        }
    ]
    // const tableCustomStyles = {
    //     cells: {
    //         style: {
    //             justifyContent: "center"
    //         },
    //     },
    //     headCells: {
    //         style: {
    //             display: "flex",
    //             justifyContent: "center"
    //         },
    //     },
    // }
    return (
        <>
            <Layout>
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">View States</h5>
                    <NavLink to="/dashboard/masters/states/add" ><button className="btn btn-white">Add New</button></NavLink>
                </div>
                <div className="table-responsive">
                    <DataTable columns={columns} data={Data} pagination paginationTotalRows={totalRows}
                        paginationPerPage={RowPerPage}
                        paginationDefaultPage={currentPage}
                        onChangePage={(page) => setCurrentPage(page)}  
                        paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
                        paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
                        onChangeRowsPerPage={(RowsPerPageChange)=>setRowPerPage(RowsPerPageChange)}  
                        onSort={handleSort} 
                        paginationServer  fixedHeader subHeader customStyles={tableCustomStyles} progressPending={loading}
                    progressComponent={<div className="loader-container">
                    <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                </div>}
                   subHeaderComponent={
                   <input type="text" className="form-control w-25" name="search" value={search.search} onChange={HandleSearch}/>}/>
                </div>
                {deletePopupType != "" &&
                    <DeletePopupModal setDeletepopupType={setDeletepopupType} deletePopupType={deletePopupType} />
                }
            </Layout>
        </>
    )
}
export { StateList }
import React, { useEffect, useState } from "react";
import { DoctorRegestrationAdd } from "./DoctorregestrationAdd";
import { NavLink, useParams } from "react-router-dom";
import { Layout, PopupModal, DashboardLayout } from "../Components";
import axiosConfig from "../Service/axiosConfig";
import sidebarData from "../Components/DashboardLayout/sidebar";
import { MdOutlineAdd } from "react-icons/md"
import { RxCross2 } from "react-icons/rx"
import {BsArrowLeftShort} from "react-icons/bs"
const DoctorAvalibilty = () => {
    let { id } = useParams();
    const [timeData, setTimeData] = useState([]);
    const [week, setWeek] = useState([])
    const [popupType, setpopupType] = useState("");
    const [activeState, setActiveState] = useState("")
    const [StateData, setState] = useState({Sunday:[],Monday:[],Tuesday:[],Wednesday:[],Thursday:[],Friday:[],Saturday:[]})
    const [availability, setAvailability] = useState([])
    const [InputCount, setInputCount] = useState({})
    const sidebarValue = sidebarData.DoctorProfile
    const [ChildNavs, SetChildNavs] = useState("")
    const[Input,setInput]=useState({slot_time:""})
    let data = JSON.parse(localStorage.getItem("user_data"))
    var days = [{ value: "Sunday", label: "Sunday" }, { value: "Monday", label: "Monday" }, { value: "Tuesday", label: "Tuesday" }, { value: "Wednesday", label: "Wednesday" }, { value: "Thursday", label: "Thursday" }, { value: "Friday", label: "Friday" }, { value: "Saturday", label: "Saturday" }];
    const [DoctorAvailability, setDoctorAvalability] = useState({
        "doctor": data.doctor_id?data.doctor_id:id,
        "slot_time":Input.slot_time,
        "schedule": [

        ]
    })
    const HandleChange=(e)=>{
        const {name,value}=e.target
        setInput({...Input,[name]:value})
        setDoctorAvalability({...DoctorAvailability,[name]:value})
    }
    const sidebarValueadmin = {
        "DoctorProfile": [
            {
                "SubTitle": "Doctor Profile",
                "NavItems": [
                    { "label": "Doctor Info", "path": `/dashboard/doctors/${id}` },
                    { "label": "Specialites", "path": `/dashboard/profile/${id}/doctor_specialities` },
                    { "label": "Availability", "path": `/dashboard/users/${id}/doctor_availability` },
                    { "label": "Vacations", "path": `/dashboard/users/${id}/vacations` }
                ]
            }
        ],
    }
    const today = new Date();
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const dayIndex = today.getDay();
    const date = today.getDate();
    const daystring = daysOfWeek[dayIndex];
    const Handlechnage = (day) => {
        console.log(day)
        if (!week.includes(day)) {
            setWeek(prevData => [...prevData, day])
            setState(prevAvailability => ({
                ...prevAvailability,
                [day]: [...prevAvailability[day], { days: day, from_time: '', to_time: '' }], // Add the new schedule item to the schedule array
            }));

        }
        else if (week.includes(day)) {
            const existIndex = week.findIndex(find => find === day)
            days.forEach((responce,i)=>{
                let data=StateData
                const index=data[day].findIndex(find=>find.DayIndexs===i)
                data[day].splice(index,1)
                setState(data)
            })
            if (existIndex !== -1) {
                const upDate = [...week]
                const days=DoctorAvailability.schedule.findIndex(find=>find.days===day)
                let updatedDate=DoctorAvailability
                 updatedDate.schedule[days].timeslots=[];
                console.log(updatedDate)
                setDoctorAvalability(updatedDate)
                 upDate.splice(existIndex, 1);
                setWeek(upDate)
            }
        }
    }
    const Timechnage = (e, day, dayin,index) => {
        const { name, value } = e.target
        
        console.log(name,dayin,index,)
        const updatedData = { ...DoctorAvailability };
        if (updatedData.schedule[dayin]) {
          if (updatedData.schedule[dayin].timeslots[index]) {
            updatedData.schedule[dayin].timeslots[index][name] = value;
          } else {
            updatedData.schedule[dayin].timeslots.push({ [name]: value });
          }
        } else {
          updatedData.schedule[dayin] = { days: day, timeslots: [{ [name]: value }] };
        }
        setDoctorAvalability(updatedData);
    }
    const currentURL = window.location.href;
    const formSubmit = () => {
        axiosConfig.post(`/clinics/doctoravailability/`, DoctorAvailability).then(res => {
            setpopupType("success")
        }).catch(error => {
            console.log(error)
            setpopupType("error")
        })
    }


     const getAvailability = async () => {
         try {
             const responce = await axiosConfig.get(`clinics/doctoravailability/?doctor__clinic=${data.clinic_id ? data.clinic_id :""}&doctor=${data.doctor_id?data.doctor_id:id}`)
             setAvailability(responce.data.results)
             console.log(responce.data.results)
             let sample = {
                Sunday: [],
                Monday: [],
                Tuesday: [],
                Wednesday: [],
                Thursday: [],
                Friday: [],
                Saturday: []
              };
              let upDate={}
              responce.data.results.forEach(element => {
                const DayIndexs = days.findIndex(item => item.label === element.days);
                console.log(DayIndexs)
               setInput({slot_time:element.slot_time})
               upDate["slot_time"]=element.slot_time
                const timeslot = {
                    days:element.days,
                    from_time: element.from_time,
                    to_time: element.to_time,
                    DayIndexs: DayIndexs,
                };

                sample[element.days].push(timeslot)
                setWeek(prevWeek => {
                    const newWeek = new Set(prevWeek);
                      if (!newWeek.has(element.days)) {
                        newWeek.add(element.days);
                      }
                    return Array.from(newWeek);
                  });
            });
            const processedData = {
                doctor: data.doctor_id?data.doctor_id:id,
                slot_time:upDate.slot_time,
                schedule: Object.keys(sample).map(day => ({
                    days: day,
                    timeslots: sample[day]
                }))
            };
            setDoctorAvalability(processedData);
                setState(sample)
              
             
            
         }
         catch (error) {
             console.log(error)

         }

     }
     console.log(DoctorAvailability,StateData)
    useEffect(() => {
        SetChildNavs(localStorage.getItem("url"))
         getAvailability()
    }, [])
    const AddTimeInput = (day,index) => {
        setState(prevAvailability => ({
            ...prevAvailability,
            [day]: [...prevAvailability[day], { days: `${day}`, from_time: '', to_time: '' }], 
        }));
        setInputCount((prevInputCount) => ({ ...prevInputCount, [day]: (prevInputCount[day] || 0) + 1 }));
    };
    const AddTimeRemoveInput = (day,i,index) => {
        const updatedData=DoctorAvailability
        updatedData.schedule[i].timeslots.splice(index,1)
        console.log(updatedData)
        setDoctorAvalability(updatedData)
        setState(prevState => {
            const updatedStateData = { ...prevState };
            if (updatedStateData[day].length > 1) {
              updatedStateData[day].splice(-1, 1); 
              setInputCount(prevInputCount => ({ ...prevInputCount, [day]: prevInputCount[day] - 1 }));
            }
            return updatedStateData;
          });
    };
    const OnChildNavClicked = (path) => {
        SetChildNavs(path)
        localStorage.setItem("url", path)
    }

    return (
        <>
            <DashboardLayout>
                <div className="mainbar">
                    <div className="sidebar-filters px-2">
                    {data.user_type==="admin"&&<NavLink to="/dashboard/doctors/list" ><button type="submit" className="btn btn-primary"><BsArrowLeftShort />back to doctors</button></NavLink>}
                    {data.user_type==="staff"&&<NavLink to="/dashboard/doctors/list" ><button type="submit" className="btn btn-primary"><BsArrowLeftShort />back to doctors</button></NavLink>}
                        {
                            data.user_type==="doctor"&&
                            sidebarValue.map((childItem, index) => {
                                return (
                                    <div key={index}>
                                        <h5>{childItem.SubTitle}</h5>
                                        {
                                            childItem.NavItems.map((item, idx) => {
                                                return (
                                                    <div key={idx} className={ChildNavs == item.path ? "Configiration-list active" : "Configiration-list"}>
                                                        <NavLink to={item.path} className={ChildNavs == item.path ? "colorChange" : "NoColorChange"} onClick={() => OnChildNavClicked(item.path)}>
                                                            <span className="configiration-icon">{item.icon}</span>
                                                            <span className="list-name">{item.label}</span>
                                                        </NavLink>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            })
                        }
                          {
                            data.user_type==="admin"&&
                            sidebarValueadmin.DoctorProfile.map((childItem, index) => {
                                return (
                                    <div key={index}>
                                        <h5>{childItem.SubTitle}</h5>
                                        {
                                            childItem.NavItems.map((item, idx) => {
                                                return (
                                                    <div key={idx} className={ChildNavs == item.path ? "Configiration-list active" : "Configiration-list"}>
                                                        <NavLink to={item.path} className={ChildNavs == item.path ? "colorChange" : "NoColorChange"} onClick={() => OnChildNavClicked(item.path)}>
                                                            <span className="configiration-icon">{item.icon}</span>
                                                            <span className="list-name">{item.label}</span>
                                                        </NavLink>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            })
                        }
                         {
                            data.user_type==="staff"&&
                            sidebarValueadmin.DoctorProfile.map((childItem, index) => {
                                return (
                                    <div key={index}>
                                        <h5>{childItem.SubTitle}</h5>
                                        {
                                            childItem.NavItems.map((item, idx) => {
                                                return (
                                                    <div key={idx} className={ChildNavs == item.path ? "Configiration-list active" : "Configiration-list"}>
                                                        <NavLink to={item.path} className={ChildNavs == item.path ? "colorChange" : "NoColorChange"} onClick={() => OnChildNavClicked(item.path)}>
                                                            <span className="configiration-icon">{item.icon}</span>
                                                            <span className="list-name">{item.label}</span>
                                                        </NavLink>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="main-code">
                        <div className="dashboard-header">
                            <h5 className="dashboard-subtitle">Availability</h5>
                        </div>
                        {
                            days.map((res,i) => {
                                const dayData = availability.find(item => item.days === res.label) || {};
                                return (
                                    <div className="row my-2" key={res.value}>
                                        <div className="form-group col-2">
                                            <label className="form-label">{res.label}:</label>
                                        </div>
                                        <div className="form-group col-2">
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" id={res.label} checked={week.includes(res.label)} onChange={() => Handlechnage(res.label)} />
                                                <label className="form-check-label" htmlFor={res.label}>{week.includes(res.label) ? "Open" : "Closed"}</label>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            {
                                                StateData[res.label].map((State, index) => {
                                                    if (res.label === State.days) {
                                                        return (
                                                            <div>
                                                                <div className="d-flex align-items-center justify-content-around">
                                                                    <div className="form-group">
                                                                        <input type="time" className="form-control time-input" name="from_time" data-day={res.label} defaultValue={State.from_time} onChange={(e) => Timechnage(e, res.label, i,index)} required />
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <input type="time" className="form-control time-input" name="to_time" data-day={res.label} defaultValue={State.to_time}  onChange={(e) => Timechnage(e, res.label, i,index)} required />
                                                                    </div>
                                                                    <div>
                                                                        {index === 0 ? ( 
                                                                            <MdOutlineAdd onClick={() => AddTimeInput(res.label,index)} />
                                                                        ):<RxCross2 onClick={()=>AddTimeRemoveInput(res.label,i,index)}/>}

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        )
                                                    }
                                                })}
                                        </div>
                                    </div>
                                )
                            })
                        }
                        <div className="row mb-2">
                                <>
                                    <div className="col-2">
                                        <label>Slot Time:</label>
                                    </div>
                                    <div className="col-2 d-flex align-items-center">
                                        <input type="text" className="form-control" name="slot_time" value={Input.slot_time} onChange={HandleChange} />
                                        <span>Minutes</span>
                                    </div>
                                </>

                        </div>
                        <div >
                            <button type="submit" className="btn btn-primary" onClick={formSubmit}>Save</button>
                        </div>
                    </div>
                </div>
                {popupType != "" &&
                    <PopupModal popupType={popupType} Navigation="" />
                }
            </DashboardLayout >

        </>
    )
}
export { DoctorAvalibilty }
import React, { useState, useEffect } from "react";
import "./StaffLogin.css";
import axiosConfig from "../../Service/axiosConfig";
import { useNavigate, useLocation } from "react-router-dom";
import { MdOutlineEdit } from "react-icons/md";

const StaffLogin = () => {
    let navigate = useNavigate();
    let location = useLocation()
    const [visible, SetVisable] = useState(false);
    const [LoadingButton, setLoadingButton] = useState(false);
    const inputType = visible ? "text" : "password";
    const [Data, SetData] = useState({
        mobileno: "",
        password: "",
        otp: "",
        new_password: "",
        confirm_password: "",
    });
    const [error, SetError] = useState("");
    const [siteSettings, setSiteSettings] = useState({});
    const [LoginTitle, setLogintitle] = useState("Login your Account");
    const [Title, setTitle] = useState("Login");

    const InputHandleer = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        console.log(name, value);

        SetData({ ...Data, [name]: value });
    };

    const SubmitHandller = (e) => {
        e.preventDefault();
        const { mobileno, password, otp, new_password,
            confirm_password } = Data;

        if (location?.pathname === "/") {
            if (mobileno === "") {
                SetError(
                    <>
                        <img className="errorIcon" src={require('./images/LoginError.png')} alt="LoginError" />
                        Email is required
                    </>
                );
                return;
            }
            if (password === "") {
                SetError(
                    <>
                        <img className="errorIcon" src={require('./images/LoginError.png')} alt="LoginError" />
                        Password is required
                    </>
                );
                return;
            }
        } else if (location?.pathname === "/send_otp") {
            if (mobileno === "") {
                SetError(
                    <>
                        <img className="errorIcon" src={require('./images/LoginError.png')} alt="LoginError" />
                        Email is required
                    </>
                );
                return;
            }
        } else if (location?.pathname === "/verify_otp") {
            if (otp === "") {
                SetError(
                    <>
                        <img className="errorIcon" src={require('./images/LoginError.png')} alt="LoginError" />
                        OTP is required
                    </>
                );
                return;
            }
        }
        else if (location?.pathname === "/reset_password" || location?.pathname === "/set_password") {
            if (new_password === "") {
                SetError(
                    <>
                        <img className="errorIcon" src={require('./images/LoginError.png')} alt="LoginError" />
                        New Password is required
                    </>
                );
                return;
            }
            if (confirm_password === "") {
                SetError(
                    <>
                        <img className="errorIcon" src={require('./images/LoginError.png')} alt="LoginError" />
                        Confirm Password is required
                    </>
                );
                return;
            }
            if (new_password !== confirm_password) {
                SetError(
                    <>
                        <img className="errorIcon" src={require('./images/LoginError.png')} alt="LoginError" />
                        New password and confirm password do not match
                    </>
                );
                return;
            }
        }

        setLoadingButton(true);

        const token = localStorage.getItem('token');
        if (location?.pathname === "/send_otp") {
            axiosConfig.post("/stores/reset_request/", { email: Data.mobileno })
                .then((res) => {
                    navigate("/verify-otp");
                    setLoadingButton(false);
                    SetError("")
                    SetData({
                        mobileno: Data.mobileno,
                        password: "",
                        otp: "",
                        new_password: "",
                        confirm_password: ""
                    })
                })
                .catch((error) => {
                    console.error(error);
                    SetError(
                        <>
                            <img className="errorIcon" src={require('./images/LoginError.png')} alt="LoginError" />
                            {error.response?.data?.error || "An error occurred"}
                        </>
                    );
                    setLoadingButton(false);
                });
            return;
        }

        if (location?.pathname === "/verify-otp") {
            axiosConfig.post("/stores/otp_verifivation/", { otp: Data.otp, email: Data.mobileno })
                .then((res) => {
                    navigate("/reset_password");
                    setLoadingButton(false);
                    SetError("")
                })
                .catch((error) => {
                    console.error(error);
                    SetError(
                        <>
                            <img className="errorIcon" src={require('./images/LoginError.png')} alt="LoginError" />
                            {error.response?.data?.message || "An error occurred"}
                        </>
                    );
                    setLoadingButton(false);
                });
            return;
        }
        if (location?.pathname === "/reset_password") {
            axiosConfig.post("/stores/forgotpassword/", { otp: Data.otp, email: Data.mobileno, new_password: new_password, confirm_password: confirm_password })
                .then((res) => {
                    navigate("/");
                    setLoadingButton(false);
                    SetError("")
                    SetData({
                        mobileno: Data.mobileno,
                        password: "",
                        otp: "",
                        new_password: "",
                        confirm_password: ""
                    })
                })
                .catch((error) => {
                    console.error(error);
                    SetError(
                        <>
                            <img className="errorIcon" src={require('./images/LoginError.png')} alt="LoginError" />
                            {error.response?.data?.message || "An error occurred"}
                        </>
                    );
                    setLoadingButton(false);
                });
            return;
        }
        if (location?.pathname === "/set_password") {
            axiosConfig.post("/account/api/set_password/", { token: token, new_password: new_password, confirm_password: confirm_password })
                .then((res) => {
                    navigate("/");
                    setLoadingButton(false);
                    SetError("")
                    SetData({
                        mobileno: Data.mobileno,
                        password: "",
                        otp: "",
                        new_password: "",
                        confirm_password: ""
                    })
                })
                .catch((error) => {
                    console.error(error);
                    SetError(
                        <>
                            <img className="errorIcon" src={require('./images/LoginError.png')} alt="LoginError" />
                            {error.response?.data?.message || "An error occurred"}
                        </>
                    );
                    setLoadingButton(false);
                });
            return;
        }
        // Default case for login
        const formData = new FormData();
        formData.append("mobileno", mobileno);
        formData.append("password", password);

        axiosConfig.post("/accounts/api/login/", formData)
            .then((res) => {
                localStorage.setItem("token", res.data.token);
                console.log(res, "res");
                localStorage.setItem("user_data", JSON.stringify(res.data.user));
                console.log(res.data.user_account?.store, "store id")
                if (res.data.user_account?.store) {
                    localStorage.setItem('Store', JSON.stringify(res.data.user_account.store));
                }
                return navigate("/dashboard/orders");
            })
            .catch((e) => {
                console.error(e)
                SetError(
                    <>
                        <img className="errorIcon" src={require('./images/LoginError.png')} alt="LoginError" />
                        {e.response.data.error || e.response.data.detail}
                    </>
                );
                setLoadingButton(false);
            });
    };

    const GetSiteSettings = async () => {
        const site_response = await axiosConfig.get("/sitedata/site/");
        console.log("site data", site_response.data.results);
        if (site_response.data.results.length > 0) {
            setSiteSettings(site_response.data.results[0]);
            document.title = site_response.data.results[0].title;
            const favicon =
                document.querySelector("link[rel='icon']") ||
                document.createElement("link");
            favicon.rel = "icon";
            favicon.href =
                JSON.parse(localStorage.getItem("site_data"))?.id ===
                    site_response.data.results[0]?.id
                    ? JSON.parse(localStorage.getItem("site_data")).favicon.image
                    : site_response.data.results[0].favicon.image;
            document.head.appendChild(favicon);
            localStorage.setItem(
                "site_data",
                JSON.stringify(site_response.data.results[0])
            );
        }
    };
    useEffect(() => {
        GetSiteSettings();
    }, []);

    useEffect(() => {
        const pathMapping = {
            "/send_otp": { loginTitle: "Forgot Password", title: "Send OTP" },
            "/verify-otp": { loginTitle: "Verify OTP", title: "Verify OTP" },
            "/reset_password": { loginTitle: "Reset Password", title: "Save" },
            "/": { loginTitle: "Login your Account", title: "Login" },
            "/set_password": { loginTitle: "Set New Password", title: "Save" }
        };

        const currentPath = pathMapping[location.pathname];

        if (currentPath) {
            setLogintitle(currentPath.loginTitle);
            setTitle(currentPath.title);
            SetError("");
        }
    }, [location]);


    return (
        <>
            <div className="login-main">
                <div className="container">
                    <div className="login-container">
                        <div className="login-c-left">
                            <div className="login-image text-center">
                                <img
                                    className="vector1"
                                    src={
                                        JSON.parse(localStorage.getItem("site_data"))?.id === siteSettings?.id ?
                                            JSON.parse(localStorage.getItem("site_data"))?.logo?.image
                                            : siteSettings.logo ? siteSettings.logo.image
                                                : require("./images/opotologo.png")
                                    }
                                    alt="Logo"
                                />
                                <img
                                    className="vector2"
                                    src={
                                        JSON.parse(localStorage.getItem("site_data"))?.id === siteSettings?.id ?
                                            JSON.parse(localStorage.getItem("site_data"))?.banner?.image
                                            : siteSettings.banner
                                                ? siteSettings.banner.image
                                                : require("./images/banner.png")
                                    }
                                    alt="banner"
                                />
                            </div>
                        </div>
                        <div className="login-c-right">
                            <div className="login-form">
                                <div className="form-container">
                                    <h2>{LoginTitle}</h2>
                                    <div className="Loginerror">
                                        <p>{error}</p>
                                    </div>
                                    <form>
                                        {LoginTitle === "Login your Account" &&
                                            <>
                                                <div className="form-group">
                                                    <label>Email or Phone:</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Email Address or Phone Number"
                                                        name="mobileno"
                                                        value={Data.mobileno}
                                                        onChange={InputHandleer}
                                                    />
                                                </div>

                                                <div className="form-group has-icon">
                                                    <label>Password:</label>
                                                    <input
                                                        type={inputType}
                                                        className="form-control"
                                                        placeholder="Enter password"
                                                        name="password"
                                                        value={Data.password}
                                                        onChange={InputHandleer}
                                                    />
                                                    {visible ? (
                                                        <img
                                                            className="inputicon1"
                                                            src={require("../../Pages/images/show.png")}
                                                            alt="password"
                                                            onClick={() => SetVisable(false)}
                                                        />
                                                    ) : (
                                                        <img
                                                            className="inputicon"
                                                            src={require("./images/eye.jpg")}
                                                            alt="password"
                                                            onClick={() => SetVisable(true)}
                                                        />
                                                    )}
                                                </div>
                                            </>
                                        }
                                        {(LoginTitle === "Reset Password" || LoginTitle === "Set New Password") && (
                                            <>
                                                <div className="form-group">
                                                    <label>New Password:</label>
                                                    <input type="text" className="form-control" placeholder="" name="new_password" value={Data.new_password} onChange={InputHandleer} />
                                                </div>
                                                <div className="form-group has-icon">
                                                    <label>Confirm Password:</label>
                                                    <input type={inputType} className="form-control" placeholder="" name="confirm_password" value={Data.confirm_password} onChange={InputHandleer} />
                                                    {visible ? (
                                                        <img className="inputicon1" src={require('../../Pages/images/show.png')} alt="password" onClick={() => SetVisable(false)} />
                                                    ) : (
                                                        <img className="inputicon" src={require('./images/eye.jpg')} alt="password" onClick={() => SetVisable(true)} />
                                                    )}
                                                </div>
                                            </>
                                        )}

                                        {LoginTitle === "Forgot Password" &&
                                            <div className="form-group">
                                                <label>Email:</label>
                                                <input type="text" className="form-control" placeholder="Email Address" name="mobileno" value={Data.mobileno} onChange={InputHandleer} />
                                            </div>
                                        }
                                        {LoginTitle === "Verify OTP" && <>
                                            <div className="form-group position-relative">
                                                <label>Email:</label>
                                                <div className="position-relative">
                                                    <input type="text" className="form-control" placeholder="Email Address" name="mobileno" value={Data.mobileno} onChange={InputHandleer} disabled />
                                                    <span className="edit-icon" onClick={() => navigate("/send_otp")} >
                                                        <MdOutlineEdit />
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label>OTP:</label>
                                                <input type="text" className="form-control" name="otp" value={Data.otp} onChange={InputHandleer} />
                                            </div>
                                        </>}

                                        <div className="d-grid">
                                            <button type="submit" className="btn btn-primary btn-block" onKeyPress={(e) => e.key === "ENTER" ? SubmitHandller : null} onClick={SubmitHandller} disabled={LoadingButton}>
                                                {LoadingButton ? <div>
                                                    <img src={require('../FormParser/images/loader.gif')} alt="Loading..." className="loader-image" />
                                                </div> : Title}
                                            </button>
                                        </div>

                                        {LoginTitle === "Login your Account" &&
                                            <div className="form-group d-flex justify-content-end" onClick={() => { navigate("/send_otp") }}>
                                                <label className="cursor-pointer">Forgot Password</label>
                                            </div>
                                        }
                                    </form>
                                    <div className="f-copy text-center">
                                        {siteSettings.copy_right && (
                                            <p className="copyright">
                                                &copy; {JSON.parse(siteSettings.copy_right).website}{" "}
                                                India Private Limited, 2016-2023
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export { StaffLogin };

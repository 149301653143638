import React, { useEffect, useMemo,useRef, useState } from "react";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { DashboardLayout } from "../Components";
import 'react-big-calendar/lib/sass/styles.scss';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import Modal from 'react-bootstrap/Modal';
import axiosConfig from "../Service/axiosConfig"
import Select from 'react-select';
import { CalanderSlot } from "./CalenderSlot";

const Calander = () => {
  const today = new Date();
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const dayIndex = today.getDay();
  const scrollContainerRef = useRef()
  const date = today.getDate();
 const [days,setDays]=useState(daysOfWeek[dayIndex])
  const [lgShow, setLgShow] = useState(false);
  const [Services, setService] = useState([]);
  const [IDDocotr, setIDDoctor] = useState([]);
  const[Doctors,setDoctors]=useState([])
  const [IDApp, setIDApp] = useState([]);
  const [UserData, SetUserData] = useState({});
  const [isRefreshed, setIsRefreshed] = useState(false);
  let data = localStorage.getItem("user_data")
  const NewDate = (date) => {
    const currentDate = date ? date : new Date();

    const getDay = currentDate.getDate().toString().padStart(2, '0');
    const getMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const getYear = currentDate.getFullYear();

    const formattedDate = `${getYear}-${getMonth}-${getDay}`;
    return formattedDate;

  };
  const [filterValue, setFilterValues] = useState({
    FormData: NewDate(new Date()),
    ToDate: NewDate(new Date()),
    clinics: JSON.parse(data) ? JSON.parse(data).clinic_id : "",
    Doctor: JSON.parse(data) ? JSON.parse(data).doctor_id : "",
    services: "",
    speciality: ""
  });


  const [FullDate, setFullDate] = useState(NewDate());

  const handleNextDayClick = (e,buttonType) => {
    const nextDate = new Date(FullDate);
    if (buttonType === 'Next') {
      nextDate.setDate(nextDate.getDate() + 1);
      const dayIndex = nextDate.getDay();
      const daystring = daysOfWeek[dayIndex];
      GetDoctorsAvalibilty(daystring) 
      setFullDate(NewDate(nextDate));
      setFilterValues({ FormData: NewDate(nextDate), ToDate: NewDate(nextDate) })
      appointmentData()
    }
    if (buttonType === 'Prev') {
      nextDate.setDate(nextDate.getDate() - 1);
      const dayIndex = nextDate.getDay();
      const daystring = daysOfWeek[dayIndex];
      GetDoctorsAvalibilty(daystring) 
      setFullDate(NewDate(nextDate));
      setFilterValues({ FormData: NewDate(nextDate), ToDate: NewDate(nextDate) })
      appointmentData()

    }
     else if (buttonType === 'date') {
        const{name,value}=e.target
       setFullDate(value);
       const nextDate = new Date(value);
       const dayIndex = nextDate.getDay();
       const daystring = daysOfWeek[dayIndex];
       GetDoctorsAvalibilty(daystring) 
       setFilterValues({ FormData: value, ToDate: value })
       appointmentData();
    }

  
  };
  const handleRefresh=()=>{
    const nextDate = new Date();
   setFilterValues({FormData: NewDate(nextDate),
    ToDate: NewDate(nextDate),
    clinics:"",
    Doctor: "",
    services: "",
    speciality: ""
  })
  setFullDate(NewDate(nextDate))
  setIsRefreshed(true)
  }
  const [specialities, setSpacalitys] = useState([]);
  const [ClinicsData, setClinicData] = useState([]);
  const [DoctorsData, setDoctorsData] = useState([]);
  const [ServiceFilter, setServiceFilter] = useState();
  const [AppData, setAppData] = useState([]);
  const [ResourceMap, setResourcesMap] = useState([])
  const [availabilityTime, setavailabilityTime] = useState([])
  const [doctoravailability, setDoctorAvalibilty] = useState([])
  const [doctorSlots, setdoctorSlots] = useState([])
  const [calendarEvent, setCalendarEvent] = useState([]);
  const resourceMap = []
  const events = []
  const HandleSelcetfilter = (Selected, name) => {
    setFilterValues({ ...filterValue, [name]: Selected })
  }
  const HandleChange = (event) => {
    const { name, value } = event.target;
    setFilterValues({ ...filterValue, [name]: value })

  };
  const GetServicesData = async () => {
    try {
      const responce = await axiosConfig.get(`/masters/services/`)
      setService(responce.data.results)
    }
    catch (error) {
      console.log(error)
    }

  }
  const GetSpacalitysData = async () => {
    try {
      const responce = await axiosConfig.get(`/masters/specialities/`)
      setSpacalitys(responce.data.results)
    }
    catch (error) {
      console.log(error)
    }

  }
  const GetClinicsData = async () => {
    try {
      const responce = await axiosConfig.get(`/clinics/clinics/`)
      setClinicData(responce.data.results)
    }
    catch (error) {
      console.log(error)
    }

  }
  const GetDoctorsData = async () => {
    try {
      const responce = await axiosConfig.get(`/clinics/doctors/?clinic=${filterValue.clinics?filterValue.clinics:""}&id=${filterValue.Doctor?filterValue.Doctor:""}`)
      setDoctorsData(responce.data.results)
      const nextDate = new Date(FullDate);
      const dayIndex = nextDate.getDay();
      const daystring = daysOfWeek[dayIndex];
      GetDoctorsAvalibilty(daystring) 
      appointmentData();
    }
    catch (error) {
      console.log(error)
    }

  }
  const GetDoctorsFilters = async () => {
    try {
      const responce = await axiosConfig.get(`/clinics/doctors/`)
      setDoctors(responce.data.results)
    }
    catch (error) {
      console.log(error)
    }

  }
  const GetDoctorsAvalibilty = async (daystring) => {
    try {
      const responce = await axiosConfig.get(`/clinics/doctoravailability/?doctor=${filterValue.Doctor?filterValue.Doctor:""}&days=${daystring?daystring:days}`)
      console.log(responce)
      setDoctorAvalibilty(responce.data.results)
      FormatTime(responce.data.results)
      let Id_doctor = []
      responce.data.results.forEach(async (doc) => {
        Id_doctor.push(doc.doctor)
      })
      setIDDoctor(Id_doctor)
    }
    catch (error) {
      console.log(error)
    }
  }
  const appointmentData = async () => {
    try {
      const respo = await axiosConfig.get(`/clinics/appointment/?clinic=${filterValue.clinics?filterValue.clinics:""}&speciality=&doctor=${filterValue.Doctor?filterValue.Doctor:""}&service=&appointment_start_datetime_after=${FullDate}&appointment_start_datetime_before=${FullDate}`)
      console.log(respo)
      let data = []
      let appId = []
      respo.data.results.forEach(res => {
        appId.push(res.doctor)
        const datetime = new Date(res.appointment_start_datetime);

        let hours = datetime.getHours();
        let minutes = datetime.getMinutes();

        const period = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12;
        const formattedTime = `${hours}:${minutes.toString().padStart(2, '0')} ${period}`;
        const enddatetime = new Date(res.appointment_end_datetime);

        let endhours = enddatetime.getHours();
        let endminutes = enddatetime.getMinutes();

        const endperiod = endhours >= 12 ? 'PM' : 'AM';
        endhours = endhours % 12 || 12;
        const EndformattedTime = `${endhours}:${endminutes.toString().padStart(2, '0')} ${endperiod}`;
        console.log({ doctor: res.doctor, time: formattedTime + " to " + EndformattedTime })
        data.push({id:res.id, doctor: res.doctor, time: formattedTime + " to " + EndformattedTime })
      })
      setavailabilityTime(data)
      setIDApp(appId)
    }
    catch (error) {

    }
  }
  const FormatTime = (res) => {
    let data = []
    res.forEach(time => {
      const timeString = time.from_time;
      let [hours, minutes] = timeString.split(":");
      let period = "AM";
      if (parseFloat(hours) > 12) {
        hours = parseFloat(hours) - 12
        period = "PM";
        if (parseFloat(hours) < 10) {
          hours = '0' + hours
        }
      }
      else if (parseFloat(hours) < 12) {
        hours = parseFloat(hours)
        if (parseFloat(hours) < 10) {
          hours = '0' + hours
        }
      }
      else if (parseInt(hours) === 0) {
        hours = "12";
      }
      const formattedTime = `${hours}:${minutes} ${period}`;
      const endtime = time.to_time;
      let [endhours, endminutes] = endtime.split(":");
      let endperiod = "AM";
      if (parseFloat(endhours) > 12) {
        endhours = parseFloat(endhours) - 12
        endperiod = "PM";
        if (parseFloat(endhours) < 10) {
          endhours = '0' + endhours
        }
      }
      else if (parseFloat(endhours) < 12) {
        endhours = parseFloat(endhours)
        if (parseFloat(endhours) < 10) {
          endhours = '0' + endhours
        }
      }
      else if (parseInt(endhours) === 0) {
        endhours = "12";
      }
      const endformattedTime = `${endhours}:${endminutes} ${endperiod}`;
      data.push({slot_time:time.slot_time, doctor: time.doctor, time: `${formattedTime} to ${endformattedTime}` })

    })
    console.log(data)
    setdoctorSlots(data)
  }
  
  useEffect(() => {
    NewDate();
    GetClinicsData()
    GetServicesData()
    GetSpacalitysData()
    GetDoctorsAvalibilty();
    GetDoctorsData();
    appointmentData();
    GetDoctorsFilters();
    SetUserData(JSON.parse(data))
    setIsRefreshed(false);
  }, [isRefreshed])
  const timeBlocks= [
    "00:00 AM",
    "01:00 AM",
    "02:00 AM",
    "03:00 AM",
    "04:00 AM",
    "05:00 AM",
    "06:00 AM",
    "07:00 AM",
    "08:00 AM",
    "09:00 AM",
    "10:00 AM",
    "11:00 AM",
    "12:00 PM",
    "01:00 PM",
    "02:00 PM",
    "03:00 PM",
    "04:00 PM",
    "05:00 PM",
    "06:00 PM",
    "07:00 PM",
    "08:00 PM",
    "09:00 PM",
    "10:00 PM",
    "11:00 PM",
    "12:00 AM"
  ];
  return (
    <>
      <DashboardLayout>
        <div className="mainbar">
          <div className="sidebar-filters1">
            {UserData.user_type === "admin" &&
              <>
                <div className="form-group">
                  <label className="form-label">Clinics:</label>
                  <Select placeholder="Clinics" options={ClinicsData.map((res) => (
                    { "value": res.id, "label": res.clinic_name }))} onChange={(selectedOption) => HandleSelcetfilter(selectedOption.value, "clinics")} />
                </div>
                <div className="form-group">
                  <label className="form-label">Doctors:</label>
                  <Select placeholder="Doctors" options={Doctors.map((res) => (
                    { "value": res.id, "label": res.name }))}  onChange={(selectedOption) => HandleSelcetfilter(selectedOption.value, "Doctor")} />
                </div>
                <div className="form-group">
                  <label className="form-label">Services:</label>
                  <Select placeholder="services" options={Services.map((res) => (
                    { "value": res.id, "label": res.name }))} onChange={(selectedOption) => HandleSelcetfilter(selectedOption.value, "services")} />
                </div>
                <div className="form-group">
                  <label className="form-label">Specialities:</label>
                  <Select placeholder="Specialities" options={specialities.map((res) => (
                    { "value": res.id, "label": res.name }))} onChange={(selectedOption) => HandleSelcetfilter(selectedOption.value, "speciality")} />
                </div>
              </>}
            {UserData.user_type === "staff" &&
              <>
                <div className="form-group">
                  <label className="form-label">Doctors:</label>
                  <Select placeholder="Doctors" options={Doctors.map((res) => (
                    { "value": res.id, "label": res.name }))}  onChange={(selectedOption) => HandleSelcetfilter(selectedOption.value, "Doctor")} />
                </div>
                <div className="form-group">
                  <label className="form-label">Services:</label>
                  <Select placeholder="services" options={Services.map((res) => (
                    { "value": res.id, "label": res.name }))} onChange={(selectedOption) => HandleSelcetfilter(selectedOption.value, "services")} />
                </div>
                <div className="form-group">
                  <label className="form-label">Specialities:</label>
                  <Select placeholder="Specialities" options={specialities.map((res) => (
                    { "value": res.id, "label": res.name }))} onChange={(selectedOption) => HandleSelcetfilter(selectedOption.value, "speciality")} />
                </div></>}
            <button type="submit" className="btn btn-primary  btn btn-block" onClick={GetDoctorsData}>Submit</button>
          </div>
          <div className="main-code">
            <div className="calender-component">
              <div className="cal-header">
                <div className="selected-date">{FullDate}</div>
                <div className="date-selection">
                  <button type="button" className="btn btn-default" onClick={(e) => handleNextDayClick(e,"Prev")}>Prev</button>
                  <input type="date" value={filterValue.FormData} className="form-control" onChange={(e) => handleNextDayClick(e,"date")}/>
                  <button type="button" className="btn btn-default" onClick={(e) => handleNextDayClick(e,"Next")}>Next</button>
                </div>
                <div className="btn-ref">
                  <button className="btn btn-default" onClick={handleRefresh}>Refresh</button>
                </div>
              </div>
              <div className="calender-main-container">
                <div className="calender-vertical-layout">
                  <div className="calender-doctor">
                    {
                      DoctorsData.map((doctoredata, idx) => {
                        return (
                          <div className="calender-vertical-item calender-head" key={idx}>
                            <div className="cal-con-doctors">
                              <div className="cal-con-body">
                                <p className="doctor-title"><img src={doctoredata.image} width="100" className="doctor-dp" />{doctoredata.name}</p>
                                <p>Speciality</p>
                                {
                                  doctorSlots&&
                              doctorSlots.map((avl, i) => {
                                return (
                                  <div>
                                    {avl.doctor === doctoredata.id && <div className="cal-con-availability">
                                      <div className="cal-con-body">
                                        <p>{avl.time}</p>
                                      </div>
                                    </div>}
                                  </div>
                                )
                              })
                            }
                             {
                              availabilityTime&&
                              availabilityTime.map((avl, i) => {
                                return (
                                  <div>
                                    {avl.doctor === doctoredata.id && <div className="cal-con-availability">
                                      <div className="cal-con-body">
                                        <p>{avl.time}</p>
                                      </div>
                                    </div>}
                                  </div>
                                )
                              })
                            }
                            
                              </div>
                            </div>
                            <div className="cal-con-blocks">
                              <div className="cal-con-body">
                                <div className="calender-vertical-time-blocks">
                                  {console.log(availabilityTime, doctorSlots,DoctorsData, "aaaaa")}
                                  {timeBlocks.map((tblock, i) => {
                                    return (
                                      <div class="cal-ver-itm">
                                        {idx == 0 && <p className="cal-block-itm">{tblock}</p>}
                                        <CalanderSlot slot_time={10} timeblock={tblock} availability_list={availabilityTime} DoctorsData={doctoredata} doctorSlots={doctorSlots} is_popup="success" setIsRefreshed={handleRefresh} />
                                      </div>
                                    )
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DashboardLayout>

    </>
  )
}
export { Calander }
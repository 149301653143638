import React, { useEffect, useState } from "react";
import { DashboardLayout, DeletePopupModal } from "../Components";
import { NavLink, useNavigate } from "react-router-dom";
import axiosConfig from "../Service/axiosConfig"
import DataTable from 'react-data-table-component';
import { MdDeleteOutline, MdOutlineEdit, MdOutlineAssignment } from "react-icons/md"
import { AiOutlineEye } from "react-icons/ai"
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import tableCustomStyles from "../Components/PopupModal/tableCustomStyles";
const ClicnicCreateList = () => {
    const [RowId, SetRowId] = useState()
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("")
    const [deletePopupType, setDeletepopupType] = useState("")
    const [loading, setLoading] = useState(false)
    let navigate = useNavigate();
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(20);
    const [search, SetSearch] = useState({
        search: ""
    })
    const [Datafilter, SetDatafilter] = useState([])
    const [Data, SetData] = useState([])
    const GetData = async () => {
        setLoading(true)
        try {
            const responce = await axiosConfig.get(`/clinics/clinics/?page=${currentPage}&page_size=${RowPerPage}`)
            SetData(responce.data.results)
            SetDatafilter(responce.data.results)
            setLoading(false)
            setTotalRows(responce.data.count)
        }
        catch (error) {
            console.log(error)
        }

    }
    useEffect(() => {
        GetData();
    }, [currentPage,RowPerPage])

    const HandleSearch = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
         console.log(name, value)
         axiosConfig.get(`/clinics/clinics/?search=${value}&page=${currentPage}&page_size=${RowPerPage}`).then(res=>{
             SetData(res.data.results)
         }).catch(error=>{
            console.log(error)
         })
         SetSearch({ ...search, [name]: value })
        
    }
    const handleSort = (column, direction) => {
        console.log(column.selector,direction)
         axiosConfig.get(`/clinics/clinics/?ordering=${direction==="asc"?column.selector:`-${column.selector}`}&page=${currentPage}&page_size=${RowPerPage}`).then(res=>{
             SetData(res.data.results)
         }).catch(error=>{
            console.log(error)
         })
         setCurrentPage(1);
      };

    const columns = [
        {
            name: "Id",
            selector: "id",
            sortable: true
        },
        {
            name: "Clinic Name",
            selector:"clinic_name",
            sortable: true,
        },
        {
            name: "Phone Number",
            selector:"mobile_number",
            sortable: true,
        },
        {
            name: "Registration Number",
            selector:"registration_number",
            sortable: true
        },
        {
            name: "Email",
            selector:"email",
            sortable: true
        },
        {
            name: "ACTIONS",
            selector: row => {
                const HandleEdit = async () => {
                    return navigate(`/dashboard/clinics/${row.id}/edit`);

                }
                const DeleteAlert = () => {
                    SetRowId(row.id)
                    SetAlertDeletePopupType("success")
                }
                const CancelAlerrt = () => {
                    SetAlertDeletePopupType("error")
                }
                const HandleDelete = async (id) => {
                    await axiosConfig.delete(`/clinics/clinics/${id}/`).then(() => {
                        setDeletepopupType("success")
                        SetAlertDeletePopupType("error")
                        GetData()
                    }).catch((error) => {
                        setDeletepopupType("error")
                        SetAlertDeletePopupType("error")
                        console.log(error)
                    })
                }
                const  Handlechange=(e)=>{
                    const{name,value}=e.target
                    if(value==="0"){
                        navigate(`/dashboard/clinics/${row.id}/edit`)
                        localStorage.setItem("url",`/dashboard/clinics/${row.id}/edit`)
                    }
                    if(value==="1"){
                        navigate(`/dashboard/clinics/${row.id}/info`)
                        localStorage.setItem("url",`/dashboard/clinics/${row.id}/info`)
                    }
                    if(value==="2"){
                        navigate(`/dashboard/clinics/${row.id}/address`)
                        localStorage.setItem("url",`/dashboard/clinics/${row.id}/address`)
                    }
                    if(value==="3"){
                        navigate(`/dashboard/clinics/${row.id}/specialities`)
                        localStorage.setItem("url",`/dashboard/clinics/${row.id}/specialities`)
                    }
                    if(value==="4"){
                        navigate(`/dashboard/clinic/${row.id}/gallery`)
                        localStorage.setItem("url",`/dashboard/clinic/${row.id}/gallery`)
                    }
                    if(value==="5"){
                        navigate(`/dashboard/clinics/${row.id}/clinic_availability`)
                        localStorage.setItem("url",`/dashboard/clinics/${row.id}/clinic_availability`)
                    }
                    if(value==="6"){
                        navigate(`/dashboard/clinics/${row.id}/clinic_vacations`)
                        localStorage.setItem("url",`/dashboard/clinics/${row.id}/clinic_vacations`)
                    }

                  }
                return (
                    <>
                        <div className="row align-items-center">
                            <div className="col-2">
                            <MdDeleteOutline className="dashboard-tabe-data-actions" onClick={DeleteAlert} />
                            </div>
                            <div className="col-10">
                                <select className="form-select" onChange={Handlechange} >
                                    <option value="">SELECT</option>
                                    <option value="0">Clinc Ino</option>
                                    <option value="3">Specialities</option>
                                    <option value="4">ClinicGallery</option>
                                    <option value="5">Availability</option>
                                    <option value="6">Vacations</option>
                                </select>
                            </div>
                        </div>
                        {
                            AlertDeletePopupType === "success" &&
                            <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} HandleDelete={HandleDelete} CancelAlerrt={CancelAlerrt} row={RowId} />
                        }
                    </>
                )

            }
        }
    ]
    return (
        <>
            <DashboardLayout>
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">View Clinic List Form</h5>
                    <NavLink to="/dashboard/clinics/add" ><button className="btn btn-white">Add New</button></NavLink>
                </div>
                <div className="table-responsive">
                    <DataTable columns={columns} data={Data} pagination paginationTotalRows={totalRows}
                        paginationPerPage={RowPerPage}
                        paginationDefaultPage={currentPage}
                        onChangePage={(page) => setCurrentPage(page)}  
                        paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
                        paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
                        onChangeRowsPerPage={(RowsPerPageChange)=>setRowPerPage(RowsPerPageChange)}  
                        onSort={handleSort} 
                        paginationServer  fixedHeader progressPending={loading}
                        progressComponent={<div className="loader-container">
                            <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                        </div>}
                        customStyles={tableCustomStyles} subHeader
                        subHeaderComponent={
                            <input type="text" className="form-control w-25" name="search" value={search.search} onChange={HandleSearch} />} />
                </div>
                {deletePopupType != "" &&
                    <DeletePopupModal setDeletepopupType={setDeletepopupType} deletePopupType={deletePopupType} />
                }
            </DashboardLayout>
        </>
    )
}
export { ClicnicCreateList }
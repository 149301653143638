import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import SlotRegetration from "./SlotRegestration"
const CreateAppointment=({TimeSlot,Appointment,setAppointment,doctor,setIsRefreshed})=>{
    const [showSuccess, setSuccessShow] = useState(false);
    const handleSuccessClose = () =>{
        setSuccessShow(false);
        setAppointment("")
    }
    const GetData = () => {
        if(Appointment=="success"){
            setSuccessShow(true);
        }else{
            setSuccessShow(false);
        }
    }
    useEffect(() => {
        GetData();
    }, [Appointment])
    return(
        <div>
        <Modal
     size="lg"
     show={showSuccess}
     onHide={handleSuccessClose}
     aria-labelledby="example-modal-sizes-title-lg"
   >
     <Modal.Header closeButton>
       <Modal.Title id="example-modal-sizes-title-lg">
         Appointment
       </Modal.Title>
     </Modal.Header>
     <Modal.Body>
       <SlotRegetration slotTime={TimeSlot} doctor={doctor} handleSuccessfalse={handleSuccessClose} setIsRefreshed={setIsRefreshed}/>
     </Modal.Body>
   </Modal>
     </div> 
    )
}
export {CreateAppointment}
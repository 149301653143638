import React, { useEffect, useState } from "react";
import { DashboardLayout, FormParser, PopupModal } from "../Components";
import { NavLink, useParams } from "react-router-dom";
import Masters from "../Components/masters.json"
import "./Pages.css"
import axiosConfig from "../Service/axiosConfig"
import sidebarData from "../Components/DashboardLayout/sidebar";
import {IoIosPeople} from "react-icons/io"
import {BsArrowLeftShort} from "react-icons/bs"
const ClinicsAdminEdit = () => {
    const [Error, SetError] = useState("")
    const [popupType, setpopupType] = useState("")
    const MastersModal = Masters.ClinicsEdit
    const Sidebaravalue=sidebarData.clinicProfile
    let user_data=JSON.parse(localStorage.getItem("user_data"))

    const[ChildNavs,SetChildNavs]=useState("")
    const formSubmit = (Data) => {
        const formData = new FormData();
        formData.append("clinic_name",Data.clinic_name)
        formData.append("email",Data.email)
        formData.append( "mobileno",Data.mobileno)
        formData.append("registration_number",Data.registration_number)
        formData.append( "about",Data.about)
        formData.append( "first_name",Data.first_name)
        formData.append( "last_name",Data.last_name)
        formData.append( "password",Data.password)
        if(typeof(Data.logo) == "object"){
            formData.append("logo",Data.logo)
        }
        Data.facilities.forEach(res=>{
            formData.append( "facilities",res)
        })
        formData.append( "address",Data.address)
        formData.append("pincode",Data.pincode)
        formData.append( "city",Data.city)
        formData.append("gps_coordinates",Data.gps_coordinates)
        
        axiosConfig.defaults.headers['Content-Type'] = 'multipart/form-data'; 
        axiosConfig.patch(`/clinics/clinics/${user_data.clinic_id ? user_data.clinic_id : id}/`, formData).then(res => {
            console.log(res)
            setpopupType("success")
        }).catch(error => {
            console.log(error)
            let ErrorData = []
            ErrorData.push({
                code: error.response.data.code || ["null"],
                name: error.response.data.name || ["null"],
            })
            console.log(ErrorData[0])
            if (ErrorData[0].code[0] === "This field must be unique.") {
                SetError("Code Already Exists")
            }
            if (ErrorData[0].name[0] === "This field must be unique.") {
                SetError("Name Already Exists")
            }
            setpopupType("error")
        })
    }
    let { id } = useParams();
    let clinics= {"clinicProfile": [
        {
            "SubTitle":"Clinics",
            "NavItems":[
                {"label":"Clinic","path":`/dashboard/clinics/${id}/edit`,"icon":<IoIosPeople fontSize='small'/>},
                {"label":"Clinics Specialites","path":`/dashboard/clinics/${id}/specialities`,"icon":<IoIosPeople fontSize='small'/>},
                {"label":"Clinics Availability","path":`/dashboard/clinics/${id}/clinic_availability`,"icon":<IoIosPeople fontSize='small'/>},
                {"label":"Clinics Gallery","path":`/dashboard/clinic/${id}/gallery`,"icon":<IoIosPeople fontSize='small'/>},
                {"label":"Clinics Vacations","path":`/dashboard/clinics/${id}/clinic_vacations`,"icon":<IoIosPeople fontSize='small'/>}
            ]
        }
    ]}
    if(user_data.user_type == "staff"){
        clinics=sidebarData
    }
    const [Data, SetData] = useState([])
    const GetData = async () => {
        try {
            const responce = await axiosConfig.get(`/clinics/clinics/${user_data.clinic_id ? user_data.clinic_id : id}/`)
            SetData(responce.data)

        }
        catch (error) {
            console.log(error)

        }
    }
    const OnChildNavClicked=(path)=>{
        SetChildNavs(path)
        localStorage.setItem("url",path)
    }
    useEffect(() => {
        GetData();
        SetChildNavs(localStorage.getItem("url"))
    }, [])
    return (
        <>
            <DashboardLayout>
                <div className="mainbar">
                    <div className="sidebar-filters px-2">
                    {user_data&&user_data.user_type=="admin" &&
                        <NavLink to="/dashboard/clinics/list" ><button type="submit" className="btn btn-primary"><BsArrowLeftShort/>back to Clinics</button></NavLink>
                    }
                    {
                            clinics.clinicProfile.map((childItem, index) => {
                                return(                                                    
                                    <div key={index}>
                                        <h5>{childItem.SubTitle}</h5>                        
                                            {
                                                childItem.NavItems.map((item,idx)=>{
                                                    return(
                                                        <div key={idx} className={ ChildNavs==item.path ? "Configiration-list active" : "Configiration-list"}>
                                                         <NavLink to={item.path} className={ChildNavs==item.path?"colorChange":"NoColorChange"}  onClick={() => OnChildNavClicked(item.path)}> 
                                                              <span className="configiration-icon">{item.icon}</span>
                                                            <span className="list-name">{item.label}</span>
                                                        </NavLink>
                                                    </div>
                                                    )
                                                })
                                            }
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="main-code">
                    <div className="dashboard-header">
                            <h5 className="dashboard-subtitle">Clinics</h5>
                        </div>
                        <FormParser modelObject={MastersModal} formData={Data} formSubmit={formSubmit} error={Error} />
                    </div>
                </div>
                {popupType != "" &&
                    <PopupModal popupType={popupType} Navigation="" />
                }
            </DashboardLayout>
        </>
    )

}
export { ClinicsAdminEdit }
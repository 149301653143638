import React, { useEffect, useState } from "react";
import { DashboardLayout, FormParser, PopupModal } from "../Components";
import { NavLink, useParams } from "react-router-dom";
import Masters from "../Components/masters.json"
import "./Pages.css"
import axiosConfig from "../Service/axiosConfig"
import sidebarData from "../Components/DashboardLayout/sidebar";
import {IoIosPeople} from "react-icons/io"
import {BsArrowLeftShort} from "react-icons/bs"
const ClinicAdminVacations = () => {
    let {id}=useParams()
    const [popupType, setpopupType] = useState("");
    const [formerrorData, SetformErrorData] = useState({})
    const MastersModal = Masters.ClinicVacations
    const [Error, SetError] = useState("")
    const [activeState, setActiveState] = useState("")
    const clinicProfile=[
        {
            "SubTitle":"Clinics",
            "NavItems":[
                {"label":"Clinic","path":`/dashboard/clinics/${id}/edit`,"icon":<IoIosPeople fontSize='small'/>},
                {"label":"Clinics Specialites","path":`/dashboard/clinics/${id}/specialities`,"icon":<IoIosPeople fontSize='small'/>},
                {"label":"Clinics Availability","path":`/dashboard/clinics/${id}/clinic_availability`,"icon":<IoIosPeople fontSize='small'/>},
                {"label":"Clinics Gallery","path":`/dashboard/clinic/${id}/gallery`,"icon":<IoIosPeople fontSize='small'/>},
                {"label":"Clinics Vacations","path":`/dashboard/clinics/${id}/clinic_vacations`,"icon":<IoIosPeople fontSize='small'/>}
            ]
        }
    ]
    const Sidebaravalue = clinicProfile
    const [ChildNavs, SetChildNavs] = useState("")
    const [Data, setData] = useState([])
    const [clinicVacations, setClinicVacations] = useState([])
    let data = localStorage.getItem("user_data")

    const formSubmit = (Data) => {
        console.log(Data)
        SetError([])
        axiosConfig.post("/clinics/clinicvacation/", { fromdatetime: Data.fromdatetime, todatetime: Data.todatetime, clinic: id }).then(res => {
            console.log(res)
            setpopupType("success")
        }).catch(error => {
            let errorData = {}
            console.log(errorData)
            SetformErrorData(errorData)
            if (error.response.data.country_name[0] === "Enter a valid country name.") {
                errorData.country_name = "Enter a valid country name."
                SetError("Enter a valid country name.")
            }
            if (error.response.data.country_name[0] === "This field must be unique.") {
                errorData.country_name = "Country Already Exists."
                SetError("Country Already Exists.")

            }
            if (error.response.data.country_name[0] === "This field may not be blank.") {
                SetError("Country may not be blank.")

            }
            setpopupType("error")
        })
    }
    const currentURL = window.location.href;
    const OnChildNavClicked = (path) => {
        SetChildNavs(path)
        localStorage.setItem("url", path)

    }

    const GetClinicVacations = async () => {
        try {
            const responce = await axiosConfig.get(`/clinics/clinicvacation/${JSON.parse(data).clinic_id}/`);
            console.log(responce.data.results, "vacations");
            setData(responce.data)
        }
        catch (error) {
            console.log(error)
        }

    }
    useEffect(() => {
        SetChildNavs(localStorage.getItem("url"));
        GetClinicVacations()
    }, [])
    return (
        <>
            <DashboardLayout>
                <div className="mainbar">
                    <div className="sidebar-filters px-2">
                    <NavLink to="/dashboard/clinics/list" ><button type="submit" className="btn btn-primary"><BsArrowLeftShort/>back to Clinics</button></NavLink>
                        {
                            Sidebaravalue.map((childItem, index) => {
                                return (
                                    <div key={index}>
                                        <h5>{childItem.SubTitle}</h5>
                                        {
                                            childItem.NavItems.map((item, idx) => {
                                                return (
                                                    <div key={idx} className={ChildNavs == item.path ? "Configiration-list active" : "Configiration-list"}>
                                                        <NavLink to={item.path} className={ChildNavs == item.path ? "colorChange" : "NoColorChange"} onClick={() => OnChildNavClicked(item.path)}>
                                                            <span className="configiration-icon">{item.icon}</span>
                                                            <span className="list-name">{item.label}</span>
                                                        </NavLink>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="main-code">
                        <div className="dashboard-header">
                            <h5 className="dashboard-subtitle">Vacations</h5>
                        </div>
                        <FormParser modelObject={MastersModal} formSubmit={formSubmit} formData={Data} />
                        <div>
                            <h3 className="my-3">Past vacations</h3>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>S No.</th>
                                        <th>From Date</th>
                                        <th>To Date</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {clinicVacations && clinicVacations.map((result, index) => (
                                        <tr key={result.id}>
                                            <td>{index + 1}</td>
                                            <td>{result.fromdatetime}</td>
                                            <td>{result.todatetime}</td>
                                            <td>{result.comments ? 'Completed' : 'Pending'}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                {popupType != "" &&
                    <PopupModal popupType={popupType} Navigation="" />
                }
            </DashboardLayout>
        </>
    )

}
export { ClinicAdminVacations }
import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const ModuleModal = ({ modalIsOpen, setModalIsOpen,addNewComponent,id }) => {
    const [items, setItems] = useState([
        {
            type: 'image',
            src: require('../Shopidy/images/image1.png'),
            alt: 'Image 1',
            label:"Carousel"
        },
        {
            type: 'image',
            src: require('../Shopidy/images/image 2.png'),
            alt: 'Image 2',
            label:"Carousel"
        },
        {
            type: 'image',
            src: require('../Shopidy/images/image 3.png'),
            alt: 'Image 3',
            label:"Carousel"
        },
        {
            type: 'image',
            src: require('../Shopidy/images/image 4.png'),
            alt: 'Image 4',
            label:"Carousel"
        },
        {
            type: 'quill',
            src: require('../Shopidy/images/react-quill.jpg'),
            alt: 'Quill Image',
            label:"Text Block"
        },
        {
            type: 'banner',
            src: require('../Shopidy/images/FullWidthBanner.png'),
            alt: 'Full Width Banner',
            label:"Full Width Banner"
        },
        {
            type: 'slider',
            src: require('../Shopidy/images/image1.png'),
            alt: 'Slider Image 1',
            label:"Banner Slider"
        },
        {
            type: 'slider',
            src: require('../Shopidy/images/image 2.png'),
            alt: 'Slider Image 2',
            label:"Banner Slider"
        },
    ]);
    const handleModuleType=(type)=>{
      addNewComponent(id,type);
      setShowModal(false)
      setModalIsOpen(false)
    }
    const renderItems = () => {
        const groupedItems = {};
    
        // Group items by type
        items.forEach((data) => {
          if (!groupedItems[data.type]) {
            groupedItems[data.type] = {
              items: [],
              label: data.label,
            };
          }
          groupedItems[data.type].items.push(data);
        });
    
        const renderedItems = Object.entries(groupedItems).map(([type, { items, label }], index) => (
          <div key={index} className='col-6 cursor-pointer'>
            <div className='module-border'>
              {type === 'image' && (
                <div className='row' onClick={()=>handleModuleType(label)}>
                  {items.map((item, itemIndex) => (
                    <div key={itemIndex} className='col-6 p-2'>
                      <img src={item.src} alt={item.alt} style={{width:"100%", height:"88px"}}/>
                    </div>
                  ))}
                </div>
              )}
              {type !== 'image' && type !=="slider"&& (
                <div onClick={()=>handleModuleType(label)}>
                  {items.map((item, itemIndex) => (
                    <img
                      key={itemIndex}
                      src={item.src}
                      alt={item.alt}
                      style={{
                        width: type === 'quill' || type === 'banner' ? '100%' : '160px',
                        height: type==="quill"? "161px":"auto",
                        border: type === 'quill' ? '1px solid #000' : 'none', // Apply border only to 'quill'
                        flexShrink: 0
                        
                      }}
                    />
                  ))}
                </div>
              )}
               {type === 'slider' && (
                <div className='row' onClick={()=>handleModuleType(label)}>
                  {items.map((item, itemIndex) => (
                    <div key={itemIndex} className='col-6 p-2'>
                      <img src={item.src} alt={item.alt} style={{width:"100%", height:"88px"}}/>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className='text-center mt-2'>
              <strong style={{color: "#5C5C5C"}}>{label}</strong>
            </div>
          </div>
        ));
    
        return renderedItems;
      };

    const [showModal, setShowModal] = useState(false);

    const openModal = () => setShowModal(true);
    const closeModal = () => {
        setShowModal(false);
        setModalIsOpen(false)
    }
    useEffect(() => {
        if (modalIsOpen) {
            openModal()
        }
        else {
            closeModal();
        }

    }, [modalIsOpen])
    return (
        <div>
            <Modal centered size="lg" show={showModal} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Module</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row align-items-center'>{renderItems()}</div>;
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default ModuleModal;

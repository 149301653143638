import React, { useEffect, useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { DashboardLayout, Pagination } from "../Components";
import { FaRegUserCircle } from "react-icons/fa";
import { BiArrowBack } from "react-icons/bi";
import axiosConfig from "../Service/axiosConfig";
import { MdOutlineMedicalInformation, MdListAlt, MdMeetingRoom, MdHistory } from "react-icons/md"
import { FaFileInvoice } from "react-icons/fa"
import sidebarData from "../Components/DashboardLayout/sidebar";
import DataTable from 'react-data-table-component';
import tableCustomStyles from "../Components/PopupModal/tableCustomStyles";
import { useParams } from "react-router-dom";

const PatientVisitHistory = () => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [patientData, setPatientData] = useState([])
    const [emrDetails, setEMRDetails] = useState([])
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(20);
    const [Direction, setDirection] = useState("");
    const [Columns, setColumns] = useState("");
    const [ChildNavs, SetChildNavs] = useState("")
    const [activeButton, setActiveButton] = useState('');
    const [patientDataFetched, setPatientDataFetched] = useState(false);
    const [search, SetSearch] = useState({
        search: ""
    })
    const { uhid } = useParams();
    let patient_details;
    const patient_profile = [
        {
            "SubTitle": "Patient Profile",
            "NavItems": [
                { "label": "Info", "path": `/dashboard/patients/${uhid}/info`, "icon": <MdOutlineMedicalInformation fontSize='small' /> },
                { "label": "Invoices", "path": `/dashboard/patients/${uhid}/invoices`, "icon": <FaFileInvoice fontSize='small' /> },
                { "label": "Records", "path": `/dashboard/patients/${uhid}/records`, "icon": <MdListAlt fontSize='small' /> },
                { "label": "Appointments", "path": `/dashboard/patients/${uhid}/appointments`, "icon": <MdMeetingRoom fontSize='small' /> },
                { "label": "Visit History", "path": `/dashboard/patients/${uhid}/visit_history`, "icon": <MdHistory fontSize='small' /> },
            ]
        }
    ]

    const visit_history_columns = [
        {
            name: 'Bill Item Type',
            selector: row => row.bill_item_type,
            sortable: true,
        },
        {
            name: 'Follow-up Date',
            selector: "follow_up_date",
            cell: row => row.follow_up_date ? new Date(row.follow_up_date).toLocaleString() : "N/A",
            sortable: true,
        },
        {
            name: 'Clinic Name',
            selector: "clinic_name",
            sortable: true,
        },
        {
            name: 'Patient Name',
            selector: "patient_name",
            cell: row => row.patient_name,
            sortable: true,
        },
        {
            name: 'Doctor Name',
            selector: "doctor_name",
            cell: row => row.doctor_name,
            sortable: true,
        },
        {
            name: 'EMR Completed Date',
            selector: "emr_completed_date",
            cell: row => new Date(row.emr_completed_date).toLocaleString(),
            sortable: true,
        },
        {
            name: 'Actions',
            cell: row => <button className="btn btn-primary" onClick={() => navigate(`/dashboard/worklist/${row.id}/emr/${row.status}/${row.patient}/${row.bill_item}`)}>EMR</button>,
        }
    ];

    const GetPatientData = async () => {
        setLoading(true)
        try {
            const responce = await axiosConfig.get(`/clinics/patient/?uhid=${uhid}`)
            setPatientData(responce.data.results)
            setPatientDataFetched(true)
            setLoading(false)
        }
        catch (error) {
            console.log(error)
        }
    }

    const GetEMRDetails = async () => {
        setLoading(true)
        try {
            const responce = await axiosConfig.get(`/clinics/emr/?patient=${patientData ? patientData[0].id : ""}&page=${currentPage}&page_size=${RowPerPage}&ordering=${Direction === "asc" ? Columns : `-${Columns}`}`)
            setEMRDetails(responce.data.results)
            setTotalRows(responce.data.count)
            setLoading(false)
        }
        catch (error) {
            console.log(error)
        }
    }

    const OnChildNavClicked = (path) => {
        SetChildNavs(path)
        localStorage.setItem("url", path)
    }

    const handleFilters = async (filter) => {
        setLoading(true)
        try {
            const responce = await axiosConfig.get(`/clinics/emr/?clinic=&patient=${patientData[0].id}&doctor=&bill_item=&bill_item__bill_item_type=${filter}`) // add filter in API call
            setEMRDetails(responce.data.results)
            setLoading(false)
            setActiveButton(filter);
        }
        catch (error) {
            console.log(error)
        }
    }

    const HandleSearch = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        axiosConfig.get(`clinics/emr/?search=${value}`).then(res => {
            setEMRDetails(res.data.results)
        }).catch(error => {
            console.log(error)
        })
        SetSearch({ ...search, [name]: value })
    }

    const handleSort = (column, direction) => {
        setDirection(direction)
        setColumns(column.selector)
        setCurrentPage(1);
    };

    useEffect(() => {
        GetPatientData()
        if (patientData) {
            GetEMRDetails()
        }
    }, [currentPage, RowPerPage, Columns, Direction,patientDataFetched])

    return (
        <DashboardLayout>
            <div className="mainbar">
                <div className="sidebar-filters px-3" style={{ height: "100vh" }}>
                    <button className="btn btn-primary mb-3" onClick={() => navigate(`/dashboard/patients/registration`)}><BiArrowBack />back to patients</button>
                    {
                        patient_profile.map((res, index) => {
                            return (
                                <div key={index}>
                                    <h5>{res.SubTitle}</h5>
                                    {
                                        res.NavItems.map((item, idx) => {
                                            return (
                                                <div key={idx} className={ChildNavs == item.path ? "Configiration-list active" : "Configiration-list"}>
                                                    <NavLink to={item.path} className={ChildNavs == item.path ? "colorChange" : "NoColorChange"} onClick={() => OnChildNavClicked(item.path)}>
                                                        <span className="configiration-icon">{item.icon}</span>
                                                        <span className="list-name">{item.label}</span>
                                                    </NavLink>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            )
                        })
                    }
                </div>
                <div className="main-code">
                    <div className="dashboard-header">
                        <h5 className="dashboard-subtitle">Patient Visit History</h5>
                        <NavLink className="text-decoration-none" to="#"><button className="btn btn-primary d-inline">New Visit</button></NavLink>
                    </div>
                    <DataTable
                        columns={visit_history_columns}
                        data={emrDetails}
                        subHeader
                        fixedHeader
                        pagination paginationTotalRows={totalRows}
                        paginationPerPage={RowPerPage}
                        paginationDefaultPage={currentPage}
                        onChangePage={(page) => setCurrentPage(page)}
                        paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
                        paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
                        onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
                        onSort={handleSort}
                        paginationServer
                        customStyles={tableCustomStyles}
                        subHeaderComponent={
                            <>
                                <div className="d-flex">
                                    <button
                                        className={`${activeButton === 'Consulatation' ? 'btn btn-primary' : 'btn btn-white'}`}
                                        onClick={() => handleFilters('Consulatation')}>
                                        Consultations
                                    </button>
                                    <button
                                        className={`${activeButton === 'Procedure' ? 'btn btn-primary' : 'btn btn-white'}`}
                                        onClick={() => handleFilters('Procedure')}>
                                        Procedures
                                    </button>
                                    <button className={`${activeButton === 'Service' ? 'btn btn-primary' : 'btn btn-white'}`}
                                        onClick={() => handleFilters('Service')}>
                                        Services
                                    </button>
                                    <input type="text" className="form-control" name="search" value={search.search} onChange={HandleSearch} />
                                </div>
                            </>}
                    />
                </div>
            </div>
        </DashboardLayout>
    )
}

export { PatientVisitHistory }
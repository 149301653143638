import React, { useEffect, useState } from "react";

const PrintTable = () => {
    const [data, setData] = useState(null);

    useEffect(() => {
        const storedData = JSON.parse(localStorage.getItem('pdf_data'));
        if(storedData.length > 0){
            setData(storedData);
            window.print()
        }
    }, [])

    return (
        <table className="table">
            <thead>
                <tr>
                    {data && Object.keys(data[0]).map((header) => (
                        <th key={header}>{header}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {data && data.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                        {Object.keys(data[0]).map((column) => (
                            <td key={`${rowIndex}-${column}`}>{row[column]}</td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export { PrintTable }
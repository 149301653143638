import React, { useEffect, useState } from "react";
import { SettingNavbarComponent } from "../Components/navbar/settingsnav";
import axiosConfig from "../Service/axiosConfig";
import DataTable from "react-data-table-component";
import { FaHandHoldingHeart } from "react-icons/fa";
import { MdDeleteOutline, MdOutlineEdit } from "react-icons/md";
import Modal from "react-bootstrap/Modal";
import { DeletePopupModal, PopupModal, FormParser } from "../Components";
import Masters from "../Components/masters.json";
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";

const ConfigureChargesHyperlocal = () => {
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("")
    const [deletePopupType, setDeletepopupType] = useState("")
    const [loading, setLoading] = useState(false)
    const [RowId, SetRowId] = useState()
    const [popupType, setpopupType] = useState("")
    const [message, setMessage] = useState('')

    const customStyles = {
        header: {
            style: {
                minHeight: '56px',
            },
        },
        headRow: {
            style: {
                backgroundColor: '#F7F7F7',
            },
        },
        headCells: {
            style: {
                textTransform: 'uppercase',
                fontSize: '12px',
            },
        }
    };

    const [packingsData, setPackingsData] = useState([]);
    const [deliveryData, setDeliveryData] = useState([]);
    const [waiverData, setWaiverData] = useState([]);
    const [show, setShow] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [mastersModal, setMastersModal] = useState(null)
    const [LoadingButton, setLoadingButton] = useState();
    const [formData, setFormData] = useState({});

    const packing_columns = [
        {
            name: "ID",
            selector: (row) => row.id,
            sortable: true
        },
        {
            name: "from(kg)",
            selector: (row) => `${row.from_kg} kgs`,
            sortable: true
        },
        {
            name: "to(kg)",
            selector: (row) => `${row.to_kg} kgs`,
            sortable: true
        },
        {
            name: "charges(₹)",
            selector: (row) => `₹ ${row.charges}`,
            sortable: true,
        },
        {
            name: "Actions",
            selector: (row) => {
                return (
                    <>
                        {row.is_suspended ?
                            <FaHandHoldingHeart className="dashboard-tabe-data-actions" onClick={() => handleRevive("packings", row)} />
                            :
                            <>
                                <MdOutlineEdit className="me-2 dashboard-tabe-data-actions" onClick={() => handleEdit("packings", row)} />
                                <MdDeleteOutline className="dashboard-tabe-data-actions" onClick={() => DeleteAlert(row.id, "packings")} />
                            </>
                        }
                        {
                            AlertDeletePopupType === "success" &&
                            <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} HandleDelete={HandleDelete} CancelAlerrt={CancelAlerrt} row={RowId} />
                        }
                    </>
                )
            }
        }
    ];

    const delivery_columns = [
        {
            name: "ID",
            selector: (row) => row.id,
            sortable: true,
            width: "70px"
        },
        {
            name: "express from value",
            selector: (row) => `₹ ${row.express_delivery_charges_order_value_from}`,
            sortable: true
        },
        {
            name: "express to value",
            selector: (row) => `₹ ${row.express_delivery_charges_order_value_to}`,
            sortable: true
        },
        {
            name: "schedule from value",
            selector: (row) => `₹ ${row.schedule_delivery_charges_order_value_from}`,
            sortable: true
        },
        {
            name: "schedule to value",
            selector: (row) => `₹ ${row.schedule_delivery_charges_order_value_to}`,
            sortable: true
        },
        {
            name: "express charges",
            selector: (row) => `₹ ${row.express_delivery_charges}`,
            sortable: true,
        },
        {
            name: "schedule charges",
            selector: (row) => `₹ ${row.schedule_delivery_charges}`,
            sortable: true,
        },
        {
            name: "Actions",
            selector: (row) => {

                return (
                    <>
                        {row.is_suspended ?
                            <FaHandHoldingHeart className="dashboard-tabe-data-actions" onClick={() => handleRevive("delivery", row)} />
                            :
                            <>
                                <MdOutlineEdit className="me-2 dashboard-tabe-data-actions" onClick={() => handleEdit("delivery", row)} />
                                <MdDeleteOutline className="dashboard-tabe-data-actions" onClick={() => DeleteAlert(row.id, "delivery")} />
                            </>
                        }
                        {
                            AlertDeletePopupType === "success" &&
                            <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} HandleDelete={HandleDelete} CancelAlerrt={CancelAlerrt} row={RowId} />
                        }
                    </>
                )
            }
        }
    ]

    const waiver_columns = [
        {
            name: "ID",
            selector: (row) => row.id,
            sortable: true,
            width: "70px"
        },
        {
            name: "Express Delivery Waiver Above",
            selector: (row) => `₹ ${row.express_delivery_waier_order_value_above}`,
            sortable: true
        },
        {
            name: "Schedule Delivery Waiver Above",
            selector: (row) => `₹ ${row.schedule_delivery_waier_order_value_above}`,
            sortable: true
        },
        {
            name: "Express Packing Waiver Above",
            selector: (row) => `₹ ${row.express_packing_waier_order_value_above}`,
            sortable: true
        },
        {
            name: "Schedule Packing Waiver Above",
            selector: (row) => `₹ ${row.schedule_packing_waier_order_value_above}`,
            sortable: true
        },
        {
            name: "Actions",
            selector: (row) => {

                return (
                    <>
                        {row.is_suspended ?
                            <FaHandHoldingHeart className="dashboard-tabe-data-actions" onClick={() => handleRevive("waiver", row)} />
                            :
                            <>
                                <MdOutlineEdit className="me-2 dashboard-tabe-data-actions" onClick={() => handleEdit("waiver", row)} />
                                <MdDeleteOutline className="dashboard-tabe-data-actions" onClick={() => DeleteAlert(row.id, "waiver")} />
                            </>
                        }
                        {
                            AlertDeletePopupType === "success" &&
                            <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} HandleDelete={HandleDelete} CancelAlerrt={CancelAlerrt} row={RowId} />
                        }
                    </>
                )
            }
        }
    ]

    const packings_action = (
        <button className="btn btn-primary" onClick={() => handleShow("packings")}>+ Add New</button>
    )

    const delivery_action = (
        <button className="btn btn-primary" onClick={() => handleShow("delivery")}>+ Add New</button>
    )

    const waiver_action = (
        <button className="btn btn-primary" onClick={() => handleShow("waiver")}>+ Add New</button>
    )

    const CancelAlerrt = () => {
        SetAlertDeletePopupType("error")
    }

    const DeleteAlert = (id, type) => {
        switch (type) {
            case "packings":
                setMastersModal(Masters.PackingCharges)
                break
            case "delivery":
                setMastersModal(Masters.DeliveryCharges)
                break
            case "waiver":
                setMastersModal(Masters.WaiverCharges)
                break
            default:
                break
        }
        SetRowId(id)
        SetAlertDeletePopupType("success")
    }

    const HandleDelete = async (id) => {
        try {
            switch (mastersModal) {
                case Masters.PackingCharges:
                    await axiosConfig.delete(`masters/packing-charges-configuration/${id}/`)
                    setpopupType("success")
                    setMessage("Packing Charges deleted successfully")
                    GetPackingCharges()
                    break
                case Masters.DeliveryCharges:
                    await axiosConfig.delete(`masters/delivery-charges-configuration/${id}/`)
                    setpopupType("success")
                    setMessage("Delivery Charges deleted successfully")
                    GetDeliveryCharges()
                    break
                case Masters.WaiverCharges:
                    await axiosConfig.delete(`masters/waier-charges/${id}/`)
                    setpopupType("success")
                    setMessage("Waiver Charges deleted successfully")
                    GetWaiverCharges()
                    break
                default:
                    break
            }
            SetAlertDeletePopupType("error")
        } catch (error) {
            setDeletepopupType("error")
            SetAlertDeletePopupType("error")
        }
    }

    const handleEdit = (type, row) => {
        switch (type) {
            case "packings":
                setShow(true)
                setModalTitle("Edit Packing Charges")
                setMastersModal(Masters.PackingCharges)
                setFormData(row)
                break
            case "delivery":
                setShow(true)
                setModalTitle("Edit Delivery Charges")
                setMastersModal(Masters.DeliveryCharges)
                setFormData(row)
                break
            case "waiver":
                setShow(true)
                setModalTitle("Edit Waiver Charges")
                setMastersModal(Masters.WaiverCharges)
                setFormData(row)
                break
            default:
                break
        }
    }

    const handleRevive = async (type, row) => {
        try {
            switch (type) {
                case "packings":
                    await axiosConfig.post(`/masters/packing-charges-configuration/${row.id}/revive/`)
                    GetPackingCharges()
                    break
                case "delivery":
                    await axiosConfig.post(`/masters/delivery-charges-configuration/${row.id}/revive/`)
                    GetDeliveryCharges()
                    break
                case "waiver":
                    await axiosConfig.post(`/masters/waier-charges/${row.id}/revive/`)
                    GetWaiverCharges()
                    break
                default:
                    break
            }
        } catch (error) {
            console.log(error)
        } finally {
            setpopupType("success")
            setMessage("Charges revived successfully")
        }
    }

    const handleShow = (type) => {
        setFormData({})
        switch (type) {
            case "packings":
                setShow(true)
                setModalTitle("Add New Packing Charges")
                setMastersModal(Masters.PackingCharges)
                break
            case "delivery":
                setShow(true)
                setModalTitle("Add New Delivery Charges")
                setMastersModal(Masters.DeliveryCharges)
                break
            case "waiver":
                setShow(true)
                setModalTitle("Add New Waiver Charges")
                setMastersModal(Masters.WaiverCharges)
                break
            default:
                break
        }
    }

    const formSubmit = async (data) => {
        try {
            switch (mastersModal) {
                case Masters.PackingCharges:
                    if (data.id) {
                        await axiosConfig.put(`/masters/packing-charges-configuration/${data.id}/`, data)
                    } else {
                        await axiosConfig.post('/masters/packing-charges-configuration/', data)
                    }
                    GetPackingCharges();
                    break
                case Masters.DeliveryCharges:
                    if (data.id) {
                        await axiosConfig.put(`/masters/delivery-charges-configuration/${data.id}/`, data)
                    } else {
                        await axiosConfig.post('/masters/delivery-charges-configuration/', data)
                    }
                    GetDeliveryCharges();
                    break
                case Masters.WaiverCharges:
                    if (data.id) {
                        await axiosConfig.put(`/masters/waier-charges/${data.id}/`, data)
                    } else {
                        await axiosConfig.post('/masters/waier-charges/', data)
                    }
                    GetWaiverCharges();
                    break
                default:
                    break
            }
        } catch (error) {
            console.log(error)
        } finally {
            setShow(false)
        }
    }

    const handleClose = () => setShow(false);

    const GetWaiverCharges = async () => {
        try {
            const waiver_response = await axiosConfig.get('/masters/waier-charges/');
            setWaiverData(waiver_response.data.results);
        } catch (error) {
            console.log("error getting data :", error)
        }
    }

    const GetDeliveryCharges = async () => {
        try {
            const delivery_response = await axiosConfig.get('/masters/delivery-charges-configuration/');
            setDeliveryData(delivery_response.data.results);
        } catch (error) {
            console.log("error getting data :", error)
        }
    }

    const GetPackingCharges = async () => {
        try {
            const packing_response = await axiosConfig.get('/masters/packing-charges-configuration/');
            setPackingsData(packing_response.data.results);
        } catch (error) {
            console.log("error getting data :", error)
        }
    }

    useEffect(() => {
        GetWaiverCharges();
        GetPackingCharges();
        GetDeliveryCharges();
    }, [])

    return (
        <div className="child-sidenav">
            <SettingNavbarComponent />
            <div className="childnav-content mt-2">
                <DataTable
                    title="Packing Charges"
                    columns={packing_columns}
                    data={packingsData}
                    customStyles={customStyles}
                    fixedHeader={true}
                    actions={packings_action}
                />
                <br />
                <DataTable
                    title="Delivery Charges"
                    columns={delivery_columns}
                    data={deliveryData}
                    customStyles={customStyles}
                    fixedHeader={true}
                    actions={delivery_action}
                />
                <br />
                <DataTable
                    title="Waiver Charges"
                    columns={waiver_columns}
                    data={waiverData}
                    customStyles={customStyles}
                    fixedHeader={true}
                    actions={waiver_action}
                />
            </div>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormParser
                        modelObject={mastersModal}
                        formData={formData}
                        formSubmit={formSubmit}
                        error={Error}
                        buttonTitle="Save"
                        LoadingButton={LoadingButton}
                    />
                </Modal.Body>
            </Modal>
            {popupType != "" &&
                <PopupModal setpopupType={setpopupType} popupType={popupType} Message={message} />
            }
            {deletePopupType != "" &&
                <DeletePopupModal setDeletepopupType={setDeletepopupType} deletePopupType={deletePopupType} />
            }
        </div>
    )
}

export { ConfigureChargesHyperlocal }
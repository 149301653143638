import React from "react";
import { DashboardLayout } from "../Components";
const ClientBillInternational=()=>{
    return(
        <div>
             <DashboardLayout>
            <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">View Client International</h5>
                </div>
            </DashboardLayout>
        </div>
    )
}
export {ClientBillInternational}
import React, { useEffect, useState } from "react";
import { Layout, FormParser, PopupModal, DeletePopupModal } from "../Components";
import Masters from "../Components/masters.json"
import axiosConfig from "../Service/axiosConfig"
import { NavLink, useNavigate, Navigate } from "react-router-dom";
import DataTable from 'react-data-table-component';
import {MdDeleteOutline,MdOutlineEdit} from "react-icons/md"
import {AiOutlineEye} from "react-icons/ai"
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import tableCustomStyles from "../Components/PopupModal/tableCustomStyles";
import "./Pages.css"
const ReferenceDomainValue = () => {
    const[Error,SetError]=useState("")
    const [popupType, setpopupType] = useState("");
    const [loading, setLoading] = useState(false);
    const [deletePopupType, setDeletepopupType] = useState("");
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("");
    let navigate = useNavigate();
    const [RowId, SetRowId] = useState()
    const [DomainId, SetdomainId] = useState(0)
    let domainId = DomainId
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(20);
    const [search, SetSearch] = useState({
        search: ""
    })
    const[Datafilter,SetDatafilter]=useState([])
    const [Data, SetData] = useState([])
    const [DomainName, SetDomainName] = useState("")
    const [domainData, SetDomainData] = useState([])
    const GetData = async () => {
        setLoading(true)
        try {
            const responce = await axiosConfig.get(`/masters/referencedomainvalue/?domain=${domainId}&page=${currentPage}&page_size=${RowPerPage}`)
            console.log(responce)
             SetData(responce.data.results)
             SetDatafilter(responce.data.results)
             setTotalRows(responce.data.count)
            setLoading(false)
        }
        catch (error) {
            console.log(error)
        }

    }
    const HandleSearchValue = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
         console.log(name, value)
         axiosConfig.get(`/masters/referencedomainvalue/?search=${value}`).then(res=>{
             SetData(res.data.results)
         }).catch(error=>{
            console.log(error)
         })
         SetSearch({ ...search, [name]: value })
        
    }
    const handleSort = (column, direction) => {
        console.log(column.selector,direction)
         axiosConfig.get(`/masters/countries/?ordering=${direction==="asc"?column.selector:-column.selector}`).then(res=>{
             SetData(res.data.results)
         }).catch(error=>{
            console.log(error)
         })
         setCurrentPage(1);
      };
    useEffect(() => {
        GetData();
    }, [currentPage,RowPerPage])

    const columns = [
        {
            name: "S.No",
            selector: "id",
            sortable: true
        },
        {
            name: "Code",
            selector: "code",
            sortable: true
        },
        {
            name: "Description",
            selector: "description",
            sortable: true
        },
        {
            name: "Display Order",
            selector: "display_order",
            sortable: true
        }, {
            name: "ACTIONS",
            selector: row => {
                const HandleEdit = async () => {
                    return navigate(`/dashboard/masters/domains/value/${row.id}/edit`);
                }
                const DeleteAlert=()=>{
                    SetRowId(row.id)
                    SetAlertDeletePopupType("success")
                }
                const CancelAlerrt=()=>{
                    SetAlertDeletePopupType("error")
                }
                const HandleDelete = async (id) => {
                    await axiosConfig.delete(`/masters/referencedomainvalue/${id}/`).then(() => {
                        GetData()
                        setDeletepopupType("success")
                        SetAlertDeletePopupType("error")
                    }).catch((error) => {
                        console.log(error)
                        setDeletepopupType("error")
                        SetAlertDeletePopupType("error")
                    })
                }
                return (
                    <>
                        <div className="row" >
                            <div className="col-4 text-center">
                            <AiOutlineEye className="dashboard-tabe-data-actions"  onClick={HandleEdit}/>
                            {/* <p className="action-title">View</p> */}
                            </div>
                            <div className="col-4">
                                <MdOutlineEdit className="dashboard-tabe-data-actions"  onClick={HandleEdit}/>
                                {/* <p className="action-title">Edit</p> */}
                            </div>
                            <div className="col-4">
                                <MdDeleteOutline className="dashboard-tabe-data-actions" onClick={DeleteAlert}/>
                                {/* <p className="action-title">delete</p> */}
                            </div>
                        </div>
                        {
                            AlertDeletePopupType === "success" &&
                            <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} HandleDelete={HandleDelete} CancelAlerrt={CancelAlerrt} row={RowId} />
                        }
                    </>
                )

            }
        }
    ]

    const MastersModal = Masters.ReferenceDomainValue
    const formSubmit = (Data) => {
        console.log(Data)
        axiosConfig.post("masters/referencedomainvalue/", Data).then(res => {
            console.log(res.data)
            setpopupType("success")
        }).catch(error => {
            console.log(error)
            setpopupType("error")
        })
    }
    const HandleSearch = (e) => {
        e.preventDefault();
        const name = e.target.name
        const value = e.target.value
        SetDomainName(value)
        axiosConfig.get(`/masters/referencedomain/?search=${value}`).then(response => {
            console.log(value, response)
            SetDomainData(response.data.results)
        }).catch(error => {
            console.log(error)
            setpopupType("error")
        })
    }
    const HandleDomain = (e) => {
        e.preventDefault();
        const name = e.target.name
        const value = e.target.value
        console.log(name, value)
        domainId = e.currentTarget.dataset.domainid
        SetdomainId(domainId)
        GetData()
        SetDomainName(e.currentTarget.dataset.domainname)
        SetDomainData([])
    }
    const NavLinkDomain = () => {
        navigate(`/dashboard/masters/domains/${DomainId}/value/add`)
    }
    return (
        <>
            <Layout>
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">ReferenceDomainValue's</h5>
                    {DomainId !== 0 &&
                    <button onClick={NavLinkDomain} className="btn btn-primary" >Add New</button>
                }
                </div>
                <div className="search-form">
                    <div className="row">
                        <div className="col-3">
                            <label>Domain Code:</label>
                        </div>
                        <div className="col-9">
                            <div className="searchinput-holder">
                                <input type="text" className="form-control" placeholder="Domain Code" name="domain" value={DomainName} onChange={HandleSearch} />
                                <div className="search-dropdowns">
                                    {domainData.map((domainItem, idx) => {
                                        return (
                                            <p onClick={HandleDomain} key={domainItem.id} data-domainid={domainItem.id} data-domainname={domainItem.code}>{domainItem.code}</p>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="table-responsive">
                    <DataTable columns={columns} data={Data}  pagination paginationTotalRows={totalRows}
                        paginationPerPage={RowPerPage}
                        paginationDefaultPage={currentPage}
                        onChangePage={(page) => setCurrentPage(page)}  
                        paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
                        paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
                        onChangeRowsPerPage={(RowsPerPageChange)=>setRowPerPage(RowsPerPageChange)}  
                        onSort={handleSort} 
                        paginationServer  fixedHeader subHeader customStyles={tableCustomStyles} progressPending={loading}
                    subHeaderComponent={
                        <input type="text" className="form-control w-25" name="search" value={search.search} onChange={HandleSearchValue}/>}/>
                </div>
               { Data.length>0&&<div className="f-copy">
                <p><span className="copy-sym">&copy;</span> Pride Product of Apollo Radiology International. All Copy Rights Reserved 2023
                </p>
            </div>}
                {deletePopupType != "" &&
                    <DeletePopupModal deletePopupType={deletePopupType} setDeletepopupType={setDeletepopupType} />
                }
                {/* <FormParser modelObject={MastersModal} formSubmit={formSubmit}/> */}
                {popupType != "" &&
                    <PopupModal popupType={popupType} />
                }
            </Layout>
        </>
    )

}
export { ReferenceDomainValue }
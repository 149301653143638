import React, { useState } from "react";
import { Layout, FormParser, PopupModal } from "../Components";
import { NavLink } from "react-router-dom";
import Masters from "../Components/masters.json"
import "./Pages.css"
import axiosConfig from "../Service/axiosConfig"

const CityAdd = () => {
    const [formerrorData, SetformErrorData] = useState({})
    const [Error, SetError] = useState("")
    const [popupType, setpopupType] = useState("");

    const MastersModal = Masters.City
    const formSubmit = (Data) => {
        console.log(Data)
        axiosConfig.post("/masters/cities/", Data).then(res => {
            console.log(res)
            setpopupType("success")
        }).catch(error => {
            console.log(error.response.data)
            const ErrorData = []
            ErrorData.push({
                city_name: error.response.data.city_name || ["null"],
                state_id: error.response.data.state_id || ["null"],
            })
            console.log(ErrorData[0])
            if (ErrorData[0].city_name[0] === "This field is required.") {
                SetError("City is required.")
            }
            if (ErrorData[0].state_id[0] === "This field is required.") {
                SetError("State is required.")
            }
            if (ErrorData[0].city_name[0] === "Enter a valid cityortown name.") {
                SetError("Enter a valid city or town name.")
            }
            if (ErrorData[0].city_name[0] === "already exists") {
                SetError("City Already Exists")
            }
            if (ErrorData[0].city_name[0] === "This field may not be blank.") {
                SetError("city may not be blank.")
            }
            if (ErrorData[0].state_id[0] === "This field may not be null.") {
                SetError("This field may not be null.")
            }
            setpopupType("error")
        })
    }
    return (
        <>
            <Layout>
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">Add City</h5>
                    <NavLink to="/dashboard/masters/cities" ><button className="btn btn-white">Manage</button></NavLink>
                </div>
                <FormParser modelObject={MastersModal} formSubmit={formSubmit} error={Error}/>
                {popupType != "" &&
                    <PopupModal popupType={popupType} Navigation="/dashboard/masters/cities" />
                }
            </Layout>
        </>
    )

}
export { CityAdd }
import React, { useEffect, useMemo, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import SlotRegetration from "./SlotRegestration";
import { CreateAppointment } from "./AppointmentsCreate"
const CalanderSlot = (props) => {
  const [lgShow, setLgShow] = useState(false);
  const [TimeSlot, setTimelot] = useState({});
  const [Doctor, setDoctor] = useState()
  const [itmMinutes, setitmMinutes] = useState()
  const [Appointment, setAppointment] = useState("")
  let { slot_time, timeblock, availability_list, doctorSlots, DoctorsData, doctorId, IDApp, availability,is_popup,setSlotBooking,SlotBooking,addMinutesToTime,setIsRefreshed } = props;
  let data = []
  const isSlotInRange = (slot) => {
    if (availability_list) {
      for (let i = 0; i < availability_list.length; i++) {
        const [startTime, endTime] = availability_list[i].time.split(' to ');
        const startMinutes = convertTimeToMinutes(startTime);
        const endMinutes = convertTimeToMinutes(endTime);
        if (slot >= startMinutes && slot < endMinutes && availability_list[i].doctor === DoctorsData.id) {
          return { booked: true, availability: availability_list[i].id }; // If slot is in availability_list, return true (to set it in red).
        }
      }
    }

    // If not found in availability_list, return false (to proceed with checking doctorSlots).
    return { booked: false, availability: null };
  };

  const isSlotBooked = (slot) => {
    if (availability_list) {
      for (let i = 0; i < availability_list.length; i++) {
        const [startTime, endTime] = availability_list[i].time.split(' to ');
        const startMinutes = convertTimeToMinutes(startTime);
        const endMinutes = convertTimeToMinutes(endTime);

        if (slot >= startMinutes && slot < endMinutes && availability_list[i].doctor === DoctorsData.id) {
          return false; // Overlapping slot found in availability_list, so return false to ignore it.
        }
      }
    }

    if (doctorSlots) {
      for (let i = 0; i < doctorSlots.length; i++) {
        const [startTime, endTime] = doctorSlots[i].time.split(' to ');
        const startMinutes = convertTimeToMinutes(startTime);
        const endMinutes = convertTimeToMinutes(endTime);

        if (slot >= startMinutes && slot < endMinutes && doctorSlots[i].doctor === DoctorsData.id) {
          return { booked: true, doctorId: doctorSlots[i].doctor }; // If slot is in doctorSlots, return true (to set it in green or grey).
        }
      }
    }

    return { booked: false, doctorId: null };
  };



  const convertTimeToMinutes = (time) => {
    const [hour, minute] = time.split(':');
    if (hour === '12' && time.includes('AM')) {
      return 0;
    }
    if (hour === '12' && time.includes('PM')) {
      return parseInt(minute) + 12 * 60;
    }

    const period = time.includes('PM') ? 12 * 60 : 0;
    return parseInt(hour) * 60 + parseInt(minute) + period;
  };
  const HandlePopup = (timeblock, itm, doctor_id, clinic_id, doctoid) => {
    var splitTime = timeblock.split(':');
    var hours = parseInt(splitTime[0]);
    var minutes = 0;
    var meridiem = timeblock.slice(-2);
    if (meridiem.toLowerCase() === 'am' && hours === 12) {
      hours = 0;
    } else if (meridiem.toLowerCase() === 'pm' && hours !== 12) {
      hours += 12;
    }

    var formattedTime = hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0');
    const givenTime = formattedTime;
    console.log({ doctor: doctor_id, clinic: clinic_id, minutes: itm,service_type:"appointment" })
    setDoctor({ doctor: doctor_id, clinic: clinic_id, minutes: itm, doctoid: doctoid ,service_type:"appointment"})
    convertTimeToISOString(givenTime,itm);
  }
  const HandleBookedPopup = (timeblock, itm, doctor_id, clinic_id, availability) => {
    var splitTime = timeblock.split(':');
    var hours = parseInt(splitTime[0]);
    var minutes = 0;
    var meridiem = timeblock.slice(-2);
    if (meridiem.toLowerCase() === 'am' && hours === 12) {
      hours = 0;
    } else if (meridiem.toLowerCase() === 'pm' && hours !== 12) {
      hours += 12;
    }

    var formattedTime = hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0');
    const givenTime = formattedTime;
    setDoctor({ doctor: doctor_id, clinic: clinic_id, minutes: itm, availabilityId: availability,service_type:"appointment" })
    convertTimeToISOString(givenTime,itm,availability);
  }
  function convertTimeToISOString(timeString,itm,availability) {
    const [time, period] = timeString.split(" ");
    const [hours, minutes] = time.split(":").map(Number);
    let hours24 = hours;
    if (period === "PM" && hours !== 12) {
      hours24 += 12;
    } else if (period === "AM" && hours === 12) {
      hours24 = 0;
    }
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    const convertedTime = new Date(Date.UTC(year, month - 1, day, hours24, minutes));
    const convertedISOString = convertedTime.toISOString().slice(0, -5);
    setTimelot(convertedISOString)
      if(is_popup==="success"){
        setAppointment("success")
      }
      else{
        setAppointment("")
        addMinutesToTime&&addMinutesToTime(convertedISOString,itm)
       setSlotBooking&&setSlotBooking(prevState => {
          const dateTime = new Date(convertedISOString);
          dateTime.setMinutes(dateTime.getMinutes() + itm);
          const updatedYear = dateTime.getFullYear();
          const updatedMonth = String(dateTime.getMonth() + 1).padStart(2, "0");
          const updatedDay = String(dateTime.getDate()).padStart(2, "0");
          const updatedHours = String(dateTime.getHours()).padStart(2, "0");
          const updatedMinutes = String(dateTime.getMinutes()).padStart(2, "0");
          const updatedTime = `${updatedYear}-${updatedMonth}-${updatedDay}T${updatedHours}:${updatedMinutes}:00`;
          return { ...prevState,"appointment_start_datetime":convertedISOString, "appointment_end_datetime": updatedTime };
        });
      }
  }
  const getSlotTimeForDoctor = (doctorId) => {
    const doctorData = doctorSlots.find((doctor) => doctor.doctor === doctorId);
    return doctorData ? doctorData.slot_time : slot_time;
  };
  const createSlotsListForDoctor = (doctorId) => {
    const slotsPerHour = 60 / getSlotTimeForDoctor(doctorId);
    const slotsList = [];
    for (let i = 0; i < slotsPerHour; i++) {
      slotsList.push(getSlotTimeForDoctor(doctorId) + i * getSlotTimeForDoctor(doctorId));
    }
    return slotsList;
  };
  const createSlotsListForAllDoctors = () => {
    const slotsPerHour = 60 / slot_time;
    const slotsList = [];
    for (let i = 0; i < slotsPerHour; i++) {
      slotsList.push(slot_time + i * slot_time);
    }
    return slotsList;
  };
  const slotsList = doctorSlots && DoctorsData
    ? createSlotsListForDoctor(DoctorsData.id)
    : createSlotsListForAllDoctors();
  return (
    <>
      <div className="cal-block-itm">
        <div className="slots-div" data-timeblock={timeblock} data-doctor={DoctorsData.id}>
          {slotsList.map((itm, idx) => {
            const currentSlot = convertTimeToMinutes(timeblock) + idx * getSlotTimeForDoctor(DoctorsData.id);
            const isBooked = isSlotInRange(currentSlot);
            const isAvailable = isSlotBooked(currentSlot);
            let className;
            if (isAvailable) {
              className = isAvailable.booked ? 'btn btn-default slot-green' : 'btn btn-default btn-grey';
            } else if (isBooked) {
              className = isBooked.booked ? 'btn btn-default slot-red' : 'btn btn-default btn-grey';
            } else {
              className = 'btn btn-default btn-grey';
            }
            return (
              <div className="slot-item" onClick={() => (isBooked.booked&&is_popup==="success" && HandleBookedPopup(timeblock, itm, DoctorsData.id, DoctorsData.clinic, isBooked.availability)) || (isAvailable.booked && HandlePopup(timeblock, itm, DoctorsData.id, DoctorsData.clinic, isAvailable.doctorId))} data-time={itm} data-slotstatus="available">
                <button className={className}></button>
              </div>
            );
          })}
        </div>
        {
          Appointment === "success" &&
          <CreateAppointment TimeSlot={TimeSlot} setAppointment={setAppointment} Appointment={Appointment} doctor={Doctor} setIsRefreshed={setIsRefreshed}/>
        }
      </div>
    </>
  )
}
export { CalanderSlot }
import React, { useState, useEffect } from "react";
import { DashboardLayout, FormParser, PopupModal } from "../Components";
import { NavLink, useParams } from "react-router-dom";
import Masters from "../Components/masters.json"
import "./Pages.css"
import axiosConfig from "../Service/axiosConfig"
import { IoIosPeople } from "react-icons/io"
import { AiFillSetting } from "react-icons/ai"
import sidebarData from "../Components/DashboardLayout/sidebar";
import { async } from "q";

const ClinicSpecialites = () => {
    const [popupType, setpopupType] = useState("");
    const [formerrorData, SetformErrorData] = useState({})
    const [Error, SetError] = useState("")
    const [specialties_id, setId] = useState()
    const [serviceId, setServiceId] = useState()
    const [doctors, setDoctors] = useState([])
    const [Data, SetData] = useState([])
    const [clinicSpeciality, setClinicSpeciality] = useState([])
    const [clinicService, setClinicService] = useState([])
    const [specialtyArray, setSpecialtyArray] = useState([])
    const [serviceArray, setServiceArray] = useState([])
    const [specialFilter, Setspecailfilter] = useState([])
    const [ServiceFilter, SetserviceFilter] = useState([])
    const [servicetitle, setserviceTitle] = useState("")
    const [ChildNavs, SetChildNavs] = useState("")
    const [activeState, setActiveState] = useState("")
    const [ServiceData, setServiceData] = useState([])
    const [tariffs, SetTariffs] = useState([])
    const [filteredTariffs, SetFilteredTariffs] = useState([])
    const [fee, SetFee] = useState("")
    const [specificity, setSpecificity] = useState(false)
    const doctors_to_post = []
    let { id } = useParams()

    const Sidebaravalue = sidebarData.clinicProfile
    let data = localStorage.getItem("user_data")
    const role = JSON.parse(data).user_type
    const clinicId = JSON.parse(data).clinic_id

    const clinic_profile = [
        {
            "SubTitle": "Clinics",
            "NavItems": [
                { "label": "Clinic", "path": `/dashboard/clinics/${id}/edit`, "icon": <IoIosPeople fontSize='small' /> },
                { "label": "Clinics Specialites", "path": `/dashboard/clinics/${id}/specialities`, "icon": <IoIosPeople fontSize='small' /> },
                { "label": "Clinics Availability", "path": `/dashboard/clinics/${id}/clinic_availability`, "icon": <IoIosPeople fontSize='small' /> },
                { "label": "Clinics Gallery", "path": `/dashboard/clinic/${id}/gallery`, "icon": <IoIosPeople fontSize='small' /> },
                { "label": "Clinics Vacations", "path": `/dashboard/clinics/${id}/clinic_vacations`, "icon": <IoIosPeople fontSize='small' /> }
            ]
        }
    ]

    const [PostData, SetPostdata] = useState({
        doctor: '',
        service: serviceId,
        amount: '',
        doctor_share: null,
        clinic: clinicId ? clinicId : id
    })

    const GetDoctors = async () => {
        try {
            const responce = await axiosConfig.get(`/clinics/doctors/?clinic=${clinicId ? clinicId : id}`)
            console.log(responce.data.results, "doctors")

            setDoctors(responce.data.results)
        }
        catch (error) {
            console.log(error)

        }

    }

    const GetData = async () => {
        try {
            const responce = await axiosConfig.get(`/masters/specialities/`)
            SetData(responce.data.results)
            Setspecailfilter(responce.data.results)
        }
        catch (error) {
            console.log(error)

        }

    }

    const getClinicSpecialities = async () => {
        try {
            const responce = await axiosConfig.get(`/clinics/clinicspecialitys/?clinic=${clinicId ? clinicId : id}`)
            setClinicSpeciality(responce.data.results)
            const specialities = responce.data.results.map((result) => result.speciality_name);
            console.log(specialities)
            setSpecialtyArray(specialities)
        }
        catch (error) {
            console.log(error)

        }
    }

    const GetServiceData = async (res) => {
        try {
            const responce = await axiosConfig.get(`/masters/services/?speciality=${res.id}`)
            const services_data = await axiosConfig.get(`clinics/clinicservices/?service=&clinicspeciality__speciality=${res.id}`)
            const services = services_data.data.results.map((result) => result.service_details.name)
            setClinicService(services_data.data.results)
            setServiceArray(services)
            setServiceData(responce.data.results)
            SetserviceFilter(responce.data.results)
        }
        catch (error) {
            console.log(error)

        }

    }

    const getClinicServices = async (res) => {
        try {
            const response = await axiosConfig.get(`clinics/clinicservices/?service=&clinicspeciality__speciality=${res.speciality}`);
            const services = response.data.results.map((result) => result.service_details.name)
            setClinicService(response.data.results)
            setServiceArray(services)
        } catch (error) {
            console.log(error);
        }
    }

    const getTariffs = async () => {
        try {
            const responce = await axiosConfig.get(`/clinics/tariffs/?clinic=${clinicId ? clinicId : id}`)
            SetTariffs(responce.data.results)
        }
        catch (error) {
            console.log(error)

        }

    }

    const HandClincserviceApi = async (res, name, title) => {
        let serviceTariff = tariffs.filter(result => result.service_name === res.name)
        setserviceTitle(title)
        if (serviceTariff[0] && serviceTariff[0].amount) {
            SetFee(serviceTariff[0].amount)
        } else {
            SetFee('')
        }
        try {
            const responce = await axiosConfig.get(`/clinics/clinicservices/?service=${res.id}&clinicspeciality__speciality=${res.speciality}`)
            let service_id = responce.data.results[0].service
            const filtered_doctor_tariffs = await axiosConfig.get(`/clinics/tariffs/?clinic=${clinicId ? clinicId : id}&doctor=&service=${service_id}`)
            SetFilteredTariffs(filtered_doctor_tariffs.data.results)
            console.log(filtered_doctor_tariffs.data.results, "filtered tariffs")
            setServiceId(responce.data.results[0].service)
            if (responce.data.results[0].service_details.is_doctor_specific) {
                setSpecificity(true)
            } else {
                setSpecificity(false)
            }
            SetPostdata({
                ...PostData,
                [name]: res.id
            })
        }
        catch (error) {
            console.log(error)

        }
        console.log(doctors, specificity,fee,"////")
    }

    const Handlespecialities = (e) => {
        const { name, value } = e.target
        const find_data = specialFilter.filter(find => find.name && find.name.toLowerCase().includes(value.toLowerCase()))
        SetData(find_data)
    }

    const HandleServices = (e) => {
        const { name, value } = e.target
        const find_data = ServiceFilter.filter(find => find.name && find.name.toLowerCase().includes(value.toLowerCase()))
        setServiceData(find_data)
    }

    const HandlePost = async (e) => {
        console.log(specificity,"specificity")
        e.preventDefault()
        if (specificity && doctors.length > 0) {
            try {
                await Promise.all(
                    doctors_to_post.map(async (doctor_to_post) => {
                        if (filteredTariffs.some(tariff => tariff.doctor === doctor_to_post.doctor)) {
                            const existingTariff = filteredTariffs.find(tariff => tariff.doctor === doctor_to_post.doctor);
                            return axiosConfig.patch(`/clinics/tariffs/${existingTariff.id}/`, doctor_to_post);
                        } else {
                            console.log(doctor_to_post, doctors_to_post, "inside else post")
                            return axiosConfig.post(`/clinics/tariffs/`, doctor_to_post);
                        }
                    })
                );
                setpopupType("success");
                console.log("All requests completed successfully");
            } catch (error) {
                console.log(error);
            }
        }
        else {
            console.log(PostData,"PostData")
            try {
                const existingData = await axiosConfig.get(`/clinics/tariffs/?clinic=${PostData.clinic}&doctor=${PostData.doctor}&service=${PostData.service}`);
                console.log(existingData.data.results, "post data");
                if (existingData.data.results.length > 0) {
                    // Data already exists, send PUT request
                    const response = await axiosConfig.patch(`/clinics/tariffs/${existingData.data.results[0].id}/`, PostData);
                    console.log(response);
                    setpopupType("success");
                } else {
                    // Data does not exist, send POST request
                    const response = await axiosConfig.post(`/clinics/tariffs/`, PostData);
                    console.log(response);
                    setpopupType("success");
                }
            } catch (error) {
                console.log(error);
            }
        }
        window.location.reload();
    }

    const HandleChnage = (e) => {
        console.log(PostData,"post data")
        const { name, value, type, checked } = e.target;
        if (type === 'checkbox') {
            SetPostdata({ ...PostData, [name]: checked });
        } else {
            SetPostdata({ ...PostData, [name]: value });
        }
    };

    const handleMapChange = (e) => {
        console.log(doctors_to_post, "doctors_to_post")
        const doctorId = parseInt(e.target.dataset.doctorId);
        const { name, value } = e.target;

        const existingDoctor = doctors_to_post.find((doctor_to_post) => doctor_to_post.doctor === doctorId);
        console.log(doctors_to_post, doctorId, existingDoctor, value, "doctors_to_post")

        if (existingDoctor) {
            existingDoctor[name] = value;
        } else {
            const newDoctor = {
                doctor: doctorId,
                service: serviceId,
                amount: null,
                doctor_share: null,
                clinic: clinicId ? clinicId : id
            };

            newDoctor[name] = value;
            doctors_to_post.push(newDoctor);
        }
    }

    const handleSpecialityCheck = async (res, event) => {
        let deleteItem = clinicSpeciality.filter((result) => result.speciality_name === res.name)
        if (event.target.checked) {
            await axiosConfig.post(`/clinics/clinicspecialitys/`, {
                "clinic": clinicId ? clinicId : id,
                "speciality": res.id,
                "is_configured": false
            }).then(res => {
                SetPostdata((prev) => ({
                    ...prev,
                    clinicspeciality: res.id
                }))
                getClinicSpecialities();
            }).catch(error => {
                console.log(error)
            })
        } else {
            await axiosConfig.delete(`/clinics/clinicspecialitys/${deleteItem[0].id}/`)
                .then(res => {
                    console.log(res, "deleted item")
                    getClinicSpecialities();
                }).catch(error => {
                    console.log(error)
                })
        }
    }

    const handleServiceCheck = async (res, event) => {
        let deleteItem = clinicService.filter((result) => result.service_details.name === res.name)
        let postItem = clinicSpeciality.filter((result) => result.speciality_name === res.speciality_name)
        setserviceTitle(res.name)
        if (event.target.checked) {
            await axiosConfig.post(`/clinics/clinicservices/`, {
                "service": res.id,
                "clinicspeciality": postItem[0].id,
                "is_configured": false
            }).then(posted_data => {

                getClinicServices(res)
            }).catch(error => {
                console.log(error)
            })
        } else {
            await axiosConfig.delete(`/clinics/clinicservices/${deleteItem[0].id}/`)
                .then(deleted_data => {
                    getClinicServices(res);
                }).catch(error => {
                    console.log(error)
                })
        }
    }

    const OnChildNavClicked = (path) => {
        SetChildNavs(path)
        console.log(path)
        localStorage.setItem("url", path)
    }

    useEffect(() => {
        getTariffs();
        GetDoctors();
        GetData();
        getClinicSpecialities();
        SetChildNavs(localStorage.getItem("url"))
    }, [])

    return (
        <>
            <DashboardLayout>
                <div className="mainbar">
                    <div className="sidebar-filters px-2">
                        {role === "staff" &&
                            Sidebaravalue.map((childItem, index) => {
                                return (
                                    <div key={index}>
                                        <h5>{childItem.SubTitle}</h5>
                                        {
                                            childItem.NavItems.map((item, idx) => {
                                                return (
                                                    <div key={idx} className={ChildNavs == item.path ? "Configiration-list active" : "Configiration-list"}>
                                                        <NavLink to={item.path} className={ChildNavs == item.path ? "colorChange" : "NoColorChange"} onClick={() => OnChildNavClicked(item.path)}>
                                                            <span className="configiration-icon">{item.icon}</span>
                                                            <span className="list-name">{item.label}</span>
                                                        </NavLink>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            })
                        }
                        {role === "admin" &&
                            clinic_profile.map((childItem, index) => {
                                return (
                                    <div key={index}>
                                        <h5>{childItem.SubTitle}</h5>
                                        {
                                            childItem.NavItems.map((item, idx) => {
                                                return (
                                                    <div key={idx} className={ChildNavs == item.path ? "Configiration-list active" : "Configiration-list"}>
                                                        <NavLink to={item.path} className={ChildNavs == item.path ? "colorChange" : "NoColorChange"} onClick={() => OnChildNavClicked(item.path)}>
                                                            <span className="configiration-icon">{item.icon}</span>
                                                            <span className="list-name">{item.label}</span>
                                                        </NavLink>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="main-code">
                        <div className="dashboard-header">
                            <h5 className="dashboard-subtitle">Specialities</h5>
                        </div>
                        <div className="row specialities-main">
                            <div className="col-md-3">
                                <div className="Specialities-master">
                                    <div className="d-flex justify-content-between">
                                        <p className="list-name">Specialities</p>
                                        <input className="form-control form-discount w-50" onChange={Handlespecialities} />
                                    </div>
                                    {
                                        Data.map((res, i) => {
                                            let matchedSpecialities = clinicSpeciality.length > 0 && clinicSpeciality.filter((result) => result.speciality_name === res.name && result.is_configured);
                                            return (
                                                <div key={i} className={res.id === specialties_id ? "specialties-mas Acitve" : "specialties-mas inactive"}>
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id={`speciality${i}`}
                                                            name="clinicspeciality"
                                                            checked={specialtyArray.includes(res.name)}
                                                            value="true"
                                                            onChange={(e) => handleSpecialityCheck(res, e)} />
                                                        <label className="form-check-label" htmlFor={`speciality${i}`}>{res.name}</label>
                                                    </div>
                                                    {specialtyArray.includes(res.name) ?
                                                        <AiFillSetting
                                                            onClick={() => GetServiceData(res)}
                                                            className={matchedSpecialities.length > 0 ? "clinicspeciality acitve" : "clinicspeciality inacitve"}
                                                        /> : ""
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="Specialities-master">
                                    <div className="d-flex justify-content-between">
                                        <p className="list-name">Services</p>
                                        <input className="form-control form-discount w-50" onChange={HandleServices} />
                                    </div>
                                    {
                                        ServiceData.map((res, i) => {
                                            let matchedServices = clinicService.length > 0 && clinicService.filter(result => result.service_details.name === res.name && result.is_configured);
                                            return (
                                                <div key={i} className="specialties-service">
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id={`service${i}`}
                                                            name="service"
                                                            value="true"
                                                            checked={serviceArray.includes(res.name)}
                                                            onChange={(event) => handleServiceCheck(res, event)}
                                                        />
                                                        <label className="form-check-label" htmlFor={`service${i}`}>{res.name}</label>
                                                    </div>
                                                    {serviceArray.includes(res.name) ?
                                                        <AiFillSetting
                                                            onClick={() => HandClincserviceApi(res, "service", res.name)}
                                                            className={matchedServices.length > 0 ? "clinicspeciality acitve" : "clinicspeciality inacitve"}
                                                        /> : ""
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="Specialities-master">
                                    <p>{servicetitle}</p>
                                    <form>
                                        {doctors.length > 0 && specificity ?
                                            (<div>
                                                <div className="row justify-content-around">
                                                    <div className="col-1"></div>
                                                    <div className="col-3"></div>
                                                    <div className="col-4 text-center">
                                                        <p>Price</p>
                                                    </div>
                                                    <div className="col-4 text-center">
                                                        <p>Doctor Share %</p>
                                                    </div>
                                                </div>

                                                {(doctors.map((doctor, doc_idx) => {
                                                    const matchingTariff = filteredTariffs.find(tariff => tariff.doctor === doctor.id);
                                                    let amountValue = "";
                                                    let doctorShareValue = "";
                                                    if (matchingTariff) {
                                                        amountValue = matchingTariff.amount;
                                                        doctorShareValue = matchingTariff.doctor_share;
                                                        doctors_to_post.push({
                                                            doctor: matchingTariff.doctor,
                                                            service: serviceId,
                                                            amount: amountValue,
                                                            doctor_share: doctorShareValue,
                                                            clinic: clinicId ? clinicId : id
                                                        })
                                                    }
                                                    return (
                                                        <div className="row justify-content-around" key={doc_idx}>
                                                            <div className="col-4">
                                                                <p>{doctor.name}</p>
                                                            </div>
                                                            <div className="col-4">
                                                                <input type="text" className="form-control form-discount" name="amount" defaultValue={amountValue} data-doctor-id={doctor.id} onChange={(e) => handleMapChange(e)} />
                                                            </div>
                                                            <div className="col-4">
                                                                <input type="text" className="form-control form-discount" name="doctor_share" defaultValue={doctorShareValue} data-doctor-id={doctor.id} onChange={(e) => handleMapChange(e)} />
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                )}
                                            </div>
                                            ) : (
                                                <div className="form-group row">
                                                    <label className="col-sm-2 col-form-label">Fee:</label>
                                                    <div className="col-sm-8">
                                                        <input
                                                            type="text"
                                                            className="form-control form-discount w-50"
                                                            name="amount"
                                                            defaultValue={fee}
                                                            onChange={HandleChnage}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        }
                                        <div className="row">
                                            <div className="col-2">
                                                <button type="submit" className="btn btn-primary" onClick={(e) => HandlePost(e)}>Save</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {popupType != "" &&
                    <PopupModal popupType={popupType} />
                }
            </DashboardLayout>
        </>
    )

}
export { ClinicSpecialites }
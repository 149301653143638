import React, { useState, useEffect } from "react"
import "./BannerSliders.css"
import axiosConfig from "../../Service/axiosConfig"
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const BannersSliders = ({ type }) => {

    var settings = {
        centerMode: true,
        centerPadding: '60px',
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    centerPadding: '30px'
                }
            },
            {
                breakpoint: 766,
                settings: {
                    centerMode: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerPadding: '30px'
                }
            }
        ]
    };


    const [Data, SetData] = useState([])

    const GetData = async () => {
        try {
            const responce = await axiosConfig.get("/catlog/banners/?banner_type=slider&page_size=7&is_suspended=false")
            console.log(responce)
            SetData(responce.data.results)
        }
        catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        GetData();
        console.log("banner data", Data)
    }, [])

    return (
        <>
            <div className="banners-container">
                <div className="container">
                    {type === "slide" ? (
                        <Slider {...settings}>
                            {Data.map((bannerItem) => (
                                <div className="banner-item" key={bannerItem.id}>
                                    <div className="banner-img">
                                        <img src={bannerItem.image&&bannerItem.image.image} alt={`Banner ${bannerItem.id}`} />
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    ) : (
                       <div  className="banner-item-grid">
                        { Data.map((bannerItem) => (
                                <div className="banner-img-grid" key={bannerItem.id}>
                                    <img src={bannerItem.image&&bannerItem.image.image} alt={`Banner ${bannerItem.id}`} />
                                </div>
                        ))}
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}
export { BannersSliders };

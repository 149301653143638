import React from "react";
import { DashboardLayout } from "../Components";
const ClientAri = () => {
    return (
        <div>
            <DashboardLayout>
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">View Client ARI</h5>
                </div>
            </DashboardLayout>
        </div>
    )
}
export { ClientAri }
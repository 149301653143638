import React, { useEffect, useState } from "react";
import { DashboardLayout, FormParser, PopupModal } from "../Components";
import { NavLink, useParams } from "react-router-dom";
import Masters from "../Components/masters.json"
import "./Pages.css"
import axiosConfig from "../Service/axiosConfig"
import sidebarData from "../Components/DashboardLayout/sidebar";
import {IoIosPeople} from "react-icons/io"
import {BsArrowLeftShort} from "react-icons/bs"
const ClinicGalleryAdminEdit = () => {
    let {id}=useParams()
    const [Error, SetError] = useState("")
    const [popupType, setpopupType] = useState("")

    const [activeState, setActiveState] = useState("")
    const [ChildNavs, SetChildNavs] = useState("")
    const clinicProfile=[
        {
            "SubTitle":"Clinics",
            "NavItems":[
                {"label":"Clinic","path":`/dashboard/clinics/${id}/edit`,"icon":<IoIosPeople fontSize='small'/>},
                {"label":"Clinics Specialites","path":`/dashboard/clinics/${id}/specialities`,"icon":<IoIosPeople fontSize='small'/>},
                {"label":"Clinics Availability","path":`/dashboard/clinics/${id}/clinic_availability`,"icon":<IoIosPeople fontSize='small'/>},
                {"label":"Clinics Gallery","path":`/dashboard/clinic/${id}/gallery`,"icon":<IoIosPeople fontSize='small'/>},
                {"label":"Clinics Vacations","path":`/dashboard/clinics/${id}/clinic_vacations`,"icon":<IoIosPeople fontSize='small'/>}
            ]
        }
    ]
    const Sidebaravalue = clinicProfile
    const MastersModal = Masters.ClinicGallery
    let data = localStorage.getItem("user_data")
    const formSubmit = (Data) => {
        axiosConfig.defaults.headers['Content-Type'] = 'multipart/form-data';
        axiosConfig.patch(`/clinics/clinicgallery/${JSON.parse(data) && JSON.parse(data).clinic_id}/`, Data).then(res => {
            console.log(res)
            setpopupType("success")
        }).catch(error => {
            console.log(error)
            let ErrorData = []
            ErrorData.push({
                code: error.response.data.code || ["null"],
                name: error.response.data.name || ["null"],
            })
            console.log(ErrorData[0])
            if (ErrorData[0].code[0] === "This field must be unique.") {
                SetError("Code Already Exists")
            }
            if (ErrorData[0].name[0] === "This field must be unique.") {
                SetError("Name Already Exists")
            }
            setpopupType("error")
        })
    }
    const [Data, SetData] = useState([])
    const GetData = async () => {
        try {
            const responce = await axiosConfig.get(`/clinics/clinicgallery/${JSON.parse(data) && JSON.parse(data).clinic_id}/`)
            SetData(responce.data)
        }
        catch (error) {
            console.log(error)

        }

    }
    useEffect(() => {
        GetData();
        SetChildNavs(localStorage.getItem("url"))
    }, [])
    const currentURL = window.location.href;
    const OnChildNavClicked = (path) => {
        SetChildNavs(path)
        localStorage.setItem("url",path)
    }
    return (
        <>
            <DashboardLayout>
                <div className="mainbar">
                    <div className="sidebar-filters px-2">
                    <NavLink to="/dashboard/clinics/list" ><button type="submit" className="btn btn-primary"><BsArrowLeftShort/>back to Clinics</button></NavLink>
                        {
                            Sidebaravalue.map((childItem, index) => {
                                return (
                                    <div key={index}>
                                        <h5>{childItem.SubTitle}</h5>
                                        {
                                            childItem.NavItems.map((item, idx) => {
                                                return (
                                                    <div key={idx} className={ChildNavs == item.path ? "Configiration-list active" : "Configiration-list"}>
                                                        <NavLink to={item.path} className={ChildNavs == item.path ? "colorChange" : "NoColorChange"} onClick={() => OnChildNavClicked(item.path)}>
                                                            <span className="configiration-icon">{item.icon}</span>
                                                            <span className="list-name">{item.label}</span>
                                                        </NavLink>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="main-code">
                        <div className="dashboard-header">
                            <h5 className="dashboard-subtitle">Clinic Gallery</h5>
                        </div>
                        <FormParser modelObject={MastersModal} formSubmit={formSubmit} formData={Data} error={Error} />
                        {/* <FormParser modelObject={MastersModal} formSubmit={formSubmit} error={Error} /> */}
                        <h3>Gallery</h3>
                        <div className="container">
                            <div className="row">
                                <div className="col-2">
                                    <div className="card">
                                        <img className="card-img-top" src={Data.image} alt="Card Image" />
                                        <div className="card-body">
                                            <h5 className="card-title">{Data.caption}</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className="card">
                                        <img className="card-img-top" src={Data.image} alt="Card Image" />
                                        <div className="card-body">
                                            <h5 className="card-title">Title</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {popupType != "" &&
                    <PopupModal popupType={popupType} Navigation="" />
                }
            </DashboardLayout>
        </>
    )

}
export { ClinicGalleryAdminEdit }

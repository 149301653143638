import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { MdTune, MdOutlineNextPlan, MdStorefront, MdOutlineMarkEmailRead, MdOutlinePayment, MdOutlineLocalShipping, MdOutlinePolicy,MdDeliveryDining } from "react-icons/md";
import { BsPeople, BsCashStack } from "react-icons/bs";
import { SlCalculator } from "react-icons/sl";
import { FaReact } from "react-icons/fa";


const SettingNavbarComponent = () => {
    const ecommerce_type = JSON.parse(localStorage.getItem("site_data")).ecommerce_type;
    return (
        <div className="categorie-header">
            <NavLink to="/dashboard/masters/sitesettings" className="categorie-subtitle" ><div className="child-title">
                <MdTune size={24} className="childnav-icon" />
                <p className="childnav-name">Site Settings</p>
            </div></NavLink>
            <NavLink to="/dashboard/masters/emailgateway" className="categorie-subtitle" ><div className="child-title">
                <MdOutlineMarkEmailRead size={24} className="childnav-icon" />
                <p className="childnav-name">Email Gateway</p>
            </div></NavLink>
            <NavLink to="/dashboard/masters/paymentgateway" className="categorie-subtitle" ><div className="child-title">
                <MdOutlinePayment size={24} className="childnav-icon" />
                <p className="childnav-name">Payment Gateway</p>
            </div></NavLink>
            {/* <NavLink to="/dashboard/masters/staff" className="categorie-subtitle" ><div className="child-title">
                <BsPeople size={24} className="childnav-icon" />
                <p className="childnav-name">Staff</p>
            </div> </NavLink> */}
            <NavLink to="/dashboard/masters/policies" className="categorie-subtitle" ><div className="child-title">
                <MdOutlinePolicy size={24} className="childnav-icon" />
                <p className="childnav-name">Policies</p>
            </div> </NavLink>
            <NavLink to="/dashboard/masters/taxsettings" className="categorie-subtitle" ><div className="child-title">
                <BsCashStack size={24} className="childnav-icon" />
                <p className="childnav-name">Tax Settings</p>
            </div></NavLink>
            {/* <NavLink to="/dashboard/masters/shipment" className="categorie-subtitle" ><div className="child-title">
                <MdOutlineLocalShipping size={24} className="childnav-icon" />
                <p className="childnav-name">Shipment & Fulfillment</p>
            </div></NavLink> */}
            {ecommerce_type === "Hyperlocal" ?
                <NavLink to="/dashboard/masters/configure_charges_hyperlocal" className="categorie-subtitle" ><div className="child-title">
                    <SlCalculator size={24} className="childnav-icon" />
                    <p className="childnav-name">Configure Charges</p>
                </div></NavLink>
                :
                <NavLink to="/dashboard/masters/configure_charges_conventional" className="categorie-subtitle" ><div className="child-title">
                    <SlCalculator size={24} className="childnav-icon" />
                    <p className="childnav-name">Configure Charges</p>
                </div></NavLink>
            }
            {/* <NavLink to="/dashboard/masters/manageplans" className="categorie-subtitle" ><div className="child-title">
                <MdOutlineNextPlan size={24} className="childnav-icon" />
                <p className="childnav-name">Manage Plans</p>
            </div></NavLink> */}
            <NavLink to="/dashboard/admin/profile" className="categorie-subtitle" ><div className="child-title">
                <MdStorefront size={24} className="childnav-icon" />
                <p className="childnav-name">Stores</p>
            </div></NavLink>
            <NavLink to="/dashboard/masters/shipping" className="categorie-subtitle" ><div className="child-title">
                <MdOutlineLocalShipping size={24} className="childnav-icon" />
                <p className="childnav-name">Shipping Methods</p>
            </div></NavLink>
            <NavLink to="/dashboard/masters/delivery-agent" className="categorie-subtitle" ><div className="child-title">
                <MdDeliveryDining size={24} className="childnav-icon" />
                <p className="childnav-name">Delivery Agents</p>
            </div></NavLink>
            <NavLink to="/dashboard/masters/react-url" className="categorie-subtitle" ><div className="child-title">
                <FaReact  size={24} className="childnav-icon" />
                <p className="childnav-name">ReactUrls</p>
            </div></NavLink>
        </div>
    )
}

export { SettingNavbarComponent }
import { useState, useEffect } from "react";
import { CMSNavbarComponent } from "../Components/navbar";
import { FormParser } from "../Components";
import Masters from "../Components/masters.json";
import { MdOutlineEdit } from "react-icons/md";
import axiosConfig from "../Service/axiosConfig";
import { PopupModal } from "../Components";
const AboutUs = () => {
    const MastersModal = Masters.AboutUs;
    const [formData, setFormData] = useState({})
    const [readOnly, setReadOnly] = useState(true)
    const [popupType, setpopupType] = useState("")
    const [message, setMessage] = useState(null)
    const formSubmit = async (Data) => {
        try {
            const data_to_send = {
                ...Data,
                main_image: Data.main_image?.id,
                sub_image1: Data.sub_image1?.id,
                sub_image2: Data.sub_image2?.id,
                sub_image3: Data.sub_image3?.id
            }
            if (Data.id) {
                const response = await axiosConfig.patch(`cms/aboutus/${Data.id}/`, data_to_send)
                setMessage("About Us details updated successfully")
            } else {
                const response = await axiosConfig.post(`cms/aboutus/`, data_to_send)
                setMessage("About Us details added successfully")
            }
            setReadOnly(true)
            setpopupType("success")
        } catch (error) {
            console.log(error)
        }
    };
    const GetData = async () => {
        try {
            const responce = await axiosConfig.get(`cms/aboutus/`);
            if (responce.data.results.length > 0) {
                setFormData(responce.data.results[0]);
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        GetData();
    }, [])

    return (
        <div className="child-sidenav">
            <CMSNavbarComponent />
            <div className="childnav-content">
                <FormParser modelObject={MastersModal} formData={formData} readOnly={readOnly} buttonTitle="Save" formSubmit={formSubmit} />
                {readOnly &&
                    <button className={`btn btn-primary d-flex align-items-center`} onClick={() => setReadOnly(false)}>
                        <div>Edit</div>
                        <MdOutlineEdit className="ms-5" />
                    </button>
                }
                {popupType !== "" &&
                    <PopupModal setpopupType={setpopupType} popupType={popupType} Navigation="" Message={message} />
                }
            </div>
        </div>
    )
}

export { AboutUs }
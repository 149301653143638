import React, { useEffect, useState } from "react";
import { DashboardLayout, FormParser, PopupModal } from "../Components";
import { NavLink, useParams } from "react-router-dom";
import Masters from "../Components/masters.json"
import "./Pages.css"
import axiosConfig from "../Service/axiosConfig"
import {BsArrowLeftShort} from "react-icons/bs"
import sidebarData from "../Components/DashboardLayout/sidebar";

const DoctorRegestrationCliniEdit = ({children}) => {
    const [popupType, setpopupType] = useState("");
    const[formerrorData,SetformErrorData]=useState({})
    const MastersModal = Masters.DoctorRegestrationEdit
    const AdminModal=Masters.adminDoctorRegestrationEdit
    const[ChildNavs,SetChildNavs]=useState("")
    const [Error, SetError] = useState("")
    const [Data, SetData] = useState([])
    let {id}=useParams()
    let user_data=JSON.parse(localStorage.getItem("user_data"))
    let Master={"DoctorProfile": [
        {
            "SubTitle":"Doctor Profile",
            "NavItems":[
                {"label":"Doctor Info","path":`/dashboard/doctors/${id}`},
                {"label":"Specialites","path":`/dashboard/profile/${id}/doctor_specialities`},
                {"label":"Availability","path":`/dashboard/users/${id}/doctor_availability`},
                {"label":"Vacations","path":`/dashboard/users/${id}/vacations`}
            ]
        }
    ],
    }
    if(user_data.user_type == "doctor"){
        Master=sidebarData
    }
        const[activeState,setActiveState]=useState("")
        
    const formSubmit = (Data) => {
        axiosConfig.defaults.headers['Content-Type'] = 'multipart/form-data';
        let data=[]
        const formData=new FormData()
        formData.append("title",Data.title)
        formData.append("name",Data.name)
        if(typeof(Data.image) == "object"){
            formData.append("image",Data.image)
        }
        Data.qualification.forEach(element => {
            formData.append("qualification",element)
        });
        formData.append("registration_number",Data.registration_number)
        formData.append("about",Data.about)
        formData.append("email",Data.email)
        formData.append("mobileno",Data.mobileno)
        formData.append("first_name",Data.first_name)
        formData.append("last_name",Data.last_name)
        formData.append("address",Data.address)
        formData.append("user_type",user_data.user_type)
        formData.append("clinic",user_data.clinic_id?user_data.clinic_id:Data.clinic)
            axiosConfig.patch(`/clinics/doctors/${user_data.doctor_id ? user_data.doctor_id : id}/`, formData).then(res => {
                console.log(res)
                setpopupType("success")
            }).catch(error => {
                console.log(error.data.response)
                let ErrorData=[]
                ErrorData.push({
                    code:error.response.data.code||["null"],
                    name:error.response.data.name||["null"],
                    })
                    console.log(ErrorData[0])
                    if(error.response.data.detail==="User with this email already exists."){
                        SetError("Email Already Exists")
                    }
                    if(error.response.data.detail==="User with this  mobile number already exists."){
                        SetError("Mobile Number Already Exists")
                    }
                setpopupType("error")
            })
    }
    const currentURL = window.location.href;
    const GetData = async () => {
        try {
            const responce = await axiosConfig.get(`/clinics/doctors/${user_data.doctor_id ? user_data.doctor_id : id}/`)
            let doctor=responce.data
            const userData = {
                about: responce.data.about,
                clinic: responce.data.clinic,
                clinic_name: responce.data.clinic_name,
                image: responce.data.image,
                name: responce.data.name,
                qualification: responce.data.qualification,
                qualification_name: responce.data.qualification_name,
                registration_number: responce.data.registration_number,
                title: responce.data.title,
                title_code: responce.data.title_code,
                address: responce.data.user.address,
                email: responce.data.user.email,
                first_name:responce.data.user.first_name,
                last_name: responce.data.user.last_name,
                mobileno: responce.data.user.mobileno,
                user_type: responce.data.user.user_type
              };
            SetData(userData)
        }
        catch (error) {
            console.log(error)
            
        }

    }
    useEffect(()=>{
        GetData();
    },[])
    const OnChildNavClicked=(path)=>{
        SetChildNavs(path)
        localStorage.setItem("url",path)
    }
    return (
        <>
            <DashboardLayout>
            <div className="mainbar">
                    <div className="sidebar-filters px-2">
                    {user_data&&user_data.user_type!="doctor" &&
                        <NavLink to="/dashboard/doctors/list" ><button type="submit" className="btn btn-primary"><BsArrowLeftShort/>back to doctors</button></NavLink>
                    }
                    {
                            Master.DoctorProfile.map((childItem, index) => {
                                return (
                                    <div key={index}>
                                        <h5>{childItem.SubTitle}</h5>
                                        {
                                            childItem.NavItems.map((item, idx) => {
                                                return (
                                                    <div key={idx} className={ChildNavs == item.path ? "Configiration-list active" : "Configiration-list"}>
                                                        <NavLink to={item.path} className={ChildNavs == item.path ? "colorChange" : "NoColorChange"} onClick={() => OnChildNavClicked(item.path)}>
                                                            <span className="configiration-icon">{item.icon}</span>
                                                            <span className="list-name">{item.label}</span>
                                                        </NavLink>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            })
                        }
                        </div>
                    <div className="main-code">
                    <div className="dashboard-header">
                <h5 className="dashboard-subtitle">Edit Doctor</h5>
                </div>{console.log(Data)}
                {user_data&& (user_data.user_type==="staff" || user_data.user_type==="doctor")&&<FormParser modelObject={MastersModal} formData={Data} formSubmit={formSubmit} error={Error}/>}
                {user_data&&user_data.user_type==="admin"&&<FormParser modelObject={AdminModal} formData={Data} formSubmit={formSubmit} error={Error}/>}
                        </div>
                        </div>
                {popupType != "" &&
                    <PopupModal popupType={popupType} Navigation={user_data.user_type == "doctor" ?"/dashboard/profile": "/dashboard/doctors/list"}/>
                }
            </DashboardLayout>
        </>
    )

}
export { DoctorRegestrationCliniEdit }
import React, { useState, useEffect } from "react";
import { DashboardLayout } from "../Components";
import { ReportsNav } from "../Components/navbar";
import DataTable from 'react-data-table-component';
import tableCustomStyles from "../Components/PopupModal/tableCustomStyles";
import axiosConfig from "../Service/axiosConfig";
import Select from 'react-select';
import { AiOutlineFilePdf, AiOutlineFileExcel } from "react-icons/ai";
import { downloadExcel } from 'react-export-table-to-excel';
import { useNavigate } from "react-router"

const ClientWiseSales = () => {

    const [data, setData] = useState([]);
    const [stats, setStats] = useState({});
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(20);
    const [loading, setLoading] = useState(false);
    const [selectedClient, setSelectedClient] = useState({ label: "All Stores", value: null });
    const Store_id = JSON.parse(localStorage.getItem('Store'));
    const [formData, setFormData] = useState({
        fromDate: (new Date()).toISOString().split('T')[0],
        toDate: (new Date()).toISOString().split('T')[0],
        store: Store_id?.id
    });
    const [users, setUsers] = useState([]);
    const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    const Navigate = useNavigate();

    const columns = [
        {
            name: 'S. No.',
            selector: (row, index) => index + 1,
            sortable: true,
        },
        {
            name: 'User',
            selector: 'user_name',
            sortable: true,
        },
        {
            name: 'Sale Value',
            selector: 'total_amount',
            sortable: true,
        },
        {
            name: 'Quantity',
            selector: 'qty',
            sortable: true,
        }
    ];

    const multiSelectStyles = {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#c9dddc' : 'white', // Change background color for selected option
            color: state.isSelected ? '#1b7d78' : 'black', // Change text color for selected option
            ':hover': {
                backgroundColor: '#c9dddc', // Change background color on hover for options
            },
        }),
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const header = [
        "S.No",
        "User",
        "Sale Value",
        "Quantity",
    ];
    const body = data.map((res, index) => [index + 1, res.user_name, res.total_amount, res.qty])

    const HandleSelect = (selectedOption) => {
        setSelectedClient(selectedOption);
        setFormData((prevFormData) => ({
            ...prevFormData,
            store: selectedOption.value
        }))
    };

    function handleDownloadExcel() {
        downloadExcel({
            fileName: "User & Product Report",
            sheet: "User & Product Report",
            tablePayload: {
                header: header,
                body: body,
            },
        });
    }

    const HandlePdf = (data) => {
        localStorage.setItem('pdf_data', JSON.stringify(data));
        window.open(`${window.location.origin}/print`, '_blank');
    }

    const handleSort = (column, direction) => {
        console.log(column, direction)
        // axiosConfig.get(`/accounts/orders/?orderstatus=Delivered&store=${formData.store ? formData.store : ''}&${selectedDateType === 'ordered_date' ? 'order_date_after' : 'expected_date_time_before'}=${formData.fromDate}&${selectedDateType === 'ordered_date' ? 'order_date_before' : 'expected_date_time_after'}=${formData.toDate}&page=${currentPage}&page_size=${RowPerPage}&ordering=${direction === "asc" ? column.name : `-${column.selector}`}`).then(res => {
        //     SetData(res.data.results)
        // setStats({
        //     "average_qty": response.data.average_qty,
        //     "average_amount": response.data.average_amount,
        //     "total_qty": response.data.total_qty,
        //     "total_amount": response.data.total_amount
        // })
        // }).catch(error => {
        //     console.log(error)
        // })
        // setCurrentPage(1);
    };

    const CustomHeader = () => {
        return (
            <div className="rowEndCenter gap-10">
                <AiOutlineFileExcel
                    className="report-icons"
                    onClick={handleDownloadExcel}
                />
                <AiOutlineFilePdf
                    className="report-icons"
                    onClick={() => HandlePdf(data)}
                />
            </div>
        );
    };

    const GetData = async () => {
        try {
            const response = await axiosConfig.get(`/accounts/generate_user_sale_reports/?from_date=${formData.fromDate}&to_date=${formData.toDate}&store=${formData.store ? formData.store : ''}`)
            const data = response.data.data
            console.log("Clientwise Data", data)
            setData(data)
            setStats({
                "average_qty": response.data.average_qty,
                "average_amount": response.data.average_amount,
                "total_qty": response.data.total_qty,
                "total_amount": response.data.total_amount
            })
        } catch (error) {
            console.log(error)
        }
    }

    const GetClients = async () => {
        try {
            const response = await axiosConfig.get(`/stores/stores/?page_size=100`)
            const clients = response.data.results
            console.log(clients)
            setUsers(clients)
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        GetData();
        GetClients();
    }, [])

    return (
        <>
            <div className="child-sidenav">
                <ReportsNav />
                <div className="flex-1 reports-parent">
                    <div className="report-filters">
                        <Select
                            className="flex-1"
                            placeholder="All Stores"
                            options={[{ label: "All Stores", value: null }].concat(users.map((user) => ({ label: user.name, value: user.id })))}
                            onChange={(selectedOption) => HandleSelect(selectedOption)}
                            styles={multiSelectStyles}
                            value={users.map(res => ({ "value": res.id, "label": res.name })).find(res => res.value === formData.store)}
                            isDisabled={Store_id?.id ? true : false}
                        />
                        <div className="form-group flex-1">
                            <input
                                className="form-control"
                                type="date"
                                name="fromDate"
                                value={formData.fromDate}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group flex-1">
                            <input
                                className="form-control"
                                type="date"
                                name="toDate"
                                value={formData.toDate}
                                onChange={handleChange}
                            />
                        </div>
                        <button className="btn btn-primary" style={{ height: '37px' }} onClick={() => GetData()}>Get Reports</button>
                    </div>
                    <div className="row" style={{ margin: "16px" }}>
                        <div className="col-3 f_14_500">Total Sale Rs : <span className="f_14_400">{stats.total_amount}</span></div>
                        <div className="col-3 f_14_500">Total Qty : <span className="f_14_400"> {stats.total_qty}</span> </div>
                    </div>
                    <DataTable columns={columns} data={data} pagination paginationTotalRows={totalRows}
                        title={<CustomHeader />}
                        paginationPerPage={RowPerPage}
                        paginationDefaultPage={currentPage}
                        onChangePage={(page) => setCurrentPage(page)}
                        paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
                        paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
                        onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
                        onSort={handleSort}
                        paginationServer
                        customStyles={tableCustomStyles}
                        progressPending={loading}
                        progressComponent={<div className="loader-container">
                            <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                        </div>}
                    />
                </div>
            </div>
        </>
    )
}

export { ClientWiseSales }
import React, { useEffect, useState, useRef } from "react";
import { DashboardLayout, FormParser, PopupModal } from "../Components";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import Masters from "../Components/masters.json"
import './Pages.css';
import axiosConfig from "../Service/axiosConfig"
import { RxCross2 } from "react-icons/rx"
import { EMRData } from "./EMRData";
import { EMRinput } from "./EMRinput";
import { RiCheckboxCircleFill, RiCheckboxBlankCircleLine } from "react-icons/ri"
import { FaRegUserCircle } from "react-icons/fa"
import { MdCheckCircleOutline, MdCheckCircle } from "react-icons/md"
import Select from 'react-select';
import sidebarData from "../Components/DashboardLayout/sidebar";
import { BiArrowBack } from "react-icons/bi"
import { all } from "axios";
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { MdDateRange, MdMedication } from "react-icons/md"
import { FaUserDoctor } from "react-icons/fa"
import { BsFillPrinterFill, BsPersonCircle } from "react-icons/bs"
const Emr = () => {
    let { id, status, patient, bill_item } = useParams();
    let navigate = useNavigate()
    let MastersModal = sidebarData.EMR
    const [getFormcomp, Setformcomp] = useState({ complaints: [] })
    const [getFormdignosis, Setformdignosis] = useState({ diagnosis: [] })
    const [getFormRx, SetformRx] = useState({ rx: [] })
    const [vitalvalue, Setvitalvalue] = useState([])
    const [PataientData, SetPataientData] = useState({})
    const [popupType, setpopupType] = useState("")
    const [Error, SetError] = useState("")
    const [Choose, SetChoose] = useState([])
    const [FormData, SetFormData] = useState({ vitals: [], complaints: [], diagnosis: [], rx: [] })
    const [PastVisits, setPastVisits] = useState([])
    const [PatientRecords, setPatientRecords] = useState([])
    const [complaintsFormData, SetcomplaintsFormdata] = useState({})
    const [Complaints, setcomplaints] = useState([
        { label_name: 'Complaints', "name": "complaint_id", options_list: [] },
        { label_name: 'Frequency', "name": "frequency_id", options_list: [] },
        { label_name: 'Severity', "name": "severity_id", options_list: [] },
        { label_name: 'Duration', "name": "duration", "type": "text", options_list: [] },
        { label_name: 'Span', "name": "span_id", options_list: [] },
        { label_name: 'Date', "type": "text", "name": "date", options_list: [] }
    ])
    const [Rx, setRx] = useState([
        { label_name: 'Medicine', name: 'medicine_id', options_list: ['Dolo 650', 'Paracitamol', 'Azithromicine'] },
        { label_name: 'Dose', name: 'dose_id', options_list: ['1-0-1', '1-1-1', '1-0-0', '0-1-1'] },
        { label_name: 'When', name: 'when_id', options_list: ['Before Breakfast', 'Before Lunch',] },
        { label_name: 'Frequency', name: 'frequency_id', options_list: ['Daily', 'Alternate Day'] },
        { label_name: 'Duration', name: 'duration', "type": "text", options_list: [] },
        { label_name: 'Span', name: 'span_id', options_list: ['day', 'week', 'month', 'year'] },
        { label_name: 'Notes', "type": "text", "name": "notes", options_list: [] }
    ])
    const [Vitals, SetVitals] = useState([])
    const [Inesigations, setInvestigations] = useState([])

    const options = [
        { value: 'option1', label: 'Option 1' },
        { value: 'option2', label: 'Option 2' },
        { value: 'option3', label: 'Option 3' },
        { value: 'option4', label: 'Option 4' },
    ];


    const [Diagnosis, setDiagnosis] = useState([
        { label_name: 'Diagnosis', name: 'diagnosis_id', options_list: ['FEVER', 'COUGH', 'COLD', 'VOMITING', 'LOOSE MOTION', 'ABDOMINAL PAIN', 'NASAL BLOCKAGE', 'REDUCED APETITE', 'FEVER', 'COUGH', 'COLD', 'VOMITING', 'LOOSE MOTION', 'ABDOMINAL PAIN', 'NASAL BLOCKAGE', 'REDUCED APETITE'] },
        { label_name: 'Duration', name: 'duration', "type": "text", options_list: [] },
        { label_name: 'Span', name: 'span_id', options_list: ['day', 'week', 'month', 'year'] },
        { label_name: 'Date', "type": "text", "name": "date", options_list: [] }
    ])
    const [topPosition, setTopPosition] = useState(82);
    const complaintHeaders = ['Complaints', 'Frequency', 'Severity', 'Duration', 'Date']
    const dropRef = useRef(null)
    const [show, setShow] = useState(true)

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        section.scrollIntoView({ behavior: 'smooth' });
    };
    const HandleSmootScroll = (sectionId) => {
        if (status === "InProgress") {
            scrollToSection(sectionId);
        } else if (status === "Scheduled") {
            scrollToSection(sectionId);
        } else {
        }
    }
    let user_data = JSON.parse(localStorage.getItem("user_data"))
    const GetData = async () => {
        try {
            const responce = await axiosConfig.get(`/clinics/bill-items/${id}/`)
            SetPataientData(responce.data)
        }
        catch (error) {
            console.log(error)
        }

    }
    const GetbilltData = async () => {
        try {
            const emrdata = await axiosConfig.get(`/clinics/emr/?clinic=${user_data.clinic_id ? user_data.clinic_id : ""}&patient=${patient}&doctor=${user_data.doctor_id ? user_data.doctor_id : ""}&bill_item=${id}&bill_item__bill_item_type=${bill_item}`)
            emrdata.data.results.forEach(async (emrdata) => {
                const responce = await axiosConfig.get(`/clinics/emr_summary/?patient_id=${patient}&bill_item_type=${bill_item}&emr_id=${emrdata.id}`)
                responce.data.forEach(data => {
                    Setvitalvalue(data.vitals)
                    data.vitals && data.vitals.forEach(vital => {
                        SetFormData(prevDoctor => ({
                            ...prevDoctor,
                            vitals: [...prevDoctor.vitals, { "vital_id": vital.vital, "value": vital.vital_value }],
                        }));
                        if (vital) {
                            SetChoose(pre => [...pre, "Vitals"])
                        }
                    })
                    data.complaints && data.complaints.forEach(complaints => {
                        SetFormData(prevDoctor => ({
                            ...prevDoctor,
                            complaints: [...prevDoctor.complaints, { "complaint_id": complaints.complaint, "frequency_id": complaints.frequency, "severity_id": complaints.severity, "duration": complaints.duration, "span_id": complaints.span, "date": complaints.date }],
                        }));

                        if (complaints) {
                            SetChoose(pre => [...pre, "Complaints"])
                            const newComplaint = {
                                value: complaints.complaint,
                                label: complaints.complaint_name
                            };

                            const newFrequency = {
                                value: complaints.frequency,
                                label: complaints.frequency_name
                            };

                            const newSeverity = {
                                value: complaints.severity,
                                label: complaints.severity_name
                            };

                            const newDuration = {
                                value: complaints.duration,
                                label: 'duration'
                            };

                            const newSpan = {
                                value: complaints.span,
                                label: complaints.span_name
                            };

                            const newDate = {
                                value: complaints.date,
                                label: 'date'
                            };

                            Setformcomp(prevDoctor => ({
                                ...prevDoctor,
                                complaints: [...prevDoctor.complaints, [
                                    newComplaint,
                                    newFrequency,
                                    newSeverity,
                                    newDuration,
                                    newSpan,
                                    newDate
                                ]]
                            }));
                        }
                    })
                    data.diagnosis && data.diagnosis.forEach(diagnosis => {
                        SetFormData(prevDoctor => ({
                            ...prevDoctor,
                            diagnosis: [...prevDoctor.diagnosis, { diagnosis_id: diagnosis.diagnosis, "duration": diagnosis.duration, "span_id": diagnosis.span, "date": diagnosis.date }],
                        }));
                        if (diagnosis) {
                            const newDiagnosis = {
                                value: diagnosis.diagnosis,
                                label: diagnosis.diagnosis_name
                            };

                            const newDuration = {
                                value: diagnosis.duration,
                                label: "duration"
                            };

                            const newSpan = {
                                value: diagnosis.span,
                                label: diagnosis.span_name
                            };

                            const newDate = {
                                value: diagnosis.date,
                                label: 'date'
                            };

                            Setformdignosis(prevDoctor => ({
                                ...prevDoctor,
                                diagnosis: [...prevDoctor.diagnosis, [
                                    newDiagnosis,
                                    newDuration,
                                    newDuration,
                                    newSpan,
                                    newDate
                                ]]
                            }));
                            SetChoose(pre => [...pre, "Diagnosis"])
                        }
                    })
                    data.rx && data.rx.forEach((rx, i) => {
                        console.log(rx)
                        SetFormData(prevDoctor => ({
                            ...prevDoctor, advice: data.advice, "investigations_ids": data.investigations_ids,
                            rx: [...prevDoctor.rx, { "medicine_id": rx.drug, "dose_id": rx.dose, "when_id": rx.when, "frequency_id": rx.frequency, "duration": rx.duration, "span_id": rx.span, "notes": rx.notes }],
                        }));

                        if (rx) {
                            const newDrug = {
                                value: rx.drug,
                                label: rx.drug_name
                            };

                            const newDose = {
                                value: rx.dose,
                                label: rx.dose_name
                            };

                            const newWhen = {
                                value: rx.when,
                                label: rx.when_name
                            };
                            const newFrequency = {
                                value: rx.frequency,
                                label: rx.frequency_name
                            };
                            const newDuration = {
                                value: rx.duration,
                                label: 'duration'
                            };

                            const newSpan = {
                                value: rx.span,
                                label: rx.span_name
                            };

                            const newNotes = {
                                value: rx.notes,
                                label: 'notes'
                            };

                            SetformRx(prevDoctor => ({
                                ...prevDoctor,
                                rx: [...prevDoctor.rx, [
                                    newDrug,
                                    newDose,
                                    newWhen,
                                    newFrequency,
                                    newDuration,
                                    newSpan,
                                    newNotes
                                ]]
                            }));
                            SetChoose(pre => [...pre, "Rx"])
                        }
                        if (data.advice) {
                            SetChoose(pre => [...pre, "Advice"])
                        }
                        if (data.investigations_ids) {
                            SetChoose(pre => [...pre, "Test Requested"])
                        }

                    })

                })
            })
        }
        catch (error) {
            console.log(error)
        }

    }
    console.log(FormData)
    const data1 = [
        { label_name: 'complaints', options_list: ['FEVER', 'COUGH', 'COLD', 'VOMITING', 'LOOSE MOTION', 'ABDOMINAL PAIN', 'NASAL BLOCKAGE', 'REDUCED APETITE', 'FEVER', 'COUGH', 'COLD', 'VOMITING', 'LOOSE MOTION', 'ABDOMINAL PAIN', 'NASAL BLOCKAGE', 'REDUCED APETITE'] },
        { label_name: 'frequency', options_list: ['daily', 'alternate_day', 'weekly', 'twice_weekly', 'fortnight', 'monthly'] },
        { label_name: 'severity', options_list: ['mild', 'moderate', 'severe', 'profound'] },
        { label_name: 'duration', "type": "text", "name": "day", options_list: [] },
        { label_name: 'span', options_list: ['day', 'week', 'month', 'year'] },
        { label_name: 'date', "type": "text", "name": "date", options_list: [] }
    ];

    const data2 = [
        { label_name: 'diagnosis', options_list: ['FEVER', 'COUGH', 'COLD', 'VOMITING', 'LOOSE MOTION', 'ABDOMINAL PAIN', 'NASAL BLOCKAGE', 'REDUCED APETITE', 'FEVER', 'COUGH', 'COLD', 'VOMITING', 'LOOSE MOTION', 'ABDOMINAL PAIN', 'NASAL BLOCKAGE', 'REDUCED APETITE'] },
        { label_name: 'duration', "type": "text", "name": "day", options_list: [] },
        { label_name: 'span', options_list: ['day', 'week', 'month', 'year'] },
        { label_name: 'date', "type": "text", "name": "date", options_list: [] }
    ];

    const data3 = [
        { label_name: 'Medicine', name: 'medicine_id', options_list: ['Dolo 650', 'Paracitamol', 'Azithromicine'] },
        { label_name: 'Dose', name: 'dose_id', options_list: ['1-0-1', '1-1-1', '1-0-0', '0-1-1'] },
        { label_name: 'When', name: 'when_id', options_list: ['Before Breakfast', 'Before Lunch',] },
        { label_name: 'Frequency', name: 'frequency_id', options_list: ['Daily', 'Alternate Day'] },
        { label_name: 'Duration', name: 'duration_id', "type": "text", "name": "day", options_list: [] },
        { label_name: 'Span', name: 'span_id', options_list: ['day', 'week', 'month', 'year'] },
        { label_name: 'Notes', "type": "text", "name": "notes", options_list: [] }
    ];

    function getnmap(data) {
        let formatted_results = []
        data.map((item, idx) => {
            formatted_results.push({ 'label': item.code, 'value': item.id })
        })
        return formatted_results

    }
    const GetComplaints = async () => {
        try {
            const responce = await axiosConfig.get(`/masters/complaints/`)
            let complaints = [...Complaints]
            let complaintsdata = []
            responce.data.results.forEach(res => {
                complaintsdata.push({ 'label': res.name, 'value': res.id })
            })
            complaints[0].options_list = complaintsdata

            const resp = await axiosConfig.get(`/masters/referencedomainvalue/?domain__code=Frequency`)
            complaints[1].options_list = getnmap(resp.data.results)

            const resp2 = await axiosConfig.get(`/masters/referencedomainvalue/?domain__code=Severity`)
            complaints[2].options_list = getnmap(resp2.data.results)

            const resp3 = await axiosConfig.get(`/masters/referencedomainvalue/?domain__code=Span`)
            complaints[4].options_list = getnmap(resp3.data.results)
            setcomplaints(complaints)
        }
        catch (error) {
            console.log(error)
        }

    }
    const GetDiagnosis = async () => {
        try {
            const responce = await axiosConfig.get(`/masters/provisionaldiagnosis/`)
            let daignosis = [...Diagnosis]
            let daignosisdata = []
            responce.data.results.forEach(res => {
                daignosisdata.push({ 'label': res.name, 'value': res.id })
            })
            daignosis[0].options_list = daignosisdata
            setDiagnosis(daignosis)
            const resp3 = await axiosConfig.get(`/masters/referencedomainvalue/?domain__code=Span`)
            daignosis[2].options_list = getnmap(resp3.data.results)
            setDiagnosis(daignosis)
        }
        catch (error) {
            console.log(error)
        }

    }
    const GetVitals = async () => {
        try {
            const responce = await axiosConfig.get(`/masters/vitals/`)
            SetVitals(responce.data.results)
        }
        catch (error) {
            console.log(error)
        }

    }
    const GetRx = async () => {
        try {
            let RxData = [...Rx]
            const resp0 = await axiosConfig.get(`/masters/drug/`)
            let daignosisdata = []
            resp0.data.results.forEach(res => {
                daignosisdata.push({ 'label': res.name, 'value': res.id })
            })
            RxData[0].options_list = daignosisdata
            setRx(RxData)
            const resp2 = await axiosConfig.get(`/masters/referencedomainvalue/?domain__code=Dose`)
            RxData[1].options_list = getnmap(resp2.data.results)

            const resp3 = await axiosConfig.get(`/masters/referencedomainvalue/?domain__code=When`)
            RxData[2].options_list = getnmap(resp3.data.results)
            const resp4 = await axiosConfig.get(`/masters/referencedomainvalue/?domain__code=Frequency`)
            RxData[3].options_list = getnmap(resp4.data.results)
            const resp5 = await axiosConfig.get(`/masters/referencedomainvalue/?domain__code=Span`)
            RxData[5].options_list = getnmap(resp5.data.results)
            setRx(RxData)
        }
        catch (error) {
            console.log(error)
        }
    }
    const HandleChange = (e, id, name) => {
        const { value } = e.target
        const Index = FormData.vitals.findIndex(find => find.vital_id === id)
        if (Index === -1) {
            SetFormData(prevDoctor => ({
                ...prevDoctor,
                vitals: [...prevDoctor.vitals, { "vital_id": id, "value": value }], // Add the new schedule item to the schedule array
            }));
            if (name === 'Temperature') {
                SetChoose(pre => [...pre, "Vitals"])
            }
        }

        else {
            const UpdateData = FormData
            UpdateData.vitals[Index] = { "vital_id": id, "value": value }

        }

    }
    const HandleTextChange = (e) => {
        const { name, value } = e.target

        SetFormData({ ...FormData, [name]: value })
        if (!Choose.includes("Advice")) {
            SetChoose(pre => [...pre, name === "advice" ? "Advice" : ""])
        }
    }
    const GetInvistigations = async () => {
        try {
            const responce = await axiosConfig.get(`/masters/investigations/`)
            setInvestigations(responce.data.results)

        }
        catch (error) {
            console.log(error)
        }

    }
    const [selectedOptions, setSelectedOptions] = useState([]);

    const handleSelectChange = (selectedOption, name) => {
        setSelectedOptions(selectedOption);
        let data = []
        selectedOption && selectedOption.forEach(res => {
            data.push(res.value)
        })
        SetFormData({ ...FormData, ["investigations_ids"]: data })
        if (!Choose.includes("Test Requested")) {
            SetChoose(pre => [...pre, name === "investigations_ids" ? "Test Requested" : ""])
        }
    };
    const patientRcord = async () => {
        try {
            const responce = await axiosConfig.get(`/clinics/PatientRecord/?patient_id=${patient}`)
            console.log(responce.data.results)
            setPatientRecords(responce.data.results)
        }
        catch (error) {
            console.log(error)

        }
    }
    useEffect(() => {
        patientRcord();
        GetbilltData();
        GetRx();
        GetData();
        GetComplaints()
        GetDiagnosis();
        GetVitals();
        emr_summary();
        GetInvistigations();
    }, [])
    const HandleSubmit = async () => {
        try {
            const responce = await axiosConfig.post(`/clinics/bill-items/${id}/create_emr/`, { ...FormData, request_status: "Completed" })
            setpopupType("success")
        }
        catch (error) {
            console.log(error)
            setpopupType("error")
        }
    }
    const TemplateSubmit = async () => {
        try {
            const responce = await axiosConfig.post(`/clinics/bill-items/${id}/create_emr/`, { ...FormData, request_status: "InProgress" })
            setpopupType("success")
        }
        catch (error) {
            console.log(error)
            setpopupType("error")
        }
    }
    let dtaa = []
    const emr_summary = async () => {
        try {
            const responce = await axiosConfig.get(`/clinics/emr_summary/?patient_id=${patient}`)
            setPastVisits(responce.data)
        }
        catch (error) {
            console.log(error)

        }
    }
    const ExampleComponent = (time) => {
        const timestamp = time;
        const date = new Date(timestamp);
        const formattedDate = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
        return formattedDate
    }
    const HandleChnage = (e) => {
        console.log(e.target.value)
    }
    function formatDate(inputDate) {
        const months = [
            "January", "February", "March", "April", "May", "June", "July",
            "August", "September", "October", "November", "December"
        ];

        const date = new Date(inputDate);
        const year = date.getFullYear();
        const month = months[date.getMonth()];
        const day = date.getDate();
        const hour = date.getHours();
        const minute = date.getMinutes();
        const amOrPm = hour >= 12 ? "PM" : "AM";

        const formattedDate = `${month} ${day}, ${year}, ${hour % 12}:${minute.toString().padStart(2, '0')} ${amOrPm}`;

        return formattedDate;
    }
    const handlePrint = (index) => {
    const visitContent = document.getElementById(`past-visit-${index}`);
    const visit = document.getElementById(`patient-info`);

  if (visitContent && visit) {
    const printButton = visitContent.querySelector('.printButton');
    const userIcon=visit.querySelector(".user-icon")
    printButton.style.display = 'none';
    userIcon.style.display = 'none';
    const mergedContent = visit.innerHTML + visitContent.innerHTML;
    const printWindow = window.open('', '_blank');
    printWindow.document.write(`
    <html>
    <head>
      <link rel="stylesheet" type="text/css" href="Pages.css" media="print">
    </head>
    <body>
      ${mergedContent}
    </body>
    </html>
  `);
    printWindow.document.close();
    printWindow.print();

    printButton.style.display = 'block'; // or 'inline' if it's an inline element
    userIcon.style.display = 'block';
  }
}

    return (
        <>
            <DashboardLayout>
                <div className="mainbar">
                    <div className="sidebar-filters px-2">
                        <NavLink onClick={() => navigate(-1)} className="back-app"><BiArrowBack />Appointments</NavLink>
                        {
                            MastersModal.map((res, index) => {

                                return (
                                    <div key={index}>
                                        {
                                            res.NavItems.map((item, idx) => {
                                                return (
                                                    <div key={idx} className="Configiration-list1">
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <div className="">
                                                                <div onClick={() => HandleSmootScroll(item.label)}>
                                                                    <span className="configiration-icon">{item.icon}</span>
                                                                    <span className="list-name">{item.label}</span>
                                                                </div>
                                                            </div>
                                                            {idx < 6 && (
                                                                <div className="">
                                                                    {Choose.includes(item.label) ? <MdCheckCircle /> : <MdCheckCircleOutline />}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="main-code">
                        <div className="dashboard-header">
                            <h5 className="dashboard-subtitle">EMR {id}</h5>
                            <NavLink to="/dashboard/worklist" ><button className="btn btn-white">Manage</button></NavLink>
                        </div>
                        {/* <FormParser modelObject={MastersModal} formData={Data} formSubmit={formSubmit} error={Error}/> */}


                        
                                    <div className="clientbill-info" id="patient-info">
                                    <div className="patient-info">
                                        <div className="lf">
                                            <FaRegUserCircle className="user-icon" />
                                        </div>
                                        <div className="rs">
                                            <p>{PataientData.title} {PataientData.patient_name}</p>
                                            <p>{PataientData.age} yrs, {PataientData.gender},  {PataientData.mobile_no} </p>
                                        </div>
                                        {status === "Scheduled" || status === "InProgress" ?<div className="end">
                                            <button className="btn btn-danger" onClick={HandleSubmit}>End Consultation</button>
                                        </div>:""}
                                    </div>
                                </div>
                                
                            {status === "Scheduled" || status === "InProgress" ?
                                    <div className="complaints-container">
                                        <div className="vitals-section mb-3">
                                            <h3>Vitals</h3>
                                            <div className="row">
                                                {

                                                    Vitals.map(vitals => {
                                                        let find = vitalvalue.find(val => val.vital === vitals.id)
                                                        return (
                                                            <div className="col-md-4" id="Vitals">
                                                                <label>{vitals.name}</label>
                                                                <span class="hx-vitalFields d-flex">
                                                                    <input className="form-control  vital-input " type="text" defaultValue={find && find.vital_value} onChange={(e) => HandleChange(e, vitals.id, vitals.name)} />
                                                                    <span class="hx-vitalUnit">{vitals.measurement}</span>
                                                                </span>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                        <EMRinput rw_data={Complaints} title="Complaints" showData={getFormcomp.complaints || []} FormData={FormData} SetFormData={SetFormData} subtitle="complaints" id="Complaints" SetChoose={SetChoose} Choose={Choose} />
                                        <EMRinput rw_data={Diagnosis} title="Diagnosis" showData={getFormdignosis.diagnosis || []} FormData={FormData} SetFormData={SetFormData} subtitle="diagnosis" id="Diagnosis" SetChoose={SetChoose} Choose={Choose} />
                                        <EMRinput rw_data={Rx} title="Rx" FormData={FormData} showData={getFormRx.rx || []} SetFormData={SetFormData} subtitle="rx" id="Rx" SetChoose={SetChoose} Choose={Choose} />
                                        {/* <EMRinput rw_data={Inesigations} title="Investigations" /> */}



                                        <label>Advice</label>
                                        <textarea className="form-control" name="advice" onChange={HandleTextChange} defaultValue={FormData.advice} id="Advice"></textarea>

                                        <div className="form-group" id="Test Requested">
                                            <label className="form-label">Test Required:</label>
                                            <Select isMulti options={Inesigations.map((res) => (
                                                { "value": res.id, "label": res.name }
                                            ))}
                                                value={Inesigations.map((res) => (
                                                    { "value": res.id, "label": res.name }
                                                )).filter(res => Array.isArray(FormData.investigations_ids) && FormData.investigations_ids.includes(res.value))}
                                                onChange={(selectedOption) => handleSelectChange(selectedOption, "investigations_ids")} />
                                        </div>
                                        <div className="template">
                                            <input type="text" className="form-control w-25" placeholder="Template Name" />
                                            <button className="btn btn-success">Save As Template</button>
                                        </div>
                                        <button className="btn btn-success save-button" onClick={TemplateSubmit}>Save</button>
                                    </div>:""}
                                    <h3 className="timeline-pastvisits">Past Visits</h3>
                                    <div className="timeline" id="Past Visits" >
                                        {
                                            PastVisits.map((item, index) => {
                                                return (
                                                    <div key={index} id={`past-visit-${index}`} >
                                                        <div class={index % 2 === 0 ? 'past left' : 'past right'}>
                                                            <div className="content">
                                                                <div className="emr-count-main">
                                                                <div className="d-flex justify-content-between">
                                                                    <div>
                                                                        <p><MdDateRange className="ico-emr" />{formatDate(item.emr_completed_date)}</p>
                                                                        <p><MdMedication className="ico-emr" />{item.doctor_name}</p>
                                                                        <p><BsPersonCircle className="ico-emr" />{item.speciality}</p>
                                                                    </div>
                                                                    <div className="text-center printButton">
                                                                        <BsFillPrinterFill className="ico-emr" onClick={()=>handlePrint(index)} />
                                                                        <p className="form-label" >Print</p>
                                                                    </div>
                                                                </div>
                                                                </div>
                                                                <div className="time-content-head">
                                                                <h5 className="dashboard-subtitle">Vitals:</h5>
                                                                <div className="row time-content">
                                                                    {item.vitals.map((event, eventIndex) =>
                                                                    (
                                                                        
                                                                        <div key={eventIndex} className="col-4">
                                                                            <p>{event.vital_name}:{event.vital_value}{event.measurement}</p>
                                                                        </div>
                                                                      
                                                                    ))}
                                                                   
                                                                </div>
                                                                </div>
                                                                <div  className="time-content-head">
                                                                <h5 className="dashboard-subtitle">Complaints:</h5>
                                                                <ul>
                                                                    {item.complaints.map((event, eventIndex) => (

                                                                        <li  key={`complaints-${index}-${eventIndex}`}>{event.complaint_name}-{event.frequency_name}-{event.severity_name}-{event.duration}{event.span_name}'s</li>

                                                                    ))}
                                                                </ul>
                                                                </div>
                                                                <div  className="time-content-head">
                                                                <h5 className="dashboard-subtitle">Diagnosis:</h5>
                                                                <ul>
                                                                    {item.diagnosis.map((event, eventIndex) => (
                                                                        <li key={`diagnosis-${index}-${eventIndex}`}>{event.diagnosis_name}-{event.duration}{event.span_name}'s</li>
                                                                    ))}
                                                                </ul>
                                                                </div>
                                                                <div  className="time-content-head">
                                                                <h5 className="dashboard-subtitle">Rx:</h5>
                                                                <ol>
                                                                    {item.rx.map((event, eventIndex) => (
                                                                        <div key={`diagnosis-${index}-${eventIndex}`}>
                                                                            <li>{event.drug_name}-{event.dose_name}-{event.when_name}-{event.frequency_name}-{event.duration}{event.span_name}'s</li>
                                                                        </div>
                                                                    ))}
                                                                </ol>
                                                                </div>
                                                                <div  className="time-content-head">
                                                                <h5 className="dashboard-subtitle">Advice:</h5>
                                                                <div className="advice-emr">
                                                                    <p>
                                                                        {item.advice}</p>
                                                                </div>
                                                                </div>
                                                                <div  className="time-content-head">
                                                                <h5 className="dashboard-subtitle">Tests Required:</h5>
                                                                <ol>
                                                                    {
                                                                        item.investigations_name.map(res => {
                                                                            return (
                                                                                <li>{res}</li>
                                                                            )
                                                                        })
                                                                    }
                                                                </ol>
                                                                </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className="row my-4 mx-1" id="Records">
                                        {
                                            PatientRecords.map((res, i) => {
                                                console.log(res)
                                                return (
                                                    <div key={i}>
                                                        <div className="col-2">
                                                            <div className="card">
                                                                <img className="card-img-top" src={res.file} alt="Card Image" />
                                                                <div className="card-body text-center">
                                                                    <h5 className="card-title">{ExampleComponent && ExampleComponent(res.date)}</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>


                    </div>
                </div>
                {popupType != "" &&
                    <PopupModal popupType={popupType} Navigation="/dashboard/worklist" />
                }
            </DashboardLayout>
        </>
    )

}
export { Emr }
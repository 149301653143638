import React, { useEffect, useState } from "react";
import { Layout, DeletePopupModal } from "../Components";
import { NavLink, useNavigate } from "react-router-dom";
import axiosConfig from "../Service/axiosConfig"
import {MdDeleteOutline,MdOutlineEdit} from "react-icons/md"
import {AiOutlineEye} from "react-icons/ai"
import DataTable from 'react-data-table-component';
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import tableCustomStyles from "../Components/PopupModal/tableCustomStyles";
const ComplaintsList = () => {
    const [visible, SetVisable] = useState([])
    const[action,setaction]=useState(0)
    const[action2,setaction2]=useState(0)
    const[action3,setaction3]=useState(0)
    const [loading, setLoading] = useState(false);
    const [deletePopupType, setDeletepopupType] = useState("")
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("")
    const [RowId, SetRowId] = useState()
    const [Datafilter, SetDatafilter] = useState([])
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(20);
    const [search, SetSearch] = useState({
        search: ""
    })
    let navigate = useNavigate();

    const [Data, SetData] = useState([])
    const GetData = async () => {
        setLoading(true)
        try {
            const responce = await axiosConfig.get(`/masters/complaints/?page=${currentPage}&page_size=${RowPerPage}`)
            SetData(responce.data.results)
            SetDatafilter(responce.data.results)
            setLoading(false)
            setTotalRows(responce.data.count)
        }
        catch (error) {
            console.log(error)
        }

    }
    useEffect(() => {
        GetData();
    }, [currentPage,RowPerPage])

    const HandleSearch = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        console.log(name, value)
        SetSearch({ ...search, [name]: value })
        axiosConfig.get(`/masters/complaints/?search=${value}`).then(res=>{
            SetData(res.data.results)
        }).catch(error=>{
           console.log(error)
        })
    }
    const handleSort = (column, direction) => {
        console.log(column.selector,direction)
         axiosConfig.get(`/masters/complaints/?ordering=${direction==="asc"?column.selector:-column.selector}`).then(res=>{
             SetData(res.data.results)
         }).catch(error=>{
            console.log(error)
         })
         setCurrentPage(1);
      };
    const columns = [
        {
            name: "Id",
            selector:"id",
            sortable: true,
        },
        {
            name: "Code",
            selector:"code",
            sortable: true,
        },
        {
            name: "Name",
            selector: "name",
            sortable: true,
        },
        {
            name: "ACTIONS",
            selector: (row) => {
                const HandleEdit = async () => {
                    return navigate(`/dashboard/masters/complaints/${row.id}/edit`);
                }
                const DeleteAlert = () => {
                    SetRowId(row.id)
                    SetAlertDeletePopupType("success")
                }
                const CancelAlerrt = () => {
                    SetAlertDeletePopupType("error")
                }
                const HandleDelete = async (id) => {
                    await axiosConfig.delete(`/masters/complaints/${id}/`).then(() => {
                        setDeletepopupType("success")
                        SetAlertDeletePopupType("error")
                        GetData()
                    }).catch((error) => {
                        console.log(error)
                        setDeletepopupType("error")
                        SetAlertDeletePopupType("error")
                    })
                }
                const HandleView = async (id) => {
                    return navigate(`/dashboard/masters/complaints/${row.id}/edit`);
                }
                return (
                    <>
                         <div className="row" >
                            <div className="col-4 text-center">
                            <AiOutlineEye className="dashboard-tabe-data-actions"  data-toggle="tooltip" data-placement="right"  title="view"  onClick={HandleView}/>
                            </div>
                            <div className="col-4">
                                <MdOutlineEdit className="dashboard-tabe-data-actions"  data-toggle="tooltip" data-placement="right"  title="Edit" onClick={HandleEdit}/>
                            </div>
                            <div className="col-4">
                                <MdDeleteOutline className="dashboard-tabe-data-actions"  data-toggle="tooltip" data-placement="right"  title="Delete" onClick={DeleteAlert}/>
                            </div>
                        </div>
                        {
                            AlertDeletePopupType === "success" &&
                            <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} HandleDelete={HandleDelete} CancelAlerrt={CancelAlerrt} row={RowId} />
                        }
                    </>
                )

            },
        },
    ]
    return (
        <>
            <Layout>
                <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">View Complaints</h5>
                    <NavLink to="/dashboard/masters/complaints/add" ><button className="btn btn-white">Add New</button></NavLink>
                </div>
                <div className="table-responsive">
                    <DataTable columns={columns} data={Data} pagination paginationTotalRows={totalRows}
                        paginationPerPage={RowPerPage}
                        paginationDefaultPage={currentPage}
                        onChangePage={(page) => setCurrentPage(page)}  
                        paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
                        paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
                        onChangeRowsPerPage={(RowsPerPageChange)=>setRowPerPage(RowsPerPageChange)}  
                        onSort={handleSort} 
                        paginationServer fixedHeader customStyles={tableCustomStyles} subHeader progressPending={loading}
                        progressComponent={<div className="loader-container">
                        <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                    </div>}
                        subHeaderComponent={
                            <input type="text" className="form-control w-25" name="search" value={search.search} onChange={HandleSearch} />} />
                </div>
                { Data.length>0&&<div className="f-copy">
                <p><span className="copy-sym">&copy;</span> Pride Product of Apollo Radiology International. All Copy Rights Reserved 2023
                </p>
            </div>}
                {deletePopupType != "" &&
                    <DeletePopupModal setDeletepopupType={setDeletepopupType} deletePopupType={deletePopupType} />
                }
            </Layout>
        </>
    )
}
export { ComplaintsList }
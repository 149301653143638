import React, { useEffect, useState } from "react";
import { Layout, FormParser,PopupModal, DashboardLayout } from "../Components";
import { NavLink,useParams } from "react-router-dom";
import Masters from "../Components/masters.json"
import "./Pages.css"
import axiosConfig from "../Service/axiosConfig"
import sidebarData from "../Components/DashboardLayout/sidebar";
const ClinicInfoEdit = () => {
    let { id } = useParams();
    const[Error,SetError]=useState("")
    const[popupType,setpopupType]=useState("")
    const[ChildNavs,SetChildNavs]=useState("")
    const[activeState,setActiveState]=useState("")
    let data=localStorage.getItem("user_data")
    let clinicid=JSON.parse(data).clinic_id
     const Sidebaravalue=sidebarData.clinicProfile
    const MastersModal=Masters.ClinicInformation
    const formSubmit = (Data) => {
        axiosConfig.defaults.headers['Content-Type'] = 'multipart/form-data'; 
        const formData = new FormData();
        formData.append('logo', Data.logo);
        formData.append('about', Data.about);
        let data=[]
        Data.facilities.forEach((option) => {
            formData.append("facilities",option)
        });
            axiosConfig.patch(`/clinics/clinicinfos/${clinicid}/`, formData).then(res => {
                console.log(res)
                setpopupType("success")
            }).catch(error => {
                console.log(error)
                let ErrorData=[]
                ErrorData.push({
                    code:error.response.data.code||["null"],
                    name:error.response.data.name||["null"],
                    })
                    console.log(ErrorData[0])
            if(ErrorData[0].code[0]==="This field must be unique."){
                SetError("Code Already Exists")
            }
            if(ErrorData[0].name[0]==="This field must be unique."){
                SetError("Name Already Exists")
            }
                setpopupType("error")
            })
    }
    const [Data, SetData] = useState([])
    const GetData = async () => {
        try {
            const responce = await axiosConfig.get(`/clinics/clinicinfos/${clinicid}/`)
            SetData(responce.data)
        }
        catch (error) {
            console.log(error)
            
        }

    }
    const OnChildNavClicked=(path)=>{
        SetChildNavs(path)
        localStorage.setItem("url",path)
    }
    useEffect(() => {
        GetData();
        SetChildNavs(localStorage.getItem("url"))
    }, [])
    const currentURL = window.location.href;
    console.log(currentURL)
    return (
        <>
            <DashboardLayout>
            <div className="mainbar">
                    <div className="sidebar-filters px-2">
                    {
                            Sidebaravalue.map((childItem, index) => {
                                return(                                                    
                                    <div key={index}>
                                        <h5>{childItem.SubTitle}</h5>                        
                                            {
                                                childItem.NavItems.map((item,idx)=>{
                                                    return(
                                                        <div key={idx} className={ ChildNavs==item.path ? "Configiration-list active" : "Configiration-list"}>
                                                         <NavLink to={item.path} className={ChildNavs==item.path?"colorChange":"NoColorChange"}  onClick={() => OnChildNavClicked(item.path)}> 
                                                              <span className="configiration-icon">{item.icon}</span>
                                                            <span className="list-name">{item.label}</span>
                                                        </NavLink>
                                                    </div>
                                                    )
                                                })
                                            }
                                    </div>
                                )
                            })
                        }
                        </div>
                    <div className="main-code">
                    <div className="dashboard-header">
                    <h5 className="dashboard-subtitle">clinicInfo</h5>
                    </div>
                      <FormParser modelObject={MastersModal} formData={Data} formSubmit={formSubmit} error={Error}/>
                        </div>
                        </div>
                {popupType != "" &&
                    <PopupModal popupType={popupType} Navigation=""/>
                }
            </DashboardLayout>
        </>
    )

}
export {ClinicInfoEdit}
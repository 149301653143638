import React, { useEffect, useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { DashboardLayout, Pagination, FormParser, PopupModal } from "../Components";
import { FaRegUserCircle } from "react-icons/fa";
import { BiArrowBack } from "react-icons/bi";
import axiosConfig from "../Service/axiosConfig";
import { MdOutlineMedicalInformation, MdListAlt, MdMeetingRoom, MdHistory } from "react-icons/md"
import { FaFileInvoice } from "react-icons/fa"
import Masters from "../Components/masters.json"
import sidebarData from "../Components/DashboardLayout/sidebar";
import DataTable from 'react-data-table-component';
import { useParams } from "react-router-dom";
import { CreateAppointment } from "./AppointmentsCreate"
const PatientDetails = () => {
    const [Appointment, setAppointment] = useState("")
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [patientData, setPatientData] = useState([])
    const [invoices, setInvoices] = useState([])
    const [records, setRecords] = useState([])
    const [appointments, setAppointments] = useState([])
    const [emrSummary, setEMRSummary] = useState([])
    const [ChildNavs, SetChildNavs] = useState("")
    const { uhid } = useParams();
    const[patient,setpatient]=useState({})
    const [Error, SetError] = useState("")
    const [popupType, setpopupType] = useState("");

    const MastersModal = Masters.PataientRegestration
    const formSubmit = (Data) => {
        console.log(Data)
        axiosConfig.patch(`/clinics/patient/${patientData.id}/`, Data).then(res => {
            console.log(res)
            setpopupType("success")
        }).catch(error => {
            console.log(error.response.data)
            const ErrorData = []
            ErrorData.push({
                code: error.response.data.code || ["null"],
                name: error.response.data.name || ["null"],
            })
            console.log(ErrorData[0])
            if (ErrorData[0].code[0] === "This field must be unique.") {
                SetError(" code already exists.")
            }
            if (ErrorData[0].name[0] === "This field must be unique.") {
                SetError("Tittle already Exists")
            }
            setpopupType("error")
        })
    }
    const patient_profile = [
        {
            "SubTitle": "Patient Profile",
            "NavItems": [
                { "label": "Info", "path": `/dashboard/patients/${uhid}/info`, "icon": <MdOutlineMedicalInformation fontSize='small' /> },
                { "label": "Invoices", "path": `/dashboard/patients/${uhid}/invoices`, "icon": <FaFileInvoice fontSize='small' /> },
                { "label": "Records", "path": `/dashboard/patients/${uhid}/records`, "icon": <MdListAlt fontSize='small' /> },
                { "label": "Appointments", "path": `/dashboard/patients/${uhid}/appointments`, "icon": <MdMeetingRoom fontSize='small' /> },
                { "label": "Visit History", "path": `/dashboard/patients/${uhid}/visit_history`, "icon": <MdHistory fontSize='small' /> },
            ]
        }
    ]

    const GetPatientData = async () => {
        setLoading(true)
        try {
            const responce = await axiosConfig.get(`/clinics/patient/?uhid=${uhid}`)
            console.log(...responce.data.results, "patient data")
            setPatientData(...responce.data.results)
            setLoading(false)
        }
        catch (error) {
            console.log(error)
        }
    }

    const OnChildNavClicked = (path) => {
        SetChildNavs(path)
        localStorage.setItem("url", path)
    }
    const AppointmentPopup=(service_type)=>{
         setpatient({patient:patientData.id,service_type:service_type})
        setAppointment("success")
    }

    useEffect(() => {
        GetPatientData()
    }, [])

    return (
        <DashboardLayout>
            <div className="mainbar">
                <div className="sidebar-filters px-3" style={{ height: "100vh" }}>
                    <button className="btn btn-primary mb-3" onClick={() => navigate(`/dashboard/patients/registration`)}><BiArrowBack />back to patients</button>
                    {
                        patient_profile.map((res, index) => {
                            return (
                                <div key={index}>
                                    <h5>{res.SubTitle}</h5>
                                    {
                                        res.NavItems.map((item, idx) => {
                                            return (
                                                <div key={idx} className={ChildNavs == item.path ? "Configiration-list active" : "Configiration-list"}>
                                                    <NavLink to={item.path} className={ChildNavs == item.path ? "colorChange" : "NoColorChange"} onClick={() => OnChildNavClicked(item.path)}>
                                                        <span className="configiration-icon">{item.icon}</span>
                                                        <span className="list-name">{item.label}</span>
                                                    </NavLink>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            )
                        })
                    }
                </div>
                <div className="main-code">
                    <div className="clientbill-info">
                        <div className="patient-info d-flex justify-content-between">
                            <div className="lf">
                                <FaRegUserCircle className="user-icon" />
                            </div>
                            <div className="rs">
                                {patientData.length > 0 && (
                                    <>
                                        <p>{patientData[0].title} {patientData[0].name}</p>
                                        <p>{patientData[0].patient_id} {patientData[0].age} | {patientData[0].gender} | {patientData[0].mobile_no} </p>
                                    </>
                                )}
                            </div>
                            <div>
                                <button className="btn btn-primary" onClick={()=>AppointmentPopup("appointment")}>Create Appointment</button>
                                <button className="btn btn-primary mx-4" onClick={()=>AppointmentPopup("Procedure") }>Create procedure</button>
                                <button className="btn btn-primary" onClick={()=>AppointmentPopup("service") }>Create Service</button>
                            </div>
                        </div>
                    </div>
                    <FormParser modelObject={MastersModal} formData={patientData} formSubmit={formSubmit} error={Error} />
                    {popupType != "" &&
                        <PopupModal popupType={popupType} Navigation="#" />
                    }
                    {
                        Appointment === "success" &&
                        <CreateAppointment setAppointment={setAppointment} Appointment={Appointment} doctor={patient}  />
                    }
                </div>
            </div>
        </DashboardLayout>
    )
}

export { PatientDetails }